import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import {
  AsyncSelect,
  Breakpoints,
  Icon,
  IconType,
  SelectOption,
  SelectableTagList,
} from '@ateams/components';
import AnswerScoreRow, {
  AnswerScoreRowProps,
} from '@src/components/AnswerScore';
import { Expertise } from '@src/stores/Profile/models';
import { useScreenClass } from 'react-grid-system';
import useDebounceState from '@src/hooks/useDebounceState';
import { sortBy } from 'lodash';
import { createUseStyles } from 'react-jss';
import { isNonNullable } from '@src/helpers/sort';

interface Props {
  onSearch: (query: string) => Promise<Expertise[]>;
  hideSearch?: boolean;
  onChange: (items: Expertise[]) => void;
  selectedItems?: Expertise[];
  displayType?: 'score' | 'tags';
  limitAnswersHeight?: boolean;
  withFeaturedCheckbox?: boolean;
  disableFeatured?: boolean;
  singleSelection?: boolean;
  sectionTitle?: string;
  placeholder?: string;
  defaultBgColor?: string;
  defaultRating?: Expertise['rating'];
  defaultFeatured?: boolean;
  closeMenuOnSelect?: boolean;
  setFeaturedOnScores?: number[];
  featuredTooltip?: string;
  defaultMenuIsOpen?: boolean;
  scoreTooltips?: AnswerScoreRowProps['scoreTooltips'];
  className?: string;
  style?: CSSProperties;
  suggestedType?: 'skills' | 'industries' | 'roles';
  blockSkillsWhenNotRated?: boolean;
}

const defaultProps = {
  displayType: 'score',
  singeSelection: false,
  withFeaturedCheckbox: true,
  closeMenuOnSelect: true,
  limitAnswersHeight: true,
  hideSearch: false,
};

const useStyles = createUseStyles({
  sectionTitle: {
    marginBottom: 12,
    marginTop: 40,
  },
  answersContainer: {
    height: (props: Props) => (props.limitAnswersHeight ? '45vh' : 'auto'),
    marginBottom: (props: Props) =>
      props.limitAnswersHeight ? 'auto' : '30px',
    overflow: 'auto',
    transform: 'scale(1)',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    answersContainer: {
      height: (props: Props) => (props.limitAnswersHeight ? 370 : 'auto'),
    },
  },
});

const ExpertiseSelector = (props: Props): ReactElement => {
  const {
    onSearch,
    hideSearch,
    onChange,
    selectedItems,
    displayType,
    withFeaturedCheckbox,
    disableFeatured,
    singleSelection,
    sectionTitle,
    placeholder,
    defaultBgColor,
    defaultRating,
    defaultMenuIsOpen,
    defaultFeatured,
    closeMenuOnSelect,
    setFeaturedOnScores,
    featuredTooltip,
    scoreTooltips,
    className,
    style,
    suggestedType,
    blockSkillsWhenNotRated,
  } = props;

  const styles = useStyles(props);
  const screenClass = useScreenClass();
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Expertise[]>([]);
  const [newlyAddedItems, setNewlyAddedItems] = useState<SelectOption[]>([]);
  const [localItems, setLocalItems] = useState<Expertise[]>(
    suggestedType === 'roles'
      ? []
      : selectedItems?.map((item) => {
          return { ...item, rating: item.rating ?? defaultRating };
        }) || [],
  );

  useEffect(() => {
    onChange(localItems);
  }, [localItems]);

  useEffect(() => {
    if (displayType === 'tags' && hideSearch) {
      setSearchResults(selectedItems || []);
    }
    if (displayType === 'tags' && !hideSearch) {
      onSearch('').then((res) => {
        setSearchResults(res);
      });
    }
  }, []);

  const [query, setQuery] = useDebounceState(
    '',
    (query) => {
      setLoading(true);

      onSearch(query).then((res) => {
        setSearchResults(res);
        setLoading(false);
      });
    },
    200,
  );

  const localItemIds = useMemo(() => {
    return localItems.map((item) => item.id);
  }, [localItems]);

  const searchResultsIds = useMemo(() => {
    return searchResults.map((item) => item.id);
  }, [searchResults]);

  const localItemsQueryResult = useMemo(() => {
    return localItems.filter((item) => searchResultsIds.includes(item.id));
  }, [localItems, searchResults]);

  const searchItemsQueryResult = useMemo(() => {
    return searchResults.filter((item) => !localItemIds.includes(item.id));
  }, [localItemIds, searchResults]);

  const itemResults = useMemo(() => {
    let results = sortBy(
      displayType === 'tags'
        ? [...localItemsQueryResult, ...searchItemsQueryResult]
        : localItems,
      'name',
    );
    if (blockSkillsWhenNotRated) {
      const newItems = newlyAddedItems
        .map((item) => results.find((res) => res.id === item.value))
        .filter(isNonNullable);

      results = [
        ...newItems,
        ...results.filter(
          (item) =>
            !newlyAddedItems.find((newItem) => newItem.value === item.id),
        ),
      ];
    }
    return results;
  }, [
    query,
    localItems,
    searchResults,
    newlyAddedItems,
    blockSkillsWhenNotRated,
  ]);

  const onTagSelect = (tag: string, selected: boolean) => {
    const existingItem = itemResults.find((item) => item.name === tag);
    existingItem &&
      setItemData({
        ...existingItem,
        featured: selected,
      });
  };

  const setItemData = (item: Expertise) => {
    let list = [...localItems];
    if (singleSelection) {
      list = list.map((item) => {
        return { ...item, featured: false };
      });
    }
    const existingLocalItem = list.findIndex(
      (existingItem) => existingItem.id === item.id,
    );
    if (existingLocalItem !== -1) {
      setLocalItems(list);
      if (suggestedType === 'roles') {
        setLocalItems(
          localItems.filter((localItems) => localItems.id !== item.id),
        );
      } else {
        list[existingLocalItem] = item;
        setLocalItems(list);
      }
    } else {
      const existingSearchItem = searchResults.findIndex(
        (existingItem) => existingItem.id === item.id,
      );
      if (existingSearchItem !== -1) {
        setLocalItems([...list, item]);
      }
    }
  };

  const getResults = async (query: string): Promise<SelectOption[]> => {
    const res = await onSearch(query);
    return res.map((item) => {
      const existingLocalItem = localItems.find(
        (localItem) => item.id === localItem.id,
      );
      return {
        ...item,
        ...existingLocalItem,
        value: item.id,
        label: item.name,
      };
    });
  };

  return (
    <div className={className} style={style}>
      {sectionTitle && <h3 className={styles.sectionTitle}>{sectionTitle}</h3>}
      {!hideSearch && (
        <>
          {displayType === 'tags' ? (
            <OutlinedInput
              placeholder={placeholder || 'Search...'}
              value={query}
              loading={loading}
              onChange={(e) => setQuery(e.target.value)}
              endAdornment={
                <Icon type={IconType.Search} muted size={'exact'} />
              }
            />
          ) : (
            <AsyncSelect
              isMulti={true}
              closeMenuOnSelect={closeMenuOnSelect}
              hideTags
              defaultMenuIsOpen={defaultMenuIsOpen}
              placeholder={placeholder || 'Search...'}
              loadOptions={getResults}
              defaultValue={localItems.map((item) => {
                return {
                  ...item,
                  label: item.name,
                  value: item.id,
                };
              })}
              onChange={(items) => {
                if (items) {
                  // Items added
                  const addedItems = items.filter(
                    (item: SelectOption) =>
                      !localItems.find(
                        (localItem) => item.value === localItem.id,
                      ),
                  );

                  // Items removed
                  const removedItems = localItems.filter(
                    (localItem) =>
                      !items.find(
                        (item: SelectOption) => item.value === localItem.id,
                      ),
                  );

                  // Update localItems
                  setLocalItems(
                    items.map((item: SelectOption) => {
                      const existingLocalItem = localItems.find(
                        (localItem) => item.value === localItem.id,
                      );
                      return {
                        ...existingLocalItem,
                        id: item.id,
                        name: item.name,
                        rating: existingLocalItem?.rating || defaultRating,
                        ...(!existingLocalItem &&
                          defaultFeatured !== undefined && {
                            featured: defaultFeatured && !disableFeatured,
                          }),
                      };
                    }),
                  );

                  // Update newlyAddedItems
                  setNewlyAddedItems((prev) => {
                    let updatedItems = [...prev];

                    // Remove the items
                    removedItems.forEach((item) => {
                      updatedItems = updatedItems.filter(
                        (addedItem) => addedItem.value !== item.id,
                      );
                    });

                    // Add the items
                    addedItems.forEach((item: SelectOption) => {
                      updatedItems.unshift(item);
                    });

                    return updatedItems;
                  });
                } else {
                  setNewlyAddedItems([]);
                }
              }}
            />
          )}
        </>
      )}

      <div className={styles.answersContainer}>
        {displayType === 'tags' ? (
          <SelectableTagList
            color="tag"
            selectedColor="success"
            selectedItems={localItems
              .filter((item) => item.featured)
              .map((item) => item.name)}
            items={itemResults.map((item) => item.name)}
            onChange={onTagSelect}
          />
        ) : (
          itemResults &&
          itemResults?.length > 0 &&
          itemResults.map((item) => (
            <AnswerScoreRow
              key={item.id}
              item={item}
              onChange={setItemData}
              minLabel="No expertise"
              maxLabel="Deep expertise"
              bgColor={screenClass === 'xs' ? '#F7F7F7' : defaultBgColor}
              setFeaturedOnScores={setFeaturedOnScores}
              withFeaturedCheckbox={withFeaturedCheckbox}
              featuredTooltip={featuredTooltip}
              scoreTooltips={scoreTooltips}
              disableFeatured={disableFeatured}
            />
          ))
        )}
      </div>
    </div>
  );
};

ExpertiseSelector.defaultProps = defaultProps;
export default ExpertiseSelector;
