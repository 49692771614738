import { useAnalytics } from '@ateams/analytics/dist/platform';
import useLoadingState from '@src/hooks/useLoadingState';
import { MissionPageLocation } from '@src/locations';
import { useStores } from '@src/stores';
import { ApplicationStep } from '@src/stores/Application';
import { useHistory } from 'react-router-dom';

export const useApplicationNavbarActions = () => {
  const { users, auth, missions, missionControl } = useStores();
  const analytics = useAnalytics();
  const { profile } = users;

  const history = useHistory();

  let applied = null;
  if (typeof window === 'object') {
    const queryParams = new URLSearchParams(window.location.search);
    applied = queryParams.get('applied');
  }

  const isViewModeOnly = missions.isViewModeOnly;

  const isAppliedQueryParam = applied !== null && applied === 'true';

  const [loading, setLoading] = useLoadingState();

  const isRecommendationsStep =
    !isAppliedQueryParam && !!profile?.application?.isRecommendationsStep;
  const isInterviewAvailabilityStep =
    !isAppliedQueryParam && !!profile?.application?.isInterviewAvailabilityStep;

  const isNewConnectRecommendations = isRecommendationsStep;

  const handleSkipPostApplicationSuggestions = () => {
    profile?.application?.setShowSkipPostApplicationSuggestionsModal(true);
  };

  const submitApplication = async () => {
    const cta = profile?.cta;
    await cta?.callback();
    await missionControl.loadAppliedToMissions(false);
  };

  const handleSubmit = () => {
    const cta = profile?.cta;
    const missionId = missions.currentMission?.data.mid;
    const roleId = profile?.application?.rid;
    if (auth.uid && missionId && roleId) {
      analytics.trackApplicationCompleted({
        missionId,
        userId: auth.uid,
        roleId,
      });
    }
    cta && setLoading(submitApplication(), cta.confirmation || null);
  };

  const onBackArrowClick = () => {
    if (isAppliedQueryParam || isViewModeOnly) {
      history.goBack();
      return;
    }
    profile?.application?.mid &&
      history.push(MissionPageLocation(profile.application.mid));
  };

  const isDetailedMenuOpen = !!profile?.application?.detailsMenuOpen;

  const onDetailsMenuClick = () => {
    profile?.application?.setDetailsMenuOpen(
      !profile.application.detailsMenuOpen,
    );
  };

  const showDropdown =
    users.isCurrentUser || auth.isAdmin || auth.canSwitchUser;

  const hideMissionTitle =
    users.profile?.application?.step === ApplicationStep.Application;

  const companyLogo = missions.currentMission?.data.logoURL;
  const companyTitle = missions.currentMission?.data.title;

  return {
    isRecommendationsStep,
    isNewConnectRecommendations,
    isInterviewAvailabilityStep,
    loading,
    isDetailedMenuOpen,
    companyLogo,
    companyTitle,
    profile,
    showDropdown,
    hideMissionTitle,
    isViewModeOnly,
    onBackArrowClick,
    handleSkipPostApplicationSuggestions,
    handleSubmit,
    onDetailsMenuClick,
  };
};
