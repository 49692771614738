import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
  Tag,
} from '@ateams/components';

import AdminNotesObject, {
  AdminNotesScore,
  ScoreLabels,
  ScoreType,
  ScoreTypeLabels,
} from '@a_team/models/dist/AdminNotesObject';

interface Props {
  scores: AdminNotesObject['scores'];
}

const useStyles = createUseStyles({
  '@global': {
    '.tippy-popper': {
      zIndex: '999999 !important',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: Colors.backgroundLight,
    borderRadius: 5,
    padding: Spacing.large,
    paddingTop: 0,
  },
  title: {
    fontSize: FontSizes.large,
    fontWeight: FontWeights.medium,
    color: Colors.dark,
  },
  row: {
    display: 'flex',
  },
  col: {
    display: 'flex',
    alignItems: 'center',
    marginRight: Spacing.medium,
  },
  tag: {
    marginLeft: Spacing.small,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    wrapper: {
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: 0,
    },
  },
});

export const EvaluationScoresBanner = ({ scores }: Props): ReactElement => {
  const styles = useStyles();

  if (!scores || Object.values(scores).every((score) => score === undefined))
    return <></>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <h4 className={styles.title}>Evaluation scores</h4>
        <div className={styles.row}>
          {(Object.entries(scores) as [ScoreType, AdminNotesScore][]).map(
            ([key, value]) => (
              <div key={key} className={styles.col}>
                <span className={styles.label}>{ScoreTypeLabels[key]}</span>
                <Tag
                  fontSize="medium"
                  color={'backgroundWhite'}
                  square
                  borderColor="regular"
                  className={styles.tag}
                >
                  {ScoreLabels[key][value]}
                </Tag>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};
