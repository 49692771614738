import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  VettingProcessAudit,
  VettingProcessAuditTypes,
} from '@a_team/models/dist/vetting-process-audit';
import { ActivityRecordMainText, ActivityRecordUser } from '.';
import { Button } from '@src/views/VettingFeedbackForm/components/button';
import useToggle from '@src/hooks/useToggle';
import { PreVettingAnswersModal } from '@src/components/Modal/PreVettingAnswersModal';
import { Spacing } from '@ateams/components';

export interface PreVettingFormSubmissionActivityRecordProps {
  vettingProcessAudit: VettingProcessAudit<VettingProcessAuditTypes.PreVettingFormSubmission>;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacing.medium,
  },
  button: {
    height: '24px',
    width: '95px',
    padding: 0,
    fontSize: '12px',
    background: '#FE630C',
  },
});

export const PreVettingFormSubmissionActivityRecord: React.FC<
  PreVettingFormSubmissionActivityRecordProps
> = ({ vettingProcessAudit }) => {
  const styles = useStyles();
  const [preVettingAnswersModalOpen, togglePreVettingAnswersModalOpen] =
    useToggle();

  return (
    <div className={styles.container}>
      <div className={styles.mainTextContainer}>
        <ActivityRecordMainText>{`Pre-evaluation form submitted${
          vettingProcessAudit.user ? ' by' : ''
        }`}</ActivityRecordMainText>

        {vettingProcessAudit.user && (
          <ActivityRecordUser user={vettingProcessAudit.user} />
        )}
      </div>

      <Button
        className={styles.button}
        onClick={togglePreVettingAnswersModalOpen}
      >
        See Answers
      </Button>

      <PreVettingAnswersModal
        open={preVettingAnswersModalOpen}
        onClose={togglePreVettingAnswersModalOpen}
        preVettingAnswers={vettingProcessAudit.payload.formAnswers}
        user={vettingProcessAudit.user}
      />
    </div>
  );
};
