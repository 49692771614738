import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import {
  ProductType,
  ProductTypeLabels,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { Select, SelectProps } from '@ateams/components';
import { InputContainer } from '../components/input-container';
import { InputLabel } from '../components/input-label';

const ProductTypeOptions = Object.entries(ProductTypeLabels).map(
  ([key, label]) => ({ value: key, label }),
);

export type OnProductTypeChange = (productTypes: ProductType[]) => void;

export interface ProductTypeSelectProps {
  value: ProductType[];
  onChange?: OnProductTypeChange;
  error?: string;
  onBlur?: () => void;
  className?: string;
  selectClassName?: string;
  'data-testing-id'?: string;
}

const useStyles = createUseStyles({
  tagListClassName: {
    marginTop: 0,
  },
});

export const ProductTypeSelect: React.FC<ProductTypeSelectProps> = (props) => {
  const styles = useStyles();

  const onChange: SelectProps<true>['onChange'] = (options) => {
    const companySizes = options.map(({ value }) => value as ProductType);

    props.onChange?.(companySizes);
  };

  const onRemoveOption: SelectProps['onRemoveOption'] = (option) => {
    const newProductType = props.value.filter((cs) => cs !== option.value);

    props.onChange?.(newProductType);
  };

  return (
    <InputContainer error={props.error} className={props.className}>
      <InputLabel>What type of products have you worked on? *</InputLabel>

      <Select<true>
        placeholder={'Select product type...'}
        onChange={onChange}
        onRemoveOption={onRemoveOption}
        options={ProductTypeOptions}
        className={cx(props.selectClassName, {
          [styles.tagListClassName]: props.value.length,
        })}
        isMulti
        onBlur={props.onBlur}
        error={Boolean(props.error)}
        data-testing-id={props['data-testing-id']}
      />
    </InputContainer>
  );
};
