import React, { ReactElement, useMemo } from 'react';
import {
  BasicInvoiceObject,
  InvoiceStatus,
  InvoiceType,
} from '@a_team/models/dist/InvoiceObject';
import { createUseStyles } from 'react-jss';
import { Icon, IconType, Card } from '@ateams/components';
import TextButton from '@src/components/TextButton';
import { numberWithCommas } from '@src/helpers/numbers';
import { stringifyMinutes } from '@src/helpers/time';
import { InvoiceStatusDisplay } from '@src/views/Mission/Documents/InvoicesTable/InvoiceStatusDisplay';
import { format } from 'date-fns';
import { useScreenClass } from 'react-grid-system';
import { MobileInvoicesTable } from '@src/views/Mission/Documents/InvoicesTable/MobileInvoicesTable';
import MissionPaymentCycle from '@src/stores/Missions/MissionPaymentCycle';
import MissionPaymentCycleObject from '@a_team/models/dist/MissionPaymentCycleObject';

export type PaymentCycleInvoice = BasicInvoiceObject & {
  type: InvoiceType.MissionPaymentCycle;
  paymentCycle: NonNullable<BasicInvoiceObject['paymentCycle']>;
};

export function isPaymentCycleInvoice(
  invoice: BasicInvoiceObject,
): invoice is PaymentCycleInvoice {
  return (
    invoice.type === InvoiceType.MissionPaymentCycle && !!invoice.paymentCycle
  );
}

export interface Props {
  invoices: BasicInvoiceObject[];
  paymentCycles: MissionPaymentCycle[];
  onMarkInvoiceAsPaid?(invoice: BasicInvoiceObject): void;
  onCancelInvoice?(invoice: PaymentCycleInvoice): void;
  className?: string;
  'data-testing-id'?: string;
}

const useStyles = createUseStyles({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #C0C0C0',
    borderLeft: 'none',
    borderTop: 'none',
    fontSize: 15,
    fontWeight: 'normal',
    color: '#62646A',
    padding: 32,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tr: {
    borderBottom: '1px solid #C0C0C0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  td: {
    padding: 24,
    textAlign: 'center',
    borderRight: '1px solid #C0C0C0',
    '&:last-child': {
      borderRight: 'none',
    },
  },
});

export const currencies = {
  USD: '$',
};

const InvoicesTable = (props: Props): ReactElement => {
  const {
    invoices,
    paymentCycles,
    onMarkInvoiceAsPaid,
    onCancelInvoice,
    className,
    ...left
  } = props;
  const styles = useStyles();
  const screenClass = useScreenClass();

  const paymentCycleIdToPeriodMap = useMemo(
    () =>
      paymentCycles.reduce<Record<MissionPaymentCycleObject['yid'], string>>(
        (map, paymentCycle) => {
          map[paymentCycle.yid] = paymentCycle.formattedPeriod;

          return map;
        },
        {},
      ),
    [paymentCycles],
  );

  if (screenClass === 'xs') {
    return (
      <MobileInvoicesTable
        invoices={invoices}
        paymentCycles={paymentCycles}
        paymentCycleIdToPeriodMap={paymentCycleIdToPeriodMap}
      />
    );
  }

  return (
    <Card className={className} style={{ padding: 0 }}>
      <table className={styles.table} {...left}>
        <tbody>
          <tr>
            <th className={styles.th}>Date</th>
            <th className={styles.th}>Invoice #</th>
            <th className={styles.th}>Invoice Period</th>
            <th className={styles.th}>Invoice Status</th>
            <th className={styles.th}>Total Hours</th>
            <th className={styles.th}>Total Payment</th>
            <th className={styles.th} />
          </tr>
          {invoices.map((invoice) => (
            <tr className={styles.tr} key={`${invoice.iid}`}>
              <td className={styles.td}>
                {format(new Date(invoice.createdAt), 'PP')}
              </td>
              <td className={styles.td} style={{ textAlign: 'left' }}>
                <strong>
                  {invoice.type === InvoiceType.MissionRolePlatformFee
                    ? 'Fees'
                    : 'Payment'}{' '}
                  #{invoice.referenceNumber}
                </strong>
              </td>
              <td className={styles.td}>
                {invoice.paymentCycle &&
                  paymentCycleIdToPeriodMap[invoice.paymentCycle]}
              </td>
              <td className={styles.td}>
                <InvoiceStatusDisplay
                  status={invoice.status}
                  paidAt={invoice.paidAt}
                />
              </td>
              <td className={styles.td}>
                {invoice.hourlyRate &&
                  invoice.type !== InvoiceType.MissionRolePlatformFee &&
                  stringifyMinutes(
                    Math.round((60 * invoice.totalAmount) / invoice.hourlyRate),
                  )}
              </td>
              <td className={styles.td}>
                {currencies[invoice.currency]}
                {numberWithCommas(invoice.totalAmount)}
              </td>
              <td className={styles.td}>
                {invoice.status === InvoiceStatus.Created && (
                  <>
                    {onMarkInvoiceAsPaid && (
                      <TextButton
                        color="info"
                        style={{ marginRight: 16 }}
                        onClick={() => onMarkInvoiceAsPaid(invoice)}
                      >
                        Mark as Paid
                      </TextButton>
                    )}
                    {isPaymentCycleInvoice(invoice) && onCancelInvoice && (
                      <TextButton
                        color="warning"
                        style={{ marginRight: 16 }}
                        onClick={() => onCancelInvoice(invoice)}
                      >
                        Cancel
                      </TextButton>
                    )}
                  </>
                )}
                <TextButton
                  highlight
                  onClick={() =>
                    invoice.downloadURL && window.open(invoice.downloadURL)
                  }
                  disabled={!invoice.downloadURL}
                >
                  <Icon
                    muted={!invoice.downloadURL}
                    type={IconType.OrangeDownload}
                    style={{
                      marginRight: '8px',
                      cursor: invoice.downloadURL ? 'pointer' : 'not-allowed',
                    }}
                  />
                  Export
                </TextButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default InvoicesTable;
