import {
  Button,
  Colors,
  FontSizes,
  FormGroup,
  FormGroupElement,
  FormGroupRow,
  HelpTooltip,
  Icon,
  IconType,
  Spacing,
  Spinner,
} from '@ateams/components';
import {
  EmailFromTargeterSendDtoEmailCategoryEnum,
  EmailFromTargeterSendDtoTypeEnum,
  ReplacementVariableByCategory,
  ReplacementVariableByCategoryIdEnum,
} from '@a_team/user-notification-service-js-sdk';
import BasicEmailUserSelector from '@src/components/BasicEmailUserSelector';
import MissionSearch from '@src/components/TeamGraphBuilderQuery/MissionContext/MissionSearch';
import { FieldErrorToMessageList } from '@src/helpers/ReactHookFormUtils';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Controller,
  FieldValues,
  UseFieldArrayReturn,
  UseFormReturn,
  useFormState,
} from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { ReplacementVariableSelect } from './ReplacementVariableSelect';
import TargeterEmailRTE from './TargeterEmailRTE';
import { TargeterEmailService } from './TargeterEmailService';

interface Props {
  form: UseFormReturn;
  numAudiences: number;
  additionalFromAndReplyToEmails: string[];
  ccAddressesFieldArray: UseFieldArrayReturn<FieldValues, 'ccAddresses', 'id'>;
  bccAddressesFieldArray: UseFieldArrayReturn<
    FieldValues,
    'bccAddresses',
    'id'
  >;
  additionalReplyToUsersFieldArray: UseFieldArrayReturn<
    FieldValues,
    'additionalReplyToUsers'
  >;
  showTeamArchitectField: boolean;
  onClickOnGenerate?: () => void;
  isGenerating?: boolean;
}

export interface EmailFormDataUser {
  email: string;
  fullName?: string;
  imageUrl?: string;
  id?: string;
}

export interface EmailFormData {
  category: EmailFromTargeterSendDtoEmailCategoryEnum;
  type: EmailFromTargeterSendDtoTypeEnum;
  fromUser: EmailFormDataUser;
  mainReplyToUser: EmailFormDataUser;
  missionId: string;
  subject: string;
  body: string;
  bodySingleRoleMatch: string;
  bodyMultipleRoleMatch: string;
  videoURL: string;
  teamArchitectUser: EmailFormDataUser;
  ccAddresses: { value: string }[];
  bccAddresses: { value: string }[];
  additionalReplyToUsers: EmailFormDataUser[];
  signatureUser: EmailFormDataUser;
  gptUsageLogId: string;
}

const useStyles = createUseStyles({
  formGroup: {
    marginBottom: Spacing.large,
  },
  positionRelative: {
    position: 'relative',
  },
  labelWrapper: {
    display: 'flex',
    '& label': {
      marginBottom: 2,
    },
    '& button': {
      cursor: 'pointer',
      padding: '0 5px 0 0',
      background: 'none',
      border: 'none',
      fontSize: FontSizes.small,
      color: Colors.regularDark,
      marginRight: Spacing.xsmall,
      '& i': {
        marginTop: '-5px',
        cursor: 'pointer',
      },
    },
  },
  label: {
    fontWeight: 500,
  },
  noUnsubscribeWarning: {
    color: Colors.warning,
    marginTop: 0,
    marginBottom: Spacing.large,
  },
  formGroupLabelRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: Spacing.small,
    alignItems: 'center',
  },
  discreteButtonList: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      padding: 4,
      marginLeft: Spacing.xsmall,
      border: 0,
      background: 'none',
      color: Colors.regularDark,
      fontSize: FontSizes.small,
    },
  },
  generateIcon: {
    marginRight: Spacing.xsmall,
  },
  generateButton: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: Spacing.small,
  },
  bodyActions: {
    display: 'flex',
  },
  bodyLoading: {
    background: 'rgba(255, 255, 255, .8)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    'z-index': 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function EmailForm({
  form,
  numAudiences,
  additionalFromAndReplyToEmails,
  additionalReplyToUsersFieldArray,
  ccAddressesFieldArray,
  bccAddressesFieldArray,
  showTeamArchitectField,
  onClickOnGenerate,
  isGenerating,
}: Props): ReactElement | null {
  const styles = useStyles();
  const { auth } = useStores();
  const [replacementVariablesByCategory, setReplacementVariablesByCategory] =
    useState<ReplacementVariableByCategory[]>([]);

  const { errors } = useFormState(form);

  useEffect(() => {
    TargeterEmailService.getReplacementVariablesByCategory(auth).then(
      (response) => {
        setReplacementVariablesByCategory(
          response.replacementVariablesByCategory || [],
        );
      },
    );
  }, []);

  const formEmailType = form.watch('type');
  const formEmailCategory = form.watch('category');
  const fromUser = form.watch('fromUser');
  const mainReplyToUser = form.watch('mainReplyToUser');
  const teamArchitectUser = form.watch('teamArchitectUser');
  const additionalReplyToUsers = form.watch('additionalReplyToUsers');
  const signatureUser = form.watch('signatureUser');
  const formMissionId = form.watch('missionId');

  const isMissionEmail =
    formEmailType === EmailFromTargeterSendDtoTypeEnum.PlainReachout ||
    formEmailType === EmailFromTargeterSendDtoTypeEnum.RichReachout;

  const isNoUnsubscribeLink =
    formEmailType === EmailFromTargeterSendDtoTypeEnum.CustomPlain ||
    formEmailType === EmailFromTargeterSendDtoTypeEnum.PlainReachout;

  const typeRegister = form.register('type', { required: true });
  const copyDisabled =
    formEmailType === EmailFromTargeterSendDtoTypeEnum.RichReachout;

  return (
    <div>
      {isNoUnsubscribeLink && (
        <p className={styles.noUnsubscribeWarning}>
          <strong>Warning: </strong>This email doesn't contain an unsubscribe
          link.
        </p>
      )}
      <FormGroup className={styles.formGroup}>
        <FormGroupRow>
          <FormGroupElement
            errors={FieldErrorToMessageList(errors['category'])}
          >
            <label className={styles.label}>Email Category</label>
            <select {...form.register('category', { required: true })}>
              <option
                value={
                  EmailFromTargeterSendDtoEmailCategoryEnum.MissionNotification
                }
              >
                Mission notification
              </option>
              <option
                value={
                  EmailFromTargeterSendDtoEmailCategoryEnum.CompanyMarketing
                }
              >
                Marketing
              </option>
            </select>
          </FormGroupElement>
          <FormGroupElement errors={FieldErrorToMessageList(errors['type'])}>
            <label className={styles.label}>Email Type</label>
            <select
              {...typeRegister}
              onClick={(e) => {
                // This hack is needed to make the field dirty when it's clicked.
                typeRegister.onBlur({ target: e.target, type: 'blur' });
              }}
            >
              <option value={EmailFromTargeterSendDtoTypeEnum.CustomTemplated}>
                Custom - Templated
              </option>
              <option value={EmailFromTargeterSendDtoTypeEnum.CustomPlain}>
                Custom - Plain
              </option>
              {formEmailCategory ===
              EmailFromTargeterSendDtoEmailCategoryEnum.MissionNotification ? (
                <>
                  <option value={EmailFromTargeterSendDtoTypeEnum.RichReachout}>
                    Reachout - Templated
                  </option>
                  <option
                    value={EmailFromTargeterSendDtoTypeEnum.PlainReachout}
                  >
                    Reachout - Plain
                  </option>
                </>
              ) : null}
            </select>
          </FormGroupElement>
        </FormGroupRow>
      </FormGroup>

      <div className={styles.discreteButtonList}>
        {!copyDisabled && (
          <>
            <button onClick={() => ccAddressesFieldArray.append(undefined)}>
              Add CC
            </button>
            <button onClick={() => bccAddressesFieldArray.append(undefined)}>
              Add BCC
            </button>
          </>
        )}
        <button
          onClick={() => additionalReplyToUsersFieldArray.append(undefined)}
        >
          Add Reply-to
        </button>
      </div>

      <FormGroup className={styles.formGroup}>
        <FormGroupRow>
          <FormGroupElement
            inputColor="secondaryLight"
            errors={FieldErrorToMessageList(errors['fromUser'])}
          >
            <label className={styles.label}>From</label>
            <Controller
              control={form.control}
              name="fromUser"
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <BasicEmailUserSelector
                  placeholder="Add an address..."
                  selectedUser={fromUser}
                  name="fromUser"
                  onlyAdmins
                  noBorder
                  onSelect={(user?: EmailFormDataUser): void => {
                    onChange(user);
                    if (user) {
                      form.setValue('mainReplyToUser', user);
                      form.clearErrors('mainReplyToUser');
                    }
                  }}
                  margin="none"
                  additionalEmails={additionalFromAndReplyToEmails}
                />
              )}
            />
          </FormGroupElement>

          <FormGroupElement
            inputColor="secondaryLight"
            errors={FieldErrorToMessageList(errors['mainReplyToUser'])}
          >
            <label className={styles.label}>Reply-to</label>
            <Controller
              control={form.control}
              name="mainReplyToUser"
              rules={{ required: true }}
              render={({ field: { onChange } }) => {
                return (
                  <BasicEmailUserSelector
                    placeholder="Add an address..."
                    selectedUser={mainReplyToUser}
                    name="mainReplyToUser"
                    onlyAdmins
                    noBorder
                    onSelect={(user?: EmailFormDataUser): void => {
                      onChange(user);
                    }}
                    margin="none"
                    additionalEmails={additionalFromAndReplyToEmails}
                  />
                );
              }}
            />
          </FormGroupElement>
        </FormGroupRow>
        {!copyDisabled &&
          ccAddressesFieldArray.fields.map((field, index) => {
            return (
              <FormGroupRow key={field.id}>
                <FormGroupElement
                  inputColor="secondaryLight"
                  errors={FieldErrorToMessageList(
                    /* eslint-disable-next-line */ // Issue with react-hook-form types
                    (errors.ccAddresses as unknown as any[])?.[index]?.value,
                  )}
                >
                  <div className={styles.labelWrapper}>
                    <button onClick={() => ccAddressesFieldArray.remove(index)}>
                      <Icon type={IconType.CloseGray} size="exact" />
                    </button>
                    <label className={styles.label}>
                      CC{' '}
                      <HelpTooltip>
                        <p>
                          Be careful. This address will receive one email per
                          audience recipient.
                        </p>
                      </HelpTooltip>
                    </label>
                  </div>

                  <input
                    {...form.register(`ccAddresses.${index}.value`, {
                      required: true,
                      pattern: {
                        value: /\S+@a.team/,
                        message: 'Invalid a.team email address',
                      },
                    })}
                    autoComplete="off"
                    type="email"
                    placeholder="Enter a CC email address..."
                  />
                </FormGroupElement>
              </FormGroupRow>
            );
          })}
        {!copyDisabled &&
          bccAddressesFieldArray.fields.map((field, index) => {
            return (
              <FormGroupRow key={field.id}>
                <FormGroupElement
                  inputColor="secondaryLight"
                  errors={FieldErrorToMessageList(
                    /* eslint-disable-next-line */ // Issue with react-hook-form types
                    (errors.bccAddresses as unknown as any[])?.[index]?.value,
                  )}
                >
                  <div className={styles.labelWrapper}>
                    <button
                      onClick={() => bccAddressesFieldArray.remove(index)}
                    >
                      <Icon type={IconType.CloseGray} size="exact" />
                    </button>
                    <label className={styles.label}>
                      BCC{' '}
                      <HelpTooltip>
                        <p>
                          Be careful. This address will receive one email per
                          audience recipient.
                        </p>
                      </HelpTooltip>
                    </label>
                  </div>
                  <input
                    type="email"
                    {...form.register(`bccAddresses.${index}.value`, {
                      required: true,
                      pattern: {
                        value: /\S+@a.team/,
                        message: 'Invalid a.team email address',
                      },
                    })}
                    autoComplete="off"
                    placeholder="Enter a BCC email address..."
                  />
                </FormGroupElement>
              </FormGroupRow>
            );
          })}
        {additionalReplyToUsersFieldArray.fields.map((field, index) => {
          return (
            <FormGroupRow key={field.id}>
              <FormGroupElement
                inputColor="secondaryLight"
                errors={FieldErrorToMessageList(
                  /* eslint-disable-next-line */ // Issue with react-hook-form types
                  (errors.additionalReplyToUsers as unknown as any[])?.[index],
                )}
              >
                <div className={styles.labelWrapper}>
                  <button
                    onClick={() =>
                      additionalReplyToUsersFieldArray.remove(index)
                    }
                  >
                    <Icon type={IconType.CloseGray} size="exact" />
                  </button>
                  <label className={styles.label}>Reply-to</label>
                </div>
                <Controller
                  control={form.control}
                  name={`additionalReplyToUsers.${index}`}
                  rules={{ required: false }}
                  render={({ field: { onChange } }) => (
                    <BasicEmailUserSelector
                      placeholder="Select one address..."
                      selectedUser={additionalReplyToUsers[index]}
                      name={`additionalReplyToUsers.${index}`}
                      onlyAdmins
                      noBorder
                      onSelect={(user?: EmailFormDataUser): void => {
                        onChange(user);
                      }}
                      margin="none"
                      additionalEmails={additionalFromAndReplyToEmails}
                    />
                  )}
                />
              </FormGroupElement>
            </FormGroupRow>
          );
        })}
      </FormGroup>
      <FormGroup className={styles.formGroup}>
        {isMissionEmail && (
          <>
            <FormGroupRow>
              <FormGroupElement
                errors={FieldErrorToMessageList(errors['missionId'])}
              >
                <div data-testing-id="targeter-mission-search">
                  <label className={styles.label}>Mission</label>
                  <Controller
                    control={form.control}
                    rules={{ required: true }}
                    name="missionId"
                    render={({ field: { onChange, value } }) => (
                      <MissionSearch
                        noBorder
                        selectedMission={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </FormGroupElement>
            </FormGroupRow>
            {showTeamArchitectField && (
              <FormGroupRow>
                <FormGroupElement
                  errors={FieldErrorToMessageList(errors['teamArchitectUser'])}
                >
                  <div data-testing-id="targeter-team-architect">
                    <label className={styles.label}>Team architect</label>
                    <Controller
                      control={form.control}
                      name={`teamArchitectUser`}
                      rules={{ required: true }}
                      render={({ field: { onChange } }) => (
                        <BasicEmailUserSelector
                          placeholder="Select one user"
                          selectedUser={teamArchitectUser}
                          name={`teamArchitectUser`}
                          onlyAdmins
                          noBorder
                          onSelect={(user?: EmailFormDataUser): void => {
                            onChange(user);
                          }}
                          margin="none"
                        />
                      )}
                    />
                  </div>
                </FormGroupElement>
              </FormGroupRow>
            )}
          </>
        )}
        <FormGroupRow>
          <FormGroupElement errors={FieldErrorToMessageList(errors['subject'])}>
            <label className={styles.label}>Subject</label>
            <input
              {...form.register('subject', { required: true, minLength: 3 })}
              autoComplete="off"
              type="text"
              placeholder="Enter subject"
            />
          </FormGroupElement>
        </FormGroupRow>
        {(formEmailType === EmailFromTargeterSendDtoTypeEnum.CustomTemplated ||
          formEmailType === EmailFromTargeterSendDtoTypeEnum.CustomPlain) && (
          <FormGroupRow>
            <FormGroupElement errors={FieldErrorToMessageList(errors['body'])}>
              <div className={styles.formGroupLabelRow}>
                <label className={styles.label}>Body</label>
                <ReplacementVariableSelect
                  visibleCategories={[ReplacementVariableByCategoryIdEnum.User]}
                  replacementVariables={replacementVariablesByCategory}
                ></ReplacementVariableSelect>
              </div>
              <Controller
                control={form.control}
                name="body"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <TargeterEmailRTE onChange={onChange} value={value} />
                )}
              />
            </FormGroupElement>
          </FormGroupRow>
        )}
        {formEmailType === EmailFromTargeterSendDtoTypeEnum.RichReachout && (
          <>
            <FormGroupRow>
              <div className={styles.positionRelative}>
                <FormGroupElement
                  errors={FieldErrorToMessageList(errors['body'])}
                >
                  <div className={styles.formGroupLabelRow}>
                    <label className={styles.label}>Body (in template)</label>
                    <div className={styles.bodyActions}>
                      <ReplacementVariableSelect
                        visibleCategories={[
                          ReplacementVariableByCategoryIdEnum.User,
                          ReplacementVariableByCategoryIdEnum.Mission,
                        ]}
                        replacementVariables={replacementVariablesByCategory}
                      ></ReplacementVariableSelect>
                      <Button
                        size="xsmall"
                        squared
                        color="regularLight"
                        width="auto"
                        className={styles.generateButton}
                        disabled={!formMissionId || isGenerating}
                        onClick={() => onClickOnGenerate && onClickOnGenerate()}
                      >
                        <Icon
                          type={IconType.CrystalBall}
                          size="exact"
                          className={styles.generateIcon}
                        />
                        Generate
                      </Button>
                    </div>
                  </div>
                  <Controller
                    control={form.control}
                    name="body"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <TargeterEmailRTE onChange={onChange} value={value} />
                    )}
                  />
                </FormGroupElement>
                {isGenerating ? (
                  <div className={styles.bodyLoading}>
                    <Spinner size={30} color="#333" />
                  </div>
                ) : null}
              </div>
            </FormGroupRow>

            <FormGroupRow>
              <FormGroupElement
                errors={FieldErrorToMessageList(errors['signatureUser'])}
              >
                <div data-testing-id="targeter-team-architect">
                  <label className={styles.label}>Signature</label>
                  <Controller
                    control={form.control}
                    name={`signatureUser`}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <BasicEmailUserSelector
                        placeholder="Select one user"
                        selectedUser={signatureUser}
                        name={`signatureUser`}
                        onlyAdmins
                        noBorder
                        onSelect={(user?: EmailFormDataUser): void => {
                          onChange(user);
                        }}
                        margin="none"
                      />
                    )}
                  />
                </div>
              </FormGroupElement>
            </FormGroupRow>

            <FormGroupRow>
              <FormGroupElement
                disabled
                errors={FieldErrorToMessageList(errors['type'])}
              >
                <label className={styles.label}>Video URL</label>
                <input
                  disabled
                  {...form.register('videoURL', {
                    minLength: 3,
                  })}
                  type="text"
                  placeholder="Select a mission to retrieve the video URL"
                />
              </FormGroupElement>
            </FormGroupRow>
          </>
        )}
        {formEmailType === EmailFromTargeterSendDtoTypeEnum.PlainReachout && (
          <>
            <FormGroupRow>
              <FormGroupElement
                errors={FieldErrorToMessageList(errors['bodySingleRoleMatch'])}
              >
                <div className={styles.formGroupLabelRow}>
                  <label className={styles.label}>
                    Body (single role match)
                  </label>
                  <ReplacementVariableSelect
                    replacementVariables={replacementVariablesByCategory}
                    visibleCategories={[
                      ReplacementVariableByCategoryIdEnum.User,
                      ReplacementVariableByCategoryIdEnum.Mission,
                      ReplacementVariableByCategoryIdEnum.PlainReachoutSingle,
                    ]}
                  ></ReplacementVariableSelect>
                </div>

                <Controller
                  control={form.control}
                  name="bodySingleRoleMatch"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TargeterEmailRTE onChange={onChange} value={value} />
                  )}
                />
              </FormGroupElement>
            </FormGroupRow>
            {numAudiences > 1 && (
              <FormGroupRow>
                <FormGroupElement
                  errors={FieldErrorToMessageList(
                    errors['bodyMultipleRoleMatch'],
                  )}
                >
                  <div className={styles.formGroupLabelRow}>
                    <label className={styles.label}>
                      Body (multiple role match)
                    </label>
                    <ReplacementVariableSelect
                      replacementVariables={replacementVariablesByCategory}
                      visibleCategories={[
                        ReplacementVariableByCategoryIdEnum.User,
                        ReplacementVariableByCategoryIdEnum.Mission,
                        ReplacementVariableByCategoryIdEnum.PlainReachoutMultiple,
                      ]}
                    ></ReplacementVariableSelect>
                  </div>

                  <Controller
                    control={form.control}
                    name="bodyMultipleRoleMatch"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <TargeterEmailRTE onChange={onChange} value={value} />
                    )}
                  />
                </FormGroupElement>
              </FormGroupRow>
            )}
          </>
        )}
      </FormGroup>
    </div>
  );
}

export default observer(EmailForm);
