import { Icon, Modal } from '@a_team/ui-components';
import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Request from './Request';
import DeclineReason from './DeclineReason';
import DeclineNotAvailable from './DeclineNotAvailable';
import DeclineNotInterested from './DeclineNotInterested';
import ShareRate from './ShareRate';
import ScheduleConfirmed from './ScheduleConfirmed';
import {
  useGetClientInterviewByIdQuery,
  useUpdateClientInterviewStatusByIdMutation,
} from '@src/rq/clientInterviews';
import {
  ClientInterviewDeclineReason,
  ClientInterviewStatus,
} from '@a_team/models/dist/ClientInterviewObject';
import { DateTime, IANAZone } from 'luxon';
import { useHistory } from 'react-router-dom';
import { MissionControlRecommendedLocation } from '@src/locations';
import RequestSkeleton from './RequestSkeleton';
import RequestExpired from './RequestExpired';
import { round } from 'lodash';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';

interface InterviewRequestModalProps {
  open: boolean;
  clientInterviewId: string;
  onClose: () => void;
}

const useStyles = createUseStyles({
  modal: {
    padding: 0,
    width: '680px !important',
    '& > div': {
      padding: 0,
    },
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffebee',
    color: '#d32f2f',
    padding: '16px',
    borderRadius: '8px',
    margin: '30px auto 0 auto',
    fontSize: '14px',
    gap: 10,
    width: '90%',
    '& svg': {
      marginRight: '8px',
    },
  },
});

enum Steps {
  Request = 'Request',
  DeclineReason = 'DeclineReason',
  DeclineNotAvailable = 'DeclineNotAvailable',
  DeclineNotInterested = 'DeclineNotInterested',
  ShareRate = 'ShareRate',
  ScheduleConfirmed = 'ScheduleConfirmed',
  RequestExpired = 'RequestExpired',
}

const InterviewRequestModal = ({
  open,
  clientInterviewId,
  onClose,
}: InterviewRequestModalProps): JSX.Element => {
  const styles = useStyles();
  const { auth } = useStores();

  const [currentStep, setCurrentStep] = useState<Steps>(Steps.Request);

  const [declineReason, setDeclineReason] = useState<
    ClientInterviewDeclineReason | undefined
  >();
  const [otherReason, setOtherReason] = useState('');
  const [builderHourlyRate, setBuilderHourlyRate] = useState<
    number | undefined
  >();

  const history = useHistory();

  const {
    data,
    isLoading,
    error: clientInterviewQueryError,
  } = useGetClientInterviewByIdQuery({
    id: clientInterviewId,
    onSuccess: (data) => {
      if (data.status === ClientInterviewStatus.InterviewAccepted) {
        setCurrentStep(Steps.ScheduleConfirmed);
      } else if (data.status === ClientInterviewStatus.InterviewRejected) {
        setCurrentStep(Steps.DeclineNotInterested);
      } else {
        const createdAt = new Date(data.createdAt);
        const now = new Date();
        const timeLeftMs =
          createdAt.getTime() + 24 * 60 * 60 * 1000 - now.getTime();
        if (timeLeftMs <= 0) {
          setCurrentStep(Steps.RequestExpired);
        }
      }
    },
  });

  const {
    mutateAsync: updateClientInterviewStatus,
    isLoading: isUpdateClientStatusLoading,
    error: updateClientStatusError,
  } = useUpdateClientInterviewStatusByIdMutation();

  const companyName = data?.clientCompany.name || '';
  const builderTimezone = data?.builderTimezone;

  const { date, time } = useMemo(() => {
    if (!data?.builderTimezone) {
      return { date: 'Invalid date', time: 'Invalid time' };
    }
    const isValidTimeZone = IANAZone.isValidZone(data.builderTimezone);

    if (!data?.startDate || !data?.endDate || !builderTimezone) {
      console.error('Invalid input:', {
        startDate: data?.startDate,
        endDate: data?.endDate,
        isValidTimeZone,
      });
      return { date: 'Invalid date', time: 'Invalid time' };
    }

    const startDate = DateTime.fromISO(data.startDate, {
      zone: data.builderTimezone,
    });
    const endDate = DateTime.fromISO(data.endDate, {
      zone: data.builderTimezone,
    });

    if (!startDate.isValid || !endDate.isValid) {
      console.error('Conversion error:', {
        startDateError: startDate.invalidReason,
        endDateError: endDate.invalidReason,
      });
      return { date: 'Invalid date', time: 'Invalid time' };
    }

    const formattedDate = startDate.toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    const startTime = startDate
      .toLocaleString(DateTime.TIME_SIMPLE)
      .toLowerCase();
    const endTime = endDate.toLocaleString(DateTime.TIME_SIMPLE).toLowerCase();
    const timeZone = startDate.toFormat('ZZZZ'); // Get the timezone abbreviation

    const formattedTime = `${startTime} to ${endTime} ${timeZone}`;

    return { date: formattedDate, time: formattedTime };
  }, [data?.startDate, data?.endDate, builderTimezone]);

  const description = data?.interviewRoleDescription || '';

  const companyImageUrl = data?.clientCompany.logo || null;

  const error = clientInterviewQueryError || updateClientStatusError;

  const [clientMinHourlyRateForBuilder, clientMaxHourlyRateForBuilder] =
    useMemo(() => {
      if (!data?.clientMinHourlyRate || !data?.clientMaxHourlyRate)
        return [undefined, undefined];

      return [
        round(data.clientMinHourlyRate / 1.2),
        round(data.clientMaxHourlyRate / 1.2),
      ];
    }, [data?.clientMinHourlyRate, data?.clientMaxHourlyRate]);

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      onClose={onClose}
      className={styles.modal}
      shouldHideGradientStroke={true}
    >
      <div>
        {isLoading && <RequestSkeleton />}
        {error?.message && (
          <div className={styles.error}>
            <Icon color="Red@600" name="statusNegative" size="lg" />
            {error.message}
          </div>
        )}

        {!isLoading && currentStep === Steps.DeclineReason && (
          <DeclineReason
            onBack={() => {
              setCurrentStep(Steps.Request);
            }}
            setDeclineReason={setDeclineReason}
            setOtherReason={setOtherReason}
            declineReason={declineReason}
            otherReason={otherReason}
            isLoading={isUpdateClientStatusLoading}
            onContinue={async () => {
              await updateClientInterviewStatus({
                id: clientInterviewId,
                status: ClientInterviewStatus.InterviewRejected,
                declineReason,
                declineOtherReasonDetails: otherReason,
              });

              if (
                declineReason ===
                ClientInterviewDeclineReason.NotEnoughAvailability
              ) {
                setCurrentStep(Steps.DeclineNotAvailable);
              } else {
                setCurrentStep(Steps.DeclineNotInterested);
              }
            }}
            companyImageUrl={companyImageUrl}
          />
        )}

        {!isLoading && currentStep === Steps.Request && (
          <Request
            onNext={() => {
              setCurrentStep(Steps.ShareRate);
            }}
            onDecline={() => {
              setCurrentStep(Steps.DeclineReason);
            }}
            clientInterviewCreatedAt={data?.createdAt}
            clientMaxHourlyRateForBuilder={clientMaxHourlyRateForBuilder}
            clientMinHourlyRateForBuilder={clientMinHourlyRateForBuilder}
            companyName={companyName}
            date={date}
            time={time}
            description={description}
            companyImageUrl={companyImageUrl}
          />
        )}

        {!isLoading && currentStep === Steps.DeclineNotAvailable && (
          <DeclineNotAvailable
            onClose={() => {
              onClose();
            }}
            onUpdateAvailability={() => {
              onClose();
              history.push(`/${auth.user?.username}`);
            }}
            companyImageUrl={companyImageUrl}
          />
        )}

        {!isLoading && currentStep === Steps.DeclineNotInterested && (
          <DeclineNotInterested
            onClose={() => {
              onClose();
            }}
            onBrowseMissions={() => {
              onClose();
              history.push(MissionControlRecommendedLocation);
            }}
            companyImageUrl={companyImageUrl}
          />
        )}

        {!isLoading && currentStep === Steps.ShareRate && (
          <ShareRate
            onBack={() => {
              setCurrentStep(Steps.Request);
            }}
            clientMaxHourlyRateForBuilder={clientMaxHourlyRateForBuilder}
            clientMinHourlyRateForBuilder={clientMinHourlyRateForBuilder}
            isLoading={isUpdateClientStatusLoading}
            onSchedule={async () => {
              await updateClientInterviewStatus({
                id: clientInterviewId,
                status: ClientInterviewStatus.InterviewAccepted,
                builderHourlyRate,
              });
              setCurrentStep(Steps.ScheduleConfirmed);
            }}
            builderHourlyRate={builderHourlyRate}
            onChange={setBuilderHourlyRate}
            companyImageUrl={companyImageUrl}
          />
        )}

        {currentStep === Steps.ScheduleConfirmed && (
          <ScheduleConfirmed
            companyName={companyName}
            onClose={() => {
              onClose();
            }}
            date={date}
            time={time}
            companyImageUrl={companyImageUrl}
          />
        )}

        {!isLoading && currentStep === Steps.RequestExpired && (
          <RequestExpired
            onClose={() => {
              onClose();
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default observer(InterviewRequestModal);
