import React, { ReactElement } from 'react';
import Modal from '..';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import logo from './logo.svg';
import {
  TermsOfServiceLocation,
  TalentPrivacyStatementLocation,
} from '@src/locations';
import TextButton from '@src/components/TextButton';

interface Props {
  onClose(): void;
  open: boolean;
  onAccept(): void;
}

const defaultProps = {
  type: 'user',
};

const MakerToSModal = (props: Props): ReactElement => {
  const { open, onClose, onAccept } = props;

  return (
    <Modal onClose={onClose} open={open} hideCloseButton>
      <SectionHeading isFirst style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={logo}
          alt="ateams"
          style={{ width: '32px', height: '32px', marginRight: '16px' }}
        />
        <div>Terms of Service</div>
      </SectionHeading>
      <div>
        By clicking “I Agree”, you agree to our{' '}
        <TextButton
          highlight
          onClick={() => window.open(TermsOfServiceLocation, '_blank')}
        >
          Terms of Service Agreement
        </TextButton>{' '}
        and{' '}
        <TextButton
          highlight
          onClick={() => window.open(TalentPrivacyStatementLocation, '_blank')}
        >
          A.Teamer Privacy Statement
        </TextButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CallToActionButton style={{ marginTop: '32px' }} onClick={onAccept}>
          I Agree
        </CallToActionButton>
      </div>
    </Modal>
  );
};

MakerToSModal.defaultProps = defaultProps;
export default MakerToSModal;
