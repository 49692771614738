import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { pick, uniqBy } from 'lodash';
import { UserUsername } from '@a_team/models/dist/UserObject';
import TargeterSearchObject, {
  TargeterSearchId,
  TargeterSearchTab,
} from '@a_team/models/dist/TargeterSearchObject';
import { apiTeamGraph } from '@src/logic/services/endpoints';
import { useStores } from '@src/stores';
import {
  SelectableBuilder,
  TargeterTabCache,
  TargeterSearchState,
  TargeterSearchCache,
  parseTargeterQuery,
} from '@src/stores/TeamGraph/TargeterTabManager';
import useToggle from '@src/hooks/useToggle';
import TargeterLayout from '@src/views/SkillTargeter/Layout';
import TeamGraphTabs, {
  SUGGESTED_TEAMS,
  SUGGESTED_TEAMS_TIMEOUT_CHECK,
} from '@src/views/SkillTargeter/TeamGraphTabs';
import TargeterSearchView from '@src/views/SkillTargeter/SearchView';
import TargeterTeamView from '@src/views/SkillTargeter/TeamView';
import {
  cacheToSearchData,
  getCurrentTabData,
  getSelectedBuilders,
  getUserWithDefaultRate,
  searchToCache,
} from './tabTransformations';
import { stringify } from 'query-string';
import _ from 'lodash';
import { TargeterEmailView } from './TargeterEmail';
import SearchOptions from './Layout/SearchLoader';
import { Button, Spacing } from '@ateams/components';
import { AudienceData } from './TargeterEmail/Sidebar';
import {
  MissionRoleId,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import TargeterSuggestedTeams from './SuggestedTeams';
import { SuggestedTeam } from '@a_team/models/dist/TeamGraphObject';
import {
  ElasticTargeterQueryResult,
  mapTargeterApiRequest,
  queryParametersToSearchRequestBody,
} from './SearchView/utils';
import {
  initialSidebarParameters,
  updateSuggestedTeamsCriteria,
} from './SuggestedTeams/utils';
import { TargeterSuggestedTeamsSidebarParameters } from '@a_team/models/dist/TargeterSuggestedTeams';
import {
  EmailFromTargeterSendDtoEmailCategoryEnum,
  EmailFromTargeterSendDtoTypeEnum,
} from '@a_team/user-notification-service-js-sdk/models';
import { ValidAudienceFormData } from './TargeterEmail/AudienceForm';
import {
  OpenAPI,
  TeamFormationService,
  TeamTargeterCriteriaParametersV2,
  TeamTargeterRoleParametersV2,
} from '@a_team/data-science-api-client';
import { SkillTargeterCriteria } from '@ateams/api/dist/endpoints/TeamGraph';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { v1SearchUserFilterApi } from '@a_team/team-engine-search-service-sdk-js';
import {
  formationUserObject,
  persistEmailFromTargeterAudiencesFormSessionStorage,
  persistEmailFromTargeterFormSessionStorage,
} from './TargeterEmail/utils';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  hidden: {
    display: 'none',
  },
  composeEmailButton: {
    marginLeft: Spacing.small,
    whiteSpace: 'nowrap',
  },
});

const AUDIENCE_LIMIT_AUTOMATED_REACHOUT = 50;

export type FromMissionType =
  | 'outreach'
  | 'review'
  | 'teamsearch'
  | 'automated-reachout';

const TargeterViewWrapper = (): ReactElement => {
  const [windowIsDefined, setWindowIsDefined] = useState(false);
  useEffect(() => setWindowIsDefined(true), []);

  return !windowIsDefined ? <div>Loading...</div> : <TeamGraphTargeterView />;
};

const TeamGraphTargeterView = (): ReactElement => {
  const styles = useStyles();
  const { auth, targeterTabManager, users } = useStores();

  const history = useHistory();
  const location = useLocation();

  const [requiresSave, setRequiresSave] = useState(false);
  const [showTeamView, toggleTeamView] = useToggle(false);

  const [activeTab, setActiveTab] = useState<string>('');
  const [cache, setCache] = useState<TargeterTabCache>({});
  const [loadedSearch, setLoadedSearch] = useState<
    TargeterSearchState | undefined
  >(undefined);

  const [checkTimeout, setCheckTimeout] = useState<NodeJS.Timeout>();
  const [jobId, setJobId] = useState<string>();
  const [suggestedTeams, setSuggestedTeams] = useState<SuggestedTeam[]>([]);
  const [startSuggestedTeamsProcess, setStartSuggestedTeamsProcess] =
    useState(false);

  const [suggestedTeamsCriteria, setSuggestedTeamsCriteria] =
    useState<TeamTargeterCriteriaParametersV2 | null>(null);

  const [initialSuggestedTeamsParameters, setInitialSuggestedTeamsParameters] =
    useState<TargeterSuggestedTeamsSidebarParameters | undefined>();
  const [suggestedTeamsParameters, setSuggestedTeamsParameters] =
    useState<TargeterSuggestedTeamsSidebarParameters>({
      allowMultipleBuildersPerAudience: true,
      showTheAudienceLimit: true,
      audiences: {},
      showPartialTeams: false,
      requiredTimeOverlapOnExperiences: false,
      invitedByOrInvitations: true,
      missions: true,
      projects: true,
      connections: true,
      teams: true,
      companies: true,
    });

  const [activeRoleTabNames, setActiveRoleTabNames] = useState<
    string[] | undefined
  >(undefined);
  const [postSuggestedTeamsTimeout, setPostSuggestedTeamsTimeout] =
    useState<NodeJS.Timeout>();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    targeterTabManager.initializeTabCache(auth).then((initialTabs) => {
      setCache(initialTabs.cache);
      setActiveTab(initialTabs.activeTab);
      setLoadedSearch(initialTabs.mostRecentSearch);
      setActiveRoleTabNames(initialTabs.activeRoleTabNames);

      if (initialTabs.search && initialTabs.search.activeTabIndex) {
        if (initialTabs.search.activeTabIndex === -1) {
          setActiveTab(SUGGESTED_TEAMS);
        } else {
          const label =
            initialTabs.search.tabs[initialTabs.search.activeTabIndex]?.label;
          if (label) {
            setActiveTab(label);
          }
        }

        setStartSuggestedTeamsProcess(true);
      }

      if (initialTabs.mostRecentSearch?.suggestedTeamsParameters) {
        setInitialSuggestedTeamsParameters(
          initialTabs.mostRecentSearch?.suggestedTeamsParameters,
        );
      }

      if (
        initialTabs.fromMissionType &&
        Object.keys(initialTabs.cache).length > 1
      ) {
        setStartSuggestedTeamsProcess(true);
      }

      if (
        (initialTabs.fromMissionType === 'outreach' && !initialTabs.role) ||
        initialTabs.fromMissionType === 'automated-reachout'
      ) {
        const mission = initialTabs.fromMission;
        const openRoles =
          mission?.roles.filter(
            (role) => role.status === MissionRoleStatus.Open && !role.user,
          ) || [];

        const defaultAudiencesFormData = openRoles.reduce((map, role, idx) => {
          map.set(`${idx + 1}: ${role.category.title}`, {
            missionRoleId: role.rid,
            missionRoleDescription: role.headline,
            label: `${idx + 1} - ${role.category.title}`,
            ...(initialTabs.fromMissionType === 'automated-reachout'
              ? {
                  limit: AUDIENCE_LIMIT_AUTOMATED_REACHOUT,
                }
              : {}),
          } as ValidAudienceFormData);

          return map;
        }, new Map<string, ValidAudienceFormData>());

        persistEmailFromTargeterFormSessionStorage({
          missionId: mission?.mid,
          type: EmailFromTargeterSendDtoTypeEnum.RichReachout,
          category:
            EmailFromTargeterSendDtoEmailCategoryEnum.MissionNotification,
          fromUser: formationUserObject,
          mainReplyToUser: formationUserObject,
          additionalReplyToUsers:
            (auth.currentUser && [
              {
                email: auth.currentUser.email,
                fullName: auth.currentUser.fullName,
                imageUrl: auth.currentUser.profilePictureURL,
              },
            ]) ||
            [],
        });
        persistEmailFromTargeterAudiencesFormSessionStorage(
          defaultAudiencesFormData,
        );
        if (initialTabs.fromMissionType === 'automated-reachout') {
          setIsEmailViewWithGenerateBodyActive(true);
        } else {
          setIsEmailViewActive(true);
        }
      }
    });
  }, []);

  const showErrorMessage = (message: string) => {
    setErrorMessage(message);
    setTimeout(() => setErrorMessage(undefined), 5000);
  };

  const postSuggestedTeams = async ({
    teamCriteria: criteria,
    teamOptions,
  }: TeamTargeterCriteriaParametersV2) => {
    if (!auth.token) return;

    targeterTabManager.setLoadingTab(SUGGESTED_TEAMS, true);
    setJobId(undefined);
    setSuggestedTeams([]);

    const teamCriteria = (
      await Promise.all(
        Object.entries(criteria).map(async ([roleTitle, roleCriteria]) => {
          const search = await queryParametersToSearchRequestBody({
            auth,
            criteria: roleCriteria as SkillTargeterCriteria,
          });
          return { roleTitle, search: search.filter };
        }),
      )
    ).reduce(
      (
        team: Record<string, TeamTargeterRoleParametersV2>,
        {
          roleTitle,
          search,
        }: { roleTitle: string; search: v1SearchUserFilterApi },
      ): Record<string, TeamTargeterRoleParametersV2> => ({
        ...team,
        [roleTitle]: {
          ...criteria[roleTitle],
          ['search' as string]: search,
        },
      }),
      {} as Record<string, TeamTargeterRoleParametersV2>,
    );

    try {
      OpenAPI.TOKEN = auth.token;
      const result = await TeamFormationService.initiateTeamFormationV2({
        teamCriteria,
        teamOptions,
      });

      if (result) setJobId(result.jobId as never as string);
    } catch (error) {
      showErrorMessage('Failed to start team search');
    }
  };

  const fetchSuggestedTeams = async (jobId: string) => {
    const result = await apiTeamGraph.getSuggestedTeams(
      auth,
      {
        jobId,
      },
      undefined,
      10,
    );

    if (result) {
      if (result.jobStatus !== 'Pending') {
        if (
          result.jobStatus === 'Completed' ||
          result.jobStatus === 'Cancelled'
        ) {
          targeterTabManager.isCancellingTeamSearch = false;
          targeterTabManager.setLoadingTab(SUGGESTED_TEAMS, false);
          setSuggestedTeams(result.teams.items);
        } else if (result.jobStatus === 'InProgress') {
          setSuggestedTeams(result.teams.items);
          startSuggestedTeamCheck();
        } else {
          targeterTabManager.isCancellingTeamSearch = false;
          throw Error(
            `An error occured in Team Graph Api for Suggested Teams. ${result.jobStatus}: ${result.error}`,
          );
        }
      }
    }
  };

  const startSuggestedTeamCheck = () => {
    if (jobId) {
      targeterTabManager.setLoadingTab(SUGGESTED_TEAMS, true);
    }

    const timeout = setTimeout(() => {
      if (jobId) {
        fetchSuggestedTeams(jobId);
      }
    }, SUGGESTED_TEAMS_TIMEOUT_CHECK);
    setCheckTimeout(timeout);
  };

  const stopSuggestedTeamCheck = () => {
    targeterTabManager.setLoadingTab(SUGGESTED_TEAMS, false);
    if (checkTimeout) {
      clearInterval(checkTimeout);
      setCheckTimeout(undefined);
    }
  };

  useEffect(() => {
    startSuggestedTeamCheck();
    return () => stopSuggestedTeamCheck();
  }, [jobId]);

  const [isEmailViewActive, setIsEmailViewActive] = useState(false);
  const [
    isEmailViewWithGenerateBodyActive,
    setIsEmailViewWithGenerateBodyActive,
  ] = useState(false);

  const { tab, tabArray } = useMemo(() => {
    return getCurrentTabData(cache, activeTab);
  }, [cache, activeTab, isEmailViewActive, isEmailViewWithGenerateBodyActive]);

  const manageCache = (updatedCache: TargeterTabCache, updated = true) => {
    if (updated) setRequiresSave(true);

    targeterTabManager.setTabCache(updatedCache);
    setCache(updatedCache);
  };

  const manageActiveTab = (label: string) => {
    targeterTabManager.setActiveTab(label);
    setActiveTab(label);
  };

  const unloadSearch = () => {
    setLoadedSearch(undefined);
    targeterTabManager.setMostRecentSearch(undefined);
  };

  const saveSearch = async (
    name: string,
    key: string,
    tsid?: TargeterSearchId,
    autosave?: boolean,
  ): Promise<void> => {
    const searchData = cacheToSearchData(
      name,
      key,
      tabArray,
      tabArray.findIndex((tab) => tab.label === activeTab),
      suggestedTeamsParameters,
    );

    if (tsid) {
      await apiTeamGraph
        .updateTargeterSearch(auth, tsid, searchData)
        .then((search) => {
          if (!autosave) {
            handleLoadedSearch(search, false);
          } else {
            setRequiresSave(false);
          }
        });
    } else {
      await apiTeamGraph
        .createTargeterSearch(auth, searchData)
        .then((search) => {
          if (!autosave) {
            handleLoadedSearch(search, false);
          } else {
            setRequiresSave(false);
          }
        });
    }
  };

  const handleLoadedSearch = (
    search: TargeterSearchObject,
    forceReload = true,
  ) => {
    // loading the currently loaded search on the first tab doesn't fetch
    if (forceReload) setActiveTab('');

    const cacheData: TargeterTabCache = searchToCache(search);
    setLoadedSearch(search);
    targeterTabManager.setMostRecentSearch(
      pick(search, 'tsid', 'name', 'creator', 'suggestedTeamsParameters'),
    );

    if (forceReload) {
      manageCache(cacheData);
      setActiveTab(search.tabs[0].label);
    }
    setRequiresSave(false);

    if (search.activeTabIndex) {
      if (search.activeTabIndex === -1) {
        setActiveTab(SUGGESTED_TEAMS);
      } else {
        const label = search.tabs[search.activeTabIndex]?.label;
        if (label) {
          setActiveTab(label);
        }
      }

      setStartSuggestedTeamsProcess(true);
    }

    if (targeterTabManager.mostRecentSearch?.suggestedTeamsParameters) {
      setInitialSuggestedTeamsParameters(
        targeterTabManager.mostRecentSearch.suggestedTeamsParameters,
      );
    }
  };

  const queryChanged = (beforeUrl: string, afterUrl: string) => {
    const filteredUrlBefore = stringify(_.omit(parseTargeterQuery(beforeUrl)));
    const filteredUrlAfter = stringify(_.omit(parseTargeterQuery(afterUrl)));

    return filteredUrlBefore.localeCompare(filteredUrlAfter) !== 0;
  };

  const updateTabQuery = (url: string) => {
    if (queryChanged(tab.url, url)) {
      manageCache({
        ...cache,
        [tab.label]: { ...tab, url, builders: [], next: undefined },
      });
    } else {
      manageCache({
        ...cache,
        [tab.label]: { ...tab, url, next: undefined },
      });
    }
  };

  const updateSearchKey = (searchKey: string) => {
    const currentCache = targeterTabManager.tabCache;
    const currentTab = currentCache?.[tab.label];
    if (!currentTab) return;

    const updatedTab = { ...currentTab, searchKey };
    manageCache({ ...currentCache, [tab.label]: updatedTab }, false);
  };

  const updateTabResults = (
    searchKey: string,
    results: ElasticTargeterQueryResult<SelectableBuilder>,
  ) => {
    // use store to ensure state edits while fetching are preserved
    const currentCache = targeterTabManager.tabCache;
    const currentTab = currentCache?.[tab.label];

    // tab was removed
    if (!currentTab) return;

    // stale result
    if (currentTab.searchKey && currentTab.searchKey !== searchKey) {
      console.warn(`Stale search returned: ${searchKey}`);
      return;
    }

    const uniqueBuilders = uniqBy(results.items, 'uid');
    const builders = getSelectedBuilders(
      currentTab.selectedBuilders ?? [],
      uniqueBuilders,
    );

    const updatedTab = {
      ...currentTab,
      metadata: results?.metadata,
      builders,
    };

    manageCache({ ...currentCache, [tab.label]: updatedTab }, false);
  };

  const selectBuilders = (users: UserUsername[], selected?: boolean) => {
    const { label, selectedBuilders } = tab;
    const selection = selected
      ? selectedBuilders
      : selectedBuilders.filter(({ user }) => !users.includes(user.username));

    if (selected) {
      tab.builders.forEach((user) => {
        users.includes(user.username) &&
          selection.push(getUserWithDefaultRate(user));
      });
    }

    editSelectedBuilders(label, selection);
  };

  const editSelectedBuilders = (
    tabLabel: string,
    selectedBuilders: TargeterSearchTab['selectedBuilders'],
  ) => {
    const tab = cache[tabLabel];
    if (!tab) return;

    manageCache({
      ...cache,
      [tab.label]: {
        ...tab,
        builders: getSelectedBuilders(selectedBuilders, tab.builders),
        selectedBuilders: uniqBy(selectedBuilders, 'user.username'),
      },
    });
  };

  const composeEmailClick = () => {
    setIsEmailViewActive(true);

    users.trackTargeterComposeEmailClicked(tabArray.length);
  };

  const audiences = useMemo<AudienceData[]>(() => {
    return tabArray.map<AudienceData>((tab) => {
      const roleFilter = parseTargeterQuery(tab.url);
      const roleId: MissionRoleId = roleFilter.appliedRoles?.[0] || '';
      return {
        label: tab.label,
        roleId: roleId,
        description: '',
        filters: roleFilter,
        topUsers: 0,
        selectedBuilders: tab.selectedBuilders
          .filter((b) => b.user.email && b.user.fullName)
          .map((b) => ({
            email: b.user.email as string,
            firstName: b.user.firstName as string,
            lastName: b.user.lastName as string,
            username: b.user.username,
            uid: b.user.uid,
          })),
      };
    });
  }, [tabArray]);

  const updateSuggestedTeamsCriteriaFromTabs = (
    tabs: TargeterSearchCache[],
  ) => {
    let criteriaChanged = false;
    const teamCriteria: TeamTargeterCriteriaParametersV2['teamCriteria'] = {};

    tabs.forEach((tab) => {
      const roleFilter = parseTargeterQuery(tab.url);
      const criteria = mapTargeterApiRequest(roleFilter);
      const currentCriteria = teamCriteria[tab.label];

      if (
        !_.isEqual(
          criteria,
          _.omit(currentCriteria, [
            'next',
            'limit',
            'requiredCount',
            'optionalCount',
            'requiredConnections',
          ]),
        )
      ) {
        criteriaChanged = true;
      }

      teamCriteria[tab.label] = {
        ...criteria,
        limit: 500,
        requiredCount: 1,
        optionalCount: 0,
      } as TeamTargeterRoleParametersV2;
    });

    if (criteriaChanged) {
      const newParameters = initialSidebarParameters(
        teamCriteria,
        activeRoleTabNames,
        initialSuggestedTeamsParameters || suggestedTeamsParameters,
      );
      setSuggestedTeamsParameters(newParameters);
      setInitialSuggestedTeamsParameters(undefined);
      updateSuggestedTeamsCriteria(
        newParameters,
        teamCriteria,
        setSuggestedTeamsCriteria,
      );
    }
  };

  useEffect(() => {
    updateSuggestedTeamsCriteriaFromTabs(tabArray);
  }, [tabArray]);

  useEffect(() => {
    if (isEmailViewActive) {
      history.push({
        pathname: location.pathname,
        search: 'tab=email-from-targeter',
      });
    } else if (isEmailViewWithGenerateBodyActive) {
      history.push({
        pathname: location.pathname,
        search: 'tab=email-from-targeter&generate-body=true',
      });
    } else if (activeTab === SUGGESTED_TEAMS) {
      history.push({
        pathname: location.pathname,
        search: 'tab=suggested-teams',
      });
    } else if (location.search !== tab.url) {
      history.push({
        pathname: location.pathname,
        search: tab.url,
      });
    }
  }, [tab]);

  useEffect(() => {
    if (tabArray && startSuggestedTeamsProcess && suggestedTeamsCriteria) {
      if (postSuggestedTeamsTimeout) {
        clearTimeout(postSuggestedTeamsTimeout);
      }
      const newPostSuggestedTeamsTimeout = setTimeout(async () => {
        setStartSuggestedTeamsProcess(false);
        await postSuggestedTeams(suggestedTeamsCriteria);
      }, 1500);

      setPostSuggestedTeamsTimeout(newPostSuggestedTeamsTimeout);
    }
  }, [tabArray, startSuggestedTeamsProcess, suggestedTeamsCriteria]);

  // TODO: implement in the future
  /*const missionId = useMemo<string>(() => {
    const roleFilter = parseTargeterQuery(tab.url)
    return roleFilter.contextMission || '';
  }, [tab.url]);*/

  const clearSelectedBuilders = (audience: AudienceData) => {
    const tabToClearBuilders = tabArray.find((t) => t.label === audience.label);
    if (!tabToClearBuilders) return;
    editSelectedBuilders(audience.label, []);
  };

  const runSearch = async () => {
    if (!suggestedTeamsCriteria) return;

    targeterTabManager.isCancellingTeamSearch = false;
    await postSuggestedTeams(suggestedTeamsCriteria);

    const buildersCount = Object.values(
      suggestedTeamsCriteria.teamCriteria,
    ).reduce((accumulator: number, criteria: TeamTargeterRoleParametersV2) => {
      return accumulator + criteria.requiredCount + criteria.optionalCount;
    }, 0);

    users.trackSuggestedTeamsRunQueryClicked(
      tabArray.length,
      buildersCount,
      suggestedTeamsParameters.showPartialTeams,
    );
  };

  const cancelSearch = async () => {
    if (!jobId) return;
    const initialState = targeterTabManager.isCancellingTeamSearch;
    try {
      targeterTabManager.isCancellingTeamSearch = true;
      OpenAPI.TOKEN = auth.token || '';
      await TeamFormationService.cancelTeamFormationJob(jobId);
    } catch (error) {
      targeterTabManager.isCancellingTeamSearch = initialState;
      showErrorMessage('Failed to cancel search');
    }
  };

  const headerRightContent = (
    <>
      <SearchOptions
        currentSearch={loadedSearch}
        onSearchLoaded={handleLoadedSearch}
        onSave={saveSearch}
        unloadSearch={unloadSearch}
        requiresSave={requiresSave}
        audiences={audiences}
        setActiveTab={manageActiveTab}
        updateTabs={manageCache}
        toggleTeamView={toggleTeamView}
      />
      <Button
        onClick={composeEmailClick}
        className={styles.composeEmailButton}
        squared
        size="small"
        width="auto"
      >
        Compose email
      </Button>
    </>
  );

  return isEmailViewActive || isEmailViewWithGenerateBodyActive ? (
    <TargeterEmailView
      onClickOnEditAudience={() => {
        setIsEmailViewActive(false);
        setIsEmailViewWithGenerateBodyActive(false);
      }}
      audiences={audiences}
      onClickOnSelectAllAudience={clearSelectedBuilders}
    ></TargeterEmailView>
  ) : (
    <TargeterLayout headerRightContent={headerRightContent}>
      <div className={cx(styles.container, !showTeamView && styles.hidden)}>
        <TargeterTeamView
          editSelectedBuilders={editSelectedBuilders}
          toggleTeamView={toggleTeamView}
          tabs={tabArray}
        />
      </div>

      <div className={cx(styles.container, showTeamView && styles.hidden)}>
        <TeamGraphTabs
          activeTab={activeTab}
          setActiveTab={manageActiveTab}
          tabs={tabArray}
          updateTabs={manageCache}
        />

        {activeTab ? (
          activeTab === SUGGESTED_TEAMS ? (
            <TargeterSuggestedTeams
              suggestedTeamsParameters={suggestedTeamsParameters}
              setSuggestedTeamsParameters={setSuggestedTeamsParameters}
              suggestedTeams={suggestedTeams}
              suggestedTeamsCriteria={suggestedTeamsCriteria}
              setSuggestedTeamsCriteria={setSuggestedTeamsCriteria}
              activeRoleTabNames={activeRoleTabNames}
              runSearch={runSearch}
              cancelSearch={cancelSearch}
            />
          ) : (
            <TargeterSearchView
              query={tab.url}
              metadata={tab.metadata}
              builders={tab.builders}
              selectedBuilders={tab.selectedBuilders}
              onQueryChange={updateTabQuery}
              onSearchStart={updateSearchKey}
              onSearchComplete={updateTabResults}
              onBuilderSelect={selectBuilders}
            />
          )
        ) : null}
      </div>
      {errorMessage && (
        <LoadingIndicator
          loading={{
            name: 'error',
            message: errorMessage,
          }}
        />
      )}
    </TargeterLayout>
  );
};

export const TargeterView = observer(TargeterViewWrapper);
