import AuthEndpoint from './Auth';
import RegistrationEndpoint from './Registration';
import UserEndpoint from './User';
import UsersEndpoint from './Users';
import ExperiencesEndpoint from './Experiences';
import TeamsEndpoint from './Teams';
import InvitationsEndpoint from './Invitations';
import EmailTemplatesEndpoint from './EmailTemplates';
import ConnectionsEndpoint from './Connetions';
import ConnectionsEndpointV2 from './ConnectionsV2';
import NotificationsEndpoint from './Notifications';
import DiscoveryEndpoint from './Discovery';
import ServicesEndpoint from './Services';
import MissionsEndpoint from './Missions';
import RoleCategoriesEndpoint from './RoleCategories';
import TimesheetsEndpoint from './Timesheets';
import InvoicesEndpoint from './Invoices';
import PaymentsEndpoint from './Payments';
import AdminEndpoint from './Admin';
import ProposalsEndpoint from './Proposals';
import ContractsEndpoint from './Contracts';
import VetterEndpoint from './vetter';
import SelectionTeamOnboardingEndpoint from './selection-team-onboarding';
import VettingProcessEndpoint from './vetting-process';
import VettingProcessPreVettingEndpoint from './vetting-process-pre-vetting';
import VettingProcessFeedbackEndpoint from './vetting-process-feedback';
import VettingProcessFeedbackAdditionalMaterialEndpoint from './vetting-process-feedback-additional-material';
import TeamPulseEndpoint from './TeamPulse';
import TeamGraphEndpoint from './TeamGraph';
import CompanyLeads from './CompanyLeads';
import TalentCategoriesEndpoint from './TalentCategories';
import TalentSkillsEndpoint from './TalentSkills';
import TalentSpecializationsEndpoint from './TalentSpecializations';
import TalentIndustriesEndpoint from './TalentIndustries';
import CompaniesEndpoint from './Companies';
import EnrichableCompaniesEndpoint from './EnrichableCompanies';
import CompaniesEndpointV2 from './CompaniesV2';
import ReferralsEndpoint from './Referrals';
import PartnersEndpoint from './Partners';
import RewardsEndpoint from './Rewards';
import AccountsEndpoint from './Accounts';
import PaymentMethodsEndpoint from './PaymentMethods';
import ClientCompaniesEndpoint from './ClientCompanies';
import CustomUserTagsEndpoint from './CustomUserTags';
import DiscountsEndpoint from './Discounts';
import ClientSignalsEndpoint from './ClientSignals';
import OAuthEndpoint from './OAuth';
import ProfileSuggestionsEndpoint from './ProfileSuggestions';
import EvaluationSettingsEndpoint from './EvaluationSettings';
import BuilderSignalsEndpoint from './BuilderSignals';
import UserRecommendations from './UserRecommendations';
import EmailFromTargeterEndpoint from './EmailFromTargeter';
import HubspotEndpoint from './Hubspot';
import RoleOptimizationRequests from './RoleOptimizationRequests';
import ClientInterviews from './ClientInterviews';
import TranscriptsEndpoint from './Transcripts';
import RequestBuildersEndpoint from './RequestBuilders';
import BuilderLoginHistoryEndpoint from './BuilderLoginHistory';

export const apiAuth = new AuthEndpoint();
export const apiRegistration = new RegistrationEndpoint();
export const apiUser = new UserEndpoint();
export const apiUsers = new UsersEndpoint();
export const apiExperiences = new ExperiencesEndpoint();
export const apiTeams = new TeamsEndpoint();
export const apiInvitation = new InvitationsEndpoint();
export const apiEmailTemplates = new EmailTemplatesEndpoint();
export const apiConnections = new ConnectionsEndpoint();
export const apiConnectionsV2 = new ConnectionsEndpointV2();
export const apiNotifications = new NotificationsEndpoint();
export const apiDiscovery = new DiscoveryEndpoint();
export const apiServices = new ServicesEndpoint();
export const apiMissions = new MissionsEndpoint();
export const apiRoleCategories = new RoleCategoriesEndpoint();
export const apiTimesheets = new TimesheetsEndpoint();
export const apiInvoices = new InvoicesEndpoint();
export const apiPayments = new PaymentsEndpoint();
export const apiAdmin = new AdminEndpoint();
export const apiProposals = new ProposalsEndpoint();
export const apiContracts = new ContractsEndpoint();
export const apiVetter = new VetterEndpoint();
export const apiSelectionTeamOnboarding = new SelectionTeamOnboardingEndpoint();
export const apiVettingProcess = new VettingProcessEndpoint();
export const apiVettingProcessPreVetting =
  new VettingProcessPreVettingEndpoint();
export const apiVettingProcessFeedback = new VettingProcessFeedbackEndpoint();
export const apiVettingProcessFeedbackAdditionalMaterial =
  new VettingProcessFeedbackAdditionalMaterialEndpoint();
export const apiTeamPulse = new TeamPulseEndpoint();
export const apiCompanyLeads = new CompanyLeads();
export const apiTalentCategories = new TalentCategoriesEndpoint();
export const apiTalentSkills = new TalentSkillsEndpoint();
export const apiTalentSpecializations = new TalentSpecializationsEndpoint();
export const apiTalentIndustries = new TalentIndustriesEndpoint();
export const apiCompanies = new CompaniesEndpoint();
export const apiEnrichableCompanies = new EnrichableCompaniesEndpoint();
export const apiReferrals = new ReferralsEndpoint();
export const apiPartners = new PartnersEndpoint();
export const apiTeamGraph = new TeamGraphEndpoint();
export const apiRewards = new RewardsEndpoint();
export const apiAccounts = new AccountsEndpoint();
export const apiPaymentMethods = new PaymentMethodsEndpoint();
export const apiClientCompanies = new ClientCompaniesEndpoint();
export const apiCompaniesV2 = new CompaniesEndpointV2();
export const apiEmailFromTargeter = new EmailFromTargeterEndpoint();
export const apiCustomUserTags = new CustomUserTagsEndpoint();
export const apiDiscounts = new DiscountsEndpoint();
export const apiClientSignals = new ClientSignalsEndpoint();
export const apiOAuth = new OAuthEndpoint();
export const apiProfileSuggestions = new ProfileSuggestionsEndpoint();
export const apiEvaluationSettings = new EvaluationSettingsEndpoint();
export const apiBuilderSignals = new BuilderSignalsEndpoint();
export const apiUserRecommendations = new UserRecommendations();
export const apiHubspot = new HubspotEndpoint();
export const apiRoleOptimizationRequests = new RoleOptimizationRequests();
export const apiClientInterviews = new ClientInterviews();
export const apiTranscript = new TranscriptsEndpoint();
export const apiRequestBuilders = new RequestBuildersEndpoint();
export const apiBuilderLoginHistory = new BuilderLoginHistoryEndpoint();
