import { Company } from '@a_team/models/dist/Company';
import { SharedJobExperience } from '@a_team/models/dist/ConnectionObject';
import {
  ExperienceMemberRole,
  ExperienceSource,
  ExperienceType,
} from '@a_team/models/dist/ExperienceObject';
import { CollaboratorStatus } from '@a_team/models/dist/UserObject';
import { NewCompanyId } from '@src/components/CompanyPicker';
import { JobExperienceModal } from '@src/components/Modal/JobExperienceModal';
import { useCreateUserExperience } from '@src/rq/experiences';
import { useGetProfileByUsername } from '@src/rq/profile';
import { observer } from 'mobx-react';
import React from 'react';

interface AddJobExperienceModalWrapperProps {
  open: boolean;
  onClose: () => void;
  handleAddNewlyCreatedExperience: (experienceId: string) => void;
  onAddJobExperience: (jobExperience: SharedJobExperience) => void;
}

const AddJobExperienceModalWrapper = ({
  open,
  handleAddNewlyCreatedExperience,
  onAddJobExperience,
  onClose,
}: AddJobExperienceModalWrapperProps): JSX.Element => {
  const createUserExperience = useCreateUserExperience();
  const { data: user } = useGetProfileByUsername();

  const onSubmit = async (
    company: Company & { url?: string; v2?: boolean },
    title: string,
    skills: string[],
    specialization?: string | undefined,
    startDate?: string | undefined,
    endDate?: string | undefined,
  ) => {
    const newJob = {
      type: 'job' as ExperienceType.Job,
      members: user
        ? [
            {
              ...user,
              ...{
                memberTitle: user?.roleCategory?.title || '',
                experienceRole: ExperienceMemberRole.Owner,
                collaboratorStatus: CollaboratorStatus.Active,
              },
            },
          ]
        : [],
      jobRole: title,
      source: ExperienceSource.Default,
      applicableTalentSkills: skills.map((talentSkillId) => ({
        talentSkillId,
      })),
      applicableTalentSpecialization: specialization
        ? { talentSpecializationId: specialization }
        : undefined,
      startDate,
      endDate,
      ...(company.v2
        ? { companyV2Id: company.id === NewCompanyId ? null : company.id }
        : { companyId: company.id }),
      companyName: company.name,
      websiteUrl: company.url,
      // TODO: remove once linking to enriched companies are linked
      name: company.name,
    };

    const res = await createUserExperience.mutateAsync(newJob);
    handleAddNewlyCreatedExperience(res.eid);
    const job = res as SharedJobExperience;
    onAddJobExperience(job);

    onClose();
  };

  return (
    <JobExperienceModal
      title={'Create a new job experience'}
      isOwner={true}
      selectedItems={user?.industryExperiences}
      onSubmit={onSubmit}
      open={open}
      onClose={onClose}
      required
      displayType={'tags'}
      mainAndAdditionalSkills={user?.mainAndAdditionalSkills || []}
      jobToUpdate={undefined}
    />
  );
};

export default observer(AddJobExperienceModalWrapper);
