import { useEffect } from 'react';

export const NotificationPreferencesRedirect = () => {
  useEffect(() => {
    window.location.href = '/settings?menu=notifications';
  }, []);

  return null;
};

export default NotificationPreferencesRedirect;
