import {
  BasicInvoiceObject,
  InvoiceStatus,
} from '@a_team/models/dist/InvoiceObject';
import { MissionPaymentCycleStatus } from '@a_team/models/dist/MissionPaymentCycleObject';
import {
  TimesheetId,
  TimesheetStatus,
} from '@a_team/models/dist/TimesheetObject';
import { Tag } from '@ateams/components';
import AdminConfirmTimesheetModal from '@src/components/Modal/AdminConfirmTimesheetModal';
import ConfirmModal from '@src/components/Modal/ConfirmModal';
import TextButton from '@src/components/TextButton';
import UserAvatar from '@src/components/UserAvatar';
import { numberWithCommas } from '@src/helpers/numbers';
import { getLocalTime } from '@src/helpers/time';
import { SetLoading } from '@src/hooks/useLoadingState';
import Mission from '@src/stores/Missions/Mission';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { ReactElement, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useCommonStyles } from '../../TimeTracking/Table/common/commonStyles';
import divider from './divider.svg';
import { canReopenPaymentCycle, reOpenPaymentCycleModalText } from '../utils';

interface Props {
  mission: Mission;
  setLoading: SetLoading;
}

const useStyles = createUseStyles({
  title: {
    display: 'flex',
    gap: 16,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '30px',
    margin: '40px 0 24px 0',
  },
  statusCell: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tag: {
    padding: '4px 10px',
  },
  tagOpen: {
    backgroundColor: '#FFE5F6',
  },
  tagSubmitted: {
    backgroundColor: '#EDE0FF',
  },
  tagCreated: {
    backgroundColor: '#FFE5F6',
  },
  tagCancelled: {
    backgroundColor: '#FFCCDC',
  },
  tagProcessing: {
    backgroundColor: '#FFE9C7',
  },
  tagPaid: {
    backgroundColor: '#DCF2CF',
  },
});

const TimesheetsStatuses = (props: Props): ReactElement => {
  const styles = useStyles();

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const [processInvoiceModal, setProcessInvoiceModal] =
    useState<BasicInvoiceObject>();

  const [timesheetToSubmit, setTimesheetToSubmit] = useState<TimesheetId>();
  const [timesheetToInvoice, setTimesheetToInvoice] = useState<TimesheetId>();
  const [reopenCycleModal, setReopenCycleModal] = useState(false);

  const {
    mission: {
      selectedPaymentCycle,
      data: { roles },
      displayedTimesheets,
      adminSubmitUserTimesheet,
      adminReopenTimesheet,
      reopenPaymentCycle,
      processInvoice,
      createTimesheetInvoice,
    },
    setLoading,
  } = props;
  const commonStyles = useCommonStyles({ cellPadding: 16 });

  const handleCancelInvoice = (): void => {
    if (!selectedPaymentCycle) return;

    setReopenCycleModal(false);
    setLoading(reopenPaymentCycle(selectedPaymentCycle.yid, {}), 'Success!');
  };

  const getTagStyle = (status?: InvoiceStatus) => {
    switch (status) {
      case InvoiceStatus.Created:
        return styles.tagCreated;
      case InvoiceStatus.Canceled:
        return styles.tagCancelled;
      case InvoiceStatus.Processing:
        return styles.tagProcessing;
      case InvoiceStatus.Paid:
        return styles.tagPaid;
      default:
        return styles.tagCreated;
    }
  };

  const owner = props.mission.data.owner;

  return (
    <>
      {selectedPaymentCycle && (
        <AdminConfirmTimesheetModal
          startDate={getLocalTime(
            new Date(selectedPaymentCycle.data.startDate),
          )}
          endDate={getLocalTime(new Date(selectedPaymentCycle.data.endDate))}
          open={paymentModalOpen}
          onSubmit={() =>
            timesheetToSubmit
              ? setLoading(adminSubmitUserTimesheet(timesheetToSubmit))
              : undefined
          }
          title={'Submit timesheet for user'}
          text={`Are you sure you want to to submit the timesheet on behalf of this user for the period ${selectedPaymentCycle?.formattedStartDate}-${selectedPaymentCycle?.formattedEndDate}?`}
          onClose={() => setPaymentModalOpen(false)}
        />
      )}
      {processInvoiceModal && (
        <ConfirmModal
          open={!!processInvoiceModal}
          onClose={() => setProcessInvoiceModal(undefined)}
          onConfirm={() => {
            if (processInvoiceModal) {
              setLoading(processInvoice(processInvoiceModal.iid));
            }

            setProcessInvoiceModal(undefined);
          }}
          title="Process User Invoice"
          description={`Are you sure you want to process invoice with amount of $${numberWithCommas(
            processInvoiceModal.totalAmount,
          )} and pay it?`}
        />
      )}
      {timesheetToInvoice && (
        <ConfirmModal
          open={!!timesheetToInvoice}
          onClose={() => setTimesheetToInvoice(undefined)}
          onConfirm={() => {
            if (timesheetToInvoice) {
              setLoading(createTimesheetInvoice(timesheetToInvoice));
            }

            setTimesheetToInvoice(undefined);
          }}
          title="Generate Builder Invoice?"
          description={`You will not able to make any changes on this timesheet unless you re-open the payment cycle.`}
        />
      )}
      {reopenCycleModal && selectedPaymentCycle && (
        <ConfirmModal
          title={reOpenPaymentCycleModalText(selectedPaymentCycle).title}
          description={
            reOpenPaymentCycleModalText(selectedPaymentCycle).description
          }
          onConfirm={handleCancelInvoice}
          onClose={() => setReopenCycleModal(false)}
          open
        />
      )}

      <div className={styles.title}>
        <span>Invoices</span> <img src={divider} alt="Divider" />
        <span style={{ color: '#62646A' }}>
          {selectedPaymentCycle?.formattedStartDate} -{' '}
          {selectedPaymentCycle?.formattedEndDate},{' '}
          {selectedPaymentCycle?.formattedYear}
        </span>
      </div>

      <div className={commonStyles.container}>
        <table>
          <thead>
            <tr>
              <th>Member</th>
              <th>Timesheet status</th>
              <th>Invoice status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={commonStyles.cell}>
                {owner ? (
                  <UserAvatar
                    size={24}
                    src={owner.profilePictureURL}
                    label={`${owner.fullName} - Owner`}
                  />
                ) : (
                  'Paying Client'
                )}
              </td>
              <td className={commonStyles.cell}></td>
              <td className={commonStyles.cell}>
                <div className={styles.statusCell}>
                  <Tag
                    data-testing-id="paying-client-invoice-status"
                    className={cx(
                      styles.tag,
                      getTagStyle(selectedPaymentCycle?.invoice?.status),
                    )}
                  >
                    {selectedPaymentCycle?.invoice?.status || 'To Submit'}
                  </Tag>
                  {selectedPaymentCycle &&
                    canReopenPaymentCycle(selectedPaymentCycle) && (
                      <TextButton
                        highlight
                        onClick={() => setReopenCycleModal(true)}
                      >
                        Re-Open Payment Cycle
                      </TextButton>
                    )}
                </div>
              </td>
            </tr>
            {displayedTimesheets &&
              displayedTimesheets.map((timesheet) => {
                const roleData = roles.find(
                  (role) => role.rid === timesheet.rid,
                );

                return (
                  <tr key={timesheet.sid}>
                    <td className={commonStyles.cell}>
                      {roleData && (
                        <UserAvatar
                          size={24}
                          src={
                            roleData.user?.username
                              ? roleData.user?.profilePictureURL
                              : undefined
                          }
                          label={roleData.user?.fullName}
                        />
                      )}
                    </td>
                    <td className={commonStyles.cell}>
                      <div className={styles.statusCell}>
                        <div>
                          <Tag
                            className={cx(styles.tag, {
                              [styles.tagOpen]:
                                timesheet.status === TimesheetStatus.Open,
                              [styles.tagSubmitted]:
                                timesheet.status === TimesheetStatus.Submitted,
                            })}
                          >
                            {timesheet.status}
                          </Tag>
                        </div>

                        <div>
                          {timesheet.status !== TimesheetStatus.Submitted &&
                            selectedPaymentCycle?.status !==
                              MissionPaymentCycleStatus.Closed && (
                              <TextButton
                                highlight
                                onClick={() => {
                                  setTimesheetToSubmit(timesheet.sid);
                                  setPaymentModalOpen(true);
                                }}
                              >
                                Submit timesheet
                              </TextButton>
                            )}
                          {timesheet.status === TimesheetStatus.Submitted &&
                            !timesheet.invoice &&
                            selectedPaymentCycle?.status !==
                              MissionPaymentCycleStatus.Closed && (
                              <>
                                <TextButton
                                  highlight
                                  onClick={() => {
                                    setLoading(
                                      adminReopenTimesheet(timesheet.sid),
                                    );
                                  }}
                                >
                                  Re-Open
                                </TextButton>
                                {' | '}
                                <TextButton
                                  highlight
                                  onClick={() => {
                                    setTimesheetToInvoice(timesheet.sid);
                                  }}
                                >
                                  Invoice
                                </TextButton>
                              </>
                            )}
                          {timesheet.invoice?.status ===
                            InvoiceStatus.Created && (
                            <TextButton
                              highlight
                              onClick={() => {
                                setProcessInvoiceModal(timesheet.invoice);
                              }}
                            >
                              Prepay
                            </TextButton>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className={commonStyles.cell}>
                      {timesheet.invoice && (
                        <Tag
                          className={cx(
                            styles.tag,
                            getTagStyle(timesheet.invoice.status),
                          )}
                        >
                          {timesheet.invoice?.status}
                        </Tag>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default observer(TimesheetsStatuses);
