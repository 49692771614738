import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Select, SelectProps } from '@ateams/components';
import { InputContainer } from '../components/input-container';
import { InputLabel } from '../components/input-label';
import { VettingProcessFeedbackRoles } from '@a_team/models/dist/vetting-processes/feedback';
import { VettingProcessFeedbackRolesLabels } from '@src/views/VettingFeedbackForm/form/roles/view-metadata';

const PrimaryRoles = [
  VettingProcessFeedbackRoles.ArtDirector,
  VettingProcessFeedbackRoles.BrandDesigner,
  VettingProcessFeedbackRoles.GameDesigner,
  VettingProcessFeedbackRoles.GraphicsDesigner,
  VettingProcessFeedbackRoles.IndustrialDesigner,
  VettingProcessFeedbackRoles.MotionDesigner,
  VettingProcessFeedbackRoles.ProductDesigner,
  VettingProcessFeedbackRoles.UXDesigner,
  VettingProcessFeedbackRoles.UXResearcher,
];

const PrimaryRolesOptions = PrimaryRoles.map((primaryRole) => ({
  value: primaryRole,
  label: VettingProcessFeedbackRolesLabels[primaryRole],
}));

export type OnPrimaryRoleChange = (
  primaryRoles: VettingProcessFeedbackRoles[],
) => void;

export interface PrimaryRoleSelectProps {
  value: VettingProcessFeedbackRoles[];
  onChange?: OnPrimaryRoleChange;
  error?: string;
  onBlur?: () => void;
  className?: string;
  selectClassName?: string;
  'data-testing-id'?: string;
}

const useStyles = createUseStyles({
  tagListClassName: {
    marginTop: 0,
  },
});

export const PrimaryRoleSelect: React.FC<PrimaryRoleSelectProps> = (props) => {
  const styles = useStyles();

  const onChange: SelectProps<true>['onChange'] = (options) => {
    const companySizes = options.map(
      ({ value }) => value as VettingProcessFeedbackRoles,
    );

    props.onChange?.(companySizes);
  };

  const onRemoveOption: SelectProps['onRemoveOption'] = (option) => {
    const newPrimaryRoles = props.value.filter((cs) => cs !== option.value);

    props.onChange?.(newPrimaryRoles);
  };

  return (
    <InputContainer error={props.error} className={props.className}>
      <InputLabel>What’s Your Primary Role? *</InputLabel>

      <Select<true>
        placeholder={'Select primary role...'}
        onChange={onChange}
        onRemoveOption={onRemoveOption}
        options={PrimaryRolesOptions}
        className={cx(props.selectClassName, {
          [styles.tagListClassName]: props.value.length,
        })}
        isMulti
        onBlur={props.onBlur}
        error={Boolean(props.error)}
        data-testing-id={props['data-testing-id']}
      />
    </InputContainer>
  );
};
