import React, { useState } from 'react';
import { REACT_APP_CALCOM_URL } from '@src/config';
import { useGetUserCalendar } from '@src/rq/profile';
import { createUseStyles } from 'react-jss';

interface InterviewAvailabilityProps {
  title: string;
  copy?: string;
  fullWidth?: boolean;
}

const useStyles = createUseStyles({
  availabilityWrap: {
    textAlign: 'center',
  },
  title: (props: InterviewAvailabilityProps) => ({
    margin: 0,
    fontWeight: 600,
    fontSize: 20,
    marginBottom: props.copy ? 0 : 40,
  }),
  subtitle: {
    marginTop: 8,
    marginBottom: 40,
    fontSize: 14,
    maxWidth: 595,
    margin: '15px auto 0 auto',
  },
  interviewIframe: (props: InterviewAvailabilityProps) => ({
    border: 'none',
    height: 1000,
    minWidth: props.fullWidth ? 850 : 700,
    maxWidth: props.fullWidth ? 900 : 800,
  }),
});

export const InterviewAvailability = (props: InterviewAvailabilityProps) => {
  const styles = useStyles(props);
  const [iframeLoading, setIframeLoading] = useState(true);
  const { data: calendarData, isLoading: isCalendarLoading } =
    useGetUserCalendar();

  return (
    <div className={styles.availabilityWrap}>
      <h3 className={styles.title}>{props.title}</h3>
      {props.copy && <div className={styles.subtitle}>{props.copy}</div>}
      {(iframeLoading || isCalendarLoading) && (
        <div className={styles.subtitle}>Loading availability settings</div>
      )}
      <iframe
        onLoad={() => setIframeLoading(false)}
        title="update availability"
        className={styles.interviewIframe}
        src={`${REACT_APP_CALCOM_URL}/auth/login?autoLogin=true&callbackUrl=${encodeURIComponent(
          `/availability/${calendarData?.calcom?.schedule?.id}?embedded=true`,
        )}`}
      ></iframe>
    </div>
  );
};
