import { apiExperiences } from '@ateams/api';
import { useStores } from '@src/stores';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './keys';
import {
  ExperienceData,
  ExperienceId,
  ExperienceObject,
  ProjectDescriptionBlurbData,
} from '@a_team/models/dist/ExperienceObject';
import { refreshProfile } from './utils';
import { useAnalytics } from '@ateams/analytics/dist/platform';

export const useGetUserExperiencesByUserId = ({
  userId,
  enabled = true,
}: {
  userId: string;
  enabled?: boolean;
}) => {
  const stores = useStores();
  return useQuery({
    queryKey: queryKeys.experiences.byUserId(userId),
    queryFn: () => {
      return apiExperiences.getExperiencesByUserId(stores.auth, userId);
    },
    enabled: enabled,
  });
};

export const useCreateUserExperience = () => {
  const stores = useStores();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ExperienceData) => {
      return apiExperiences.createUserExperience(stores.auth, data);
    },
    onSuccess: () => {
      const userId = stores.auth.user?.uid as string;
      queryClient.invalidateQueries(queryKeys.experiences.byUserId(userId));
    },
  });
};

export const useUpdateUserExperience = () => {
  const stores = useStores();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      experienceId,
      data,
    }: {
      experienceId: string;
      data: ExperienceData;
    }) => {
      return apiExperiences.updateExperience(stores.auth, experienceId, data);
    },
    onSuccess: () => {
      const userId = stores.auth.user?.uid as string;
      queryClient.invalidateQueries(queryKeys.experiences.byUserId(userId));
    },
  });
};

export const useDeleteUserExperienceMutation = () => {
  const stores = useStores();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (experienceId: string) => {
      return apiExperiences.deleteExperience(stores.auth, experienceId);
    },
    onSuccess: () => {
      const userId = stores.auth.user?.uid as string;
      queryClient.invalidateQueries(queryKeys.experiences.byUserId(userId));
    },
  });
};

export const useApproveCollaboratorRequest = (
  approveCollaboratorRequestOnCompleted?: (
    updatedProject: ExperienceObject,
  ) => void,
) => {
  const stores = useStores();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      experienceId,
      data,
    }: {
      experienceId: string;
      data: ExperienceData;
    }) => {
      return apiExperiences.approveCollaboratorRequest(
        stores.auth,
        experienceId,
        data,
      );
    },
    onSuccess: async (updatedProject) => {
      if (approveCollaboratorRequestOnCompleted) {
        approveCollaboratorRequestOnCompleted(updatedProject);
      }

      const userId = stores.auth.user?.uid as string;
      queryClient.invalidateQueries(queryKeys.experiences.byUserId(userId));
      queryClient.invalidateQueries(
        queryKeys.experiences.hasPendingCollaboratorRequest(
          userId,
          updatedProject.eid,
        ),
      );
      await refreshProfile(stores);
    },
  });
};

export const useRejectCollaboratorRequest = () => {
  const stores = useStores();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ experienceId }: { experienceId: string }) => {
      return apiExperiences.rejectCollaboratorRequest(
        stores.auth,
        experienceId,
      );
    },
    onSuccess: (updatedProject) => {
      const userId = stores.auth.user?.uid as string;
      queryClient.invalidateQueries(queryKeys.experiences.byUserId(userId));
      queryClient.invalidateQueries(
        queryKeys.experiences.hasPendingCollaboratorRequest(
          userId,
          updatedProject.eid,
        ),
      );
    },
  });
};

export const useProjectDescriptionSuggestionMutation = ({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) => {
  const stores = useStores();
  const analytics = useAnalytics();

  return useMutation({
    mutationFn: async (data: ProjectDescriptionBlurbData) => {
      return apiExperiences.generateProjectDescriptionBlurb(stores.auth, data);
    },
    onSuccess: () => {
      stores.auth?.uid &&
        analytics.trackStartWritingProjectDescriptionClicked(
          stores.auth.uid || '',
        );
      onSuccess && onSuccess();
    },
  });
};

export const useHasPendingCollaboratorRequest = ({
  eid,
  enabled = true,
}: {
  eid: ExperienceId;
  enabled?: boolean;
}) => {
  const stores = useStores();
  return useQuery({
    queryKey: queryKeys.experiences.hasPendingCollaboratorRequest(
      stores.auth.uid ?? '',
      eid,
    ),
    queryFn: () => {
      return apiExperiences.hasPendingCollaboratorRequest(stores.auth, eid);
    },
    enabled: enabled,
  });
};
