import React from 'react';
import { observer } from 'mobx-react';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import SectionHeading from '@src/components/SectionHeading';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Button,
  Colors,
  Paddings,
  Spacing,
  Spinner,
} from '@ateams/components';
import { useGetReachoutAutomationPreviewForMission } from '@src/rq/emailFromTargeter';
import { useStores } from '@src/stores';
import cx from 'classnames';

const useStyles = createUseStyles({
  container: {
    overflow: 'auto',
    padding: Paddings.xLarge,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  header: {
    marginBottom: Spacing.xLarge,
  },
  actionsBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '12px',
    paddingRight: '12px',
    gap: Spacing.medium,
  },
  roleCard: {
    padding: Paddings.large,
    textAlign: 'left',
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.medium,
    marginBottom: Spacing.xLarge,
    '& strong': {
      marginBottom: Spacing.small,
      display: 'block',
    },
  },
  openInTargeterButton: {
    color: Colors.secondary,
  },
  openInTargeterButtonDisabled: {
    color: Colors.regular,
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    marginBottom: Spacing.xLarge,
  },
  totalAudienceCount: {
    marginLeft: Spacing.small,
    color: Colors.regular,
    textDecoration: 'line-through',
  },
  totalAudience: {
    marginTop: Spacing.small,
    marginBottom: 0,
  },
});

const AUDIENCE_LIMIT_AUTOMATED_REACHOUT = 50;

export interface AutomatedReachoutModalProps extends ModalBasicProps {
  missionId?: string;
  onSaveWithoutReachout?(): void;
  onSaveWithReachoutRedirect?(): void;
  onReachoutRedirect?(): void;
  saveAndOpenInTargeterLabel?: string;
  saveWithoutReachoutLabel?: string;
  saveWithReachoutLabel?: string;
}

const ROLE_HEADLINE_LIMIT = 150;

const AutomatedReachoutModal: React.FC<AutomatedReachoutModalProps> = ({
  open,
  onClose,
  onSaveWithoutReachout,
  onSaveWithReachoutRedirect,
  missionId,
  saveAndOpenInTargeterLabel,
  saveWithoutReachoutLabel,
  saveWithReachoutLabel,
  onReachoutRedirect,
}: AutomatedReachoutModalProps) => {
  const styles = useStyles();
  const { targeterTabManager } = useStores();
  const { data, isLoading } = useGetReachoutAutomationPreviewForMission({
    missionId: missionId || '',
    enabled: !!missionId && open,
  });
  const initializeTabCache = async (cb?: () => void) => {
    if (!data?.roles || !missionId) return;
    targeterTabManager.initializeTabCacheFromAutomatedReachoutMissionPreview(
      missionId,
      data?.roles,
    );
    cb && cb();
  };
  return (
    <Modal open={open} onClose={onClose} style={{ padding: 0, minWidth: 750 }}>
      <div className={styles.container}>
        <SectionHeading isFirst className={styles.header}>
          Confirm automated reachouts
        </SectionHeading>
        {isLoading ? (
          <div className={styles.centerBox}>
            <Spinner color="#333" />
          </div>
        ) : null}
        {!data && !isLoading && (
          <div className={styles.centerBox}>
            <p>There are no eligible roles for reachout</p>
          </div>
        )}
        {data?.roles.map((rolePreview) => (
          <div key={rolePreview.roleId} className={styles.roleCard}>
            <strong>{rolePreview.roleCategory}</strong>
            <p>
              {rolePreview.roleHeadline.slice(0, ROLE_HEADLINE_LIMIT)}
              {rolePreview.roleHeadline.length > ROLE_HEADLINE_LIMIT
                ? '...'
                : ''}
            </p>
            <p className={styles.totalAudience}>
              Total audience targeted:{' '}
              {Math.min(
                rolePreview.userCount,
                AUDIENCE_LIMIT_AUTOMATED_REACHOUT,
              )}
              {rolePreview.userCount > AUDIENCE_LIMIT_AUTOMATED_REACHOUT ? (
                <span className={styles.totalAudienceCount}>
                  {rolePreview.userCount}
                </span>
              ) : null}
            </p>
          </div>
        ))}
        <div className={styles.actionsBar}>
          {!isLoading && !data ? (
            <></>
          ) : (
            <>
              <Button
                squared
                disabled={isLoading}
                size="small"
                width="auto"
                color="transparent"
                className={cx(
                  styles.openInTargeterButton,
                  isLoading && styles.openInTargeterButtonDisabled,
                )}
                onClick={() => initializeTabCache(onReachoutRedirect)}
              >
                {'Open in targeter'}
              </Button>
              <Button
                squared
                disabled={isLoading}
                size="small"
                width="auto"
                color="transparent"
                className={cx(
                  styles.openInTargeterButton,
                  isLoading && styles.openInTargeterButtonDisabled,
                )}
                onClick={() => initializeTabCache(onSaveWithReachoutRedirect)}
              >
                {saveAndOpenInTargeterLabel || 'Save and open in targeter'}
              </Button>
            </>
          )}
          <Button
            squared
            size="small"
            width="auto"
            color="regularLight"
            onClick={onSaveWithoutReachout}
          >
            {saveWithoutReachoutLabel || 'Save without reachout'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default observer(AutomatedReachoutModal);
