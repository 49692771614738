import React, {
  CSSProperties,
  ChangeEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { Card } from '@ateams/components';
import { Company, CompanyId, CompanyStatus } from '@a_team/models/dist/Company';
import { createUseStyles } from 'react-jss';
import useDebounceState from '@src/hooks/useDebounceState';
import CompanyAvatar from '@src/components/CompanyAvatar/CompanyAvatar';
import { Input, Icon, theme } from '@a_team/ui-components';
import {
  useQueryAutoCompleteCompany,
  useQueriesGetCompanies,
} from '@src/rq/companiesV2Search';
import { CompanyItem } from './CompanyItem';
import { NewCompanyId } from '../CompanyPicker';
import { AddCompanyModal } from './NewCompanyModal';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
  },
  companyAvatarsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  companyAvatar: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: 3,
  },
  menu: {
    padding: 0,
    overflowY: 'auto',
    margin: 0,
    display: 'block',
    width: '100%',
    maxHeight: 280,
  },
  dropdown: {
    position: 'absolute',
    minWidth: 300,
    left: 0,
    zIndex: 100,
  },
  companyV2PickerInput: {
    '&:focus': {
      borderColor: '#62646A !important',
      backgroundColor: '#FFF !important',
    },
  },
  isPurple: { color: '#6D00D7 !important' },
  inputWithIcon: {
    paddingLeft: '25px !important',
  },
});

const borderlessIconStyle: CSSProperties = {
  position: 'absolute',
  top: '3px',
  left: 0,
};

const defaultIconStyle: CSSProperties = {
  left: 6,
};

interface Props {
  onSelectCompanies(company: Array<Company & { url?: string }>): void;
  isMulti?: boolean;
  required?: boolean;
  tabIndex?: number;
  error?: string;
  autoFocus?: boolean;
  initiallySelectedCompanyIds?: CompanyId[];
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  displayName?: string;
  onlySearchVerified?: boolean;
  variant?: 'borderless' | 'boxed' | undefined;
  suppressIcons?: boolean;
  canAddCompany?: boolean;
  searchUrl?: boolean;
  onChangeProps?: {
    initialSearchTerm?: string;
    onInputChange?: (value: string) => void;
    onBlur?: () => void;
  };
}

export const CompanyV2Picker = ({
  canAddCompany,
  onSelectCompanies,
  autoFocus,
  required,
  tabIndex,
  error,
  initiallySelectedCompanyIds = [],
  className,
  isMulti,
  disabled,
  placeholder,
  displayName,
  onlySearchVerified,
  variant,
  suppressIcons = false,
  onChangeProps,
  searchUrl = false,
}: Props): ReactElement => {
  const [newCompanyName, setNewCompanyName] = useState('');
  const [newCompanyModalIsOpen, setNewCompanyModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState<
    Array<Company & { url?: string }>
  >([]);
  const [fetchingInitialValues, setFetchingInitialValues] = useState(true);
  const styles = useStyles();
  const inputRef = useRef(null as HTMLInputElement | null);
  const menuRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [searchQuery, setSearchQuery] = useDebounceState<string | null>(
    onChangeProps?.initialSearchTerm || displayName || '',
    (query) => setSearchTerm(query),
    400,
  );

  const {
    isLoading,
    error: errorQuery,
    data,
  } = useQueryAutoCompleteCompany({
    searchTerm: searchTerm ? searchTerm : '',
    verified: onlySearchVerified,
    canAddCompany,
    onSuccess: () => {
      !newCompanyModalIsOpen && setIsOpen(true);
    },
  });

  useEffect(() => {
    if (
      fetchingInitialValues &&
      selectedCompanies.length === initiallySelectedCompanyIds.length
    ) {
      setFetchingInitialValues(false);
    }
    if (!fetchingInitialValues) {
      onSelectCompanies(selectedCompanies);
    }
  }, [selectedCompanies, fetchingInitialValues]);

  useQueriesGetCompanies({
    enabled: fetchingInitialValues,
    companyIds: initiallySelectedCompanyIds,
    onSuccess: (data) => {
      data &&
        setSelectedCompanies((current) =>
          isMulti
            ? [...(current || []), data]
            : [
                {
                  ...data,
                  name: displayName ?? data.name,
                },
              ],
        );
    },
  });

  const onSelectNewCompany = (company: Company & { url?: string }) => {
    setNewCompanyModalOpen(false);
    setSelectedCompanies([...selectedCompanies, company]);
  };

  const onSelectSearchResult = (company: Company & { url?: string }) => {
    setIsOpen(false);
    if (canAddCompany && company.id === NewCompanyId) {
      setNewCompanyName(company.name);
      setNewCompanyModalOpen(true);
      setIsOpen(false);
    } else {
      setSelectedCompanies([...selectedCompanies, company]);
    }
  };

  const onChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const { value } = e.target;
    setSearchQuery(value);
    onChangeProps?.onInputChange?.(value);

    if (!isMulti) {
      setSelectedCompanies([]);
    }
    if (value === '') {
      setIsOpen(false);
    }
  };

  const onCompanyDelete = (index: number) => {
    setSelectedCompanies([
      ...selectedCompanies.slice(0, index),
      ...selectedCompanies.slice(index + 1),
    ]);
  };

  useEffect(() => {
    if (selectedCompanies.length > 0) {
      inputRef.current?.blur();
    }
  }, [selectedCompanies.length]);

  if (fetchingInitialValues) {
    return <Icon name="loading" size="lg" />;
  }

  const isVerified =
    !isMulti &&
    !suppressIcons &&
    selectedCompanies.length &&
    selectedCompanies[0]?.status === CompanyStatus.Verified;

  const displayText = searchUrl
    ? selectedCompanies[0]?.url
    : selectedCompanies[0]?.name;

  const iconStyle =
    variant === 'borderless' ? borderlessIconStyle : defaultIconStyle;
  const icon = suppressIcons ? undefined : isLoading ? (
    <Icon style={iconStyle} name={'loading'} size={'md'} />
  ) : !selectedCompanies.length || isMulti ? (
    <Icon style={iconStyle} name={'search'} size={'md'} />
  ) : isVerified ? (
    <Icon
      style={{
        ...iconStyle,
        color: '#6D00D7',
      }}
      name={'link'}
      size={'md'}
    />
  ) : undefined;

  return (
    <div className={styles.container}>
      {canAddCompany && (
        <AddCompanyModal
          isOpen={newCompanyModalIsOpen}
          onClose={() => setNewCompanyModalOpen(false)}
          onSelectNewCompany={onSelectNewCompany}
          companyName={newCompanyName}
        />
      )}
      <span className={styles.companyAvatarsContainer}>
        {selectedCompanies.map((company, index) => (
          <span key={company.id} className={styles.companyAvatar}>
            <CompanyAvatar
              src={company.logoUrl}
              label={isMulti ? company.name : undefined}
              key={index}
            />
            {isMulti && (
              <Icon
                style={{ marginLeft: 3, marginBottom: 6 }}
                name={'remove'}
                size={'sm'}
                onClick={() => onCompanyDelete(index)}
              />
            )}
          </span>
        ))}
      </span>
      <Input
        className={cx(
          styles.companyV2PickerInput,
          className,
          isVerified && styles.isPurple,
          !!icon && styles.inputWithIcon,
        )}
        disabled={disabled}
        variant={variant}
        ref={inputRef}
        type="text"
        size="stretch"
        placeholder={placeholder ?? 'Search for a company...'}
        onChange={onChange}
        value={isMulti ? searchQuery ?? '' : displayText ?? searchQuery ?? ''}
        required={required}
        autoFocus={autoFocus}
        onBlur={() =>
          onChangeProps?.onBlur &&
          setTimeout(() => {
            // timeout is required to prevent the dropdown from closing
            // before the click event on the dropdown item is triggered
            onChangeProps?.onBlur?.();
            setIsOpen(false);
          }, 200)
        }
        icon={icon}
        style={{ borderColor: theme.colors.Grey['400'] }}
        tabIndex={tabIndex}
        error={
          (error !== undefined && error !== '') || errorQuery ? true : undefined
        }
        errorText={error}
      />
      {isOpen && (
        <div className={styles.dropdown}>
          <Card className={styles.menu}>
            <div ref={menuRef}>
              {data?.items.map((company) => {
                return (
                  <CompanyItem
                    company={company}
                    onSelectCompany={(c) =>
                      onSelectSearchResult(c as Company & { url: string })
                    }
                    key={company.id}
                  />
                );
              })}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
