import {
  Breakpoints,
  Colors,
  Icon,
  IconType,
  Spacing,
} from '@ateams/components';
import {
  AdminMissionApplicationObject,
  MissionApplicationStatus,
} from '@a_team/models/dist/MissionApplicationObject';
import MissionRole, {
  MissionAdminRole,
  MissionRoleStatus,
  MissionRoleVisibilityStatus,
} from '@a_team/models/dist/MissionRole';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import { WorkingHoursSchema } from '@a_team/models/dist/WorkingHoursObject';
import { CountryObject } from '@src/components/Modal/AllLocationsModal';
import {
  ApplicantsSortByOptions,
  getFilteredAndSortedApplications,
} from '@src/helpers/applications';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { ReactNode, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  MissionRoleVisibilityStatusTag,
  ReviewStatus,
} from '../MissionRoleVisibilityStatusTag';
import Availability from './Availability';
import Badges from './Badges';
import Description from './Description';
import FilteredApplications from './FIlteredApplications';
import Header from './Header';
import Location from './Location';
import LookingForMoreApplicants from './LookingForMoreApplicants';
import RoleRowNotification from './Notification';
import Skills, { SkillTypes } from './Skills';
import RateRange from './RateRange';
import Timezone from './Timezone';
import RoleDraftingStatus from './RoleDraftingStatus';
import RateRangeV2 from './RateRangeV2';
import Keywords from './Keywords';
import ClientQuestions from '@src/views/Mission/AboutMission/NewMissionRoleRow/ClientQuestion';

interface MissionRoleRowProps {
  referralMode?: boolean;
  role: MissionRole | MissionAdminRole;
  applications?: AdminMissionApplicationObject[] | null;
  onApplicationClick?: (application: AdminMissionApplicationObject) => void;
  showLookingForApplicationsToggle: boolean;
  onLookingForMoreApplicationsToggle?: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  showMoreButton?: ReactNode;
  userCountry?: [CountryObject | undefined, boolean];
  currentUserTimezone?: TimezoneObject;
  currentUserWorkingHours?: WorkingHoursSchema;
  hideMember?: boolean;
  onApply?(): void;
  onEdit?(): void;
  onInvite?(): void;
  toggleSetLocationModalOpen?(): void;
  toggleSetAvailabilityModalOpen?(): void;
  toggleAllLocationsModalOpen?(): void;
  toggleSetTimezoneModalOpen?(): void;
  toggleOptimizationRequestModal?(): void;
  setNotEnoughOverlap?(value: boolean): void;
  currentUserHasRole?: MissionRole;
  visibilityStatus?: MissionRoleVisibilityStatus;
}

const useStyles = createUseStyles({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    marginBottom: '16px',
    overflow: 'hidden',
  },
  card: {
    padding: Spacing.medium,
  },
  keywordContainer: {
    borderTop: `1px solid ${Colors.regular}`,
    padding: Spacing.medium,
  },
  slider: {
    overflow: 'hidden',
    transition: 'all 1s ease-out',
    '&.hidden': {
      display: 'none',
    },
    '&.visible': {
      display: 'block',
    },
  },
  sliderCTA: {
    display: 'block',
    fontSize: '14px',
  },
  icon: {
    marginRight: '8px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: {
      marginBottom: '24px',
    },
    card: {
      padding: `${Spacing.large}px ${Spacing.large}px ${Spacing.xsmall}px ${Spacing.large}px`,
    },
    slider: {
      '&.hidden': {
        display: 'block',
      },
      '&.visible': {
        display: 'block',
      },
    },
    sliderCTA: {
      display: 'none',
    },
    keywordContainer: {
      padding: `${Spacing.large}px ${Spacing.large}px ${Spacing.xsmall}px ${Spacing.large}px`,
    },
  },
  visibilityStatusTag: {
    borderRadius: 0,
    width: '100%',
  },
});

function MissionRoleRow({
  currentUserHasRole,
  role,
  applications,
  onApplicationClick,
  onApply,
  onEdit,
  onInvite,
  referralMode,
  showLookingForApplicationsToggle,
  onLookingForMoreApplicationsToggle,
  showMoreButton,
  userCountry,
  currentUserTimezone,
  currentUserWorkingHours,
  hideMember,
  toggleSetLocationModalOpen,
  toggleSetAvailabilityModalOpen,
  toggleAllLocationsModalOpen,
  toggleSetTimezoneModalOpen,
  toggleOptimizationRequestModal,
  setNotEnoughOverlap,
}: MissionRoleRowProps): JSX.Element {
  const stores = useStores();
  const { missions, auth } = stores;
  const { currentMission } = missions;
  const styles = useStyles();
  const [showMore, setShowMore] = React.useState(false);

  const [applicantsSortBy, setApplicantsSortBy] =
    useState<ApplicantsSortByOptions>(
      ApplicantsSortByOptions.ApplicationStatus,
    );

  const filteredApplications = getFilteredAndSortedApplications(
    applications,
    applicantsSortBy,
    auth.withMissionApplicationStatusV2,
  );

  const showVisibilityStatus =
    role.visibility?.visibilityStatus &&
    role.visibility?.visibilityStatus !== MissionRoleVisibilityStatus.All &&
    auth.isAdmin;

  const companyName = useMemo(() => {
    if (!currentMission) return undefined;
    return currentMission.data.title.includes(':')
      ? currentMission.data.title.split(':')[0]
      : undefined;
  }, [currentMission?.data.title]);

  const renderRatesRow = () => {
    if (
      !role.showRateRangeToBuilders ||
      !role.builderRateMin ||
      !role.builderRateMax
    ) {
      return null;
    }

    if (role.collectMonthlyRate && auth.withMissionMonthlyRates) {
      return (
        <RateRangeV2
          builderRateMin={role.builderRateMin}
          builderRateMax={role.builderRateMax}
          builderMonthlyRateMin={role.builderMonthlyRateMin ?? 0}
          builderMonthlyRateMax={role.builderMonthlyRateMax ?? 0}
        />
      );
    }

    return (
      <RateRange
        builderRateMin={role.builderRateMin}
        builderRateMax={role.builderRateMax}
      />
    );
  };

  const roleReviewStatus = useMemo(() => {
    if (!(role as MissionAdminRole).lastOptimizationRequest) {
      return ReviewStatus.Initial;
    }
    if ((role as MissionAdminRole).readyForReview) {
      return ReviewStatus.ReadyForReview;
    } else {
      return ReviewStatus.Pending;
    }
  }, [role]);

  return (
    <>
      <div className={styles.root} data-testing-id="mission-role-card">
        {showVisibilityStatus ? (
          <MissionRoleVisibilityStatusTag
            className={styles.visibilityStatusTag}
            color={'warning'}
            visibilityStatus={role.visibility?.visibilityStatus}
            reviewStatus={roleReviewStatus}
            onClickOnRequestOptimization={toggleOptimizationRequestModal}
          ></MissionRoleVisibilityStatusTag>
        ) : (
          !role.user && (
            <RoleDraftingStatus
              companyName={companyName}
              drafting={!!role.lookingForApplications}
            />
          )
        )}
        <div className={styles.card}>
          <Header
            logoURL={currentMission?.data.logoURL}
            role={role}
            referralMode={referralMode}
            onApply={onApply}
            onEdit={onEdit}
            onInvite={onInvite}
            currentUserHasRole={currentUserHasRole}
            hideMember={hideMember}
            withDraftingStatus={false}
          />

          <Description text={role.headline} title={'Description'} />

          {auth.isAdmin && (
            <ClientQuestions
              questions={
                role.customQuestions?.filter(
                  (question) => question.isVisible,
                ) || []
              }
            />
          )}

          {!role.user && (
            <>
              {role.recommended && <Badges />}
              {renderRatesRow()}
              <div
                className={`${styles.slider} ${
                  showMore ? 'visible' : 'hidden'
                }`}
              >
                <div>
                  {!!role?.availability?.weeklyHoursAvailable && (
                    <Availability
                      weeklyHoursAvailable={
                        role?.availability?.weeklyHoursAvailable
                      }
                      userWeeklyHoursAvailable={
                        auth?.user?.availability?.weeklyHoursAvailable
                      }
                      toggleSetAvailabilityModalOpen={
                        toggleSetAvailabilityModalOpen
                      }
                    />
                  )}
                  {role?.workingHours &&
                    role.workingHours?.name !== undefined &&
                    role.workingHours?.numberOfMinutesOverlap !== undefined &&
                    role.workingHours.numberOfMinutesOverlap > 0 &&
                    role.workingHours?.daily !== undefined &&
                    role.workingHours?.daily.length > 0 && (
                      <Timezone
                        numberOfMinutesOverlap={
                          role.workingHours.numberOfMinutesOverlap
                        }
                        roleDaily={role.workingHours.daily}
                        roleTimezoneName={role.workingHours.name}
                        userDaily={currentUserWorkingHours?.daily}
                        userTimezoneName={currentUserTimezone?.name}
                        toggleSetTimezoneModalOpen={toggleSetTimezoneModalOpen}
                        setNotEnoughOverlap={setNotEnoughOverlap}
                      />
                    )}
                  {role?.locations && role.locations?.length > 0 && (
                    <Location
                      userCountry={
                        userCountry ? userCountry : [undefined, false]
                      }
                      toggleAllLocationsModalOpen={toggleAllLocationsModalOpen}
                    />
                  )}
                  <Skills
                    label="Required skills"
                    role={role}
                    skillsType={SkillTypes.Required}
                  />
                  <Skills
                    label="Preferred skills"
                    role={role}
                    skillsType={SkillTypes.Preferred}
                  />
                </div>
                {showLookingForApplicationsToggle && (
                  <LookingForMoreApplicants
                    role={role}
                    onLookingForMoreApplicationsToggle={
                      onLookingForMoreApplicationsToggle
                    }
                  />
                )}
              </div>
              <div
                onClick={() => setShowMore(!showMore)}
                className={styles.sliderCTA}
              >
                <Icon
                  className={styles.icon}
                  type={IconType.Chevron}
                  size={'exact'}
                  flip={showMore ? 'vertical' : undefined}
                />
                {showMore ? 'Show less info' : 'Show more info'}
              </div>
            </>
          )}
        </div>

        {auth.isAdmin && auth.withRoleKeywords && (
          <div className={styles.keywordContainer}>
            <Keywords headline={role.headline} />
          </div>
        )}

        {!referralMode &&
          role.application &&
          role.status === MissionRoleStatus.Open &&
          !role.application.withdrawn &&
          (role.application.status === MissionApplicationStatus.Shortlist ? (
            <RoleRowNotification icon="star">
              You've been shortlisted! Be ready for a potential client
              interview.
            </RoleRowNotification>
          ) : (
            <RoleRowNotification>
              Thanks for applying! We'll let you know when the client selects a
              team.
            </RoleRowNotification>
          ))}
      </div>

      {filteredApplications && filteredApplications?.length > 0 && (
        <FilteredApplications
          applicantsSortBy={applicantsSortBy}
          setApplicantsSortBy={setApplicantsSortBy}
          filteredApplications={filteredApplications}
          role={role}
          applications={applications}
          onApplicationClick={onApplicationClick}
          showMoreButton={showMoreButton}
        />
      )}
    </>
  );
}

export default observer(MissionRoleRow);
