import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { add } from 'date-fns';
import {
  AvailabilitySummaryObject,
  AvailableType,
} from '@a_team/models/dist/AvailabilityObject';
import { Checkbox, TextColors } from '@ateams/components';
import { DateInput } from '@src/components/DateInput';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import Availability, {
  AvailabilityMode,
  MAX_AVAILABILITY_HOURS,
} from '@src/views/Profile/Main/Availability';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import { TeamWorkWorkingHours } from '@src/views/Mission/EditMission/TeamWorkRolesInput/TeamWorkWorkingHours';

interface Props {
  onChange: (data: AvailabilitySummaryObject | null) => void;
  onChangeWorkingHours: (
    data: Pick<RoleFilter, 'whTz' | 'whFrom' | 'whTo' | 'whOl'> | null,
  ) => void;
  setTypes: (
    data: Partial<{
      useEstimatedAvailability?: boolean;
      includeUnknownAvailability?: boolean;
      includeNotAvailable?: boolean;
    }>,
  ) => void;
  useEstimatedAvailability?: boolean;
  availability?: AvailabilitySummaryObject | null;
  availabilityDate?: string;
  weeklyHoursAvailable?: number;
  includeUnknownAvailability?: boolean;
  includeNotAvailable?: boolean;
  whTz?: string;
  whFrom?: number;
  whTo?: number;
  whOl?: number;
  sidebar?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  blockDatePickerWrapper: {
    width: '100%',
    borderRadius: 8,
    height: 'fit-content',
  },
  blockDatePicker: {
    padding: '0.6em 0.5em',
    marginBottom: 12,
    width: '100%',
    lineHeight: 'initial',
    borderRadius: 8,
    border: `1px solid ${TextColors.regularDark}`,
    '&:focus': {
      border: '1px solid #000',
    },
  },
  hourlyInput: {
    margin: 0,
    '& > div': {
      minHeight: 'unset',
      padding: '0.6em 0.5em',
    },
  },
  checkbox: {
    marginTop: '0.7em',
  },
  workingHours: {
    marginTop: '0.7em',
  },
});

const AvailabilityInput = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    onChange,
    onChangeWorkingHours,
    setTypes,
    useEstimatedAvailability,
    availabilityDate,
    weeklyHoursAvailable,
    includeUnknownAvailability,
    includeNotAvailable,
    availability,
    whFrom,
    whTo,
    whOl,
    whTz,
    sidebar = false,
  } = props;

  const handleDateChange = (input: Date | null): void => {
    if (!input) return;

    onChange({
      type: AvailableType.FutureDate,
      availableFrom: input?.toISOString().split('T')[0],
      weeklyHoursAvailable: Number(weeklyHoursAvailable),
    });
  };

  const handleHourChange = (input: string): void => {
    const inputHours = Number(input);
    if (isNaN(inputHours)) return;

    const clamped = Math.min(MAX_AVAILABILITY_HOURS, Math.max(0, inputHours));
    onChange({
      type: AvailableType.FutureDate,
      availableFrom: availabilityDate,
      weeklyHoursAvailable: clamped,
    });
  };

  /** @deprecated: remove when adding links from mission positions **/
  if (!sidebar) {
    return (
      <Availability
        withStatusSelect
        availability={availability || undefined}
        mode={AvailabilityMode.Inline}
        onChange={onChange}
        popperBelow={true}
        promptFontSize={14}
      />
    );
  }

  return (
    <div className={styles.container}>
      <DateInput
        minDate={add(new Date(), { days: 0 })}
        popperPlacement={'right'}
        selected={
          availabilityDate
            ? new Date(availabilityDate + 'T00:00:01')
            : add(new Date(), { days: 14 })
        }
        className={styles.blockDatePicker}
        wrapperClassName={styles.blockDatePickerWrapper}
        placeholderText="Latest start date..."
        onChange={(input) => handleDateChange(input as Date | null)}
      />

      <OutlinedInput
        key={weeklyHoursAvailable}
        type="number"
        className={styles.hourlyInput}
        onBlur={(e) => handleHourChange(e.target.value)}
        defaultValue={weeklyHoursAvailable}
        endAdornment={<span style={{ marginLeft: 4 }}>hrs/wk</span>}
        placeholder={'Hours'}
        max={MAX_AVAILABILITY_HOURS}
      />

      <div className={styles.workingHours}>
        <div style={{ marginBottom: '8px' }}>Working Hours</div>
        <TeamWorkWorkingHours
          key={`${whFrom}-${whTo}-${whTz}-${whOl}`}
          defaultValue={{
            name: whTz,
            daily: [{ startTime: whFrom, endTime: whTo }],
            numberOfMinutesOverlap: whOl,
          }}
          onChange={(params) => {
            const { name, daily = [], numberOfMinutesOverlap } = params ?? {};
            const { startTime, endTime } = daily[0] ?? {};
            onChangeWorkingHours({
              whTz: name,
              whFrom: startTime,
              whTo: endTime,
              whOl: numberOfMinutesOverlap,
            });
          }}
        />
      </div>
      <Checkbox
        className={styles.checkbox}
        onChange={(e) => {
          setTypes({
            useEstimatedAvailability: e.target.checked,
          });
        }}
        checked={useEstimatedAvailability ?? false}
        label="Use estimated availability"
        margin="none"
      />
      <Checkbox
        className={styles.checkbox}
        onChange={(e) =>
          setTypes({
            includeUnknownAvailability: e.target.checked
              ? undefined
              : e.target.checked,
          })
        }
        checked={includeUnknownAvailability ?? true}
        label="Include unknown availability"
        margin="none"
      />

      <Checkbox
        className={styles.checkbox}
        onChange={(e) =>
          setTypes({
            includeNotAvailable: e.target.checked
              ? e.target.checked
              : undefined,
          })
        }
        checked={includeNotAvailable}
        label="Include not available"
        margin="none"
      />
    </div>
  );
};

export default AvailabilityInput;
