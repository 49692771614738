import React, { ReactElement, useEffect, useState } from 'react';
import { AsyncSelect, SelectOption } from '@ateams/components';

import { apiMissions } from '@src/logic/services/endpoints';
import { useStores } from '@src/stores';

interface Props {
  selectedMission?: string;
  onChange: (mission: string | undefined) => void;
  noBorder?: boolean;
}

const MissionSearch = (props: Props): ReactElement => {
  const { auth } = useStores();
  const { selectedMission, onChange } = props;

  const [mission, setMission] = useState<SelectOption | null>(null);

  useEffect(() => {
    if (!selectedMission) return setMission(null);

    if (selectedMission !== mission?.value) {
      apiMissions.getMissionById(auth, selectedMission).then((mission) => {
        setMission({ value: mission.mid, label: mission.title });
      });
    }
  }, [selectedMission]);

  const searchMissions = async (
    searchTerm: string,
  ): Promise<SelectOption[]> => {
    if (!searchTerm.trim()) return [];

    const missions = await apiMissions.adminFindMissions(
      auth,
      searchTerm.trim(),
    );

    return missions.map(({ mid, title }) => ({ value: mid, label: title }));
  };

  const selectMission = async (
    selection: SelectOption | SelectOption[] | null,
  ): Promise<void> => {
    if (!selection) {
      setMission(null);
      onChange(undefined);
      return;
    }

    const selected = Array.isArray(selection) ? selection[0] : selection;
    setMission(selected);
    onChange(selected.value);
  };

  return (
    <AsyncSelect
      isClearable
      noBorder={props.noBorder}
      value={mission}
      loadOptions={searchMissions}
      onChange={selectMission}
      placeholder={'Choose a mission...'}
      margin={'none'}
      hideTags
    />
  );
};

export default MissionSearch;
