import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Spacing, TextColors } from '@ateams/components';

export interface InputContainerProps {
  error?: string;
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    marginTop: Spacing.small,
    color: TextColors.dangerLight,
    fontSize: '14px',
  },
});

export const InputContainer: React.FC<InputContainerProps> = (props) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.container, props.className)}>
      {props.children}

      {props.error && <div className={styles.error}>{props.error}</div>}
    </div>
  );
};
