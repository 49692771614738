import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  BasicRegisteredUserObject,
  CurrentUserObject,
  RegisteredUserObject,
  UserUsername,
  ScrubbingInfoUserObject,
  GoogleAuthUserInfo,
} from '@a_team/models/dist/UserObject';
import LocationObject from '@a_team/models/dist/LocationObject';
import { InvitationId } from '@a_team/models/dist/InvitationObject';
import ApiToken from '@a_team/models/dist/ApiToken';
import {
  ExperienceData,
  ExperienceSource,
} from '@a_team/models/dist/ExperienceObject';
import { MissionId } from '@a_team/models/dist/MissionObject';
import { LoginWithCredentials } from './Auth';
import { RoleCategoryId } from '@a_team/models/dist/RoleCategory';
import UserQuestionnaire from '@a_team/models/dist/UserQuestionnaire';
import TeamObject from '@a_team/models/dist/TeamObject';
import { TeamData } from './Teams';
import PortfolioObject from '@a_team/models/dist/PortfolioObject';
import { NUXQuestionnaire } from '@a_team/models/src/NuxQuestionnaire';

export const BasePath = '/registration';

export interface UTMParams {
  metadataCid?: string;
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
  referralCode?: string;
}

export interface ValidateUsernameResponse {
  reformattedUsername: string;
  isValid: boolean;
}

export interface RegistrationResponse {
  token: ApiToken;
  user: RegisteredUserObject;
  onboardingToken?: ApiToken;
}

export interface RegistrationPersonalInfo {
  password?: string;
  username: UserUsername;
  location?: LocationObject;
  roleCategory: RoleCategoryId | null;
  acceptTOS: boolean;
}

export interface RegistrationContactInfo {
  email: string;
  firstName: string;
  lastName: string;
  isNewOnboarding?: boolean;
}

export interface RegistrationSocialNetworks {
  linkedInUsername?: string;
  githubUsername?: string;
  behanceUsername?: string;
  mediumUsername?: string;
  productHuntUsername?: string;
  dribbbleUsername?: string;
}

export interface RegistrationReferral {
  referralCode: string;
}

export interface RegistrationSocialNetworksRequest
  extends RegistrationSocialNetworks {
  scanAll?: boolean;
  linkedInOptOut?: boolean;
}

export type SocialExperience = {
  altImageURLs?: string[];
} & Omit<ExperienceData, 'members'>;

export interface DesignerPortfolioResponse {
  user: RegisteredUserObject;
}

export interface RegistrationDesignerPortfolioRequest {
  portfolio: PortfolioObject;
}

export interface RegistrationRateInfo {
  minRate: number;
  maxRate: number;
}

export interface SocialNetworksResponse {
  user: RegisteredUserObject;
  newExperiences: SocialExperience[];
  updatedSources: ExperienceSource[];
}

export interface RegistrationCompleteRequest {
  experiences?: SocialExperience[];
  utmParams?: UTMParams;
  isNewOnboarding?: boolean;
  isWaitlisted?: boolean;
  isVipWaitlisted?: boolean;
  skipClientEmail?: boolean;
}

export interface RegistrationCompleteResponse {
  token: ApiToken;
  user: CurrentUserObject;
}

export interface CompanyUserRegistrationPayload {
  email: string;
  password: string;
}

export interface CreateCompanyUserPayload {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  utmParams?: UTMParams;
  recycleAccount?: boolean;
  NUXQuestionnaire?: NUXQuestionnaire;
}

export interface CreateCompanyUserCollaboratorPayload
  extends CreateCompanyUserPayload {
  mid?: MissionId;
}

export interface RegisterWithEmailRequest {
  email: string;
  firstName: string;
  lastName: string;
  utmParams?: UTMParams;
}

export interface VerifyUserResponse {
  registeredUser: RegisteredUserObject;
  onboardingToken: ApiToken;
}

// exported functions

export default class RegistrationEndpoint extends ServiceEndpoint {
  public validateUsername(
    auth: ServiceAuth,
    username: string,
  ): Promise<ValidateUsernameResponse> {
    return this.fetch(auth, BasePath + '/validate-username', null, 'post', {
      username,
    });
  }

  public validateEmail(
    auth: ServiceAuth | null,
    email: string,
  ): Promise<boolean> {
    return this.fetch(auth, BasePath + '/validate-email', null, 'post', {
      email,
    });
  }

  public registerCompanyByRequest(
    rid: MissionId,
    data: CompanyUserRegistrationPayload,
  ): Promise<RegistrationResponse> {
    return this.fetch(
      null,
      BasePath + '/company-user',
      { request: rid },
      'post',
      data,
    );
  }

  public adminCreateCompanyUser(
    auth: ServiceAuth,
    data: CreateCompanyUserPayload,
  ): Promise<RegistrationResponse> {
    return this.fetch(auth, BasePath + '/company/create', null, 'post', data);
  }

  public createCompanyUser(
    auth: ServiceAuth | null,
    data: CreateCompanyUserPayload,
  ): Promise<RegistrationResponse> {
    return this.fetch(
      auth,
      BasePath + '/company/create-user',
      null,
      'post',
      data,
    );
  }

  public createCollaborator(
    auth: ServiceAuth | null,
    data: CreateCompanyUserCollaboratorPayload,
  ): Promise<BasicRegisteredUserObject> {
    return this.fetch(
      auth,
      BasePath + '/company/create-collaborator',
      null,
      'post',
      data,
    );
  }

  public register(
    invitationId: InvitationId,
    nonce: string,
    data: LoginWithCredentials,
  ): Promise<RegistrationResponse> {
    return this.fetch(
      null,
      BasePath,
      { invitation: invitationId, nonce },
      'post',
      data,
    );
  }

  public registerWithEmail(
    request: RegisterWithEmailRequest,
  ): Promise<RegistrationResponse> {
    return this.fetch(null, BasePath + '/email', null, 'post', { ...request });
  }

  public registerTeam(auth: ServiceAuth, data: TeamData): Promise<TeamObject> {
    return this.fetch(auth, BasePath + '/team', null, 'post', data);
  }

  public setPersonalInfo(
    auth: ServiceAuth,
    personalInfo: RegistrationPersonalInfo,
  ): Promise<RegisteredUserObject> {
    return this.fetch(
      auth,
      BasePath + '/personal-info',
      null,
      'post',
      personalInfo,
    );
  }

  public setContactInfo(
    auth: ServiceAuth,
    contactInfo: RegistrationContactInfo,
  ): Promise<RegisteredUserObject> {
    return this.fetch(
      auth,
      BasePath + '/contact-info',
      null,
      'post',
      contactInfo,
    );
  }

  public setQuestionnaire(
    auth: ServiceAuth,
    questionnaire: UserQuestionnaire,
  ): Promise<RegisteredUserObject> {
    return this.fetch(
      auth,
      BasePath + '/questionnaire',
      null,
      'post',
      questionnaire,
    );
  }

  public setSocialNetworks(
    auth: ServiceAuth,
    data: RegistrationSocialNetworksRequest,
  ): Promise<SocialNetworksResponse> {
    return this.fetch(auth, BasePath + '/social-networks', null, 'post', data);
  }

  public setDesignerPortfolio(
    auth: ServiceAuth,
    data: RegistrationDesignerPortfolioRequest,
  ): Promise<DesignerPortfolioResponse> {
    return this.fetch(
      auth,
      BasePath + '/designer-portfolio',
      null,
      'post',
      data,
    );
  }

  public setRateInfo(
    auth: ServiceAuth,
    data: RegistrationRateInfo,
  ): Promise<RegisteredUserObject> {
    return this.fetch(auth, BasePath + '/rates', null, 'post', data);
  }

  public setReferral(
    auth: ServiceAuth,
    data: RegistrationReferral,
  ): Promise<boolean> {
    return this.fetch(auth, BasePath + '/referral', null, 'post', data);
  }

  public complete(
    auth: ServiceAuth,
    request: RegistrationCompleteRequest,
  ): Promise<RegistrationCompleteResponse> {
    return this.fetch(auth, BasePath + '/complete', null, 'post', request);
  }

  public sendVerificationEmail(auth: ServiceAuth): Promise<void> {
    return this.fetch(auth, BasePath + '/verification', null, 'post');
  }

  public sendWaitlistEmail(auth: ServiceAuth): Promise<void> {
    return this.fetch(auth, BasePath + '/waitlist', null, 'post');
  }

  public findScrubbingInfoByEmail(
    auth: ServiceAuth,
    email: string,
  ): Promise<ScrubbingInfoUserObject> {
    return this.fetch(auth, `${BasePath}/scrubbing-info/${email}`, null, 'get');
  }

  public decodeGoogleAuthToken(
    auth: ServiceAuth,
    credential: string,
    clientId: string,
  ): Promise<GoogleAuthUserInfo> {
    return this.fetch(
      auth,
      `${BasePath}/decode-google-auth-token`,
      null,
      'post',
      {
        credential,
        clientId,
      },
    );
  }

  /** @experimental **/
  public formationCreateCompanyUser(
    auth: ServiceAuth,
    data: CreateCompanyUserPayload,
  ): Promise<RegistrationResponse> {
    return this.fetch(
      auth,
      BasePath + '/company/formation-create',
      null,
      'post',
      data,
    );
  }
}
