import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import { Expertise } from '@src/stores/Profile/models';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { useScreenClass } from 'react-grid-system';
import { Icon, IconType } from '@ateams/components';

export type AnswerScore = 1 | 2 | 3 | 4 | 5;

export interface AnswerScoreRowProps {
  item: Expertise;
  scoreOptions?: number[];
  onChange: (item: Expertise) => void;
  readonly?: boolean;
  withFeaturedCheckbox?: boolean;
  featuredTooltip?: string;
  scoreTooltips?: { [k: number]: string };
  setFeaturedOnScores?: number[];
  minLabel?: string;
  maxLabel?: string;
  bgColor?: string;
  disableFeatured?: boolean;
  onRemove?: (item: Expertise) => void;
}

const defaultProps = {
  scoreOptions: [1, 2, 3, 4, 5],
};

const useStyles = createUseStyles({
  row: {
    margin: '0 8px 24px 8px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  num: (props: AnswerScoreRowProps) => ({
    cursor: 'pointer',
    display: props.readonly ? 'none' : 'flex',
    width: '22%',
    maxWidth: 60,
    minWidth: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginLeft: 12,
    background: props.bgColor || '#fff',
    ...(props.readonly && {
      pointerEvents: 'none',
    }),
    '&:last-of-type': {
      marginRight: 12,
    },
  }),
  selected: {
    background: '#79CE00 !important',
    color: '#fff !important',
    display: 'flex',
  },
  title: {
    fontWeight: 500,
  },
  label: {
    fontSize: 12,
    color: '#62646A',
  },
  removeButton: {
    marginRight: 8,
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    outline: 'none',
  },
});

const AnswerScoreRow = (props: AnswerScoreRowProps): ReactElement => {
  const styles = useStyles(props);
  const screenClass = useScreenClass();
  const isMobile = screenClass === 'xs' || screenClass === 'sm';
  const {
    item,
    onChange,
    minLabel,
    maxLabel,
    withFeaturedCheckbox,
    setFeaturedOnScores,
    scoreOptions,
    featuredTooltip,
    scoreTooltips,
    disableFeatured,
    onRemove,
  } = props;

  const onScoreChange = (score: number) => {
    const numberSelected = item.rating === score;

    onChange({
      ...item,
      rating: numberSelected ? undefined : (score as AnswerScore),
      featured: numberSelected
        ? false
        : setFeaturedOnScores?.length && setFeaturedOnScores.includes(score)
        ? true
        : item.featured,
    });
  };

  return (
    <div className={styles.row}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {item.name && (
          <span className={styles.title}>
            {onRemove && (
              <button
                className={styles.removeButton}
                onClick={() => {
                  onRemove(item);
                }}
              >
                <Icon type={IconType.Close} size="xsmall" />
              </button>
            )}

            {item.name}
          </span>
        )}
        {withFeaturedCheckbox && (
          <TooltipWrapped
            arrow
            title={featuredTooltip}
            disabled={!featuredTooltip || isMobile}
          >
            <LabeledCheckboxInput
              label={'Featured'}
              labelPosition={'left'}
              disabled={!item.rating || (!item.featured && disableFeatured)}
              onChange={(e) =>
                onChange({ ...item, featured: e.target.checked })
              }
              margin="none"
              checked={item.rating && item.featured}
            />
          </TooltipWrapped>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 12,
          marginBottom: 12,
        }}
      >
        {scoreOptions &&
          scoreOptions.map((num) => {
            const numberSelected = item.rating === num;
            return (
              <div
                key={num}
                className={cx(styles.num, {
                  [styles.selected]: numberSelected,
                })}
                onClick={() => onScoreChange(num)}
              >
                <TooltipWrapped
                  arrow
                  title={scoreTooltips ? scoreTooltips[num] : undefined}
                  disabled={isMobile || !scoreTooltips || !scoreTooltips[num]}
                >
                  {num}
                </TooltipWrapped>
              </div>
            );
          })}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className={styles.label}>{minLabel}</span>
        <span className={styles.label}>{maxLabel}</span>
      </div>
    </div>
  );
};

AnswerScoreRow.defaultProps = defaultProps;
export default AnswerScoreRow;
