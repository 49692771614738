import { Modal } from '@a_team/ui-components';
import HideScrollerGlobally from '@src/components/HideScrollerGlobally';
import {
  ExistingProject,
  Expertise,
  LocalProjects,
} from '@src/stores/Profile/models';
import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import Header from './sections/Header';
import Images from './sections/Images';
import Role from './sections/Role';
import Skills from './sections/Skills';
import Experience from './sections/Experience';
import Metrics from './sections/Metrics';
import Description from './sections/Description';
import Collaborators from './sections/Collaborators';
import { ExperienceUserMember } from '@a_team/models/dist/ExperienceObject';
import OtherProjects from './sections/OtherProjects';
import { ApplicationStoreData } from '@src/stores/Application';
import { Breakpoints } from '@ateams/components';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import RelatedJob from './sections/RelatedJob';
import { format } from 'date-fns';

interface ViewProjectModalV2Props {
  viewModalOpen: boolean;
  setViewModalOpen: (val: boolean) => void;
  onClose: () => void;
  currentUserId: string | null;
  selectedProject: ExistingProject;
  allIndustries?: Expertise[];
  allProfileSkills?: Expertise[];
  projects: LocalProjects;
  hiddenProjects?: ApplicationStoreData['hiddenProjects'];
  onOpenProject?: (projectId: string) => void;
}

const useStyles = createUseStyles({
  modal: {
    overflowY: 'hidden',
    '& > div': {
      padding: 0,
    },
  },
  wrapper: {
    height: '100%',
    overflowY: 'scroll',
  },
  container: {
    margin: 24,
  },
  divider: {
    marginTop: 64,
    height: 1,
    borderBottom: '1px solid #DADADC',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '90vw !important',
      maxWidth: '1200px !important',
      padding: 0,
      borderRadius: '16px !important',
      height: '90vh !important',
      maxHeight: '90vh !important',
      '& > div': {
        padding: 0,
      },
    },
    container: {
      maxWidth: '800px',
      margin: '40px auto',
    },
  },
});

const ViewProjectModalV2 = ({
  viewModalOpen,
  setViewModalOpen,
  onClose,
  currentUserId,
  selectedProject,
  allIndustries,
  allProfileSkills,
  projects,
  hiddenProjects,
  onOpenProject,
}: ViewProjectModalV2Props): JSX.Element => {
  const { auth } = useStores();
  const styles = useStyles();
  const modalRef = useRef(null as HTMLInputElement | null);

  let descriptionHTML = selectedProject.descriptionHTML;

  if (!descriptionHTML) {
    if (selectedProject.description) {
      descriptionHTML = `<h3>Project Details</h3><p>${selectedProject.description}</p>`;
    }
  }

  const handleOpenProject = (projectId: string) => {
    if (!onOpenProject) {
      return;
    }

    onOpenProject(projectId);
    modalRef.current?.scrollTo(0, 0);
  };

  return (
    <Modal
      variant="slideUp"
      isOpen={viewModalOpen}
      onClose={() => {
        onClose();
        setViewModalOpen(false);
      }}
      className={styles.modal}
      shouldHideGradientStroke={true}
    >
      <div ref={modalRef} className={styles.wrapper}>
        <div className={styles.container}>
          <Header
            logoUrl={selectedProject.logoURL}
            title={selectedProject.title}
            companyName={
              // rm observer wrapper from component export
              auth.withEnrichedCompaniesLinkedToExperiences
                ? selectedProject.companyName ?? undefined
                : selectedProject.companyData?.name
            }
            websiteUrl={selectedProject.websiteURL ?? undefined}
            industry={selectedProject.industry}
            industries={selectedProject.industries}
            allIndustries={allIndustries}
            projectUrl={selectedProject.projectUrl}
          />

          <Images images={selectedProject.imagesUrls ?? []} />
          {(!!selectedProject?.jobRole || selectedProject?.jobRoleId) && (
            <Role
              jobRole={selectedProject.jobRole}
              jobRoleId={selectedProject.jobRoleId}
              startDate={selectedProject.startDate}
              endDate={selectedProject.endDate}
            />
          )}
          {!!selectedProject?.skills && !!allProfileSkills && (
            <Skills
              skills={selectedProject.skills}
              allProfileSkills={allProfileSkills}
            />
          )}
          {(!!selectedProject?.hasZeroToOneExperience ||
            (!!selectedProject?.hasManagedPeople &&
              selectedProject.numberOfPeopleManaged)) && (
            <Experience
              hasZeroToOneExperience={selectedProject.hasZeroToOneExperience}
              hasManagedPeople={selectedProject.hasManagedPeople}
              numberOfPeopleManaged={selectedProject.numberOfPeopleManaged}
            />
          )}

          {!!selectedProject?.metrics && (
            <Metrics metrics={selectedProject.metrics} />
          )}
          {!!descriptionHTML && (
            <Description key={descriptionHTML} description={descriptionHTML} />
          )}
          {!!selectedProject.members && (
            <Collaborators
              members={selectedProject.members as ExperienceUserMember[]}
            />
          )}
          {!!selectedProject.relatedJob &&
            selectedProject.relatedJob.eid &&
            auth.withRelatedExperiences && (
              <RelatedJob
                // close prooject modal
                onCloseProjectModal={() => {
                  onClose();
                  setViewModalOpen(false);
                }}
                relatedJobId={selectedProject.relatedJob.eid}
                companyName={
                  selectedProject.relatedJob.companyName ||
                  selectedProject.companyData?.name
                }
                period={`${format(
                  new Date(selectedProject.relatedJob.startDate ?? ''),
                  'MMM, yyyy',
                )} - ${
                  selectedProject.relatedJob.endDate
                    ? format(
                        new Date(selectedProject.relatedJob.endDate ?? ''),
                        'MMM, yyyy',
                      )
                    : 'Present'
                }`}
                title={selectedProject.relatedJob.jobRole}
                logoUrl={
                  selectedProject.relatedJob.logoURL ||
                  selectedProject.relatedJob.companyData?.logoUrl
                }
              />
            )}

          <div className={styles.divider} />
          {!!projects && (
            <OtherProjects
              projects={projects}
              hiddenProjects={hiddenProjects}
              allIndustries={allIndustries}
              onOpenProject={handleOpenProject}
              selectedProjectId={selectedProject.eid}
            />
          )}
        </div>
      </div>

      {viewModalOpen && <HideScrollerGlobally />}
    </Modal>
  );
};

export default observer(ViewProjectModalV2);
