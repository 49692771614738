import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Spacing, TextColors } from '@ateams/components';
import {
  VettingFormVariant,
  VettingProcessUser,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { SubmitPreVettingFormPayload } from '@ateams/api/dist/endpoints/vetting-process-pre-vetting';
import {
  CompanySize,
  DidBuildProductsFromScratch,
  DidDesignedProductsFromScratch,
  ManagementExperience,
  PrimaryExpertise,
  ProductType,
  TypicalTeamSize,
  WeeklyHours,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { VettingProcessFeedbackRoles } from '@a_team/models/dist/vetting-processes/feedback';
import { OutlinedInput } from '../form/components/outlined-input';
import { usePreVettingFormErrors } from './use-errors';
import { InputContainer } from '../form/components/input-container';
import { InputLabel } from '../form/components/input-label';
import {
  DidBuildProductsFromScratchSelect,
  OnDidBuildProductsFromScratchChange,
} from '../form/inputs/build-products-from-scratch';
import {
  CompanySizesSelect,
  OnCompanySizesChange,
} from '../form/inputs/company-sizes';
import {
  InterviewAvailability,
  InterviewAvailabilityInput,
  InterviewAvailabilityType,
  OnInterviewAvailabilityChange,
  OnInterviewAvailabilityTypeChange,
} from '../form/inputs/interview-availability';
import {
  ManagementExperienceSelect,
  OnManagementExperienceChange,
} from '../form/inputs/management-experience';
import {
  CodeSample,
  CodeSampleInput,
  CodeSampleType,
  OnCodeSampleChange,
} from '../form/inputs/code-sample-input';
import {
  CaseStudy,
  CaseStudyInput,
  CaseStudyType,
  OnCaseStudyChange,
} from '../form/inputs/case-study';
import {
  OnPrimaryExpertiseChange,
  PrimaryExpertiseSelect,
} from '../form/inputs/primary-expertise';
import {
  OnProductTypeChange,
  ProductTypeSelect,
} from '../form/inputs/product-type';
import {
  HasExperienceWorkingOnAProduct,
  OnHasExperienceWorkingOnAProductChange,
} from '../form/inputs/has-experience-working-on-product';
import {
  HadProductDesignerOnTeam,
  OnHadProductDesignerOnTeamChange,
} from '../form/inputs/had-product-designer-on-team';
import {
  HasProfitAndLossResponsibility,
  OnHasProfitAndLossResponsibilityChange,
} from '../form/inputs/has-profit-and-loss-responsibility';
import {
  HasTechnicalBackground,
  OnHasTechnicalBackgroundChange,
} from '../form/inputs/has-technical-background';
import {
  OnTypicalTeamSizeChange,
  TypicalTeamSizeSelect,
} from '../form/inputs/typical-team-size';
import {
  OnPrimaryRoleChange,
  PrimaryRoleSelect,
} from '../form/inputs/primary-role';
import {
  DesignedProductsFromScratchSelect,
  OnDesignedProductsFromScratchChange,
} from '../form/inputs/designed-products-from-scratch';

export interface PreVettingFormOldProps {
  variant: VettingFormVariant;
  user: VettingProcessUser;
  onSubmit: OnPreVettingFormSubmit;
}

export type OnPreVettingFormSubmit = (
  payload: SubmitPreVettingFormPayload,
) => unknown;

const useStyles = createUseStyles({
  container: {
    width: '518px',
    margin: '0 auto',
    padding: '0 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontFamily: 'Inter',
  },
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  input: {
    fontSize: '15px',
    margin: 0,
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '26px',
    letterSpacing: '0.002em',
    color: TextColors.regular,
    marginTop: '20px',
  },
  subTitle: {
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: '0.002em',
    color: TextColors.regular,
    margin: '4px 0 20px 0',
  },
  submitButton: {
    width: '172px',
    marginTop: '8px',
    marginBottom: '16px',
  },
  [`@media (max-width: 500px)`]: {
    container: {
      width: '100vw',
      padding: '0 16px',
      margin: 0,
      justifyContent: 'flex-start',
    },
    title: {
      marginTop: 0,
    },
    submitButton: {
      width: '100%',
      marginBottom: 0,
    },
  },
});

export const PreVettingFormOld: React.FC<PreVettingFormOldProps> = (props) => {
  const { variant, user } = props;
  const styles = useStyles();

  const [primaryExpertise, setPrimaryExpertise] = useState<PrimaryExpertise[]>(
    [],
  );
  const [primaryRoles, setPrimaryRoles] = useState<
    VettingProcessFeedbackRoles[]
  >([]);
  const [companySize, setCompanySize] = useState<CompanySize[]>([]);
  const [productType, setProductType] = useState<ProductType[]>([]);
  const [didBuildProductsFromScratch, setDidBuildProductsFromScratch] =
    useState<DidBuildProductsFromScratch>();
  const [didDesignedProductsFromScratch, setDidDesignedProductsFromScratch] =
    useState<DidDesignedProductsFromScratch>();
  const [managementExperience, setManagementExperience] =
    useState<ManagementExperience>();
  const [interviewAvailability, setInterviewAvailability] =
    useState<InterviewAvailability>({
      interviewAvailabilityType: InterviewAvailabilityType.CalendarURL,
    });
  const [codeSample, setCodeSample] = useState<CodeSample>({
    codeSampleProgrammingLanguages: [],
    otherCodeSampleProgrammingLanguage: '',
    codeSampleType: CodeSampleType.URL,
    codeSampleRemoteUrl: '',
    codeSampleFile: undefined,
    codeSampleNotes: '',
  });
  const [caseStudy, setCaseStudy] = useState<CaseStudy>({
    caseStudyType: CaseStudyType.URL,
    caseStudyRemoteUrl: '',
    caseStudyFile: undefined,
    hadDesignedCaseStudyAsTeamOrIndividual: '',
  });
  const [hasExperienceWorkingOnAProduct, setHasExperienceWorkingOnAProduct] =
    useState<boolean>();
  const [typicalTeamSize, setTypicalTeamSize] = useState<TypicalTeamSize>();
  const [hadProductDesignerOnTeam, setHadProductDesignerOnTeam] =
    useState<boolean>();
  const [hasProfitAndLossResponsibility, setHasProfitAndLossResponsibility] =
    useState<boolean>();
  const [hasTechnicalBackground, setHasTechnicalBackground] =
    useState<boolean>();
  const { formErrors, validators, clearErrors, checkHasErrors } =
    usePreVettingFormErrors(
      variant,
      primaryExpertise,
      primaryRoles,
      companySize,
      productType,
      didBuildProductsFromScratch,
      managementExperience,
      interviewAvailability,
      codeSample,
      caseStudy,
      hasExperienceWorkingOnAProduct,
      typicalTeamSize,
      hadProductDesignerOnTeam,
      hasTechnicalBackground,
    );

  const onSubmit = () => {
    const hasErrors = checkHasErrors();
    if (hasErrors) {
      return;
    }

    let payload: SubmitPreVettingFormPayload;
    if (
      [
        VettingFormVariant.ProjectManagement,
        VettingFormVariant.ProductManagement,
      ].includes(variant)
    ) {
      payload = {
        variant,
        primaryExpertise,
        companySize,
        productType,
        hasExperienceWorkingOnAProduct,
        typicalTeamSize,
        hadProductDesignerOnTeam,
        hasProfitAndLossResponsibility,
        hasTechnicalBackground,
      } as SubmitPreVettingFormPayload;
    } else if (variant === VettingFormVariant.Design) {
      payload = {
        variant,
        primaryRoles,
        companySize,
        didDesignedProductsFromScratch,
        caseStudy: (caseStudy.caseStudyType === CaseStudyType.URL
          ? caseStudy.caseStudyRemoteUrl
          : caseStudy.caseStudyFile) as string | File,
        hadDesignedCaseStudyAsTeamOrIndividual:
          caseStudy.hadDesignedCaseStudyAsTeamOrIndividual,
      } as SubmitPreVettingFormPayload;
    } else {
      payload = {
        variant: VettingFormVariant.SoftwareEngineering,
        companySize,
        didBuildProductsFromScratch,
        managementExperience,
        codeSampleProgrammingLanguages:
          codeSample.codeSampleProgrammingLanguages,
        otherCodeSampleProgrammingLanguage:
          codeSample.otherCodeSampleProgrammingLanguage,
        codeSample: (codeSample.codeSampleType === CodeSampleType.URL
          ? codeSample.codeSampleRemoteUrl
          : codeSample.codeSampleFile) as string | File,
        codeSampleNotes: codeSample.codeSampleNotes,
      } as SubmitPreVettingFormPayload;
    }

    if (
      interviewAvailability.interviewAvailabilityType ===
      InterviewAvailabilityType.CalendarURL
    ) {
      payload.calendarUrl = interviewAvailability?.calendarUrl as string;
    } else {
      payload.weeklyHours = interviewAvailability?.weeklyHours as WeeklyHours;
    }

    props.onSubmit(payload);
  };

  const onPrimaryExpertiseChange: OnPrimaryExpertiseChange = (
    primaryExpertises,
  ) => {
    setPrimaryExpertise(primaryExpertises);
    validators.validatePrimaryExpertise(primaryExpertises);
  };

  const onPrimaryRolesChange: OnPrimaryRoleChange = (primaryRoles) => {
    setPrimaryRoles(primaryRoles);
    validators.validatePrimaryRoles(primaryRoles);
  };

  const onCompanySizesChange: OnCompanySizesChange = (companySizes) => {
    setCompanySize(companySizes);
    validators.validateCompanySize(companySizes);
  };

  const onProductTypeChange: OnProductTypeChange = (productTypes) => {
    setProductType(productTypes);
    validators.validateProductType(productTypes);
  };

  const onDidBuildProductsFromScratchChange: OnDidBuildProductsFromScratchChange =
    (didBuildProductsFromScratch) => {
      setDidBuildProductsFromScratch(didBuildProductsFromScratch);
      validators.validateDidBuildProductsFromScratch(
        didBuildProductsFromScratch,
      );
    };

  const onDidDesignedProductsFromScratchChange: OnDesignedProductsFromScratchChange =
    (didDesignedProductsFromScratch) => {
      setDidDesignedProductsFromScratch(didDesignedProductsFromScratch);
    };

  const onManagementExperienceChange: OnManagementExperienceChange = (
    managementExprience,
  ) => {
    setManagementExperience(managementExprience);
    validators.validateManagementExperience(managementExprience);
  };

  const onInterviewAvailabilityChange: OnInterviewAvailabilityChange = (
    interviewAvailability,
  ) => {
    setInterviewAvailability(interviewAvailability);

    if (interviewAvailability.weeklyHours?.timezone) {
      clearErrors.clearInterviewAvailabilityTimezoneError();
    }
  };

  const onInterviewAvailabilityTypeChange: OnInterviewAvailabilityTypeChange =
    () => {
      clearErrors.clearInterviewAvailabilityErrors();
    };

  const onCodeSampleChange: OnCodeSampleChange = (codeSampleChanges) => {
    setCodeSample((codeSample) => ({ ...codeSample, ...codeSampleChanges }));
  };

  const onCaseStudyChange: OnCaseStudyChange = (caseStudyChanges) => {
    setCaseStudy((caseStudy) => ({ ...caseStudy, ...caseStudyChanges }));
  };

  const onHasExperienceWorkingOnAProductChange: OnHasExperienceWorkingOnAProductChange =
    (hasExperienceWorkingOnAProduct) => {
      setHasExperienceWorkingOnAProduct(hasExperienceWorkingOnAProduct);
      validators.validateHasExperienceWorkingOnAProduct(
        hasExperienceWorkingOnAProduct,
      );
    };

  const onTypicalTeamSizeChange: OnTypicalTeamSizeChange = (
    typicalTeamSize,
  ) => {
    setTypicalTeamSize(typicalTeamSize);
    validators.validateTypicalTeamSize(typicalTeamSize);
  };

  const onHadProductDesignerOnTeamChange: OnHadProductDesignerOnTeamChange = (
    hadProductDesignerOnTeam,
  ) => {
    setHadProductDesignerOnTeam(hadProductDesignerOnTeam);
    validators.validateHadProductDesignerOnTeam(hadProductDesignerOnTeam);
  };

  const onHasProfitAndLossResponsibilityChange: OnHasProfitAndLossResponsibilityChange =
    (hasProfitAndLossResponsibility) => {
      setHasProfitAndLossResponsibility(hasProfitAndLossResponsibility);
    };

  const onHasTechnicalBackgroundChange: OnHasTechnicalBackgroundChange = (
    hasTechnicalBackground,
  ) => {
    setHasTechnicalBackground(hasTechnicalBackground);
    validators.validateHasTechnicalBackground(hasTechnicalBackground);
  };

  return (
    <div className={styles.container} data-testing-id={'pre-vetting-form'}>
      <div className={styles.title}>A Few Questions Before Our Call</div>
      <div className={styles.subTitle}>
        This information will help the person you'll be speaking guide the
        conversation. Thanks for taking the time!
      </div>

      <InputContainer className={styles.marginBottom}>
        <InputLabel>Full Name</InputLabel>

        <OutlinedInput
          value={user.fullName}
          disabled
          data-testing-id={'pre-vetting-form-full-name'}
        />
      </InputContainer>

      <InputContainer className={styles.marginBottom}>
        <InputLabel>Role</InputLabel>

        <OutlinedInput
          value={user.roleCategory?.title}
          disabled
          data-testing-id={'pre-vetting-form-role'}
        />
      </InputContainer>

      <InputContainer className={styles.marginBottom}>
        <InputLabel>Email Address</InputLabel>

        <OutlinedInput
          value={user.email}
          disabled
          className={styles.marginBottom}
          data-testing-id={'pre-vetting-form-email'}
        />
      </InputContainer>

      {[
        VettingFormVariant.ProductManagement,
        VettingFormVariant.ProjectManagement,
      ].includes(variant) && (
        <PrimaryExpertiseSelect
          value={primaryExpertise}
          onChange={onPrimaryExpertiseChange}
          error={formErrors.primaryExpertise}
          onBlur={() => validators.validatePrimaryExpertise()}
          className={styles.marginBottom}
          selectClassName={styles.input}
          data-testing-id={'pre-vetting-form-primary-expertise'}
        />
      )}

      {[VettingFormVariant.Design].includes(variant) && (
        <PrimaryRoleSelect
          value={primaryRoles}
          onChange={onPrimaryRolesChange}
          error={formErrors.primaryRoles}
          onBlur={() => validators.validatePrimaryRoles()}
          className={styles.marginBottom}
          selectClassName={styles.input}
          data-testing-id={'pre-vetting-form-primary-roles'}
        />
      )}

      <CompanySizesSelect
        value={companySize}
        onChange={onCompanySizesChange}
        error={formErrors.companySize}
        onBlur={() => validators.validateCompanySize()}
        className={styles.marginBottom}
        selectClassName={styles.input}
        data-testing-id={'pre-vetting-form-company-size'}
      />

      {[
        VettingFormVariant.ProductManagement,
        VettingFormVariant.ProjectManagement,
      ].includes(variant) && (
        <ProductTypeSelect
          value={productType}
          onChange={onProductTypeChange}
          error={formErrors.productType}
          onBlur={() => validators.validateProductType()}
          className={styles.marginBottom}
          selectClassName={styles.input}
          data-testing-id={'pre-vetting-form-product-type'}
        />
      )}

      {[VettingFormVariant.SoftwareEngineering].includes(variant) && (
        <DidBuildProductsFromScratchSelect
          error={formErrors.didBuildProductsFromScratch}
          onChange={onDidBuildProductsFromScratchChange}
          onBlur={() => validators.validateDidBuildProductsFromScratch()}
          className={styles.marginBottom}
          selectClassName={styles.input}
          data-testing-id={'pre-vetting-form-did-build-products-from-scratch'}
        />
      )}

      {[VettingFormVariant.Design].includes(variant) && (
        <DesignedProductsFromScratchSelect
          value={didDesignedProductsFromScratch}
          onChange={onDidDesignedProductsFromScratchChange}
          className={styles.marginBottom}
          data-testing-id={
            'pre-vetting-form-did-designed-products-from-scratch'
          }
        />
      )}

      {[VettingFormVariant.Design].includes(variant) && (
        <CaseStudyInput
          defaultValues={caseStudy}
          onChange={onCaseStudyChange}
          formErrors={formErrors}
          validators={validators}
          clearErrors={clearErrors}
          className={styles.marginBottom}
          data-testing-id={'pre-vetting-form-case-study'}
        />
      )}

      {[VettingFormVariant.SoftwareEngineering].includes(variant) && (
        <ManagementExperienceSelect
          onChange={onManagementExperienceChange}
          error={formErrors.managementExperience}
          onBlur={() => validators.validateManagementExperience()}
          className={styles.marginBottom}
          selectClassName={styles.input}
          data-testing-id={'pre-vetting-form-management-experience'}
        />
      )}

      <InterviewAvailabilityInput
        errors={formErrors.interviewAvailability}
        onChange={onInterviewAvailabilityChange}
        onInterviewAvailabilityTypeChange={onInterviewAvailabilityTypeChange}
        onCalendarUrlBlur={() => validators.validateCalendarUrl()}
        onTimezoneBlur={validators.validateWeeklyHoursTimezone}
        className={styles.marginBottom}
        data-testing-id={'pre-vetting-form-interview-availability'}
      />

      {[VettingFormVariant.SoftwareEngineering].includes(variant) && (
        <CodeSampleInput
          defaultValues={codeSample}
          onChange={onCodeSampleChange}
          formErrors={formErrors}
          validators={validators}
          clearErrors={clearErrors}
          className={styles.marginBottom}
          data-testing-id={'pre-vetting-form-code-sample'}
        />
      )}

      {[
        VettingFormVariant.ProductManagement,
        VettingFormVariant.ProjectManagement,
      ].includes(variant) && (
        <HasExperienceWorkingOnAProduct
          value={hasExperienceWorkingOnAProduct}
          onChange={onHasExperienceWorkingOnAProductChange}
          error={formErrors.hasExperienceWorkingOnAProduct}
          className={styles.marginBottom}
          data-testing-id={
            'pre-vetting-form-has-experience-working-on-a-product'
          }
        />
      )}

      {[
        VettingFormVariant.ProductManagement,
        VettingFormVariant.ProjectManagement,
      ].includes(variant) && (
        <TypicalTeamSizeSelect
          value={typicalTeamSize}
          onChange={onTypicalTeamSizeChange}
          error={formErrors.typicalTeamSize}
          className={styles.marginBottom}
          data-testing-id={'pre-vetting-form-typical-team-size'}
        />
      )}

      {[
        VettingFormVariant.ProductManagement,
        VettingFormVariant.ProjectManagement,
      ].includes(variant) && (
        <HadProductDesignerOnTeam
          value={hadProductDesignerOnTeam}
          onChange={onHadProductDesignerOnTeamChange}
          error={formErrors.hadProductDesignerOnTeam}
          className={styles.marginBottom}
          data-testing-id={'pre-vetting-form-had-product-designer-on-team'}
        />
      )}

      {[
        VettingFormVariant.ProductManagement,
        VettingFormVariant.ProjectManagement,
      ].includes(variant) && (
        <HasProfitAndLossResponsibility
          value={hasProfitAndLossResponsibility}
          onChange={onHasProfitAndLossResponsibilityChange}
          error={formErrors.hasProfitAndLossResponsibility}
          className={styles.marginBottom}
          data-testing-id={
            'pre-vetting-form-has-profit-and-loss-responsibility'
          }
        />
      )}

      {[
        VettingFormVariant.ProductManagement,
        VettingFormVariant.ProjectManagement,
      ].includes(variant) && (
        <HasTechnicalBackground
          value={hasTechnicalBackground}
          onChange={onHasTechnicalBackgroundChange}
          error={formErrors.hasTechnicalBackground}
          className={styles.marginBottom}
          data-testing-id={'pre-vetting-form-has-technical-background'}
        />
      )}

      <Button
        className={styles.submitButton}
        size={'small'}
        onClick={onSubmit}
        data-testing-id={'pre-vetting-form-submit-button'}
      >
        Submit
      </Button>
    </div>
  );
};
