import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { WeeklyHours } from '@a_team/models/dist/vetting-processes/pre-vetting';
import { Spacing } from '@ateams/components';
import { RadioButton } from '@src/components/RadioGroup/RadioButton';
import {
  OnWeeklyHoursChange,
  WeeklyHoursInput,
} from '@src/components/WeeklyHoursInput';
import { InputLabel } from '../components/input-label';
import { InputContainer } from '../components/input-container';
import { OutlinedInput } from '../components/outlined-input';
import { InterviewAvailabilityErrors } from '../use-errors';

export enum InterviewAvailabilityType {
  CalendarURL = 'calendar',
  CustomHours = 'customHours',
}

export interface InterviewAvailability {
  interviewAvailabilityType: InterviewAvailabilityType;
  calendarUrl?: string;
  weeklyHours?: Partial<WeeklyHours>;
}

export type OnInterviewAvailabilityChange = (
  interviewAvailability: InterviewAvailability,
) => void;

export type OnInterviewAvailabilityTypeChange = (
  interviewAvailabilityType: InterviewAvailabilityType,
) => void;

export interface InterviewAvailabilityInputProps {
  errors: InterviewAvailabilityErrors;
  onChange: OnInterviewAvailabilityChange;
  onInterviewAvailabilityTypeChange?: OnInterviewAvailabilityTypeChange;
  onCalendarUrlBlur?: () => void;
  onTimezoneBlur?: () => void;
  className?: string;
  'data-testing-id'?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginBottomXSmall: {
    marginBottom: Spacing.xsmall,
  },
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  inputDescription: {
    fontSize: '15px',
    lineHeight: '24px',
    marginBottom: '8px',
  },
});

export const InterviewAvailabilityInput: React.FC<
  InterviewAvailabilityInputProps
> = (props) => {
  const [interviewAvailabilityType, setInterviewAvailabilityType] =
    useState<InterviewAvailabilityType>(InterviewAvailabilityType.CalendarURL);
  const [calendarUrl, setCalendarUrl] = useState('');
  const [weeklyHours, setWeeklyHours] = useState<Partial<WeeklyHours>>({});
  const styles = useStyles();

  const onInterviewAvailabilityTypeChange = (
    newInterviewAvailabilityType: InterviewAvailabilityType,
  ) => {
    if (interviewAvailabilityType === newInterviewAvailabilityType) {
      return;
    }

    setInterviewAvailabilityType(newInterviewAvailabilityType);
    props.onInterviewAvailabilityTypeChange?.(newInterviewAvailabilityType);
    if (
      newInterviewAvailabilityType === InterviewAvailabilityType.CalendarURL
    ) {
      props.onChange({
        interviewAvailabilityType: newInterviewAvailabilityType,
        calendarUrl,
      });
    } else {
      props.onChange({
        interviewAvailabilityType: newInterviewAvailabilityType,
        weeklyHours,
      });
    }
  };

  const onCalendarUrlChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const calendarUrl = e.target.value;
    setCalendarUrl(calendarUrl);
    props.onChange({ interviewAvailabilityType, calendarUrl });
  };

  const onWeeklyHoursChange: OnWeeklyHoursChange = (weeklyHours) => {
    setWeeklyHours(weeklyHours);
    props.onChange({ interviewAvailabilityType, weeklyHours });
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <InputLabel className={styles.marginBottomXSmall}>
        Availability For Interview *
      </InputLabel>
      <div className={styles.inputDescription}>
        This can help us schedule the interview for you.
      </div>
      <RadioButton
        item={{
          label: 'Calendar URL',
          value: InterviewAvailabilityType.CalendarURL,
        }}
        onClick={() =>
          onInterviewAvailabilityTypeChange(
            InterviewAvailabilityType.CalendarURL,
          )
        }
        checked={
          interviewAvailabilityType === InterviewAvailabilityType.CalendarURL
        }
        data-testing-id={`${props['data-testing-id']}-calendar-url-radio-button`}
      />
      <RadioButton
        item={{
          label: 'Custom Hours',
          value: InterviewAvailabilityType.CustomHours,
        }}
        onClick={() =>
          onInterviewAvailabilityTypeChange(
            InterviewAvailabilityType.CustomHours,
          )
        }
        checked={
          interviewAvailabilityType === InterviewAvailabilityType.CustomHours
        }
        data-testing-id={`${props['data-testing-id']}-radio-button-custom-hours`}
      />
      {interviewAvailabilityType === InterviewAvailabilityType.CalendarURL && (
        <InputContainer
          error={props.errors.calendarUrl}
          className={styles.marginBottom}
        >
          <OutlinedInput
            value={calendarUrl}
            placeholder={'https://calendly.com/...'}
            onChange={onCalendarUrlChange}
            error={Boolean(props.errors.calendarUrl)}
            onBlur={props.onCalendarUrlBlur}
            data-testing-id={`${props['data-testing-id']}-calendar-url-input`}
          />
        </InputContainer>
      )}
      {interviewAvailabilityType === InterviewAvailabilityType.CustomHours && (
        <WeeklyHoursInput
          timezoneError={props.errors.weeklyHoursTimezone}
          weeklyHoursErrors={props.errors.weeklyHoursDays}
          onTimezoneBlur={props.onTimezoneBlur}
          onChange={onWeeklyHoursChange}
          className={styles.marginBottom}
          data-testing-id={`${props['data-testing-id']}-weekly-hours-input`}
        />
      )}
    </div>
  );
};
