import { SelectOption } from '@ateams/components';
import { Expertise } from '@src/stores/Profile/models';
import { EditingExperienceObject } from '../../types';
import { SharedExpereienceErrors, toDate, validate } from './utils';

export interface EditSharedExperienceState {
  experience: EditingExperienceObject & {
    isCurrent?: boolean;
  };
  originalExperience: EditingExperienceObject;
  roles: Expertise[];
  errors: SharedExpereienceErrors;
}

export enum EditExperienceActions {
  INIT = 'INIT',
  EDIT_DESC = 'EDIT_DESC',
  EDIT_ROLE = 'EDIT_ROLE',
  EDIT_JOB_ROLE = 'EDIT_JOB_ROLE',
  EDIT_START_DATE = 'EDIT_START_DATE',
  EDIT_END_DATE = 'EDIT_END_DATE',
  EDIT_IS_CURRENT = 'EDIT_IS_CURRENT',
  EDIT_SKILLS = 'EDIT_SKILLS',
}

interface ActionType {
  type: EditExperienceActions;
  payload?: unknown;
}

interface InitActionType {
  experience: EditingExperienceObject;
  roles: Expertise[];
}

export const initialState: EditSharedExperienceState = {
  experience: {} as EditingExperienceObject,
  originalExperience: {} as EditingExperienceObject,
  roles: [] as Expertise[],
  errors: {} as SharedExpereienceErrors,
};

export const reducer = (
  state: EditSharedExperienceState,
  action: ActionType,
): EditSharedExperienceState => {
  let newState = state;
  switch (action.type) {
    case EditExperienceActions.INIT: {
      const { experience, roles } = action.payload as InitActionType;
      const startDate = toDate(experience.startDate);
      const endDate = toDate(experience.endDate);

      newState = {
        experience: {
          ...experience,
          startDate,
          endDate,
          isCurrent: !experience.endDate,
        },
        originalExperience: {
          ...experience,
          startDate,
          endDate,
        },
        roles,
        errors: {},
      };
      break;
    }
    case EditExperienceActions.EDIT_DESC: {
      newState = {
        ...state,
        experience: {
          ...state.experience,
          description: action.payload as string,
        },
      };
      break;
    }
    case EditExperienceActions.EDIT_ROLE: {
      newState = {
        ...state,
        experience: {
          ...state.experience,
        },
      };

      newState.experience.talentSpecializationId = action.payload as string;
      break;
    }
    case EditExperienceActions.EDIT_JOB_ROLE: {
      newState = {
        ...state,
        experience: {
          ...state.experience,
          jobRole: action.payload as string,
        },
      };
      break;
    }
    case EditExperienceActions.EDIT_START_DATE: {
      newState = {
        ...state,
        experience: {
          ...state.experience,
          startDate: action.payload as Date,
        },
      };
      break;
    }
    case EditExperienceActions.EDIT_END_DATE: {
      newState = {
        ...state,
        experience: {
          ...state.experience,
          endDate: action.payload as Date,
        },
      };
      break;
    }
    case EditExperienceActions.EDIT_IS_CURRENT: {
      newState = {
        ...state,
        experience: {
          ...state.experience,
          endDate: (action.payload as boolean)
            ? undefined
            : state.originalExperience.endDate,
          isCurrent: action.payload as boolean,
        },
      };
      break;
    }
    case EditExperienceActions.EDIT_SKILLS: {
      const skills = (action.payload as SelectOption[]).filter(
        (option) => option.value && option.label,
      );
      newState = {
        ...state,
        experience: {
          ...state.experience,
        },
      };

      newState.experience.talentSkillIds = skills.map(({ value }) => value);
      break;
    }
    default: {
      return state;
    }
  }

  return {
    ...newState,
    errors: validate(newState.experience),
  };
};
