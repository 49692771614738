import { createUseStyles } from 'react-jss';
import React, { ReactElement, useState } from 'react';
import { Icon } from '@a_team/ui-components';
import { CollapsibleContainer, Colors } from '@ateams/components';
import { StructuredExplanation } from './MatchesDetailsModal';
import { SkillTargeterUserObject } from '@a_team/models/dist/TeamGraphObject';

const useStyles = createUseStyles({
  container: {},
  bottomMargin: {
    marginBottom: 10,
  },
  itemContainer: {
    display: 'flex',
    marginBottom: 10,
  },
  itemHeader: {
    display: 'flex',
    width: 180,
    marginRight: 10,
    fontSize: 16,
    fontWeight: 500,
  },
  itemIcon: {
    marginRight: 10,
  },
  itemContent: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    margin: 0,
    listStyleType: 'circle',
  },
  itemTitle: {
    marginBottom: 5,
  },
  debugHeader: {
    marginTop: 5,
    marginBottom: 5,
  },
  debugTitle: {
    marginTop: 5,
    marginBottom: 5,
    color: 'gray',
    fontWeight: 'normal!important',
  },
  horizontalRule: {
    marginTop: 10,
    marginBottom: 10,
  },
  preformattedText: {
    display: 'flex',
    marginBottom: 15,
  },
  platformUrl: {
    marginLeft: 10,
    padding: '2px 5px',
    border: '1px solid',
    borderRadius: 5,
    cursor: 'pointer',
    color: Colors.primary,
  },
});

const icons: Record<string, ReactElement> = {
  industries: <Icon name={'experience'} size={'md'} />,
  jobs: <Icon name={'projects'} size={'md'} />,
  projects: <Icon name={'projects'} size={'md'} />,
  missions: <Icon name={'projects'} size={'md'} />,
  applications: <Icon name={'pulse'} size={'md'} />,
  keywords: <Icon name={'pulse'} size={'md'} />,
  'admin notes': <Icon name={'nested'} size={'md'} />,
  'eval feedback': <Icon name={'nested'} size={'md'} />,
  default: <Icon name={'card'} size={'md'} />,
};

export function MatchingDetailsModalBody(props: {
  user: SkillTargeterUserObject;
  semanticQuery?: string;
  matchesOn?: string;
  structuredExplanations?: StructuredExplanation;
  esParsedExplanations?: unknown[];
  esSearchHighlight?: string[];
  matched?: boolean;
  error?: string;
}) {
  const {
    user,
    matchesOn,
    semanticQuery,
    structuredExplanations,
    esParsedExplanations,
    esSearchHighlight,
    error,
  } = props;

  const [showHighlightIndex, setShowHighlightIndex] = useState<
    Record<string, boolean>
  >({});
  const styles = useStyles();
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={styles.container}>
      {!structuredExplanations?.length &&
        !user.matchedOnSemanticQuery &&
        !matchesOn && <div>No match details found</div>}
      {!!user.matchedOnSemanticQuery && (
        <div className={styles.bottomMargin}>
          <div className={styles.bottomMargin}>
            <strong>Semantic match on </strong>
            {!!semanticQuery && (
              <span style={{ fontStyle: 'italic' }}>"{semanticQuery}"</span>
            )}
          </div>
          {user.matchedOnSemanticQuery}
        </div>
      )}
      {structuredExplanations && matchesOn && (
        <div>◉ Basic matches on: [ {matchesOn} ]</div>
      )}
      <br />
      {structuredExplanations?.map((exp, ei) => {
        const { title, items } = exp ?? {};
        return (
          <div key={ei} className={styles.itemContainer}>
            <div className={styles.itemHeader}>
              <div className={styles.itemIcon}>
                {icons[title.toLowerCase()] ?? icons['default']}
              </div>
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <ul className={styles.itemContent}>
              {items?.map((item, ii) => {
                const { title, highlightedMatchedText, platformLink } =
                  item ?? {};
                const idx = `${ei}${ii}`;
                return (
                  <li key={idx} className={styles.itemTitle}>
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                    {highlightedMatchedText && (
                      <span>
                        <Icon
                          style={{
                            marginLeft: 5,
                          }}
                          onClick={(e) => {
                            setShowHighlightIndex({
                              ...showHighlightIndex,
                              [idx]: !showHighlightIndex[idx],
                            });
                          }}
                          name={
                            showHighlightIndex[idx] ? 'arrowUp' : 'arrowDown'
                          }
                          size={'sm'}
                        />
                      </span>
                    )}
                    {platformLink && (
                      <a
                        href={platformLink}
                        className={styles.platformUrl}
                        target={'_blank'}
                        rel={'noreferrer'}
                      >
                        View
                      </a>
                    )}
                    {showHighlightIndex[idx] && (
                      <div
                        style={{
                          marginLeft: 10,
                          marginBottom: 5,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: highlightedMatchedText ?? '',
                        }}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
      <hr className={styles.horizontalRule} />
      <CollapsibleContainer
        title={`Developer debug information`}
        openDefault={false}
        headerClassName={styles.debugHeader}
        titleClassName={styles.debugTitle}
      >
        <pre className={styles.preformattedText}>
          Parsed Elasticsearch Explanation:{' '}
          {JSON.stringify(esParsedExplanations, null, 2)}
        </pre>
        <hr className={styles.horizontalRule} />
        <pre className={styles.preformattedText}>
          Elasticsearch Highlight: {JSON.stringify(esSearchHighlight, null, 2)}
        </pre>
      </CollapsibleContainer>
    </div>
  );
}
