import React, { ReactElement } from 'react';
import UserAvatar from '@src/components/UserAvatar';
import { stringifyMinutes } from '@src/helpers/time';
import { numberWithCommas } from '@src/helpers/numbers';
import { MissionAdminRole } from '@a_team/models/dist/MissionRole';
import { createUseStyles } from 'react-jss';
import { Colors } from '@ateams/components';
import Tooltip from '@src/components/Tooltip';
import { AdminMissionPaymentCycleSummarizeRole } from '@ateams/api/dist/endpoints/Missions';

interface Props {
  user?: MissionAdminRole['user'];
  roleInvoiceSummary: AdminMissionPaymentCycleSummarizeRole;
}

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    borderBottom: '1px solid #C0C0C0',
    paddingBottom: 16,
    marginBottom: 18,
  },
  label: {
    width: 108,
    marginRight: 12,
    display: 'inline-block',
  },
  rate: {
    color: '#999',
  },
});

const InvoiceRow = (props: Props): ReactElement | null => {
  const { roleInvoiceSummary, user } = props;
  const styles = useStyles();

  if (!user) {
    return null;
  }

  return (
    <div key={roleInvoiceSummary.rid} className={styles.row}>
      <UserAvatar
        src={'uid' in user ? user.profilePictureURL : undefined}
        containerStyle={{ marginRight: 24 }}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontSize: 15, fontWeight: 500, marginBottom: 2 }}>
          {user.fullName}
          {!roleInvoiceSummary.submitted && (
            <Tooltip
              label={'The user has not submitted their timesheet yet'}
              style={{ marginTop: -20 }}
            >
              <span
                style={{
                  display: 'inline-block',
                  marginLeft: 4,
                  textAlign: 'center',
                  background: Colors.warning,
                  borderRadius: '50%',
                  padding: '0 8px',
                  fontWeight: 600,
                  color: '#fff',
                  transform: 'scale(0.8)',
                  verticalAlign: 'middle',
                }}
              >
                !
              </span>
            </Tooltip>
          )}
        </span>
        {'uid' in user && (
          <span style={{ fontSize: 15, color: '#62646A', marginBottom: 8 }}>
            {user.title}
          </span>
        )}
        <span>
          <span className={styles.label}>Total hours:</span>
          {stringifyMinutes(roleInvoiceSummary.totalMinutes)}{' '}
          <span className={styles.rate}>
            ($
            {numberWithCommas(roleInvoiceSummary.hourlyRate)}/hr)
          </span>
        </span>
        <span style={{ marginTop: 4 }}>
          <span className={styles.label}>Platform Fees:</span>$
          {numberWithCommas(roleInvoiceSummary.totalFees)}{' '}
          <span className={styles.rate}>
            ($
            {numberWithCommas(roleInvoiceSummary.originHourlyRate)}/hr)
          </span>
        </span>
        <span style={{ marginTop: 8 }}>
          <span className={styles.label}>VAT:</span>
          {`$${numberWithCommas(roleInvoiceSummary.totalVAT)}`}{' '}
          <span className={styles.rate}>
            ({roleInvoiceSummary.marginVAT * 100}%)
          </span>
        </span>
        <span style={{ marginTop: 8 }}>
          <strong>
            <span className={styles.label}>Total Payment:</span>
            {`$${numberWithCommas(roleInvoiceSummary.totalPayments)}`}
          </strong>
        </span>
      </div>
    </div>
  );
};

export default InvoiceRow;
