import {
  EvaluationInterviewScheduledNotificationObject,
  NotificationType,
} from '@a_team/models/dist/NotificationObject';
import React, { useMemo } from 'react';
import Notification from './Notification';
import { DateTime, IANAZone } from 'luxon';
import aTeamLogo from '@src/assets/svgs/ateam-logo.svg';

interface EvaluationInterviewScheduledNotificationProps {
  notification: EvaluationInterviewScheduledNotificationObject;
  onMarkAsRead(): void;
  isRead?: boolean;
  onViewRequest: () => void;
}

const EvaluationInterviewScheduledNotification = ({
  isRead,
  onMarkAsRead,
  onViewRequest,
  notification,
}: EvaluationInterviewScheduledNotificationProps) => {
  const title = `You have an upcoming evaluation interview`;

  const { date, time } = useMemo(() => {
    if (!notification.builderTimezone) {
      return { date: 'Invalid date', time: 'Invalid time' };
    }
    const isValidTimeZone = IANAZone.isValidZone(notification.builderTimezone);

    if (!notification.interviewStartDate || !notification.builderTimezone) {
      console.error('Invalid input:', {
        startDate: notification.interviewStartDate,
        isValidTimeZone,
      });
      return { date: 'Invalid date', time: 'Invalid time' };
    }

    const startDate = DateTime.fromISO(notification.interviewStartDate, {
      zone: notification.builderTimezone,
    });

    if (!startDate.isValid) {
      console.error('Conversion error:', {
        startDateError: startDate.invalidReason,
      });
      return { date: 'Invalid date', time: 'Invalid time' };
    }

    const formattedDate = startDate.toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });

    const startTime = startDate
      .toLocaleString(DateTime.TIME_SIMPLE)
      .toLowerCase();
    const timeZone = startDate.toFormat('ZZZZ'); // Get the timezone abbreviation

    const formattedTime = `${startTime} ${timeZone}`;

    return { date: formattedDate, time: formattedTime };
  }, [notification.interviewStartDate, notification.builderTimezone]);

  const description = useMemo(() => {
    return `You have successfully booked an evaluation call. The call is set to happen on ${date} at ${time}.`;
  }, []);

  return (
    <>
      <Notification
        createdAt={notification.createdAt}
        notificationType={NotificationType.EvaluationInterviewScheduled}
        ctaName="Edit invite"
        ctaAction={() => {
          onViewRequest();
        }}
        title={title}
        description={description}
        imageUrl={aTeamLogo}
        onMarkAsRead={onMarkAsRead}
        isRead={isRead}
      />
    </>
  );
};

export default EvaluationInterviewScheduledNotification;
