import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Icon, IconType } from '@ateams/components';
import logo from './logo.svg';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import { RootLocation } from '@src/locations';
import Bell from '@src/components/Bell';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import { useGetAllNotifications } from '@src/rq/notifications';

interface Props {
  setMenuOpen?: () => void;
}

const useStyles = createUseStyles({
  mobileTopBar: {
    justifyContent: 'space-between',
    height: 50,
    padding: 12,
    width: '100%',
    background: 'rgba(123, 11, 233, 0.9)',
    position: 'fixed',
    zIndex: 9999,
    backdropFilter: 'blur(10px)',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 3px 1px rgba(0,0,0,0.31)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
  },

  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    mobileTopBar: {
      display: 'none',
    },
  },
});

const MobileTopBar = (props: Props): ReactElement => {
  const styles = useStyles();
  const { uiStore, auth } = useStores();
  const { data } = useGetAllNotifications({
    onlyUnread: true,
  });

  return (
    <div className={styles.mobileTopBar}>
      <div className={styles.container} />
      {!!props.setMenuOpen && (
        <Icon
          type={IconType.Menu}
          size="small"
          style={{
            width: 24,
            height: 24,
            position: 'absolute',
            left: 24,
            fill: '#fff',
          }}
          onClick={props.setMenuOpen}
        />
      )}
      <div className={styles.container}>
        <Link to={RootLocation} style={{ display: 'inline-flex' }}>
          <img src={logo} alt="Home" width={28} />
        </Link>
      </div>
      {auth && auth?.user ? (
        <div
          className={styles.container}
          onClick={uiStore.toggleNotificationSlider}
        >
          <Bell totalUnreadNotifications={data?.length ?? 0} />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default observer(MobileTopBar);
