import React from 'react';
import { createUseStyles } from 'react-jss';
import { AdminNotesDiscovery } from '@a_team/models/dist/AdminNotesObject';
import { DiscoveryItem } from './DiscoveryItem';
import { FontWeights, Spacing } from '@ateams/components';

export interface DiscoveryTabProps {
  discovery?: AdminNotesDiscovery;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
  },
  tabSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
  title: {
    fontSize: 20,
    fontWeight: FontWeights.medium,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
  },
});

export const DiscoveryTab: React.FC<DiscoveryTabProps> = ({ discovery }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {discovery?.clientSignals && discovery.clientSignals.length > 0 && (
        <div className={styles.tabSection}>
          <div className={styles.title}>Builder stars</div>
          <div className={styles.listContainer}>
            {discovery?.clientSignals.map((signal) => (
              <DiscoveryItem
                workspaceName={signal.workspaceName}
                starredBy={signal.starredBy}
                starredAt={new Date(signal.starredAt)}
                starredContext={signal.starredContext}
              />
            ))}
          </div>
        </div>
      )}
      {discovery?.clientInterviews && discovery.clientInterviews.length > 0 && (
        <div className={styles.tabSection}>
          <div className={styles.title}>Discovery interviewes booked</div>
          <div className={styles.listContainer}>
            {discovery?.clientInterviews.map((interview) => {
              const clientSignal = discovery.clientSignals.find(
                (signal) => signal.accountId === interview.accountId,
              );
              return (
                <DiscoveryItem
                  key={interview.accountId}
                  workspaceName={interview.workspaceName}
                  starredBy={clientSignal?.starredBy}
                  starredAt={
                    clientSignal?.starredAt
                      ? new Date(clientSignal.starredAt)
                      : undefined
                  }
                  interviewStatus={interview.interviewStatus}
                  interviewDate={
                    interview.interviewDate
                      ? new Date(interview.interviewDate)
                      : undefined
                  }
                  roleDescription={interview.roleDescription}
                  minCompanyBudget={interview.minCompanyBudget}
                  maxCompanyBudget={interview.maxCompanyBudget}
                  transcripts={interview.transcripts}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
