import React, { ReactElement, useCallback, useMemo } from 'react';
import { MissionCardObject } from '@a_team/models/dist/MissionObject';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { MissionApplyEditLocation, MissionLocation } from '@src/locations';
import { Button as CallToActionButton, Card } from '@ateams/components';
import CompanyAvatar from '@src/components/CompanyAvatar/CompanyAvatar';
import UserAvatar from '@src/components/UserAvatar';
import { Link, useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import cx from 'classnames';
import { BasicUserObject } from '@a_team/models/src/UserObject';
import TooltipWrapped from '@src/components/TooltipWrapped';
import stripHtml from 'striptags';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { UserStatus } from '@a_team/models/dist/UserObject';
import { CurrentUserMissionApplicationObject } from '@a_team/models/dist/MissionApplicationObject';
import Skeleton from 'react-loading-skeleton';
import ApplicationV2StatusV2 from './ApplicationStatusV2';
import { getStatus } from './applicationStatusesUtils';
import { useStores } from '@src/stores';
import TeamUpSection from './TeamUpSection';
import { RecommendationObject } from '@a_team/models/dist/RecommendationObject';

export enum CardType {
  recommended = 'recommended',
  all = 'all',
  applied = 'applied',
  completed = 'completed',
}

interface Props {
  mission?: MissionCardObject;
  flag?: ReactElement;
  badge?: ReactElement;
  hiddenButton?: ReactElement;
  application?: CurrentUserMissionApplicationObject;
  userRecommendations?: RecommendationObject[];
  actionButton?: ReactElement;
  details: ReactElement;
  video?: ReactElement;
  matchList?: ReactElement;
  fullWidth?: boolean;
  descriptionLines?: number;
  linkTo?: string;
  clickable?: boolean;
  className?: string;
  loading?: boolean;
  type?: CardType;
  canEditApplication?: boolean;
  hideInvite?: boolean;
}

const MAX_MEMBERS_AVATARS = 3;

const MissionControlCard = (props: Props): ReactElement => {
  const history = useHistory();
  const {
    mission,
    flag,
    badge,
    actionButton,
    application,
    video,
    details,
    matchList,
    fullWidth,
    className,
    linkTo,
    clickable = true,
    loading,
    type,
    userRecommendations,
    canEditApplication,
    hideInvite,
  } = props;

  const analytics = useAnalytics();
  const styles = useStyles(props);
  const { auth } = useStores();

  const stripDescriptionHtml = (description: string): string => {
    return stripHtml(description);
  };

  const missionTitle = useMemo(() => {
    if (!mission) return '';
    const parts = mission.title.split(':');
    return parts.length > 1 ? parts.slice(1).join(':').trim() : mission.title;
  }, [mission?.title]);

  const filledRoles = useMemo(() => {
    if (!mission) return [];
    return mission.roles.filter((role) => {
      const user = role.user as BasicUserObject;
      return (
        (role.status === MissionRoleStatus.Active ||
          role.status === MissionRoleStatus.ScheduledToEnd) &&
        !!user &&
        user.status !== UserStatus.Deleted
      );
    });
  }, [mission?.roles]);

  const additionalRoles = useMemo(() => {
    return filledRoles.length - MAX_MEMBERS_AVATARS;
  }, [filledRoles?.length]);

  const handleCardClick = () => {
    if (!mission) return;
    analytics.trackMissionCardClicked({
      ...mission,
      ...(flag ? { recommended: true } : {}),
    });
  };

  const handleEdit = useCallback(() => {
    if (!mission || !mission.mid || !application?.rid || !application?.aid) {
      return;
    }
    const location = MissionApplyEditLocation(
      mission.mid,
      application.rid,
      application.aid,
    );
    history.push(location);
  }, [application]);

  const useStatusV2 = auth.withMissionApplicationStatusV2;

  const status = useMemo(() => {
    if (!application) return null;
    if (!mission) return null;
    if (!useStatusV2) return null;

    return getStatus({
      mission: mission,
      missionApplication: application,
      userBadges: auth?.user?.badges,
    });
  }, [application, mission, useStatusV2]);

  return (
    <li
      className={cx(styles.listItem, className, {
        [styles.disabled]: !clickable || loading,
      })}
    >
      {/* using a conditional here because jss doesn't consider video to be a truthy value */}
      <Card
        className={video && fullWidth ? styles.cardWithVideo : styles.card}
        onClick={handleCardClick}
      >
        {useStatusV2 && type === CardType.applied && status && (
          <ApplicationV2StatusV2 status={status} />
        )}
        <Link
          to={
            clickable && !loading
              ? linkTo || `${MissionLocation}/${mission?.mid}`
              : '#'
          }
          className={styles.cardLink}
        >
          <div className={styles.headerContainer}>
            <div className={styles.logo}>
              {loading ? (
                <Skeleton
                  circle
                  height={40}
                  width={40}
                  className={styles.logoInner}
                />
              ) : (
                <CompanyAvatar
                  src={mission?.logoURL}
                  alt={`${mission?.companyName} company logo`}
                  size={40}
                  className={styles.logoInner}
                />
              )}

              {filledRoles.slice(0, MAX_MEMBERS_AVATARS).map((role) => {
                const user = role.user as BasicUserObject;
                if (!user) return null;
                return (
                  <TooltipWrapped
                    key={`${mission?.mid}-${role.rid}`}
                    theme={'dark'}
                    position={'top'}
                    arrow
                    html={
                      <div className={styles.tooltip}>
                        <p>{user.title}</p>
                        <p className={styles.bold}>{user.fullName}</p>
                      </div>
                    }
                  >
                    <UserAvatar
                      src={user.profilePictureURL || ''}
                      alt={user.fullName}
                      size={24}
                      containerStyle={{
                        width: '24px',
                        height: '24px',
                        marginRight: '4px',
                      }}
                    />
                  </TooltipWrapped>
                );
              })}
              {additionalRoles > 0 && `+${additionalRoles}`}
            </div>
          </div>
          <div className={styles.badgeContainer}>{badge}</div>
          {!loading && <div className={styles.flagContainer}>{flag}</div>}

          <div className={cx(styles.descriptionContainer, styles.container)}>
            {loading ? (
              <>
                <Skeleton className={styles.companyName} />
                <Skeleton className={styles.title} />
                <Skeleton count={4} />
              </>
            ) : (
              <>
                <p className={styles.companyName}>
                  <small>{mission?.companyName}</small>
                </p>

                <h4 className={styles.title}>{missionTitle}</h4>

                <p className={styles.description}>
                  {mission && stripDescriptionHtml(mission.description)}
                </p>
              </>
            )}
          </div>
          <div className={cx(styles.detailsContainer, styles.container)}>
            {details}
          </div>
          {matchList ? (
            <div className={cx(styles.matchesContainer, styles.container)}>
              {matchList}
            </div>
          ) : null}
          {video ? <div className={styles.videoContainer}>{video}</div> : null}
        </Link>
        {auth.withTeamUp &&
          mission &&
          application &&
          auth.uid &&
          mission.roles.length > 1 && (
            <div className={styles.teamUpSectionContainer}>
              <TeamUpSection
                hideInvite={hideInvite}
                currentUserId={auth.uid}
                userRecommendations={userRecommendations}
                mission={mission}
                application={application}
              />
            </div>
          )}
        <div className={styles.editActionButtonContainer}>
          {loading ? (
            <>
              <Skeleton width={200} />
              <Skeleton width={100} />
            </>
          ) : (
            <>
              <div>
                {application && clickable && (
                  <CallToActionButton
                    onClick={handleEdit}
                    className={styles.applyEditCTA}
                  >
                    {canEditApplication ? 'Edit' : 'View'}
                  </CallToActionButton>
                )}
              </div>

              <div>{actionButton}</div>
            </>
          )}
        </div>
      </Card>
    </li>
  );
};

export default MissionControlCard;
