import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    padding: 16,
    border: '1px solid #FE630C',
    background: '#FFFAF7',
    borderRadius: 8,
    marginBottom: 24,
  },
});

const ErrorBar = ({ error }: { error: string }): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container} data-testing-id="timesheets-error-banner">
      {error}
    </div>
  );
};

export default ErrorBar;
