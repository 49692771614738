import React, { CSSProperties, useMemo } from 'react';
import MainApplication from './MainApplication';
import MainProfile from './MainProfile';
import OldMain from './Main';
import Profile from '@src/stores/Profile/Profile';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';

interface MainProps {
  profile?: Profile;
  style?: CSSProperties;
  skipScrollToTop?: boolean;
}

const Main = (props: MainProps): JSX.Element | null => {
  const { auth, users } = useStores();

  const profile = useMemo(() => {
    return props.profile || users.profile;
  }, [props.profile, users.profile]);

  const withSplittingProfileAndApplication =
    auth.withSplittingProfileAndApplication;

  if (withSplittingProfileAndApplication === undefined) {
    // we don't know yet if we should split profile and application so we don't render anything
    return null;
  }

  if (!withSplittingProfileAndApplication) {
    return <OldMain profile={profile} style={props.style} />;
  }

  if (profile?.application) {
    return <MainApplication profile={profile} style={props.style} />;
  }

  return <MainProfile profile={profile} style={props.style} />;
};

export default observer(Main);
