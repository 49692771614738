import { Button, Icon } from '@a_team/ui-components';
import { Breakpoints, Icon as IconComp, IconType } from '@ateams/components';
import CompanyLogo from '@src/components/CompanyLogo';
import { useStores } from '@src/stores';
import React from 'react';
import { createUseStyles } from 'react-jss';
import AdminNav from './AdminNav';
import NotInterested from './NotInterested';
import TeamMember from './TeamMember';
import { useMission } from './useMission';
import { observer } from 'mobx-react';
import NotPublished from '@src/components/NotPublished';
import { useQueryClientSignalMissions } from '@src/rq/signals';
import { getIsMissionLiked } from '@src/views/MissionControl/MissionControlCard/common/utils';
import TeamStatus from '@src/views/MissionControl/MissionControlCard/MissionControlCardV2/TeamStatus';
import Tabs from './Tabs';
import RequestBuildersModal from '@src/components/Modal/RequestBuildersModal';
import useToggle from '@src/hooks/useToggle';

interface NewMissionNavBarProps {
  toggleShareModal: (force?: unknown) => void;
  toggleDeleteModal?: (force?: unknown) => void;
  togglePublish: (force?: unknown) => void;
}

const useStyles = createUseStyles<{
  areTabsShown?: boolean;
}>({
  container: {
    padding: ({ areTabsShown }) =>
      areTabsShown ? '70px 24px 12px 24px' : '70px 24px 24px 24px',
    borderBottom: ({ areTabsShown }) =>
      areTabsShown ? 'none' : '1px solid #DADADC',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    // select all btns should have a height of 40px
    '& button': {
      height: '40px !important',
    },
  },
  missionInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    justifyContent: 'center',
  },
  line: {
    width: 20,
    height: 1,
    background: '#DADADC',
  },
  missionName: {
    marginTop: 0,
    marginBottom: 0,
  },
  companyIconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  teamArchitectContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  teamArchitect: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& a': {
      display: 'flex',
    },
  },
  teamArchitectAvatar: {
    height: 32,
    width: 32,
    borderRadius: '50%',
  },
  missionContent: { display: 'flex', gap: 20, flexDirection: 'column' },
  companyLink: {
    color: '#6D00D7',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 4,
  },
  companyInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px 10px',
    alignItems: 'center',
  },
  companyName: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    '& img': {
      height: '15px',
      width: '15px',
    },
  },
  recommended: {
    color: '#6D00D7',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  companyLikedYou: {
    color: '#FF8D24',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  currentTeam: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  teamArchitectTitle: {
    fontSize: 14,
    color: '#62646A',
  },
  teamMemberAvatarContainer: {
    display: 'flex',
  },
  missionMembers: {
    display: 'flex',
    gap: 40,
    flexWrap: 'wrap',
  },
  actions: {
    display: 'flex',
    gap: 12,
  },
  actionsDesktop: {
    display: 'none',
  },
  missionActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  companyLogo: {
    margin: 0,
  },
  companyLinkIndustry: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  share: {
    padding: '8px !important',
    display: 'flex',
    height: 'fit-content',
    fontSize: 14,
    '& i': {
      cursor: 'pointer !important',
    },
  },
  notPublished: {
    display: 'inline',
    marginLeft: 8,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      padding: '24px 40px',
    },
    actions: {
      display: 'none',
    },
    actionsDesktop: {
      display: 'flex',
      maxWidth: 450,
      width: '100%',
      justifyContent: 'flex-end',
      gap: 12,
    },
    share: {
      padding: '12px !important',
    },
    missionContent: { gap: 24, flexDirection: 'row' },
  },
});

const NewMissionNavBar = ({
  toggleShareModal,
  toggleDeleteModal,
  togglePublish,
}: NewMissionNavBarProps): JSX.Element => {
  const { auth } = useStores();
  const [isRequestBuildersModalOpen, toggleRequestBuildersModal] = useToggle();

  const { data } = useQueryClientSignalMissions({
    enabled: auth.withBuilderLikes,
  });

  const {
    industry,
    companyName,
    teamArchitect,
    isRecommended,
    logo,
    missionTitle,
    activeTeamMembers,
    website,
    isInReview,
    currentMission,
    isAuthUserActiveTeamMember,
    isNotInterested,
    mid,
    hasAtLeastOneOpenRoleLookingForMoreApplicants,
    toggleHideMission,
  } = useMission();

  const styles = useStyles({
    areTabsShown: currentMission?.areTabsShown,
  });

  const onShare = () => {
    toggleShareModal();
  };

  const companyLiked = mid ? getIsMissionLiked(data, mid) : false;

  return (
    <>
      {auth.isAdmin && (
        <AdminNav
          togglePublish={togglePublish}
          toggleDeleteModal={toggleDeleteModal}
        />
      )}

      <div className={styles.container}>
        <div className={styles.missionContent}>
          <div className={styles.companyIconContainer}>
            <CompanyLogo
              className={styles.companyLogo}
              logoUrl={logo}
              size={72}
            />{' '}
            <div className={styles.actions}>
              {!isAuthUserActiveTeamMember && (
                <NotInterested
                  onClick={toggleHideMission}
                  isNotInterested={isNotInterested}
                />
              )}

              <Button
                variant="secondary"
                className={styles.share}
                onClick={onShare}
              >
                Share
              </Button>
            </div>
          </div>
          <div className={styles.missionInfo}>
            <h3 className={styles.missionName}>
              {missionTitle}{' '}
              {auth.isAdmin && isInReview && (
                <NotPublished className={styles.notPublished} />
              )}
            </h3>
            <div className={styles.companyInfo}>
              <div className={styles.companyName}>
                <IconComp
                  clickable
                  size="exact"
                  type={IconType.CompanyGray}
                  style={{
                    height: '16px',
                    width: '16px',
                  }}
                />
                {companyName}
              </div>
              {(industry || website) && <div className={styles.line} />}
              <div className={styles.companyLinkIndustry}>
                {website && (
                  <a
                    rel="noopener noreferrer"
                    href={website}
                    className={styles.companyLink}
                    target="_blank"
                  >
                    <Icon
                      size="md"
                      name="link"
                      color={'Hannibal@600'}
                      style={{
                        top: '3px',
                        left: 0,
                        width: '100%',
                      }}
                    />
                    Website
                  </a>
                )}

                {industry && (
                  <>
                    <div>·</div>
                    {industry}
                  </>
                )}
              </div>
              {isRecommended && (
                <>
                  <div>·</div>
                  <div className={styles.recommended}>
                    <IconComp
                      clickable
                      size="exact"
                      type={IconType.PurpleRibbon}
                      style={{
                        height: '16px',
                        width: '16px',
                      }}
                    />
                    Recommended mission
                  </div>
                </>
              )}
              {companyLiked && (
                <>
                  <div>·</div>
                  <div className={styles.companyLikedYou}>
                    <IconComp
                      clickable
                      size="exact"
                      type={IconType.StarOrange}
                      style={{
                        height: '16px',
                        width: '16px',
                      }}
                    />
                    Company starred you
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className={styles.missionActionsContainer}>
          <div className={styles.missionMembers}>
            {teamArchitect?.firstName && (
              <div className={styles.teamArchitectContainer}>
                <span className={styles.teamArchitectTitle}>
                  Team architect
                </span>
                <div className={styles.teamArchitect}>
                  <img
                    className={styles.teamArchitectAvatar}
                    alt="Team Architect"
                    src={teamArchitect.profilePictureURL}
                  />
                  <div>{teamArchitect?.firstName}</div>
                  <a
                    href={`mailto:formation@a.team?subject=Message%20from%20${auth.user?.firstName}%20${auth.user?.lastName}%20about%20${companyName}%20-%20${missionTitle}`}
                  >
                    <IconComp
                      clickable
                      size="exact"
                      type={IconType.Mail}
                      style={{
                        height: '16px',
                        width: '16px',
                      }}
                    />
                  </a>
                </div>
              </div>
            )}

            {activeTeamMembers && activeTeamMembers.length > 0 && (
              <div className={styles.currentTeam}>
                <span>Team</span>
                <div className={styles.teamMemberAvatarContainer}>
                  {activeTeamMembers.map((member) => {
                    if (member?.user?.username) {
                      return (
                        <TeamMember
                          key={member.user.username}
                          user={member.user}
                          roleTitle={member.category.title}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            )}

            <div className={styles.currentTeam}>
              <span>Team status</span>
              <TeamStatus
                hasAtLeastOneOpenRoleLookingForMoreApplicants={
                  hasAtLeastOneOpenRoleLookingForMoreApplicants
                }
              />
            </div>
          </div>
          <div className={styles.actionsDesktop}>
            {!isAuthUserActiveTeamMember && (
              <NotInterested
                onClick={toggleHideMission}
                isNotInterested={isNotInterested}
              />
            )}

            <Button onClick={onShare} variant="ghost" className={styles.share}>
              Share
            </Button>

            {auth.withRequestExtension && currentMission?.areTabsShown && (
              <Button
                onClick={() => {
                  toggleRequestBuildersModal();
                }}
                variant="secondary"
                className={styles.share}
              >
                Request more builders
              </Button>
            )}
          </div>
        </div>
      </div>
      {isRequestBuildersModalOpen && currentMission?.mid && (
        <RequestBuildersModal
          open={isRequestBuildersModalOpen}
          mid={currentMission.mid}
          onClose={() => {
            toggleRequestBuildersModal();
          }}
        />
      )}

      {currentMission?.areTabsShown && (
        <Tabs
          showTeamTimeTrackingTab={
            !!(
              (activeTeamMembers?.length || 0) > 1 && auth.withNewTimesheets
            ) && !auth.isAdmin
          }
          currentMission={currentMission}
        />
      )}
    </>
  );
};

export default observer(NewMissionNavBar);
