import { apiMissions, apiTimesheets } from '@ateams/api';
import { useStores } from '@src/stores';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './keys';
import TimesheetObject, {
  TimesheetId,
  TimesheetRecordData,
  TimesheetRecordKey,
} from '@a_team/models/dist/TimesheetObject';
import {
  TimesheetInitiativeId,
  TimesheetInitiativeObject,
  TimesheetSummaryBlurbNode,
} from '@a_team/models/dist/TimesheetInitiativeObject';
import MissionPaymentCycleObject, {
  MissionPaymentCycleAdminObject,
} from '@a_team/models/dist/MissionPaymentCycleObject';

export const useQueryGetMissionPaymentCycle = ({
  mid,
  yid,
  onSuccess,
  enabled,
}: {
  mid: string;
  yid: string;
  onSuccess?: (data: MissionPaymentCycleObject | null) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.timesheets.getMissionPaymentCycle(
      mid,
      yid,
      auth.uid || '',
    ),
    queryFn: async () => {
      return await apiMissions.getMissionPaymentCycle(auth, mid, yid);
    },
    onSuccess,
    enabled,
  });
};

export const useQueryGenerateTimesheetSummaryBlurb = ({
  sid,
  onSuccess,
  enabled,
}: {
  sid: TimesheetId;
  onSuccess?: (data: {
    timesheetSummaryBlurbNodes: TimesheetSummaryBlurbNode[];
  }) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.timesheets.generateSummaryBlurb(sid, auth.uid || ''),
    queryFn: async () => {
      return await apiTimesheets.generateSummaryBlurb(auth, sid);
    },
    onSuccess,
    enabled,
  });
};

export const useQueryAdminGetMissionPaymentCycle = ({
  mid,
  yid,
  onSuccess,
  enabled,
}: {
  mid: string;
  yid: string;
  onSuccess?: (data: MissionPaymentCycleAdminObject | null) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.timesheets.adminGetMissionPaymentCycle(
      mid,
      yid,
      auth.uid || '',
    ),
    queryFn: async () => {
      return await apiMissions.adminGetMissionPaymentCycle(auth, mid, yid);
    },
    onSuccess,
    enabled,
  });
};

export const useQueryGetTimesheetInitiativesForMission = ({
  sid,
  onSuccess,
  enabled,
}: {
  sid: TimesheetId;
  onSuccess?: (timesheetInitiatives: TimesheetInitiativeObject[]) => void;
  enabled?: boolean;
}) => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.timesheets.getTimesheetInitiativesForMission(
      sid,
      auth.uid || '',
    ),
    queryFn: async () => {
      return await apiTimesheets.getTimesheetInitiativesForMission(auth, sid);
    },
    onSuccess,
    enabled,
    staleTime: 5 * 60 * 1000,
  });
};

export const useMutationSaveTimesheetSummary = ({
  onSuccess,
}: {
  onSuccess?: (timesheet: TimesheetObject) => void;
} = {}) => {
  const { auth, missions } = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      sid,
      summary,
      summaryHtml,
      usedMachineTextForSummary,
      demoLink,
    }: {
      sid: TimesheetId;
      summary: string;
      summaryHtml: string;
      usedMachineTextForSummary: boolean;
      demoLink: string;
    }) => {
      return apiTimesheets.saveTimesheetSummary(auth, sid, {
        summary,
        summaryHtml,
        usedMachineTextForSummary,
        demoLink,
      });
    },
    onSuccess: (timesheet: TimesheetObject) => {
      const mid = missions?.currentMission?.data?.mid;
      const yid = missions?.currentMission?.selectedPaymentCycle?.yid;
      if (mid && yid) {
        queryClient.invalidateQueries(
          queryKeys.timesheets.getMissionPaymentCycle(mid, yid, auth.uid || ''),
        );
      }
      if (onSuccess) {
        onSuccess(timesheet);
      }
    },
  });
};

export const useCreateTimesheetInitiativeForMission = ({
  sid,
  onSuccess,
}: {
  sid: TimesheetId;
  onSuccess?: (timesheetInitiative: TimesheetInitiativeObject) => void;
}) => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ sid, name }: { sid: TimesheetId; name: string }) => {
      return apiTimesheets.createTimesheetInitiativeForMission(auth, sid, {
        name,
      });
    },
    onSuccess: (timesheetInitiative: TimesheetInitiativeObject) => {
      queryClient.invalidateQueries(
        queryKeys.timesheets.getTimesheetInitiativesForMission(
          sid,
          auth.uid || '',
        ),
      );
      if (onSuccess) {
        onSuccess(timesheetInitiative);
      }
    },
  });
};

export const useHideTimesheetInitiativeForMission = ({
  sid,
}: {
  sid: TimesheetId;
}) => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      sid,
      siid,
    }: {
      sid: TimesheetId;
      siid: TimesheetInitiativeId;
    }) => {
      return apiTimesheets.hideTimesheetInitiativeForMission(auth, sid, siid);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.timesheets.getTimesheetInitiativesForMission(
          sid,
          auth.uid || '',
        ),
      );
    },
  });
};

export const useMutationSetRecord = () => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: async ({
      sid,
      key,
      data,
    }: {
      sid: TimesheetId;
      key: string;
      data: TimesheetRecordData;
    }) => {
      return apiTimesheets.setRecord(auth, sid, key, data);
    },
  });
};

export const useMutationSetRecords = ({
  mid,
  yid,
}: {
  mid: string;
  yid: string;
}) => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      sid,
      data,
    }: {
      sid: TimesheetId;
      data: TimesheetRecordData[];
    }) => {
      return apiTimesheets.setRecords(auth, sid, { records: data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.timesheets.adminGetMissionPaymentCycle(
          mid,
          yid,
          auth.uid || '',
        ),
      );
    },
  });
};

export const useMutationDeleteRecord = () => {
  const { auth } = useStores();

  return useMutation({
    mutationFn: async ({ sid, key }: { sid: TimesheetId; key: string }) => {
      return apiTimesheets.deleteRecord(auth, sid, key);
    },
  });
};

export const useMutationDeleteRecords = ({
  mid,
  yid,
}: {
  mid: string;
  yid: string;
}) => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      sid,
      keys,
    }: {
      sid: TimesheetId;
      keys: TimesheetRecordKey[];
    }) => {
      return apiTimesheets.deleteRecords(auth, sid, { keys });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.timesheets.adminGetMissionPaymentCycle(
          mid,
          yid,
          auth.uid || '',
        ),
      );
    },
  });
};
