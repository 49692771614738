import { Modal } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import { FormProvider, useForm } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import React, { useMemo, useRef } from 'react';
import Images from '@src/views/Profile/Main/Projects/EditProjectModalV2/sections/Images';
import Role from '@src/views/Profile/Main/Projects/EditProjectModalV2/sections/Role';
import Skills from '@src/views/Profile/Main/Projects/EditProjectModalV2/sections/Skills';
import HasZeroToOneExperience from '@src/views/Profile/Main/Projects/EditProjectModalV2/sections/HasZeroToOneExperience';
import HasManagedPeople from '@src/views/Profile/Main/Projects/EditProjectModalV2/sections/HasManagedPeople';
import Metrics from '@src/views/Profile/Main/Projects/EditProjectModalV2/sections/Metrics';
import Description from '@src/views/Profile/Main/Projects/EditProjectModalV2/sections/Description';
import Footer from '@src/views/Profile/Main/Projects/EditProjectModalV2/sections/Footer';
import HideScrollerGlobally from '@src/components/HideScrollerGlobally';
import { Icon, IconType } from '@ateams/components';
import { ExistingProject, NewProject } from '@src/stores/Profile/models';
import { EditingExperienceObject } from '../../types';
import { useStores } from '@src/stores';
import { useGetProfileByUsername } from '@src/rq/profile';
import { ModalBasicProps } from '@src/components/Modal';
import { ExperienceType } from '@a_team/models/dist/ExperienceObject';
import { isNewExperienceItem } from '@src/views/Profile/helpers/experience';
import { observer } from 'mobx-react';
import { useQueryTalentSpecializationRoles } from '@src/rq/specializations';

interface EditSharedExperienceV2Props extends ModalBasicProps {
  editingExperience: EditingExperienceObject;
  onSave: (experience: NewProject | ExistingProject) => void;
  loading?: boolean;
}

const useStyles = createUseStyles({
  modal: {
    '& > div': {
      padding: 0,
    },
  },
  container: {
    margin: '24px 24px 110px 24px',
  },
  headerWrapper: {
    padding: 12,
    backgroundColor: '#FCFAFF',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    fontWeight: 500,
    color: '#6D00D7',
  },
  description: {
    marginTop: 8,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '90vw !important',
      maxWidth: '1200px !important',
      padding: 0,
      borderRadius: '16px !important',
      height: '90vh !important',
      maxHeight: '90vh !important',
      '& > div': {
        padding: 0,
      },
    },
    container: {
      maxWidth: '800px',
      minHeight: 500,
      margin: '40px auto 110px auto',
    },
  },
});

function EditSharedExperienceV2({
  open,
  editingExperience,
  onSave,
  onClose,
  loading,
}: EditSharedExperienceV2Props) {
  const styles = useStyles();
  const { users, auth } = useStores();

  const projectToEdit = {
    ...editingExperience,
    jobRole: undefined,
    jobRoleId: undefined,
    startDate: undefined,
    endDate: undefined,
    skills: undefined,
    metrics: undefined,
    hasZeroToOneExperience: undefined,
    hasManagedPeople: undefined,
    numberOfPeopleManaged: undefined,
  } as ExistingProject;

  const methods = useForm<NewProject | ExistingProject>({
    defaultValues: projectToEdit,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const isNewProject = isNewExperienceItem(projectToEdit);

  const { data: user } = useGetProfileByUsername(users.profile?.data.username);

  const { data: roles } = useQueryTalentSpecializationRoles({
    query: '',
  });

  const roleOptions = useMemo(() => {
    return (
      roles?.map((role) => {
        return { label: role.name, value: role.id };
      }) || []
    );
  }, [roles]);

  const imagesRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const skillsRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const hasZeroToOneExperienceRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const hasManagedPeopleRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const metricsRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const roleRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const descriptionRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const onSubmit = (project: NewProject | ExistingProject) => {
    const cleanedUpProject: NewProject | ExistingProject = {
      ...project,
      metrics: project.metrics?.filter(
        (metric) => metric.title && metric.description,
      ),
      type: ExperienceType.Project,
    };

    onSave(cleanedUpProject);
  };

  return (
    <Modal
      variant="slideUp"
      isOpen={open}
      className={styles.modal}
      shouldHideGradientStroke={true}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.container}>
            <div className={styles.headerWrapper}>
              <div className={styles.title}>
                <Icon type={IconType.CollaboratorBlack} size="exact" />
                Shared experience
              </div>
              <div className={styles.description}>
                You were added to this experience by a collaborator. Edit the
                information that applies to your role and contribution.
              </div>
            </div>

            <div ref={imagesRef}>
              <Images />
            </div>

            <div ref={roleRef}>
              <Role roles={roleOptions} />
            </div>

            <div ref={descriptionRef}>
              <Skills allSkills={user?.allSkills} />
            </div>

            {auth.withNewProjectExperienceFields && (
              <>
                <div ref={hasZeroToOneExperienceRef}>
                  <HasZeroToOneExperience />
                </div>

                <div ref={hasManagedPeopleRef}>
                  <HasManagedPeople />
                </div>
              </>
            )}

            <div ref={metricsRef}>
              <Metrics />
            </div>

            <div ref={skillsRef}>
              <Description
                isNewProject={isNewProject}
                roles={roleOptions}
                allSkills={user?.allSkills}
              />
            </div>
          </div>
          <Footer
            handleClose={onClose}
            confirmLabel="Save"
            cancelLabel="Back"
            imagesRef={imagesRef}
            roleRef={roleRef}
            skillsRef={skillsRef}
            hasZeroToOneExperienceRef={hasZeroToOneExperienceRef}
            hasManagedPeopleRef={hasManagedPeopleRef}
            metricsRef={metricsRef}
            descriptionRef={descriptionRef}
            errors={errors}
            loading={loading}
          />
        </form>
      </FormProvider>
      <HideScrollerGlobally />
    </Modal>
  );
}

export default observer(EditSharedExperienceV2);
