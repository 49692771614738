import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import TextButton from '@src/components/TextButton';
import BasicUserSelector from '@src/components/BasicUserSelector';
import {
  MissionManager,
  MissionManagerAccessMode,
} from '@a_team/models/dist/MissionObject';
import {
  AnonymousUserObject,
  BasicUserObject,
} from '@a_team/models/dist/UserObject';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import { ToggleSwitch } from '@ateams/components';

interface Props {
  manager?: MissionManager;
  mainManager?: string;
  onChange(manager: MissionManager): void;
  onMainManagerChange(userName?: string): void;
  onRemove?(): void;
}

const useStyles = createUseStyles({
  root: {
    marginBottom: 24,
    '& + &': {
      marginTop: '24px',
    },
  },
  row: {
    display: 'flex',
    marginBottom: 18,
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginLeft: '16px',
    },
  },
  remove: {
    textAlign: 'right',
  },
  switch: {
    marginLeft: 16,
  },
  checkboxContainer: {
    marginBottom: '8px',
  },
});

export default function MissionManagerInput(props: Props): ReactElement {
  const { manager, mainManager, onChange, onRemove, onMainManagerChange } =
    props;
  const styles = useStyles();

  const [data, setData] = useState<MissionManager | undefined>(manager);

  useEffect((): void => {
    setData(manager);
  }, [manager]);

  const isMainManager = useMemo(() => {
    return !!mainManager && mainManager === manager?.username;
  }, [mainManager, manager?.username]);

  const handleChange = (update: {
    user: AnonymousUserObject | BasicUserObject | null;
  }): void => {
    const newRole = { ...data, ...update };

    if (newRole.user) {
      const updatedManager = {
        user: newRole.user as BasicUserObject,
        username: newRole.user.username || '',
        accessMode: MissionManagerAccessMode.RoleView,
        excludeFromInvoiceEmails: data?.excludeFromInvoiceEmails,
        excludeFromTeamPulseEmails: data?.excludeFromTeamPulseEmails,
      };
      onChange(updatedManager);
      setData(updatedManager);
    }
  };

  const onExcludeInvoicesCheckboxChange = (isExcluded: boolean) => {
    data && onChange({ ...data, excludeFromInvoiceEmails: isExcluded });
  };

  const onExcludeTeamPulseCheckboxChange = (isExcluded: boolean) => {
    data && onChange({ ...data, excludeFromTeamPulseEmails: isExcluded });
  };

  const onMainManagerCheckboxChange = (checked: boolean) => {
    manager && onMainManagerChange(checked ? manager?.username : undefined);
  };

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <BasicUserSelector
          user={data?.user}
          onSelect={(user): void => handleChange({ user })}
          margin="none"
          includeClientUsers
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div>
            <div className={styles.checkboxContainer}>
              <LabeledCheckboxInput
                margin={'none'}
                label={'Should receive invoices'}
                onChange={(e) =>
                  onExcludeInvoicesCheckboxChange(!e.target.checked)
                }
                checked={!data?.excludeFromInvoiceEmails}
                disabled={isMainManager || !manager}
              />
            </div>
            <div className={styles.checkboxContainer}>
              <LabeledCheckboxInput
                margin={'none'}
                label={'Should receive TeamPulse'}
                onChange={(e) =>
                  onExcludeTeamPulseCheckboxChange(!e.target.checked)
                }
                checked={!data?.excludeFromTeamPulseEmails}
                disabled={!manager}
              />
            </div>
          </div>
          <ToggleSwitch
            label={'Main manager'}
            size={'small'}
            onChange={onMainManagerCheckboxChange}
            checked={isMainManager}
            disabled={!manager}
            className={styles.switch}
          />
        </div>
        {onRemove && (
          <div className={styles.remove}>
            <TextButton onClick={onRemove} highlight>
              Remove
            </TextButton>{' '}
          </div>
        )}
      </div>
    </div>
  );
}
