import React from 'react';
import { BasicVettingProcessColumnProps } from '..';
import { Button } from '@ateams/components';
import { PreVettingAnswersModal } from '@src/components/Modal/PreVettingAnswersModal';
import useToggle from '@src/hooks/useToggle';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  button: {
    width: '160px',
  },
});

export const PreVettingAnswersField: React.FC<
  BasicVettingProcessColumnProps
> = ({ vettingProcess }) => {
  const styles = useStyles();
  const [preVettingAnswersModalOpen, togglePreVettingAnswersModalOpen] =
    useToggle();

  return (
    <>
      {vettingProcess.preVetting && (
        <PreVettingAnswersModal
          open={preVettingAnswersModalOpen}
          onClose={togglePreVettingAnswersModalOpen}
          preVettingAnswers={vettingProcess.preVetting}
          user={vettingProcess.user}
        />
      )}
      <Button
        onClick={togglePreVettingAnswersModalOpen}
        size="small"
        disabled={!vettingProcess.preVetting}
        className={styles.button}
      >
        Answers
      </Button>
    </>
  );
};
