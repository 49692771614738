import { useMemo } from 'react';
import MissionLayout from '@src/layouts/Mission';
import { useStores } from '@src/stores';
import { loadMission, MissionMatch } from '@src/url-loaders/loadMission';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import SectionHeading from '@src/components/SectionHeading';
import { Card, Icon, IconType } from '@ateams/components';
import { format } from 'date-fns';
import { Colors } from '@ateams/components';
import cx from 'classnames';
import {
  NotificationLogDto,
  NotificationLogDtoTypeEnum,
  NotificationLogRoleDto,
} from '@a_team/user-notification-service-js-sdk';

export interface MissionNotificationsViewProps {
  match: MissionMatch;
}

interface NotificationLogRoleInProps extends NotificationLogRoleDto {
  title: string;
  headline: string;
}

interface NotificationInProps extends NotificationLogDto {
  roles: NotificationLogRoleInProps[];
}

interface MissionNotificationCardProps {
  notification: NotificationInProps;
}

const useStyles = createUseStyles({
  header: {
    marginTop: '21px',
  },
  contentWrapper: {
    overflowY: 'auto',
  },
  timeline: {
    marginLeft: 60,
    marginTop: 10,
    minHeight: '50vh',
    '&::after': {
      content: '""',
      width: 2,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 20,
      zIndex: 1,
      background: Colors.regular,
    },
  },
  timelineItem: {
    display: 'flex',
    flex: '0 0 100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '&::after': {
      content: '""',
      width: 10,
      height: 10,
      background: Colors.regular,
      borderRadius: '50%',
      position: 'absolute',
      left: -44,
      top: 23,
      zIndex: 2,
    },
  },
  roles: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
    lineHeight: '40px',
    fontSize: 15,
  },
  roleContainer: {
    marginBottom: '20px',
    paddingBottom: '10px',
    borderBottom: '1px solid #ccc',
  },
  roleTitle: {
    fontWeight: 700,
    marginRight: 15,
  },
  details: {
    display: 'flex',
  },
  detailsText: {
    color: '#62646A',
    fontWeight: 400,
    fontSize: 15,
  },
  timelineWrapper: {
    maxWidth: 600,
    position: 'relative',
  },
  noResults: {
    margin: '0 15px',
  },
  link: {
    color: Colors.secondary,
  },
});

export const missionNotificationsViewLoader = loadMission;

const MissionNotificationCard = (props: MissionNotificationCardProps) => {
  const styles = useStyles();
  const { notification } = props;
  const totalCount = useMemo(() => {
    return notification.roles.reduce(
      (acc, role) => (acc += role.count || 0),
      0,
    );
  }, [notification]);
  return (
    <Card className={styles.timelineItem}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={styles.roles}>
          {notification.roles.map((r) => (
            <div className={styles.roleContainer}>
              <span className={styles.roleTitle}>{r.title}</span>
              <p>{r.headline}</p>
              {notification.type === NotificationLogDtoTypeEnum.Reachout && (
                <p>Targeted: {r.count}</p>
              )}
              <a
                className={styles.link}
                target="_blank"
                href={`https://bi.a.team/dashboard/118-mission-notification-logs-dashboard?roleid=${r.rid}&notificationid=${notification.notificationId}`}
                rel="noreferrer"
              >
                <Icon size="exact" type={IconType.LinkSecondary} /> Metabase
              </a>
            </div>
          ))}
        </div>
        <div className={cx(styles.details, styles.detailsText)}>
          <span>
            {notification.createdAt &&
              format(new Date(notification.createdAt), 'MMM dd, yyyy HH:mm')}
          </span>
          <span style={{ padding: '0 8px', fontSize: 12 }}>|</span>
          <span>{notification.createdBy}</span>
          {notification.type === NotificationLogDtoTypeEnum.Reachout && (
            <>
              <span style={{ padding: '0 8px', fontSize: 12 }}>|</span>
              <span>Total targeted: {totalCount}</span>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

const MissionNotificationsView = observer(
  ({ match }: MissionNotificationsViewProps): ReactElement | null => {
    const styles = useStyles();
    const stores = useStores();
    const { missions } = stores;
    const { currentMission } = missions;
    const notifications = useMemo(
      () =>
        (currentMission?.notifications || []).map((notification) => {
          return {
            ...notification,
            roles: notification.roles.map((role: NotificationLogRoleDto) => {
              const missionRole = currentMission?.data.roles.find(
                (r) => r.rid === role.rid,
              );
              return {
                ...role,
                title: missionRole?.category.title || '',
                headline: missionRole?.headline || '',
              };
            }),
          };
        }),
      [currentMission?.notifications],
    );

    return (
      <MissionLayout
        title={`${currentMission?.data.title} | Mission Notification`}
        match={match}
      >
        <SectionHeading className={styles.header}>
          Reachouts History
        </SectionHeading>
        <div className={styles.contentWrapper}>
          {currentMission?.notifications &&
          currentMission.notifications.length > 0 ? (
            <div className={styles.timelineWrapper}>
              <div className={styles.timeline}>
                {notifications.map((n, index) => (
                  <MissionNotificationCard notification={n} key={index} />
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.noResults}>
              {currentMission?.notifications
                ? 'There were no Reachouts made for this mission.'
                : 'Loading...'}
            </div>
          )}
        </div>
      </MissionLayout>
    );
  },
);

export default MissionNotificationsView;
