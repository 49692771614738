import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import TanstackTableReadOnly from './TanstackTableReadOnly';
import { addPlaceholderRecords } from '.';
import { GroupedRecords } from '@src/stores/Missions/Mission';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import { useQueryAdminGetMissionPaymentCycle } from '@src/rq/timesheets';
import TableSkeleton from './TableSkeleton';
import TanstackTableAdmin from './TanstackTableAdmin';
import MissionRole from '@a_team/models/dist/MissionRole';

export interface SelectableUser {
  sid: string;
  role: MissionRole;
  user: BasicUserObject;
}

const AdminTable = (): JSX.Element => {
  const {
    missions: { currentMission },
  } = useStores();
  const [selectedUser, setSelectedUser] = React.useState<
    SelectableUser | undefined
  >();

  const paymentCycleId =
    currentMission?.selectedPaymentCycle?.yid ??
    currentMission?.data.paymentCycles?.current?.yid ??
    '';

  const { isLoading, data, error } = useQueryAdminGetMissionPaymentCycle({
    mid: currentMission?.mid || '',
    yid: paymentCycleId,
  });

  const allRoleTimesheets = useMemo(() => {
    return (
      data?.timesheets?.filter((roleTimesheet) =>
        currentMission?.data.roles?.some(
          (role) => role.rid === roleTimesheet.rid && role.user?.username,
        ),
      ) ?? []
    );
  }, [data?.timesheets]);

  const { recordsToShow, allSelectableUsers } = useMemo(() => {
    if (!data?.endDate) {
      return { recordsToShow: [], allSelectableUsers: [] };
    }

    const today = new Date();
    const endDate = new Date(data.endDate) < today ? data.endDate : today;

    const recordsFromDB: GroupedRecords[] = [];

    if (currentMission && allRoleTimesheets) {
      allRoleTimesheets.forEach((t) => {
        const groupedRecords = currentMission.groupAllUserRecords({
          userRecords: t.records,
          roleId: t.rid,
        });
        recordsFromDB.push(...groupedRecords);
      });
    }

    const flatRecordsFromDb = recordsFromDB?.flatMap((r) => r.records) ?? [];

    const filteredRecords = selectedUser
      ? flatRecordsFromDb.filter(
          (record) =>
            (record.role.user as BasicUserObject)?.uid ===
            selectedUser.user.uid,
        )
      : flatRecordsFromDb;

    return {
      recordsToShow: addPlaceholderRecords(
        filteredRecords[0]?.role,
        data.startDate,
        endDate,
        filteredRecords,
      ),
      allSelectableUsers: flatRecordsFromDb.reduce(
        (acc: SelectableUser[], record) => {
          const roleTimesheet = allRoleTimesheets.find((rt) => {
            return rt.rid === record.role.rid;
          });

          const user = record.role.user as BasicUserObject;

          if (!acc.some((su) => su.user.uid === user.uid)) {
            acc.push({
              user,
              role: record.role,
              sid: roleTimesheet?.sid ?? '',
            });
          }
          return acc;
        },
        [],
      ),
    };
  }, [
    data,
    currentMission?.currentUserRole,
    addPlaceholderRecords,
    selectedUser?.user?.uid,
  ]);

  if (isLoading) {
    return <TableSkeleton />;
  }

  if (error) {
    return <div>There was an error please contact BX</div>;
  }

  if (selectedUser) {
    return (
      <TanstackTableAdmin
        currentMission={currentMission}
        records={recordsToShow}
        allSelectableUsers={allSelectableUsers}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    );
  }

  return (
    <div>
      <TanstackTableReadOnly
        allSelectableUsers={allSelectableUsers}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        records={recordsToShow}
        currentMission={currentMission}
        teamTable
      />
    </div>
  );
};

export default observer(AdminTable);
