import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import uniqueId from 'lodash/uniqueId';
import { Checkbox, Colors, TextColors } from '@ateams/components';
import { formatMinutesToTime } from '@src/helpers/time';
import {
  DailyHoursRange,
  HoursRange,
  TimeOfDay,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { OnTimeFieldChange, TimeField } from '../TimeField';
import { calcTotalMinutes } from '../TimeField/utils';
import { TrashIcon } from './trash-icon';
import { PlusIcon } from './plus-icon';

export type OnDailyHoursChange = (dailyHours: DailyHoursRange) => void;

export interface DailyHoursInputProps {
  day: string;
  defaultValue: DailyHoursRange;
  onChange: OnDailyHoursChange;
  disabled?: boolean;
  readonly?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${Colors.regularLight}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  dayLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '66px',
  },
  dayLabel: {
    fontWeight: 600,
    marginLeft: '12px',
    width: '40px',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 40px',
    width: '210px',
  },
  inputRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '8px',
    '&:last-child': {
      marginBottom: '8px',
    },
  },
  hoursRangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hoursRangeSeparation: {
    margin: '0 6px',
  },
  plusIcon: {
    alignSelf: 'start',
    height: '66px',
    width: '20px',
  },
  timeField: {
    padding: 0,
    margin: 0,
    minHeight: 'auto',
    height: '40px',
    width: '80px',
  },
  trashIcon: {
    marginLeft: '16px',
  },
  unavailableLabel: {
    marginRight: '22px',
    color: TextColors.regularLight,
  },
});

export const DailyHoursInput: React.FC<DailyHoursInputProps> = ({
  day,
  defaultValue,
  disabled,
  readonly,
  onChange,
}) => {
  const styles = useStyles();
  const [value, setLocalValue] = useState<DailyHoursRange>(
    defaultValue || null,
  );
  const [inputsKeys, setInputsKeys] = useState<string[]>([]);

  const onCheckboxClick: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (Array.isArray(value)) {
      setInputsKeys([]);
      setLocalValue(null);
    } else {
      setInputsKeys([uniqueId()]);
      setLocalValue([[null, null]]);
    }
  };

  const onHoursRangeChange = (hoursRange: HoursRange, index: number) => {
    if (value) {
      const newValue = [
        ...value.slice(0, index),
        hoursRange,
        ...value.slice(index + 1),
      ];

      setLocalValue(newValue);
      onChange(newValue);
    }
  };

  const onDeleteInput = (index: number) => {
    if (value) {
      let newValue: DailyHoursRange = [
        ...value.slice(0, index),
        ...value.slice(index + 1),
      ];
      if (!newValue.length) {
        newValue = null;
      }

      setInputsKeys([
        ...inputsKeys.slice(0, index),
        ...inputsKeys.slice(index + 1),
      ]);
      setLocalValue(newValue);
      onChange(newValue);
    }
  };

  const onAddInput = () => {
    const newInputValue = [null, null] as HoursRange;

    if (value) {
      const newValue = [...value, newInputValue];

      setInputsKeys([...inputsKeys, uniqueId()]);
      setLocalValue(newValue);
      onChange(newValue);
    } else {
      const newValue = [newInputValue];

      setInputsKeys([uniqueId()]);
      setLocalValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.dayLabelContainer}>
        {!readonly && (
          <Checkbox
            checked={Array.isArray(value)}
            onChange={onCheckboxClick}
            disabled={disabled}
          />
        )}

        <div className={styles.dayLabel}>{day}</div>
      </div>

      <div className={styles.inputsContainer}>
        {Array.isArray(value) ? (
          value.map((defaultValue, i) => (
            <div className={styles.inputRowContainer} key={inputsKeys[i]}>
              <HoursRangeInput
                defaultValue={defaultValue}
                onChange={(hoursRange) => onHoursRangeChange(hoursRange, i)}
                disabled={disabled}
              />
              {!readonly && (
                <TrashIcon
                  className={styles.trashIcon}
                  onClick={() => onDeleteInput(i)}
                  disabled={disabled}
                />
              )}
            </div>
          ))
        ) : (
          <div className={styles.unavailableLabel}>Unavailable</div>
        )}
      </div>

      {!readonly && (
        <PlusIcon
          onClick={onAddInput}
          className={styles.plusIcon}
          disabled={disabled}
        >
          plus
        </PlusIcon>
      )}
    </div>
  );
};

type OnHoursRangeChange = (hoursRange: HoursRange) => void;

interface HoursRangeInputProps {
  onChange: OnHoursRangeChange;
  defaultValue?: HoursRange;
  disabled?: boolean;
}

const HoursRangeInput: React.FC<HoursRangeInputProps> = (props) => {
  const styles = useStyles();
  const [startTime, setStartTime] = useState<null | TimeOfDay>(
    Array.isArray(props.defaultValue) ? props.defaultValue[0] : null,
  );
  const [endTime, setEndTime] = useState<null | TimeOfDay>(
    Array.isArray(props.defaultValue) ? props.defaultValue[1] : null,
  );

  const parseTotalMinutes = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const time: TimeOfDay = { hours, minutes };

    return time;
  };

  const onStartTimeChange: OnTimeFieldChange = (e, totalMinutes) => {
    const newTime = e?.target.value.length
      ? parseTotalMinutes(totalMinutes)
      : null;

    setStartTime(newTime);
    props.onChange([newTime, endTime]);
  };

  const onEndTimeChange: OnTimeFieldChange = (e, totalMinutes) => {
    const newTime = e?.target.value.length
      ? parseTotalMinutes(totalMinutes)
      : null;

    setEndTime(newTime);
    props.onChange([startTime, newTime]);
  };

  return (
    <div className={styles.hoursRangeContainer}>
      <TimeField
        className={styles.timeField}
        defaultValue={
          startTime !== null
            ? formatMinutesToTime(
                calcTotalMinutes(startTime.hours, startTime.minutes),
              )
            : undefined
        }
        onChange={onStartTimeChange}
        disabled={props.disabled}
      />

      <div className={styles.hoursRangeSeparation}>{'-'}</div>

      <TimeField
        className={styles.timeField}
        defaultValue={
          endTime !== null
            ? formatMinutesToTime(
                calcTotalMinutes(endTime.hours, endTime.minutes),
              )
            : undefined
        }
        onChange={onEndTimeChange}
        disabled={props.disabled}
      />
    </div>
  );
};
