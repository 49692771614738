import { MissionId } from './MissionObject';
import { UserId } from './UserObject';

export type TimesheetInitiativeId = string;

export interface TimesheetInitiativeObject {
  siid: TimesheetInitiativeId;
  mission: MissionId;
  createdBy: UserId;
  name: string;
  isHidden: boolean;
  backgroundColor: string;
  createdAt: Date;
}

export enum TimesheetSummaryBlurbNodeType {
  Title = 'Title',
  Paragraph = 'Paragraph',
}

export interface TimesheetSummaryBlurbNode {
  type: TimesheetSummaryBlurbNodeType;
  content: string;
}
