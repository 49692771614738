import { ServiceAuth, ServiceEndpoint } from './utils';
import {
  ExperienceObject,
  BasicExperienceObject,
  ExperienceData,
  ExperienceDuplicateData,
  ExperienceId,
  ExperienceMember,
  JobExperienceData,
  ProjectExperienceData,
  ProjectDescriptionBlurbData,
  ProjectDescriptionBlurbNode,
} from '@a_team/models/dist/ExperienceObject';

export const BasePath = '/experiences';

// exported functions

export default class ExperiencesEndpoint extends ServiceEndpoint {
  public generateProjectDescriptionBlurb(
    auth: ServiceAuth,
    data: ProjectDescriptionBlurbData,
  ): Promise<{ projectDescriptionBlurbNodes: ProjectDescriptionBlurbNode[] }> {
    return this.fetch(
      auth,
      BasePath + `/project-description-blurb`,
      null,
      'post',
      data,
    );
  }

  public createUserExperience(
    auth: ServiceAuth,
    data: ExperienceData,
  ): Promise<ExperienceObject> {
    return this.fetch(auth, BasePath, null, 'post', data);
  }

  public getExperienceById(
    auth: ServiceAuth,
    eid: ExperienceId,
  ): Promise<ExperienceObject> {
    return this.fetch(auth, BasePath + `/${eid}`);
  }

  public getExperiencesByUserId(
    auth: ServiceAuth,
    uid: string,
  ): Promise<{
    jobs: (BasicExperienceObject & JobExperienceData)[];
    projects: (BasicExperienceObject & ProjectExperienceData)[];
  }> {
    return this.fetch(auth, BasePath + `/user/${uid}`);
  }

  public updateExperience(
    auth: ServiceAuth,
    eid: ExperienceId,
    data: ExperienceData,
  ): Promise<ExperienceObject> {
    return this.fetch(auth, BasePath + `/${eid}`, null, 'put', data);
  }

  public duplicateExperience(
    auth: ServiceAuth,
    eid: ExperienceId,
    data: ExperienceDuplicateData,
  ): Promise<ExperienceObject> {
    return this.fetch(auth, BasePath + `/${eid}/duplicate`, null, 'post', data);
  }

  public duplicateExperiences(
    auth: ServiceAuth,
    ids: ExperienceId[],
    data: ExperienceDuplicateData,
  ): Promise<BasicExperienceObject[]> {
    return this.fetch(auth, BasePath + `/duplicate`, null, 'post', {
      ids: ids,
      ...data,
    });
  }

  public updateExperienceMember(
    auth: ServiceAuth,
    eid: ExperienceId,
    member: ExperienceMember,
  ): Promise<ExperienceObject> {
    return this.fetch(auth, BasePath + `/${eid}/member`, null, 'put', member);
  }

  public approveCollaboratorRequest(
    auth: ServiceAuth,
    eid: ExperienceId,
    data: ExperienceData,
  ): Promise<ExperienceObject> {
    return this.fetch(
      auth,
      BasePath + `/${eid}/collaboration-requests/approve`,
      null,
      'put',
      data,
    );
  }

  public rejectCollaboratorRequest(
    auth: ServiceAuth,
    eid: ExperienceId,
  ): Promise<ExperienceObject> {
    return this.fetch(
      auth,
      BasePath + `/${eid}/collaboration-requests/reject`,
      null,
      'delete',
    );
  }

  public deleteExperienceMember(
    auth: ServiceAuth,
    eid: ExperienceId,
    member: ExperienceMember,
  ): Promise<ExperienceObject> {
    return this.fetch(
      auth,
      BasePath + `/${eid}/member`,
      null,
      'delete',
      member,
    );
  }

  public deleteExperience(auth: ServiceAuth, eid: ExperienceId): Promise<null> {
    return this.fetch(auth, BasePath + `/${eid}`, null, 'delete');
  }

  public hasPendingCollaboratorRequest(
    auth: ServiceAuth,
    eid: ExperienceId,
  ): Promise<{ hasPendingCollaboratorRequest: boolean }> {
    return this.fetch(
      auth,
      BasePath + `/${eid}/collaboration-requests/has-pending-request`,
      null,
      'get',
    );
  }
}
