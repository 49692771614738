import { AdminNotesTranscript } from '@a_team/models/dist/AdminNotesObject';
import { theme } from '@a_team/ui-components';
import {
  VettingDashboardRecordingLocation,
  TranscriptLocation,
} from '@src/locations';
import { LinkIcon } from '@src/views/VettingFeedbackForm/components/icons/link';
import { ValueTag } from '@src/views/VettingFeedbackForm/components/value-tag';
import { format } from 'date-fns';
import React from 'react';
import { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import {
  BorderColors,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';

const useStyles = createUseStyles({
  starredContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.medium,
    alignItems: 'center',
  },
  listItem: {
    border: `1px solid ${BorderColors.light}`,
    borderRadius: Spacing.small,
    padding: Spacing.medium,
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
  },
  listItemTitle: {
    fontSize: 17,
    fontWeight: FontWeights.semiBold,
  },
  verticalSeparator: {
    borderRight: `1px solid ${BorderColors.lighter}`,
    height: 16,
  },
  starredByContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: Spacing.small,
  },
  starredByText: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semiBold,
  },
  starredBy: {
    fontWeight: FontWeights.medium,
    color: Colors.secondaryDark,
  },
  starredAt: {
    fontSize: FontSizes.medium,
  },
  starredOn: {
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  starredOnNegative: {
    color: Colors.danger,
  },
  roleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.medium,
    paddingLeft: 26,
    marginTop: 24,
    marginBottom: 24,
    borderLeft: `1px solid ${Colors.backgroundDark}`,
  },
  roleDescription: {
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  companyBudget: {
    fontSize: FontSizes.medium,
  },
  missionDescription: {
    fontSize: FontSizes.medium,
  },
  listLinksContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  listLinks: {
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.small,
  },
  purpleTag: {
    background: theme.colors.Hannibal[100],
    color: theme.colors.Hannibal[600],
  },
});

export enum InterviewStatus {
  InterviewAccepted = 'InterviewAccepted',
  InterviewRejected = 'InterviewRejected',
  InterviewCancelled = 'InterviewCancelled',
  InterviewExpired = 'InterviewExpired',
}

export interface DiscoveryItemProps {
  workspaceName: string;
  starredBy?: string;
  starredAt?: Date;
  starredContext?: string;
  interviewStatus?: string;
  interviewDate?: Date;
  roleDescription?: string;
  minCompanyBudget?: number;
  maxCompanyBudget?: number;
  transcripts?: AdminNotesTranscript[];
}

export const DiscoveryItem = ({
  workspaceName,
  starredBy,
  starredAt,
  starredContext,
  interviewStatus,
  interviewDate,
  roleDescription,
  minCompanyBudget,
  maxCompanyBudget,
  transcripts,
}: DiscoveryItemProps) => {
  const styles = useStyles();

  const recordingPageUrls = useMemo(() => {
    return (
      transcripts?.map((transcript) => {
        return `${VettingDashboardRecordingLocation}?recordingUrl=${encodeURIComponent(
          transcript.recordingUrl,
        )}`;
      }) ?? []
    );
  }, [transcripts]);

  return (
    <div className={styles.listItem}>
      <div className={styles.listItemTitle}>{workspaceName} workspace</div>
      {starredAt && starredBy && (
        <div className={styles.starredContainer}>
          <div className={styles.starredByContainer}>
            <div className={styles.starredByText}>Starred by</div>
            <div className={styles.starredBy}>{starredBy}</div>
          </div>
          <div className={styles.verticalSeparator}></div>
          <div className={styles.starredAt}>
            {format(starredAt, `MMMM d, yyyy 'at' h:mm a`)}
          </div>
        </div>
      )}
      {interviewStatus ? (
        <>
          {
            {
              [InterviewStatus.InterviewAccepted]:
                interviewDate && interviewDate < new Date() ? (
                  <div className={styles.starredOn}>
                    Interview happened on{' '}
                    {format(interviewDate, `MMMM d, yyyy 'at' h:mm a`)}
                  </div>
                ) : (
                  <div className={styles.starredOn}>Interview accepted</div>
                ),
              [InterviewStatus.InterviewRejected]: (
                <div className={cx(styles.starredOn, styles.starredOnNegative)}>
                  Interview rejected
                </div>
              ),
              [InterviewStatus.InterviewExpired]: (
                <div className={cx(styles.starredOn, styles.starredOnNegative)}>
                  Interview expired
                </div>
              ),
              [InterviewStatus.InterviewCancelled]: (
                <div className={cx(styles.starredOn, styles.starredOnNegative)}>
                  Interview cancelled
                </div>
              ),
            }[interviewStatus]
          }

          <div className={styles.roleContainer}>
            <div className={styles.roleDescription}>Role description</div>
            <div className={styles.companyBudget}>
              Company's budget — ${minCompanyBudget} to ${maxCompanyBudget} per
              hour
            </div>
            <div className={styles.missionDescription}>{roleDescription}</div>
          </div>

          {transcripts?.map((transcript, index) => {
            return (
              <div key={transcript.jobId} className={styles.listLinksContainer}>
                <div className={styles.listLinks}>
                  <a
                    key={recordingPageUrls[index]}
                    href={recordingPageUrls[index]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ValueTag
                      className={styles.purpleTag}
                      startAdornment={<LinkIcon />}
                    >
                      Recording link
                    </ValueTag>
                  </a>
                </div>
                <div className={styles.listLinks}>
                  <a
                    key={transcript.jobId}
                    href={`${TranscriptLocation(transcript.jobId)}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ValueTag
                      className={styles.purpleTag}
                      startAdornment={<LinkIcon />}
                    >
                      Transcript link
                    </ValueTag>
                  </a>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className={styles.starredOn}>Starred on {starredContext}</div>
      )}
    </div>
  );
};
