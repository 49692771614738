import React from 'react';
import {
  DidBuildProductsFromScratch,
  DidBuildProductsFromScratchLabels,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { Select, SelectProps } from '@ateams/components';
import { InputContainer } from '../components/input-container';
import { InputLabel } from '../components/input-label';

const DidBuildProductsFromScratchOptions = Object.entries(
  DidBuildProductsFromScratchLabels,
).map(([key, label]) => ({ value: key, label }));

export type OnDidBuildProductsFromScratchChange = (
  didBuildProductsFromScratch: DidBuildProductsFromScratch,
) => void;

export interface DidBuildProductsFromScratchSelectProps {
  onChange?: OnDidBuildProductsFromScratchChange;
  error?: string;
  onBlur?: () => void;
  className?: string;
  selectClassName?: string;
  'data-testing-id'?: string;
}

export const DidBuildProductsFromScratchSelect: React.FC<
  DidBuildProductsFromScratchSelectProps
> = (props) => {
  const onChange: SelectProps['onChange'] = (option) => {
    if (option) {
      const newDidBuildProductsFromScratch =
        option.value as DidBuildProductsFromScratch;

      props.onChange?.(newDidBuildProductsFromScratch);
    }
  };

  return (
    <InputContainer error={props.error} className={props.className}>
      <InputLabel>Did You Ever Build Products From Scratch? *</InputLabel>

      <Select
        placeholder={'Select an answer...'}
        onChange={onChange}
        options={DidBuildProductsFromScratchOptions}
        className={props.selectClassName}
        error={Boolean(props.error)}
        onBlur={props.onBlur}
        data-testing-id={props['data-testing-id']}
      />
    </InputContainer>
  );
};
