import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import AspectRatioBox from '../AspectRatioBox';
import { DateISOString } from '@a_team/models/dist/misc';
import NoCover, { noCoverColors } from './NoCover';
import arrowRight from './arrow-right.svg';
import divider from './divider.svg';
import { Button } from '@a_team/ui-components';
import {
  BorderColors,
  Colors,
  Icon,
  Icon as IconComponent,
  IconType,
} from '@ateams/components';
import cx from 'classnames';
import CompanyLogoFallback from '../CompanyLogoFallback';
import {
  useGetProfileSuggestions,
  usePartiallyRejectSuggestion,
} from '@src/rq/profileSuggestions';
import { BuilderSuggestionType } from '@a_team/models/dist/ProfileEnhancements';
import { RecommendationMatch } from '@a_team/models/dist/MissionApplicationObject';
import starsWhite from './starsWhite.svg';
import { joinWithCommasAnd } from '@src/logic/utils';
import Image from '../Image';

type ProjectCardV2Props = {
  index: number;
  companyName: string;
  companyLogo: string;
  projectCover?: string;
  projectName: string;
  projectIndustry: string;
  projectIndustries: string[];
  startDate?: DateISOString;
  endDate?: DateISOString;
  isEditMode?: boolean;
  onOpenProject: () => void;
  onEdit: () => void;
  onDelete: () => void;
  isHidden?: boolean;
  canEdit?: boolean;
  canHide?: boolean;
  isOwner?: boolean;
  isSuggested?: boolean;
  recommendation?: RecommendationMatch;
  currentlyViewing?: boolean;
  projectId?: string;
  canSelect?: boolean;
  selectedPosition?: number;
  onSelect?: (projectId: string) => void;
  onDeselect?: (projectId: string) => void;
  disabledOnHidden?: boolean;
  isMissingMinimumRequirements?: boolean;
};

const useStyles = createUseStyles<{
  noCoverColor: string;
  isHidden?: boolean;
  canHide?: boolean;
  isRecommended?: boolean;
  disabledOnHidden?: boolean;
  isMissingMinimumRequirements?: boolean;
  isEditMode?: boolean;
}>({
  wrapper: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
    backgroundColor: Colors.light,
    padding: ({ canHide }) => (canHide ? '24px 24px 56px 24px' : '0 0 60px 0'),
    border: ({ isHidden, canHide }) =>
      isHidden || !canHide ? 0 : `2px solid ${Colors.secondaryDark}`,
    borderRadius: 16,
    '&::before': {
      content: ({ isHidden, canHide, selectedPosition, isRecommended }) =>
        isHidden || !canHide
          ? isRecommended
            ? '"Recommended"'
            : ''
          : `"${selectedPosition}"`,
      background: ({ isRecommended, isHidden, canHide }) =>
        isRecommended && (isHidden || !canHide)
          ? `url("${starsWhite}") left center no-repeat ${Colors.secondaryDark}`
          : `${Colors.secondaryDark}`,
      height: ({ isHidden, canHide, isRecommended }) =>
        isHidden || !canHide ? (isRecommended ? 32 : 0) : 32,
      padding: ({ isRecommended, isHidden, canHide }) =>
        isRecommended && (isHidden || !canHide)
          ? '0 13px 0 35px'
          : '0 13px 0 12px',
      borderRadius: '8px',
      color: Colors.light,
      lineHeight: '32px',
      top: 7,
      left: 7,
      position: 'absolute',
      zIndex: 9,
    },
    '&::after': {
      content: ({ canHide }) => (canHide ? '""' : ''),
      position: 'absolute',
      top: 0,
      left: 0,
      width: ({ isHidden, canHide }) =>
        isHidden || !canHide ? 'calc(100% - 2px)' : 0,
      height: ({ isHidden, canHide }) => (isHidden || !canHide ? '100%' : 0),
      background: ({ disabledOnHidden, isMissingMinimumRequirements }) =>
        disabledOnHidden || isMissingMinimumRequirements
          ? Colors.backgroundWhite
          : 'transparent',
      zIndex: 9,
      opacity: ({ disabledOnHidden, isMissingMinimumRequirements }) =>
        disabledOnHidden || isMissingMinimumRequirements ? 0.5 : 1,
      border: `1px solid ${BorderColors.lighter}`,
      borderRadius: '16px',
      boxShadow:
        '0px 5px 3px -2px rgba(0, 0, 0, 0.02), 0px 3px 1px -2px rgba(0, 0, 0, 0.06)',
    },
  },
  container: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: ({ canHide }) => (canHide ? 24 : 16),
    '&::after': {
      content: ({ canHide, isEditMode, isMissingMinimumRequirements }) =>
        !canHide && isEditMode && isMissingMinimumRequirements ? '""' : '',
      position: 'absolute',
      top: 45,
      left: 0,
      width: '100%',
      height: '100%',
      background: Colors.backgroundWhite,
      zIndex: 9,
      opacity: 0.5,
      border: 'none',
    },
  },
  projectCoverContainer: {
    width: '100%',
    cursor: 'pointer',
  },
  projectCoverWrapper: {
    borderRadius: '8px',
  },
  projectCover: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  hidden: {
    opacity: ({ disabledOnHidden }) => (disabledOnHidden ? 0.3 : 1),
  },
  companyLogoContainer: {
    width: '24px',
    height: '24px',
  },
  projectName: {
    fontSize: ({ canHide }) => (canHide ? 20 : 16),
    fontWeight: ({ canHide }) => (canHide ? 600 : 500),
    margin: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '26px',
  },
  openProjectBtn: {
    border: 'none',
    outline: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    '& > img': {
      width: 16,
      height: 16,
    },
  },
  projectDate: {
    fontSize: 12,
    color: '#6E7177',
  },
  companySection: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginTop: ({ canHide }) => (canHide ? 8 : 0),
    marginBottom: 6,
  },
  companyNameAndIndustry: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
  },
  match: {
    display: 'flex',
    gap: 8,
    color: Colors.secondaryDark,
    fontSize: 16,
    alignItems: 'flex-start',
    padding: '24px 0 24px 0',
    fontWeight: 500,
  },
  companySectionWithNoRecommendation: {
    marginBottom: 24,
  },
  editDeleteBtnsContainer: {
    display: 'flex',
    gap: 10,
  },
  actionBtns: {
    display: 'flex',
    justifyContent: ({ canHide }) => (canHide ? 'flex-start' : 'space-between'),
    position: 'absolute',
    bottom: 24,
    zIndex: 10,
    gap: 10,
  },
  rightArrowIcon: {
    width: 16,
    height: 16,
  },
  btnControl: {
    paddingRight: '0 !important',
    paddingLeft: '0 !important',
    width: '60px !important',
    maxWidth: '60px !important',
  },
  topBanner: {
    padding: '12px 12px 20px 12px',
    borderRadius: '8px 8px 0px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8,
    marginBottom: -8,
    fontWeight: '500',
  },
  topBannerOnApplication: {
    marginBottom: -14,
    padding: '16px 12px 22px 16px',
  },
  suggested: {
    background: '#EDE0FF',
    color: Colors.secondaryDark,
  },
  missingDetails: {
    background: Colors.backgroundLight,
    color: Colors.dark,
  },
  currentlyViewing: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(255, 255, 255, 0.7)',
    borderRadius: '8px',
  },
  currentlyViewingLabel: {
    position: 'absolute',
    top: 8,
    left: 8,
    padding: 8,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.10)',
    fontSize: 12,
    lineHeight: '16px',
    borderRadius: 5,
  },
  approveProjectButton: {
    padding: '0 10px !important',
  },
  discardProjectButton: {
    padding: '7px 16px !important',
  },
});

const ProjectCardV2 = ({
  companyName,
  companyLogo,
  projectCover,
  projectName,
  projectIndustry,
  startDate,
  endDate,
  index,
  isSuggested,
  canEdit,
  canHide,
  isOwner,
  isHidden,
  recommendation,
  isEditMode,
  onEdit,
  onDelete,
  onOpenProject,
  currentlyViewing,
  projectId,
  selectedPosition,
  canSelect,
  onSelect,
  onDeselect,
  isMissingMinimumRequirements,
}: ProjectCardV2Props): JSX.Element => {
  const styles = useStyles({
    noCoverColor: noCoverColors[index % noCoverColors.length],
    isHidden,
    selectedPosition,
    canHide,
    isRecommended: !!recommendation,
    isMissingMinimumRequirements,
    isEditMode: isEditMode,
  });
  const {
    mutateAsync: rejectSuggestedProject,
    isLoading: isRejectingSuggestion,
  } = usePartiallyRejectSuggestion();
  const { data: profileSuggestions } = useGetProfileSuggestions();

  const projectDate = useMemo(() => {
    if (!startDate) return 'No date';

    const start = new Date(startDate);

    const startMonth = start.toLocaleString('default', { month: 'short' });
    const startYear = start.getFullYear();

    if (!endDate) {
      return `${startMonth} ${startYear} - Present`;
    }

    const end = new Date(endDate);
    const endMonth = end.toLocaleString('default', { month: 'short' });
    const endYear = end.getFullYear();

    return `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
  }, [startDate, endDate]);

  const handleDiscard = async () => {
    if (!profileSuggestions?.projects || !isSuggested || !projectId) {
      return;
    }

    await rejectSuggestedProject({
      sid: profileSuggestions.projects.sid,
      type: BuilderSuggestionType.PROJECTS,
      partialSuggestionId: projectId,
    });
  };

  const handleSelection = () => {
    if (!projectId) {
      return;
    }

    if (isHidden) {
      onSelect && onSelect(projectId);
    } else {
      onDeselect && onDeselect(projectId);
    }
  };

  const canSelectUnSelect = useMemo(() => {
    if (isHidden) {
      return canSelect;
    }

    return true;
  }, [canSelect, isHidden]);

  const recommendationString = useMemo(() => {
    if (!recommendation) return undefined;

    if (recommendation?.industry.count) {
      return `${recommendation?.industry.items[0]}${
        recommendation?.skills.count
          ? ` + ${recommendation?.skills.count} skills`
          : ''
      }`;
    }

    if (recommendation?.skills.count > 0 && recommendation?.skills.count <= 2) {
      return joinWithCommasAnd(recommendation.skills.items);
    } else if (recommendation?.skills.count > 2) {
      return `${recommendation.skills.items[0]}, ${
        recommendation.skills.items[1]
      } and + ${recommendation?.skills.count - 2} skills`;
    }

    return 'role';
  }, [recommendation?.industry, recommendation?.skills]);

  return (
    <div>
      {canHide && canEdit && isMissingMinimumRequirements && (
        <div
          className={cx(
            styles.topBanner,
            styles.topBannerOnApplication,
            styles.missingDetails,
          )}
        >
          To select, add missing details
        </div>
      )}
      <div className={styles.wrapper}>
        {!canHide && canEdit && isMissingMinimumRequirements && (
          <div className={cx(styles.topBanner, styles.missingDetails)}>
            Missing Details
          </div>
        )}
        {isSuggested && !isMissingMinimumRequirements && (
          <div className={cx(styles.topBanner, styles.suggested)}>
            <IconComponent size="exact" type={IconType.SuggestedTeams} />
            Suggested Project
          </div>
        )}

        <div
          className={styles.container}
          data-testing-id={
            isSuggested
              ? undefined
              : `profile-project-card${isHidden ? '-hidden' : ''}`
          }
        >
          <div
            className={cx(
              styles.projectCoverContainer,
              isHidden && styles.hidden,
            )}
            onClick={onOpenProject}
          >
            <AspectRatioBox
              aspectRatio={4 / 3}
              className={styles.projectCoverWrapper}
            >
              {projectCover ? (
                <>
                  <Image
                    objectFit="cover"
                    alt={projectName}
                    src={projectCover || ''}
                    className={styles.projectCover}
                    sizes="250px"
                  />
                </>
              ) : (
                <NoCover companyLogo={companyLogo} number={index} />
              )}
            </AspectRatioBox>
          </div>
          <div className={isHidden ? styles.hidden : undefined}>
            {canHide ? (
              <>
                <h4
                  className={styles.projectName}
                  data-testing-id="profile-project-card-title-text"
                >
                  {projectName}
                </h4>

                <div
                  className={cx(styles.companySection, {
                    [styles.companySectionWithNoRecommendation]:
                      !recommendationString,
                  })}
                >
                  <div className={styles.companyLogoContainer}>
                    <CompanyLogoFallback
                      logoUrl={companyLogo}
                      borderRadius={5}
                    />
                  </div>

                  <div className={styles.companyNameAndIndustry}>
                    {companyName || 'No Company'}{' '}
                    <img
                      className={styles.divider}
                      src={divider}
                      alt="divider"
                      style={{ margin: '0px 16px' }}
                    />
                    {projectIndustry ? `${projectIndustry}` : 'No industry'}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.companySection}>
                  <div className={styles.companyLogoContainer}>
                    <CompanyLogoFallback
                      logoUrl={companyLogo}
                      borderRadius={5}
                    />
                  </div>

                  <div className={styles.companyName}>
                    {companyName || 'No Company'}
                  </div>
                </div>

                <h4
                  className={styles.projectName}
                  data-testing-id="profile-project-card-title-text"
                >
                  {projectName}
                </h4>
                <div>
                  <div className={styles.projectDate}>{projectDate}</div>
                </div>
              </>
            )}
            {recommendationString && (
              <div className={styles.match}>
                <Icon
                  type={IconType.StarsPurple}
                  size={'exact'}
                  style={{ marginTop: 2 }}
                />
                Matched on {recommendationString}
              </div>
            )}
          </div>
          <div className={styles.actionBtns}>
            {canHide && (
              <>
                {isMissingMinimumRequirements ? (
                  <Button
                    style={{
                      borderColor: '#DADADC',
                    }}
                    variant="ghost"
                    onClick={onEdit}
                    size={'sm'}
                  >
                    Update
                  </Button>
                ) : (
                  <>
                    <Button
                      disabled={!canSelectUnSelect}
                      variant={isHidden ? 'main' : 'secondary'}
                      size={'sm'}
                      onClick={handleSelection}
                      data-testing-id={`mission-application-select-button-${index}`}
                    >
                      {isHidden ? 'Select' : 'Deselect'}
                    </Button>
                    <Button
                      style={{
                        borderColor: '#DADADC',
                      }}
                      variant="ghost"
                      onClick={onEdit}
                      size={'sm'}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </>
            )}

            {!canHide && (
              <>
                {isSuggested ? (
                  <>
                    <Button
                      className={styles.approveProjectButton}
                      loading={isRejectingSuggestion}
                      variant="main"
                      onClick={onEdit}
                    >
                      Review and approve
                    </Button>
                    <Button
                      className={styles.discardProjectButton}
                      loading={isRejectingSuggestion}
                      variant="secondary"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await handleDiscard();
                      }}
                    >
                      Discard
                    </Button>
                  </>
                ) : !isEditMode ? (
                  <>
                    <button
                      className={styles.openProjectBtn}
                      onClick={onOpenProject}
                    >
                      Open Project{' '}
                      <img
                        className={styles.rightArrowIcon}
                        src={arrowRight}
                        alt="arrow-right"
                      />
                    </button>
                  </>
                ) : null}

                {!isSuggested && canEdit && isEditMode && (
                  <div className={styles.editDeleteBtnsContainer}>
                    {isOwner && (
                      <Button
                        className={styles.btnControl}
                        variant={'secondary'}
                        size={'sm'}
                        onClick={onEdit}
                      >
                        {isMissingMinimumRequirements ? 'Update' : 'Edit'}
                      </Button>
                    )}
                    {!canHide && (
                      <Button
                        style={{
                          borderColor: '#DADADC',
                        }}
                        className={styles.btnControl}
                        variant={'ghost'}
                        size={'sm'}
                        onClick={onDelete}
                        data-testing-id="profile-project-card-delete-button"
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {currentlyViewing && (
          <div className={styles.currentlyViewing}>
            <div className={styles.currentlyViewingLabel}>
              Currently viewing
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectCardV2;
