import React from 'react';
import { InputContainer } from '../components/input-container';
import { InputLabel } from '../components/input-label';
import { RadioGroup } from '../components/radio-group';
import { Item, OnRadioGroupChange } from '@src/components/RadioGroup';

export type OnHasTechnicalBackgroundChange = (
  hasTechnicalBackground?: boolean,
) => void;

export interface HasTechnicalBackgroundProps {
  value?: boolean;
  onChange?: OnHasTechnicalBackgroundChange;
  error?: string;
  className?: string;
  'data-testing-id'?: string;
}

const items: Item[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const HasTechnicalBackground: React.FC<HasTechnicalBackgroundProps> = (
  props,
) => {
  const onChange: OnRadioGroupChange = (item) => {
    if (item.value === props.value) {
      props.onChange?.(undefined);
      return;
    }

    props.onChange?.(item.value);
  };

  return (
    <InputContainer error={props.error} className={props.className}>
      <InputLabel>Do you have a technical background? *</InputLabel>

      <RadioGroup
        selectedValue={props.value}
        items={items}
        onChange={onChange}
        data-testing-id={props['data-testing-id']}
      />
    </InputContainer>
  );
};
