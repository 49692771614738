import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Spacing, TextColors } from '@ateams/components';

export interface InputLabelProps {
  className?: string;
}

const useStyles = createUseStyles({
  container: {
    color: TextColors.regularLight,
    fontSize: '14px',
    lineHeight: '19px',
    marginBottom: Spacing.small,
  },
});

export const InputLabel: React.FC<InputLabelProps> = (props) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.container, props.className)}>
      {props.children}
    </div>
  );
};
