import { Icon, IconType } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    borderRadius: 4,
    padding: 12,
    background: '#FCFAFF',
  },
  title: {
    color: '#6D00D7',
    display: 'flex',
    gap: 8,
    marginBottom: 4,
  },
});

const SuggestedBanner = (): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Icon size="smaller" type={IconType.SuggestedTeams} />
        Adding suggested job
      </div>
      <div>This job is suggested based on your LinkedIn profile.</div>
    </div>
  );
};

export default SuggestedBanner;
