import React, { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { MissionsStoreData } from '@src/stores/Missions/Missions';
import TextButton from '@src/components/TextButton';
import { TargeterLocation } from '@src/locations';
import { useStores } from '@src/stores';

interface Props {
  mission?: MissionsStoreData['currentMission'];
}

const useStyles = createUseStyles({
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 0,
  },
  smallDivider: {
    borderRight: '1px solid #C0C0C0',
    height: '16px',
    marginLeft: '12px',
    marginRight: '12px',
  },
});

const TeamGraphMissionRolesSearchUrl = (props: Props): ReactElement | null => {
  const styles = useStyles();
  const { mission } = props;
  const { users } = useStores();

  const openTargeterForMission = async (
    e: React.MouseEvent<HTMLElement>,
    type: 'outreach' | 'review' | 'teamsearch',
  ) => {
    if (!mission) return;

    const url = `${TargeterLocation}?mid=${mission.mid}&type=${type}`;

    window.open(url, '_blank');
    e.preventDefault();
    e.stopPropagation();

    switch (type) {
      case 'outreach':
        if (mission) {
          users.trackTargeterOutreachClicked(
            mission.data.mid,
            mission.data.title,
            mission.data.status,
          );
        }
        break;
      case 'review':
        if (mission) {
          users.trackTargeterReviewClicked(
            mission.data.mid,
            mission.data.title,
            mission.data.status,
          );
        }
        break;
      case 'teamsearch':
        if (mission) {
          users.trackTargeterTeamSearchClicked(
            mission.data.mid,
            mission.data.title,
            mission.data.status,
          );
        }
        break;
    }
  };

  return (
    <>
      <div className={styles.smallDivider} />
      <TextButton
        onClick={(e) => openTargeterForMission(e, 'outreach')}
        className={styles.actionContainer}
        highlight
      >
        Targeter Outreach
      </TextButton>
      <div className={styles.smallDivider} />
      <TextButton
        onClick={(e) => openTargeterForMission(e, 'review')}
        className={styles.actionContainer}
        highlight
      >
        Targeter Review
      </TextButton>

      <div className={styles.smallDivider} />
      <TextButton
        onClick={(e) => openTargeterForMission(e, 'teamsearch')}
        className={styles.actionContainer}
        highlight
      >
        Targeter Team Search
      </TextButton>
    </>
  );
};

export default TeamGraphMissionRolesSearchUrl;
