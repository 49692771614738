import { ClientInterviewDeclineReason } from '@a_team/models/dist/ClientInterviewObject';
import { Button } from '@a_team/ui-components';
import CompanyLogo from '@src/components/CompanyLogo';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useCommonStyles } from './commonStyles';
import cx from 'classnames';

interface DeclineReasonProps {
  companyImageUrl?: string | null;
  onBack: () => void;
  onContinue: () => void;
  setDeclineReason: (reason: ClientInterviewDeclineReason | undefined) => void;
  setOtherReason: (reason: string) => void;
  declineReason: ClientInterviewDeclineReason | undefined;
  otherReason: string;
  isLoading?: boolean;
}

const useStyles = createUseStyles({
  radioWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '20px',
    maxWidth: '500px',
    width: '100%',
  },
  radioBox: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid var(--Grey-500, #DADADC)',
    borderRadius: '8px',
    background: 'var(--Grey-0, #FFF)',
    padding: '16px',
    flexDirection: 'column',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      background: '#f3e8ff', // Light purple background on hover
    },
  },
  inputRadio: {
    display: 'none',
  },
  customRadio: {
    cursor: 'pointer',
    borderRadius: '50%',
    display: 'inline-block',
    flexShrink: 0,
    width: '16px',
    height: '16px',
    textAlign: 'center',
    backgroundColor: '#FFF',
    border: '1px solid rgb(110, 113, 119)',
  },
  customRadioChecked: {
    backgroundColor: '#7000E3',
    border: 'none',
  },
  selected: {
    width: '8px',
    height: '8px',
    margin: '4px',
    backgroundColor: 'var(--White, #FFF)',
    borderRadius: '50%',
  },
  notSelected: {
    border: '1px solid rgb(110, 113, 119)',
  },
  otherInput: {
    marginTop: '10px',
    padding: '0px 10px',
    width: '100%',
    boxSizing: 'border-box',
    borderRight: 'none',
    borderLeft: '2px solid #DADADC',
    borderTop: 'none',
    borderBottom: 'none',
  },
});

const reasonDescriptions = {
  NotEnoughAvailability: 'I don’t have enough availability',
  RoleNotRelevant: 'The role is not relevant to me',
  NotInterested: 'I’m not interested in the mission',
  RateTooLow: 'The rate is too low',
  Other: 'Other',
};

const DeclineReason = ({
  companyImageUrl,
  onBack,
  onContinue,
  declineReason,
  otherReason,
  setDeclineReason,
  setOtherReason,
  isLoading,
}: DeclineReasonProps): JSX.Element => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeclineReason(event.target.value as ClientInterviewDeclineReason);
  };

  const handleOtherReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOtherReason(event.target.value);
  };

  return (
    <div className={commonStyles.container}>
      <CompanyLogo logoUrl={companyImageUrl} size={72} />
      <h4 className={commonStyles.title}>
        Why are you declining the interview?
      </h4>
      <div className={styles.radioWrapper}>
        {Object.entries(ClientInterviewDeclineReason).map((entry) => {
          const [key, value] = entry;
          return (
            <label key={key} className={styles.radioBox}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  gap: 10,
                }}
              >
                <span
                  className={cx(styles.customRadio, {
                    [styles.customRadioChecked]: declineReason === value,
                  })}
                >
                  <input
                    className={cx(styles.inputRadio, {
                      [styles.notSelected]: declineReason !== value,
                    })}
                    type="radio"
                    name="declineReason"
                    value={value}
                    checked={declineReason === value}
                    onChange={handleChange}
                  />
                  {declineReason === value && (
                    <div className={styles.selected} />
                  )}
                </span>
                <span className={commonStyles.label}>
                  {reasonDescriptions[value]}
                </span>
              </div>
              {value === 'Other' && declineReason === 'Other' && (
                <input
                  type="text"
                  className={styles.otherInput}
                  value={otherReason}
                  onChange={handleOtherReasonChange}
                  placeholder="Enter the reason for declining the meeting"
                />
              )}
            </label>
          );
        })}
      </div>
      <div className={commonStyles.btnsContainer}>
        <Button onClick={onBack} disabled={isLoading} variant="secondary">
          Back
        </Button>
        <Button
          loading={isLoading}
          disabled={
            !declineReason ||
            (declineReason === 'Other' && !otherReason.trim()) ||
            isLoading
          }
          onClick={onContinue}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default DeclineReason;
