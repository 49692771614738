import React, { ReactElement, useEffect, useState } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { Stores, useStores } from '@src/stores';
import { Link } from 'react-router-dom';
import MainLayout from '@src/layouts/Main';
import { apiAdmin } from '@src/logic/services/endpoints';
import {
  AdminInvoicesDashboardLocation,
  AdminTeamPulseDashboardLocation,
  ProfileLocation,
} from '@src/locations';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import Loader from '@src/components/Loader';
import UserAvatar, { BadgesPosition } from '@src/components/UserAvatar';
import { createUseStyles } from 'react-jss';
import {
  AdminBasicUserObject,
  UserStatus,
} from '@a_team/models/dist/UserObject';
import useLoadingState from '@src/hooks/useLoadingState';
import useDebounceState from '@src/hooks/useDebounceState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import UserSup from '@src/components/UserSup';
import { Button as CallToActionButton } from '@ateams/components';
import AuthStore from '@src/stores/Auth';
import { isUserFullyVetted } from '@src/helpers/users';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    width: '100%',
    padding: '32px',
  },
  userList: {
    marginTop: '48px',
    maxWidth: '1000px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  userCard: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px',
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: 'white',
    '&.greyedOut': {
      opacity: 0.7,
    },
  },
  userAvatar: {
    marginRight: '8px',
  },
  exports: {
    color: '#fff',
    textAlign: 'center',
    marginTop: 80,
    display: 'flex',
    flexDirection: 'column',

    '& > * + *': {
      marginTop: 12,
    },
  },
});

export async function adminSearchViewLoader(stores: Stores): Promise<unknown> {
  const { users } = stores;

  return Promise.all([users.loadUserStats(), users.loadNewUsers()]);
}

function AdminSearchView(): ReactElement {
  const stores = useStores();

  adminSearchViewLoader(stores).catch(stores.error.handleError);

  const { stats, newUsers } = stores.users;
  const [search, setSearch, changedSearch] = useDebounceState(
    '',
    (query) => {
      if (!query) {
        setUsersResult(newUsers || []);
        return;
      }

      setLoading(
        stores.users
          .findUsers({
            query,
            userStatus: [
              UserStatus.Active,
              UserStatus.Rejected,
              UserStatus.Registered,
            ],
          })
          .then(
            (users) => setUsersResult(users as AdminBasicUserObject[]),
            (err) => {
              setUsersResult([]);
              throw err;
            },
          ),
      );
    },
    400,
  );
  const [usersResult, setUsersResult] = useState<AdminBasicUserObject[]>(
    newUsers || [],
  );
  const [loading, setLoading] = useLoadingState();

  useEffect(() => {
    setSearch('', true);
    setUsersResult(newUsers || []);
  }, [newUsers]);

  const styles = useStyles();

  const handleExportURL = (generate: (auth: AuthStore) => string): void => {
    window.location.assign(generate(stores.auth));
  };

  return (
    <MainLayout title="Admin Search" naked bgColor="#6d00d7">
      {usersResult ? (
        <div className={styles.container}>
          <div
            style={{
              paddingTop: '24px',
              marginRight: '32px',
              width: '60%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <OutlinedInput
                type="text"
                placeholder="Search for an A-Teamer"
                name="search"
                onChange={(e): void => setSearch(e.target.value)}
                value={search}
                width="fixed"
                margin="none"
              />
            </div>
            <div
              style={{
                textAlign: 'center',
                marginTop: '24px',
                fontWeight: 'bold',
                color: 'white',
              }}
            >
              {stats?.countByScrubbed.Exceptional} exceptional (0) &nbsp; |
              &nbsp; {stats?.countByScrubbed.Verified} verified (1) &nbsp; |
              &nbsp; {stats?.countByScrubbed.Insufficient} insufficient (2+)
              &nbsp; | &nbsp; {stats?.countByScrubbed.Unknown} unknown (-1)
            </div>
            <div className={styles.userList}>
              {usersResult.map(
                (user): ReactElement => (
                  <Link
                    key={user.uid}
                    to={ProfileLocation(user.username)}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <div
                      className={cx(styles.userCard, {
                        greyedOut:
                          !user.scrubbed || user.status !== UserStatus.Active,
                      })}
                    >
                      <UserAvatar
                        badgesInline
                        size={32}
                        containerClassName={styles.userAvatar}
                        src={user.profilePictureURL}
                        badges={user.badges}
                        badgesPosition={BadgesPosition.BeforeName}
                      />
                      <div>
                        {user.fullName}
                        {'scrubbed' in user ? (
                          <UserSup
                            type={user.type}
                            scrubbed={user.scrubbed}
                            isVetted={isUserFullyVetted(user)}
                          />
                        ) : null}
                      </div>
                    </div>
                  </Link>
                ),
              )}
            </div>
          </div>
          <div
            style={{
              paddingTop: '24px',
            }}
          >
            <div className={styles.exports}>
              <h4>Invoices</h4>
              <Link to={AdminInvoicesDashboardLocation}>
                <CallToActionButton size="small" width="auto">
                  Invoices Dashboard
                </CallToActionButton>
              </Link>
            </div>
            <div className={styles.exports}>
              <h4>Team Pulse</h4>
              <Link to={AdminTeamPulseDashboardLocation}>
                <CallToActionButton size="small" width="auto">
                  Team Pulse Dashboard
                </CallToActionButton>
              </Link>
            </div>
            <div className={styles.exports}>
              <h4>Exports</h4>
              <CallToActionButton
                onClick={() => handleExportURL(apiAdmin.getExportATeamersURL)}
                size="small"
                width="auto"
              >
                ATeamers
              </CallToActionButton>
              <CallToActionButton
                onClick={() => handleExportURL(apiAdmin.getExportMissionsURL)}
                size="small"
                width="auto"
              >
                Missions
              </CallToActionButton>
              <CallToActionButton
                onClick={() =>
                  handleExportURL(apiAdmin.getExportMissionRolesURL)
                }
                size="small"
                width="auto"
              >
                Roles
              </CallToActionButton>
              <CallToActionButton
                onClick={() => handleExportURL(apiAdmin.getExportTimesheetsURL)}
                size="small"
                width="auto"
              >
                Timesheets
              </CallToActionButton>
              <CallToActionButton
                onClick={() =>
                  handleExportURL(
                    apiAdmin.getExportMissionPaymentCycleInvoicesURL,
                  )
                }
                size="small"
                width="auto"
              >
                Client Invoices
              </CallToActionButton>
              <CallToActionButton
                onClick={() =>
                  handleExportURL(apiAdmin.getExportPaymentPendingURL)
                }
                size="small"
                width="auto"
              >
                Payment Pending
              </CallToActionButton>
              <CallToActionButton
                onClick={() =>
                  handleExportURL(apiAdmin.getExportClientsBankAccountsURL)
                }
                size="small"
                width="auto"
              >
                Clients Bank Accounts
              </CallToActionButton>
              <CallToActionButton
                onClick={() => handleExportURL(apiAdmin.getExportTeamPulsesURL)}
                size="small"
                width="auto"
              >
                Team Pulses
              </CallToActionButton>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <LoadingIndicator loading={loading || changedSearch || null} />
    </MainLayout>
  );
}

export default observer(AdminSearchView);
