import React, { ReactElement, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import { AdminNotesRequestPayload } from '@ateams/api/dist/endpoints/Users';
import { useStores } from '@src/stores';
import Profile from '@src/stores/Profile/Profile';
import {
  Breakpoints,
  Button as CallToActionButton,
  Divider,
  SelectOption,
} from '@ateams/components';
import Loader from '@src/components/Loader';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import SectionHeading from '@src/components/SectionHeading';
import Availability, {
  AvailabilityMode,
} from '@src/views/Profile/Main/Availability';
import { AdminInput } from './AdminInput';
import { UserDetailsSection } from './UserDetailsSection';
import { ProfileViewMode } from '@src/stores/Profile/models';
import {
  AdminBasicUserObject,
  UserScrubbed,
  UserStatus,
  UserType,
} from '@a_team/models/dist/UserObject';
import {
  VetterConfigurationForm,
  VetterConfigurationFormProps,
} from './vetter-configuration-form';
import { PreferencesDtoDisabledCategoriesEnum } from '@a_team/user-notification-service-js-sdk';
import { apiCustomUserTags } from '@ateams/api';
import { CustomUserTagObject } from '@a_team/models/dist/CustomUserTagObject';
import { OnEvaluationInviteSubmit } from '@src/components/Modal/EvaluationInviteModal';

interface Props {
  profile?: Profile;
  onSave?: () => void;
}

const useStyles = createUseStyles({
  sidebar: {
    background: '#fff',
    color: '#222',
    paddingTop: 120,
    width: '100%',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.08)',
    zIndex: 10,
  },
  mainDetails: {
    marginBottom: 24,
    borderBottom: '1px solid rgba(128,128,128,0.3)',
    paddingBottom: 32,
    marginRight: 24,
    marginLeft: 24,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    sidebar: {
      maxWidth: 450,
      padding: 40,
      flex: '0 0 450px',
    },
    mainDetails: {
      textAlign: 'left',
      margin: 0,
      marginBottom: 45,
      paddingBottom: 0,
      borderBottom: 'none',
    },
  },
  section: {
    marginBottom: 36,
  },
  titleContainer: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  backButton: {
    color: '#fe630e',
    fontSize: '16px',
    fontWeight: '400',
    cursor: 'pointer',
  },
});

const AdminNotesSidebar = (props: Props): ReactElement => {
  const styles = useStyles();
  const { users, vetters, notificationPreferencesAdmin, auth } = useStores();
  const [loading, setLoading] = useLoadingState();
  const [availableCustomUserTags, setAvailableCustomUserTags] = React.useState<
    CustomUserTagObject[]
  >([]);

  const profile = useMemo(() => {
    return props.profile || users.profile;
  }, [props.profile, users.profile]);

  useEffect(() => {
    notificationPreferencesAdmin.fetchNotificationPreferencesData();
  }, []);

  useEffect(() => {
    apiCustomUserTags.findCustomUserTags(auth).then((res) => {
      setAvailableCustomUserTags(res);
    });
  }, []);

  const handleUpdate = (data: AdminNotesRequestPayload) => {
    profile && setLoading(profile.updateAdminNotes(data));
  };

  const updateUserScrubbed = (data: UserScrubbed) => {
    profile && setLoading(profile.updateScrubbed(data));
  };

  const onClickOnMarkAsRejectedUser = (rejected: boolean) => {
    profile &&
      setLoading(
        rejected
          ? profile.markUserAsRejected()
          : profile.unmarkUserAsRejected(),
      );
  };

  const onClickCancelAutomatedRejection = () => {
    profile && setLoading(profile.cancelAutomatedRejection());
  };

  const handleBack = () => {
    profile && profile.refreshProfile(ProfileViewMode.View);
  };

  const onQueryUsers = async (query: string): Promise<SelectOption[]> => {
    const res = await users.adminQueryByString(query, {
      userStatus: [UserStatus.Active, UserStatus.Rejected],
      userType: [UserType.User],
    });
    return res.map((user) => {
      return {
        label: user.fullName,
        value: user.uid,
        ...user,
      };
    });
  };

  const missionNotificationsDisabled = useMemo(
    () =>
      notificationPreferencesAdmin.preferences.disabledCategories?.includes(
        PreferencesDtoDisabledCategoriesEnum.MissionNotification,
      ),
    [notificationPreferencesAdmin.preferences],
  );

  const onKeepNotificationsChange = (keepNotifications: boolean) => {
    if (keepNotifications) {
      setLoading(
        notificationPreferencesAdmin.removeDisabledCategory(
          PreferencesDtoDisabledCategoriesEnum.MissionNotification,
        ),
      );
    } else {
      setLoading(
        notificationPreferencesAdmin.addDisabledCategory(
          PreferencesDtoDisabledCategoriesEnum.MissionNotification,
        ),
      );
    }
  };

  const onUnsubscribeAllPlatformEmailsChange = (unsubscribeAll: boolean) => {
    if (unsubscribeAll) {
      setLoading(notificationPreferencesAdmin.unsubscribeAll());
    } else {
      setLoading(notificationPreferencesAdmin.subscribeAll());
    }
  };

  const handleSelectionTeam = (isVetter: boolean) => {
    profile &&
      setLoading(
        isVetter
          ? vetters.addToSelectionTeam(profile.data.uid)
          : vetters.removeFromSelectionTeam(profile.data.uid),
      );
  };

  const handleEvaluationInvite: OnEvaluationInviteSubmit = (payload) => {
    if (profile) {
      setLoading(
        (async () => {
          await profile.startVettingProcess({
            category: payload.category,
            vettingType: payload.vettingType,
            contactOwner: payload.contactOwner,
            selectedVetterIds: payload.selectedVetterIds,
            vettingEmailTemplateParameters: payload.sendEvaluationEmail
              ? {
                  subject: payload.emailSubject,
                  template: payload.emailBody,
                  templateType: payload.templateType,
                  templateVariables: {},
                  calendarUrlType: payload.calendarUrlType,
                  sendInThread: payload.sendInThread,
                }
              : undefined,
          });
          await profile.fetchAdminNotes();
        })(),
        'Started Vetting Process',
      );
    }
  };

  const handleVetterConfigurationChange: VetterConfigurationFormProps['onChange'] =
    (values) => {
      if (typeof values.type !== 'undefined') {
        profile?.vetterConfiguration?.setType(values.type);
      }
      if (typeof values.calendarUrl !== 'undefined') {
        profile?.vetterConfiguration?.setCalendarUrl(values.calendarUrl);
      }
      if (typeof values.interviewCapacity !== 'undefined') {
        profile?.vetterConfiguration?.setInterviewCapacity(
          values.interviewCapacity,
        );
      }
      if (typeof values.emailSignature !== 'undefined') {
        profile?.vetterConfiguration?.setEmailSignature(values.emailSignature);
      }
      if (typeof values.isActive !== 'undefined') {
        profile?.vetterConfiguration?.setIsActive(values.isActive);
      }
      if (typeof values.vettingSkills !== 'undefined') {
        profile?.vetterConfiguration?.setVettingSkills(values.vettingSkills);
      }
      if (typeof values.vettingRoles !== 'undefined') {
        profile?.vetterConfiguration?.setVettingRoles(values.vettingRoles);
      }
    };

  const handleReferredByChange = (user: AdminBasicUserObject) => {
    if (!profile) return;
    setLoading(profile?.updateInviterUser(user));
  };

  const onCustomUserTagsChange = async (tagIds: string[]) => {
    if (!profile) return;
    setLoading(profile?.updateUserCustomTags(tagIds));
  };

  return profile?.adminNotes ? (
    <div className={styles.sidebar}>
      <div className={styles.mainDetails}>
        <SectionHeading isFirst>
          <div className={styles.titleContainer}>
            <span>{'Structured Data'}</span>
            <span className={styles.backButton} onClick={handleBack}>
              Back
            </span>
          </div>
        </SectionHeading>
        <LoadingIndicator loading={loading} />
        <div className={styles.section}>
          <AdminInput
            mainTalentCategoryId={
              profile?.data.talentProfile?.mainTalentCategoryId
            }
            canScrub={profile?.canScrub}
            scrubbedStatus={profile?.data.scrubbed}
            onScrubbedStatusChange={updateUserScrubbed}
            notes={profile?.adminNotes.data}
            altPhone={profile?.data.phoneNumber}
            updateNotes={handleUpdate}
            verified={profile?.adminNotes.verified}
            interviewed={profile?.adminNotes.interviewed}
            beenOnMission={profile?.adminNotes.beenOnMission}
            selectionTeam={profile?.adminNotes.selectionTeam}
            hasBeenScrubbed={profile?.adminNotes.hasBeenScrubbed}
            vettingScheduled={profile?.adminNotes.vettingScheduled}
            preventPrepay={profile.adminNotes.data.preventPrepay}
            setSelectionTeam={handleSelectionTeam}
            onEvaluationInvite={handleEvaluationInvite}
            isUserMarkedAsRejected={profile.isMarkedAsRejected}
            rejectionEmailSentAt={profile.adminNotes.data.rejectionEmailSentAt}
            onClickOnMarkAsRejectedUser={(rejected: boolean) =>
              onClickOnMarkAsRejectedUser(rejected)
            }
            userFirstName={profile.data.firstName}
            availableCustomUserTags={availableCustomUserTags}
            userCustomTags={profile.data.customTags}
            onCustomUserTagsChange={onCustomUserTagsChange}
            onUnsubscribeAllPlatformEmailsChange={
              onUnsubscribeAllPlatformEmailsChange
            }
            unsubscribeAllPlatformEmails={
              notificationPreferencesAdmin.allCategoriesDisabled
            }
            pendingAutomatedRejection={profile.data.pendingAutomatedRejection}
            onClickCancelAutomatedRejection={onClickCancelAutomatedRejection}
          />
        </div>
        <>
          <Divider margin="small" />
          <div className={styles.section}>
            <Availability
              mode={AvailabilityMode.Block}
              keepNotifications={!missionNotificationsDisabled}
              onKeepNotificationsChange={onKeepNotificationsChange}
              keepNotificationsPausedEndDate={
                notificationPreferencesAdmin.formatedEndDate
              }
              availability={profile?.availabilityData}
              withStatusSelect
              onChange={(availability) =>
                profile?.setAvailability(availability)
              }
              onReminderPeriodChange={profile?.setAvailabilityReminderPeriod}
              reminderPeriod={profile?.availabilityReminderPeriod}
              disableKeepNotificationsIfPaused={false}
            />
          </div>
        </>
        <Divider margin="small" />
        <div className={styles.section}>
          <UserDetailsSection
            notes={profile?.adminNotes.data}
            joined={new Date(profile?.data.createdAt)}
            scrubbed={profile?.canScrub ? undefined : profile.data.scrubbed}
            referredBy={profile.data.invitedBy}
            onQueryUsers={onQueryUsers}
            onReferredByChange={handleReferredByChange}
          />
        </div>
        {profile?.adminNotes.selectionTeam && profile?.vetterConfiguration && (
          <>
            <Divider margin="small" />
            <div className={styles.section}>
              <VetterConfigurationForm
                defaultValues={{
                  type: profile.vetterConfiguration?.data.type,
                  calendarUrl: profile.vetterConfiguration?.data.calendarUrl,
                  interviewCapacity:
                    profile.vetterConfiguration?.data.interviewCapacity,
                  emailSignature:
                    profile.vetterConfiguration?.data.emailSignature,
                  isActive: profile.vetterConfiguration?.data.isActive,
                  vettingSkills:
                    profile.vetterConfiguration?.data.vettingSkills,
                  vettingRoles: profile.vetterConfiguration?.data.vettingRoles,
                }}
                vetterConfiguration={profile.vetterConfiguration?.data}
                onChange={handleVetterConfigurationChange}
              />
            </div>
          </>
        )}
      </div>
      {props.onSave && (
        <CallToActionButton
          style={{ margin: '10px auto' }}
          onClick={props.onSave}
        >
          Save
        </CallToActionButton>
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default observer(AdminNotesSidebar);
