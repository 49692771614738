import React, { forwardRef } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { BorderRadius, TextColors } from '@ateams/components';
import DefaultOutlinedInput from '@src/components/Inputs/OutlinedInput';

const useStyles = createUseStyles({
  input: {
    fontSize: '15px',
    margin: 0,
  },
  textInput: {
    padding: 0,
    margin: 0,
    height: '20px',
    color: TextColors.regularLight,
  },
  textInputControl: {
    padding: '10px 12px',
    height: '40px',
    minHeight: 'initial',
    borderRadius: BorderRadius.default,
  },
  textAreaInputControl: {
    padding: '10px 12px',
    minHeight: 'initial',
    borderRadius: BorderRadius.default,
  },
});

export const OutlinedInput: typeof DefaultOutlinedInput = forwardRef(
  (props, ref) => {
    const styles = useStyles();

    return (
      <DefaultOutlinedInput
        controlClassName={cx(
          props.multiline
            ? styles.textAreaInputControl
            : styles.textInputControl,
          props.controlClassName,
        )}
        inputClassName={cx(styles.textInput, props.inputClassName)}
        {...props}
        className={cx(styles.input, props.className)}
        ref={ref}
      />
    );
  },
);
