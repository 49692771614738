import { mergeQueryKeys } from '@lukemorales/query-key-factory';
import { skillKeys } from './skills';
import { discoveryKeys } from './discovery';
import { notificationKeys } from './notifications';
import { connectionKeys } from './connections';
import { experiencesKeys } from './experiences';
import { profileKeys } from './profile';
import { companiesKeys, companiesV2Keys } from './companies';
import { industriesKeys } from './industries';
import { specializationsKeys } from './specializations';
import { roleCategoriesKeys } from './roleCategories';
import { missionKeys } from './missions';
import { teamNarrativeQueryKeys } from './teamNarrativeQueryKeys';
import { signalsKeys } from './signals';
import { profileSuggestions } from './profileSuggestions';
import { searchKeys } from './search';
import { teamUpKeys } from './teamUp';
import { timesheetsKeys } from './timesheets';
import { hubspotKeys } from '@src/rq/keys/hubspot';
import { placesKeys } from '@src/rq/keys/places';
import { cityKeys } from '@src/rq/keys/cities';
import { clientInterviewKeys } from './clientInterviews';
import { emailFromTargeterKeys } from './emailFromTargeter';
import { usersKeys } from './users';
import { builderLoginHistory } from './builder-login-history';

// import more keys once defined and merge them together here.
export const queryKeys = mergeQueryKeys(
  skillKeys,
  discoveryKeys,
  notificationKeys,
  connectionKeys,
  experiencesKeys,
  profileKeys,
  companiesKeys,
  companiesV2Keys,
  cityKeys,
  industriesKeys,
  specializationsKeys,
  roleCategoriesKeys,
  missionKeys,
  teamNarrativeQueryKeys,
  signalsKeys,
  profileSuggestions,
  searchKeys,
  teamUpKeys,
  timesheetsKeys,
  hubspotKeys,
  placesKeys,
  clientInterviewKeys,
  emailFromTargeterKeys,
  usersKeys,
  builderLoginHistory,
);
