import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

export interface PlusIconProps extends React.SVGProps<SVGSVGElement> {
  disabled?: boolean;
}

const useStyles = createUseStyles({
  svg: {
    width: '16px',
    cursor: ({ disabled }: PlusIconProps) =>
      disabled ? 'not-allowed' : 'pointer',
  },
});

export const PlusIcon: React.FC<PlusIconProps> = (props) => {
  const { disabled, ...rest } = props;
  const styles = useStyles(props);

  return (
    <svg
      viewBox="0 0 448 512"
      {...rest}
      className={cx(styles.svg, props.className)}
    >
      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
    </svg>
  );
};
