import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useLoadingState, { LoadingState } from '@src/hooks/useLoadingState';
import { useStores } from '@src/stores';
import { PreVettingFormSubmittedLocation, RootLocation } from '@src/locations';
import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';

const ACCESS_TOKEN_SEARCH_PARAM = 'access_token';

export function useValidateURL(): {
  nonce: string;
  loading: LoadingState;
  status?: VettingProcessStatus;
} {
  const { auth, vettingProcesses } = useStores();
  const { nonce } = useParams<{
    nonce: string;
  }>();
  const location = useLocation();
  const history = useHistory();
  const [validateNonceLoading, setValidateNonceLoading] = useLoadingState();
  const [status, setStatus] = useState<VettingProcessStatus | undefined>(
    undefined,
  );

  const accessToken = useMemo(() => {
    return new URLSearchParams(location.search).get(ACCESS_TOKEN_SEARCH_PARAM);
  }, [location.search]);

  useEffect(() => {
    if (!nonce || !accessToken) {
      history.push(RootLocation);
    }

    auth.token = accessToken;

    setValidateNonceLoading(
      (async () => {
        try {
          const {
            isSubmitted,
            user,
            status: newStatus,
          } = await vettingProcesses.validatePreVettingFormNonce(nonce);

          if (user.isNewEvaluation) {
            setStatus(newStatus);
            if (history.location.pathname.includes('/form/')) {
              if (isSubmitted) {
                const { pathname, search } = history.location;
                const newPathname = pathname.replace('/form/', '/schedule/');
                const newUrl = `${newPathname}${search}`;
                history.replace(newUrl);
              }
            }
          } else {
            if (isSubmitted) {
              history.push(PreVettingFormSubmittedLocation);
            }
          }
        } catch (err) {
          history.push(RootLocation);
        }
      })(),
    );
  }, []);

  return {
    nonce,
    loading: validateNonceLoading,
    status,
  };
}
