import React, { useEffect, useMemo, useState } from 'react';
import EmbedCalendar from './EmbedCalendar';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import { useValidateURL } from '../form/use-validate-url';
import {
  VettingInterviewer,
  VettingType,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import {
  BorderRadius,
  Button,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Footer } from '@src/views/Registration/Footer';
import { ATeamIcon } from '@src/views/VettingPostEvaluationSurvey/form/ATeamIcon';
import { ScheduleSuccessIcon } from './ScheduleSuccessIcon';
import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';
import { useLocation } from 'react-router-dom';

const useStyles = createUseStyles({
  mainContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 12,
    paddingTop: 40,
    minHeight: '100%',
  },
  mainContent: {
    width: '100%',
    flexGrow: 1,
  },
  footerContainer: {
    alignSelf: 'center',
  },
  calendarContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
    marginTop: 40,
  },
  title: {
    fontSize: 24,
    fontWeight: FontWeights.semiBold,
  },
  showMoreOptionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
    marginTop: 0,
    height: 88,
    backgroundColor: Colors.backgroundLight,
    zIndex: 1,
    position: 'relative',
  },
  submitButton: {
    width: '177px',
    height: '40px',
    alignSelf: 'center',
    color: Colors.light,
    backgroundColor: Colors.dark,
    borderRadius: BorderRadius.medium,
    fontSize: 15,
    padding: 0,
  },
  topBar: {
    position: 'absolute',
    top: 40,
    left: 40,
  },
  [`@media (max-width: 500px)`]: {
    topBar: {
      padding: Spacing.large,
    },
  },
  successContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
  },
  successIcon: {
    marginTop: 80,
  },
  successTitle: {
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
    textAlign: 'center',
  },
  successMessage: {
    fontSize: 15,
    textAlign: 'center',
    lineHeight: '2rem',
  },
  emailTo: {
    color: Colors.secondaryDark,
    textDecoration: 'none',
  },
  grayoutIcon: {
    marginTop: 80,
    filter: 'grayscale(100%)',
  },
  backButton: {
    width: '75px',
    height: '40px',
    alignSelf: 'center',
    color: Colors.dark,
    backgroundColor: Colors.backgroundDark,
    borderRadius: BorderRadius.medium,
    fontSize: 15,
    padding: 0,
    marginTop: 64,
  },
  steps: {
    fontSize: 12,
    fontWeight: 500,
    height: 24,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: BorderRadius.large,
    padding: '2.5px 12px',
    backgroundColor: '#F7F7F7',
  },
});

enum PreVettingScheduleState {
  Schedule,
  Success,
  NoMoreInterviewers,
}

const FILTER_SEARCH_PARAM = 'filter';

const PreVettingScheduleView: React.FC = () => {
  const styles = useStyles();
  const { vettingProcesses } = useStores();
  const { loading: validateNonceLoading, nonce, status } = useValidateURL();
  const [interviewers, setInterviewers] = useState<VettingInterviewer[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [showMoreOptionsVisible, setShowMoreOptionsVisible] =
    useState<boolean>(false);
  const [state, setState] = useState<PreVettingScheduleState>(
    PreVettingScheduleState.Schedule,
  );
  const location = useLocation();
  const vettingProcess = useMemo(() => {
    return vettingProcesses?.vettingProcesses?.find(
      (vp) => vp.preVettingFormNonce === nonce,
    );
  }, [vettingProcesses, nonce]);

  const filter = useMemo(() => {
    return new URLSearchParams(location.search).get(FILTER_SEARCH_PARAM);
  }, [location.search]);

  const fullName = vettingProcesses.preVettingFormUser?.fullName || '';
  const email = vettingProcesses.preVettingFormUser?.email || '';

  useEffect(() => {
    const fetchInterviewers = async (uid: string) => {
      let newInterviewers = await vettingProcesses.fetchInterviewers(
        nonce,
        filter !== null,
      );

      if (filter) {
        const decodedFilter = Buffer.from(filter, 'base64').toString('utf8');
        const vetterIds = decodedFilter.split(',');
        newInterviewers = newInterviewers.filter((i) =>
          vetterIds.includes(i.vetterId),
        );
      }

      setInterviewers(newInterviewers);

      if (newInterviewers.length === 0) {
        setState(PreVettingScheduleState.NoMoreInterviewers);
        return;
      }
    };

    if (
      !validateNonceLoading &&
      vettingProcesses.preVettingFormUser?.id &&
      status
    ) {
      if (status === VettingProcessStatus.PendingFeedback) {
        setState(PreVettingScheduleState.Success);
        return;
      }

      fetchInterviewers(nonce);
    }
  }, [validateNonceLoading]);

  const onShowMoreOptions = () => {
    if (index === interviewers.length - 1) {
      setState(PreVettingScheduleState.NoMoreInterviewers);
      return;
    }

    setIndex(index + 1);
    setShowMoreOptionsVisible(false);
    const vettingType =
      vettingProcess?.vettingType || VettingType.SelectionTeam;
    vettingProcesses.trackMoreAvailableTimesClicked(vettingType, 'evaluation');
  };

  const onBack = () => {
    setShowMoreOptionsVisible(false);
    setState(PreVettingScheduleState.Schedule);
    setIndex(0);
  };

  const onBookingSuccessful = async (
    uid: string,
    id: number,
    email: string,
    date: Date,
  ) => {
    if (nonce) {
      setState(PreVettingScheduleState.Success);

      const interviewer = interviewers.find((i) => i.email === email);

      if (!interviewer) {
        new Error('Interviewer not found');
        return;
      }

      vettingProcesses.updateInterviewScheduled(nonce, {
        calComBookingId: id,
        calComBookingUid: uid,
        vetter: interviewer.vetterId,
        interviewDate: date,
      });

      vettingProcesses.trackEvaluationCallBooked('evaluation');
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainContent}>
        <div className={styles.topBar}>
          <ATeamIcon />
        </div>
        {state === PreVettingScheduleState.Schedule && (
          <>
            <div className={styles.steps}>Final step</div>
            {!validateNonceLoading &&
              vettingProcesses.preVettingFormUser &&
              vettingProcesses.formVariant &&
              interviewers &&
              interviewers.length > 0 && (
                <div className={styles.calendarContainer}>
                  <div className={styles.title}>Schedule a call</div>
                  <EmbedCalendar
                    fullName={fullName}
                    email={email}
                    calLink={`${interviewers[index].username}/evaluation`}
                    onCalendarReady={() => setShowMoreOptionsVisible(true)}
                    onBookingSuccessful={onBookingSuccessful}
                  />
                </div>
              )}
            {showMoreOptionsVisible && (
              <div className={styles.showMoreOptionsContainer}>
                <div>None of these times work?</div>
                <Button
                  className={styles.submitButton}
                  size={'small'}
                  onClick={onShowMoreOptions}
                  data-testing-id={'pre-vetting-form-submit-button'}
                >
                  More available times
                </Button>
              </div>
            )}
          </>
        )}
        {state === PreVettingScheduleState.Success && (
          <div className={styles.successContainer}>
            <div className={styles.title}>
              Thank you for scheduling your evaluation call!
            </div>
            <div className={styles.successIcon}>
              <ScheduleSuccessIcon />
            </div>
            <div className={styles.successTitle}>
              Thank you for setting up your evaluation call.{' '}
            </div>
            <div className={styles.successMessage}>
              We’re thrilled to have the opportunity to connect with you!
              <br /> You’ll soon receive a confirmation email containing a link
              to the call.
              <br />
              To cancel or reschedule, use the cancellation link in the booking
              confirmation email. <br />
              Then, use this form to book a new call.
            </div>
          </div>
        )}
        {state === PreVettingScheduleState.NoMoreInterviewers && (
          <div className={styles.successContainer}>
            <div className={styles.title}>Schedule a call </div>
            <div className={styles.grayoutIcon}>
              <ScheduleSuccessIcon />
            </div>
            <div className={styles.successTitle}>
              Please get in touch with our team to find a suitable time. <br />{' '}
              Send an email to{' '}
              <a
                href="mailto:evaluation@a.team"
                target="__blank"
                className={styles.emailTo}
              >
                evaluation@a.team
              </a>
            </div>
            {interviewers.length > 0 && (
              <Button
                className={styles.backButton}
                size={'small'}
                onClick={onBack}
              >
                Back
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </div>
  );
};

export default observer(PreVettingScheduleView);
