import React from 'react';
import {
  TypicalTeamSize,
  TypicalTeamSizeLabels,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { InputContainer } from '../components/input-container';
import { InputLabel } from '../components/input-label';
import { RadioGroup } from '../components/radio-group';
import { Item, OnRadioGroupChange } from '@src/components/RadioGroup';

export type OnTypicalTeamSizeChange = (
  typicalTeamSize?: TypicalTeamSize,
) => void;

export interface TypicalTeamSizeProps {
  value?: TypicalTeamSize;
  onChange?: OnTypicalTeamSizeChange;
  error?: string;
  className?: string;
  'data-testing-id'?: string;
}

const items: Item[] = Object.entries(TypicalTeamSizeLabels).map(
  ([key, value]) => {
    return { label: value, value: key };
  },
);

export const TypicalTeamSizeSelect: React.FC<TypicalTeamSizeProps> = (
  props,
) => {
  const onChange: OnRadioGroupChange = (item) => {
    if (item.value === props.value) {
      props.onChange?.(undefined);
      return;
    }

    props.onChange?.(item.value);
  };

  return (
    <InputContainer error={props.error} className={props.className}>
      <InputLabel>
        What is the typical team size that this builder has worked with?
      </InputLabel>

      <RadioGroup
        selectedValue={props.value}
        items={items}
        onChange={onChange}
        data-testing-id={props['data-testing-id']}
      />
    </InputContainer>
  );
};
