import { ServiceAuth } from '@ateams/service-utils';
import {
  AdminVettingProcess,
  ContactOwner,
  VettingProcessId,
  VettingType,
} from '@a_team/models/dist/vetting-processes/vetting-processes';
import { VettingProcessStatus } from '@a_team/models/dist/vetting-processes/status';
import { UserId } from '@a_team/models/dist/UserObject';
import {
  TalentCategory,
  TalentCategoryId,
  TalentSkillId,
  UserTalentSkillAssignment,
} from '@a_team/models/src/TalentCategories';
import { ServiceEndpoint } from './utils';
import { DateISOString } from '@a_team/models/src/misc';
import { Vetter, VetterId } from '@a_team/models/src/vetter';
import {
  VettingProccessFeedbackScaledProducts,
  VettingProcessFeedbackAcquiredOrProgressed,
  VettingProcessFeedbackBuiltProductsFromScratch,
  VettingProcessFeedbackComment,
  VettingProcessFeedbackCompanySizes,
  VettingProcessFeedbackIndustries,
  VettingProcessFeedbackPeopleManaged,
  VettingProcessFeedbackProject,
  VettingProcessFeedbackRole,
  VettingProcessFeedbackScores,
  VettingProcessFeedbackSkill,
  VettingProcessFeedbackTalentIndustries,
  VettingProcessFeedbackTalentSkill,
  VettingProcessFeedbackTechnicalBackground,
  WorkElseWhere,
} from '@a_team/models/src/vetting-processes/feedback';
import { AdminNotesScore } from '@a_team/models/src/AdminNotesObject';
import LocationObject from '@a_team/models/src/LocationObject';
import { AvailableType } from '@a_team/models/src/AvailabilityObject';
import RoleCategoryObject, {
  RoleCategoryId,
} from '@a_team/models/src/RoleCategory';
import { LocalHourRange } from '@a_team/models/src/WorkingHoursObject';
import { TalentIndustryId } from '@a_team/models/src/TalentIndustry';
import {
  VettingProcessFeedbackAdditionalMaterial,
  VettingProcessFeedbackAdditionalMaterialId,
} from '@a_team/models/src/vetting-processes/feedback-additional-material';

export const BasePath = '/vetting-process/feedback';

export interface VettingFeedbackFormDefaults {
  answers: {
    location?: LocationObject;
    worksElseWhere?: WorkElseWhere;
    availability: {
      availability?: {
        type: AvailableType;
        availableFrom?: DateISOString;
        weeklyHoursAvailable?: number;
      };
      workingHours?: Partial<LocalHourRange>;
      hoursOfOverlapWithEST?: number;
      note?: string;
    };
    callRecording?: string;
    additionalMaterialsFiles?: VettingProcessFeedbackAdditionalMaterial[];
    additionalMaterials?: string;
    scores: VettingProcessFeedbackScores;
    roles: VettingProcessFeedbackRole[];
    userRole?: RoleCategoryObject;
    experience: {
      companySizes: VettingProcessFeedbackCompanySizes;
      acquiredOrProgressed?: VettingProcessFeedbackAcquiredOrProgressed;
      builtProductsFromScratch?: VettingProcessFeedbackBuiltProductsFromScratch;
      workedOnProductThatScaled?: VettingProccessFeedbackScaledProducts;
      peopleManaged?: VettingProcessFeedbackPeopleManaged;
      teamSize?: string;
      technicalBackground?: VettingProcessFeedbackTechnicalBackground;
    };
    projects: VettingProcessFeedbackProject[];
    biggestImpactDescription?: string;
    skills: VettingProcessFeedbackTalentSkill[];
    skillsComment?: string;
    industries: VettingProcessFeedbackTalentIndustries;
    backgroundComments: VettingProcessFeedbackComment[];
    generalComments: VettingProcessFeedbackComment[];
    summaryComments: VettingProcessFeedbackComment[];
  };
  general: {
    user: {
      roleCategory: RoleCategoryObject | null;
      profileTalentSkills: UserTalentSkillAssignment[];
      linkedIn?: {
        username: string;
      };
      website?: string;
      email: string;
      profileURL: string;
      profilePictureURL: string;
      fullName: string;
    };
    vettingProcess: {
      category?: TalentCategory;
      contactOwner?: ContactOwner;
      vetter?: Vetter;
      vettingType: VettingType;
      vettingStartedDate?: DateISOString;
      submittedFormDate?: DateISOString;
      notifiedInterviewerDate?: DateISOString;
      feedbackFormSentDate?: DateISOString;
      status: VettingProcessStatus;
      interviewDate?: DateISOString;
      codeSample?: string;
      calendarUrl?: string;
      lastSubmitDate?: DateISOString;
      lastSaveDate?: DateISOString;
      recordingDownloadLinks?: string[];
      transcriptJobIds?: string[];
    };
  };
}

export interface SubmitVettingFeedbackFormPayload {
  answers: {
    location: LocationObject;
    workElseWhere?: WorkElseWhere;
    availability: {
      availability?: {
        type: AvailableType;
        availableFrom?: DateISOString;
        weeklyHoursAvailable?: number;
      };
      workingHours?: Partial<LocalHourRange>;
      hoursOfOverlapWithEST: number;
      note?: string;
    };
    callRecording?: string;
    additionalMaterialsFiles?: VettingProcessFeedbackAdditionalMaterialId[];
    additionalMaterials?: string;
    scores: {
      expertise: AdminNotesScore;
      interactionExperience: AdminNotesScore;
      englishLevel: AdminNotesScore;
      accent: AdminNotesScore;
    };
    roles: VettingProcessFeedbackRole[];
    userRole?: RoleCategoryId;
    experience: {
      companySizes: VettingProcessFeedbackCompanySizes;
      acquiredOrProgressed?: VettingProcessFeedbackAcquiredOrProgressed;
      builtProductsFromScratch?: VettingProcessFeedbackBuiltProductsFromScratch;
      workedOnProductThatScaled?: VettingProccessFeedbackScaledProducts;
      peopleManaged?: VettingProcessFeedbackPeopleManaged;
      teamSize?: string;
      technicalBackground?: VettingProcessFeedbackTechnicalBackground;
    };
    projects: (Omit<VettingProcessFeedbackProject, 'skills' | 'industries'> & {
      skills: TalentSkillId[];
      industries: TalentIndustryId[];
    })[];
    biggestImpactDescription?: string;
    skills: VettingProcessFeedbackSkill[];
    skillsComment?: string;
    industries: VettingProcessFeedbackIndustries;
    backgroundComments: SubmitVettingFeedbackFormPayloadComments;
    generalComments: SubmitVettingFeedbackFormPayloadComments;
    summaryComments: SubmitVettingFeedbackFormPayloadComments;
  };
  general: {
    category?: TalentCategoryId;
    contactOwner?: UserId;
    vetter?: VetterId;
    vettingType?: VettingType;
    status?: VettingProcessStatus;
    interviewDate?: DateISOString;
  };
}

export interface SubmitVettingFeedbackFormPayloadComments {
  commentToAdd?: string;
  commentsToEdit: Pick<VettingProcessFeedbackComment, 'id' | 'comment'>[];
  commentsToDelete: string[];
}

export type VettingFeedbackFormViewModeData = VettingFeedbackFormDefaults;
export type VettingFeedbackFormAnswersDraft = SubmitVettingFeedbackFormPayload;

export interface AcceptVettingFeedbackFormScoresPayload {
  shouldAcceptNewScores: boolean;
}

export default class VettingProcessFeedbackEndpoint extends ServiceEndpoint {
  /**
   * Get the default values for the vetting feedback form inputs
   * @param auth - Admins
   */
  public adminGetVettingFeedbackFormDefaults(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
  ): Promise<VettingFeedbackFormDefaults> {
    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/admin/defaults`,
      null,
      'get',
    );
  }

  /**
   * Saves the feedback form answers as a draft(without submitting)
   * @param auth - Admins
   */
  public adminSaveFeedbackFormDraft(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
    payload: VettingFeedbackFormAnswersDraft,
  ): Promise<VettingFeedbackFormDefaults> {
    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/admin/draft`,
      null,
      'put',
      payload,
    );
  }

  /**
   * Submits vetting process feedback form
   * @param auth - Admins
   */
  public adminSubmitVettingFeedbackForm(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
    payload: SubmitVettingFeedbackFormPayload,
  ): Promise<VettingFeedbackFormDefaults> {
    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/admin/submit`,
      null,
      'put',
      payload,
    );
  }

  /**
   * Get the default values for the vetting feedback form inputs
   * @param auth - Selection Team
   */
  public getVettingFeedbackFormDefaults(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
  ): Promise<VettingFeedbackFormDefaults> {
    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/defaults`,
      null,
      'get',
    );
  }

  /**
   * Saves the feedback form answers as a draft(without submitting)
   * @param auth - Selection Team
   */
  public vetterSaveFeedbackFormDraft(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
    payload: VettingFeedbackFormAnswersDraft,
  ): Promise<VettingFeedbackFormDefaults> {
    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/draft`,
      null,
      'put',
      payload,
    );
  }

  /**
   * Submits vetting process feedback form
   * @param auth - Selection Team
   */
  public vetterSubmitVettingFeedbackForm(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
    payload: SubmitVettingFeedbackFormPayload,
  ): Promise<VettingFeedbackFormDefaults> {
    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/submit`,
      null,
      'put',
      payload,
    );
  }

  /**
   * Accepts/rejects the feedback form scores
   * @param auth - Admins
   */
  public acceptFeedbackForm(
    auth: ServiceAuth,
    vettingProcessId: VettingProcessId,
    payload: AcceptVettingFeedbackFormScoresPayload,
  ): Promise<AdminVettingProcess> {
    return this.fetch(
      auth,
      `${BasePath}/${vettingProcessId}/admin/accept-scores`,
      null,
      'PATCH',
      payload,
    );
  }

  /**
   * Returns feedback form summary data for view only mode
   * Auth - Anonymous endpoint
   */
  public getViewModeData(
    vettingProcessId: VettingProcessId,
  ): Promise<VettingFeedbackFormViewModeData> {
    return this.fetch(
      null,
      `${BasePath}/${vettingProcessId}/view-mode`,
      null,
      'get',
    );
  }
}
