import React from 'react';
import { createUseStyles } from 'react-jss';
import Modal, { ModalBasicProps } from '..';
import { BorderRadius, Spacing, TextColors } from '@ateams/components';
import {
  VettingProcessPreVettingForm,
  VettingProcessSoftwareEngineeringPreVettingForm,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { VettingFormVariant } from '@a_team/models/dist/vetting-processes/vetting-processes';
import { PreVettingProjectManagementAnswers } from './variant-answers/project-management';
import { PreVettingProductManagementAnswers } from './variant-answers/product-management';
import { PreVettingDesignAnswers } from './variant-answers/design';
import { PreVettingSoftwareEngineeringAnswers } from './variant-answers/software-engineering';
import { PreVettingCommonAnswers } from './common';
import { Button } from '@src/views/VettingFeedbackForm/components/button';
import { Text } from '@src/views/VettingFeedbackForm/components/typography';
import { VettingProcessAuditUser } from '@a_team/models/dist/vetting-process-audit';
import UserAvatar from '@src/components/UserAvatar';

export interface PreVettingAnswersModalProps extends ModalBasicProps {
  preVettingAnswers: VettingProcessPreVettingForm;
  user?: VettingProcessAuditUser;
}

const useStyles = createUseStyles({
  container: {
    overflow: 'auto',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
  },
  builderAvatarContainer: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Spacing.medium,
  },
  builderAvatar: {
    marginRight: Spacing.small,
  },
  builderName: {
    color: TextColors.regular,
    fontSize: '15px',
  },
  titleText: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: '40px',
  },
  closeButton: {
    width: '200px',
    height: '40px',
    borderRadius: BorderRadius.medium,
    alignSelf: 'center',
  },
});

export const PreVettingAnswersModal: React.FC<PreVettingAnswersModalProps> = (
  props,
) => {
  const styles = useStyles();
  const { open, onClose, preVettingAnswers, user } = props;

  const renderVariantAnswers = () => {
    if (preVettingAnswers.variant === VettingFormVariant.ProjectManagement) {
      return <PreVettingProjectManagementAnswers answers={preVettingAnswers} />;
    }

    if (
      preVettingAnswers.variant === VettingFormVariant.ProductManagement ||
      preVettingAnswers.variant === VettingFormVariant.Marketing ||
      preVettingAnswers.variant === VettingFormVariant.Operations
    ) {
      return <PreVettingProductManagementAnswers answers={preVettingAnswers} />;
    }

    if (preVettingAnswers.variant === VettingFormVariant.Design) {
      return <PreVettingDesignAnswers answers={preVettingAnswers} />;
    }

    return (
      <PreVettingSoftwareEngineeringAnswers
        answers={
          preVettingAnswers as VettingProcessSoftwareEngineeringPreVettingForm
        }
      />
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ padding: 0, minWidth: 672, borderRadius: 0 }}
    >
      <div className={styles.container}>
        {user && (
          <a
            href={user.profileURL}
            target={'_blank'}
            rel={'noreferrer'}
            className={styles.builderAvatarContainer}
          >
            <UserAvatar
              src={user.profilePictureURL}
              size={24}
              containerClassName={styles.builderAvatar}
            />

            <div className={styles.builderName}>{user.fullName}</div>
          </a>
        )}

        <Text className={styles.titleText}>
          Answers from pre-evaluation form
        </Text>

        {renderVariantAnswers()}

        <PreVettingCommonAnswers
          answers={preVettingAnswers}
          builderEmail={user?.email}
        />

        <br />

        <Button onClick={onClose} className={styles.closeButton}>
          Close
        </Button>
      </div>
    </Modal>
  );
};
