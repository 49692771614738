import {
  AdminNotesMission,
  BuilderPaymentCycleSummary,
} from '@a_team/models/dist/AdminNotesObject';
import { createUseStyles } from 'react-jss';
import React, { ReactElement } from 'react';
import { compact, map, pickBy, identity } from 'lodash';
import {
  Colors,
  TextColors,
  Spinner,
  TagList,
  Tag,
  Spacing,
} from '@ateams/components';
import { Link } from 'react-router-dom';
import { MissionPageLocation } from '@src/locations';
import { format } from 'date-fns';
import {
  stringifyDateRangeAdvanced,
  stringifyMinutes,
} from '@src/helpers/time';
import { observer } from 'mobx-react';
import Checkbox from '@src/components//Checkbox';
import useLoadingState from '@src/hooks/useLoadingState';

interface Props {
  data: AdminNotesMission;
  setRolePerformanceFlag?: (newMission: AdminNotesMission) => Promise<void>;
}

const BORDER_STYLE = `1px solid ${Colors.regular}`;

const useStyles = createUseStyles({
  container: {
    width: '100%',
    backgroundColor: Colors.backgroundWhite,
    border: BORDER_STYLE,
    marginBottom: 10,
    borderRadius: 8,
  },
  titleBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 10,
  },
  title: {
    color: TextColors.regular,
    fontWeight: 500,
    fontSize: 17,
    lineHeight: '24px',
    letterSpacing: '0.001em',
    '&:hover': {
      color: TextColors.primaryLight,
    },
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 14,
    margin: '5px 0',
    lineHeight: '19px',
  },
  missionData: { borderTop: BORDER_STYLE, padding: 10 },
  performanceIssueCheckbox: {
    border: BORDER_STYLE,
    borderRadius: 8,
    margin: '10px 0',
    padding: 10,
    width: 172,
    fontSize: 14,
  },
  roleSkills: {
    marginTop: Spacing.medium,
  },
  monthlyRetainerTag: {
    marginBottom: Spacing.small,
  },
});

const stringifyCycle = (cycle?: BuilderPaymentCycleSummary): string => {
  if (!cycle) return '';

  const dateRange = stringifyDateRangeAdvanced(
    cycle.startDate,
    cycle.endDate,
    true,
    false,
  );

  if (!dateRange && !cycle.minutes) return '';

  return `${dateRange} ${stringifyMinutes(cycle.minutes)}`;
};

const getMissionDisplayObject = (cycles: string[], data: AdminNotesMission) => {
  return pickBy(
    {
      'Latest Timesheets': compact([
        !!cycles.length && `${cycles.join(' | ')} @`,
        !!data.builderHourlyRate && ` $${data.builderHourlyRate}/hr`,
        !!data.builderTotalMinutes &&
          ` (${stringifyMinutes(data.builderTotalMinutes)} total)`,
      ]).join(''),
      'Mission Deployed': stringifyDateRangeAdvanced(
        data.missionStartDate,
        data.missionEndDate,
        true,
      ),
      'Position Deployed': stringifyDateRangeAdvanced(
        data.builderStartDate,
        data.builderEndDate,
        true,
      ),
      'Min. Hours Per Week': data.minHours,
      'Scheduled End Date':
        data.missionScheduledEndDate &&
        format(new Date(data.missionScheduledEndDate), 'MMM dd, yyyy'),
    },
    identity,
  );
};

const FlagPerformanceButton = observer(
  ({
    setRolePerformanceFlag,
    mission,
  }: {
    mission: AdminNotesMission;
    setRolePerformanceFlag: (newMission: AdminNotesMission) => Promise<void>;
  }) => {
    const hasPerformanceIssue = mission.hasPerformanceIssue ?? false;
    const [loading, setLoading] = useLoadingState();

    const onChange = () => {
      setLoading(
        setRolePerformanceFlag({
          ...mission,
          hasPerformanceIssue: !hasPerformanceIssue,
        }),
        false,
      );
    };

    if (loading) {
      return <Spinner color="black" />;
    }

    return (
      <Checkbox
        checked={hasPerformanceIssue}
        customLabel="Performance Issue"
        onChange={onChange}
      />
    );
  },
);

export default function MissionItem({
  data,
  setRolePerformanceFlag,
}: Props): ReactElement | null {
  const styles = useStyles();

  const cycles = [
    stringifyCycle(data.thirdLastCycle),
    stringifyCycle(data.secondLastCycle),
    stringifyCycle(data.lastCycle),
  ].filter((str) => !!str);
  const showContent =
    cycles.length || data.missionStartDate || data.builderStartDate;

  return (
    <div className={styles.container}>
      <div className={styles.titleBox}>
        <div>
          {data.isFullTimeRetainer ? (
            <>
              <div className={styles.monthlyRetainerTag}>
                <Tag thin>Monthly Retainer</Tag>
              </div>
            </>
          ) : null}
          <Link to={MissionPageLocation(data.mid)} className={styles.title}>
            {data.companyName && `${data.companyName}: `}
            {data.title}
          </Link>
          <div className={styles.subtitle}>{data.roleCategoryTitle}</div>
          <TagList className={styles.roleSkills}>
            {data.roleRequiredSkills?.map((skill) => (
              <Tag thin key={skill}>
                {skill}
              </Tag>
            ))}
          </TagList>
          <p>{data.roleHeadline}</p>
        </div>
      </div>
      {showContent && (
        <div className={styles.missionData}>
          {map(getMissionDisplayObject(cycles, data), (value, key) => (
            <div
              key={`${data.mid}-${key}`}
              style={{
                display: 'grid',
                gridTemplateColumns: '25% 75%',
                padding: 5,
              }}
            >
              <span style={{ fontWeight: 500 }}>{key}</span>
              <span>{value}</span>
            </div>
          ))}
          {setRolePerformanceFlag && (
            <div className={styles.performanceIssueCheckbox}>
              <FlagPerformanceButton
                mission={data}
                setRolePerformanceFlag={setRolePerformanceFlag}
              />
            </div>
          )}
          {data.missionEndDate && !data.builderEndDate && (
            <div style={{ color: Colors.danger }}>
              This position is not ended but the mission is!
            </div>
          )}
        </div>
      )}
    </div>
  );
}
