import { createUseStyles } from 'react-jss';
import React, { ReactElement, useMemo } from 'react';
import CheckIcon from '@src/components/CheckIcon';
import {
  Colors,
  FontSizes,
  Icon,
  IconType,
  Spacing,
  Tag,
  TagList,
  TextColors,
} from '@ateams/components';
import { Link } from 'react-router-dom';
import {
  MissionApplicationLocation,
  TranscriptLocation,
  VettingDashboardRecordingLocation,
} from '@src/locations';
import { format } from 'date-fns';
import { AdminNotesMissionApplication } from '@a_team/models/src/AdminNotesObject';
import { OldApplicationStatusIndication } from '@src/views/AdminNotes/Main/ApplicationsTab/OldApplicationStatusIndication';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import { tagEditedYellowStyle } from '@src/common-styles/tag-edited';
import { isMissionApplicationEdited } from '@src/helpers/applications';
import { FontWeights } from '@ateams/components';
import { BorderRadius } from '@ateams/components';
import UserObject from '@a_team/models/dist/UserObject';
import { useStores } from '@src/stores';
import { ApplicationStatusIndication } from './ApplicationStatusIndication';
import { MissionApplicationStatus } from '@a_team/models/dist/MissionApplicationObject';
import { Button } from '@a_team/ui-components';

interface Props {
  item: AdminNotesMissionApplication;
  user?: UserObject;
  showApplicationText?: boolean;
  showRoleHeadline?: boolean;
}

const useStyles = createUseStyles({
  status: {
    fontSize: FontSizes.medium,
    color: Colors.regularDark,
  },
  tagEdited: {
    ...tagEditedYellowStyle,
    marginLeft: Spacing.small,
  },
  pitchContainer: {
    width: '100%',
    border: `1px solid ${Colors.tag}`,
    borderRadius: BorderRadius.medium,
    maxWidth: 750,
    padding: Spacing.medium,
    marginTop: Spacing.medium,
    marginBottom: Spacing.medium,
  },
  pitchTitle: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.medium,
    color: TextColors.tag,
  },
  pitch: {
    paddingTop: Spacing.small,
    fontSize: FontSizes.small,
    color: TextColors.tag,
  },
  aboutTitle: {
    marginTop: Spacing.medium,
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.medium,
    color: TextColors.tag,
  },
  about: {
    paddingTop: Spacing.small,
    fontSize: FontSizes.small,
    color: TextColors.tag,
  },
  statusTagContainer: {
    paddingTop: Spacing.medium,
  },
  statusTagTitle: {
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.medium,
    color: TextColors.tag,
  },
  missionLink: {
    color: TextColors.regular,
    textAlign: 'left',
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.semiBold,
  },
  companyName: {
    color: TextColors.regular,
    textAlign: 'left',
    fontSize: FontSizes.regular,
    fontWeight: FontWeights.small,
  },
  roleSkills: {
    marginBottom: Spacing.medium,
  },
  roleDescription: {
    fontSize: FontSizes.medium,
    color: TextColors.regular,
    fontWeight: FontWeights.small,
  },
  roleCategory: {
    fontSize: FontSizes.medium,
    marginBottom: Spacing.small,
  },
  interviewActions: {
    display: 'flex',
    gap: Spacing.small,
  },
  interviewActionButton: {
    height: '40px',
    '& i': {
      marginRight: Spacing.small,
    },
  },
  interviewFeedbackDetails: {
    marginBottom: Spacing.small,
    marginTop: Spacing.medium,
    '& p, & strong': {
      margin: `${Spacing.small}px 0 0`,
    },
  },
});

const ApplicationItem = (props: Props): ReactElement => {
  const { item, user, showApplicationText } = props;
  const { auth } = useStores();
  const styles = useStyles();

  const isApplicationEdited = useMemo(
    () => isMissionApplicationEdited(item),
    [item.changes],
  );

  const hired = useMemo(() => {
    return item?.roleUser?.uid === user?.uid;
  }, [item.roleUser?.uid, user?.uid]);

  const confirmedAnotherBuilder = useMemo(() => {
    return (
      item.roleStatus !== MissionRoleStatus.Canceled &&
      item.roleUser &&
      item.roleUser.uid !== user?.uid
    );
  }, [item.roleStatus, item.roleUser?.uid, user?.uid]);

  const sortedInterviews = useMemo(
    () =>
      props.item.interviews?.sort(
        (a, b) =>
          new Date(b.interviewDate).getTime() -
          new Date(a.interviewDate).getTime(),
      ),
    [props.item.interviews],
  );

  const openRecordingUrl = (url: string) => {
    window.open(
      `${VettingDashboardRecordingLocation}?recordingUrl=${encodeURIComponent(
        url,
      )}`,
      '_blank',
    );
  };

  const openTranscriptUrl = (jobId: string) => {
    window.open(TranscriptLocation(jobId), '_blank');
  };

  return (
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      {auth.withMissionApplicationStatusV2 ? (
        <div style={{ minWidth: '2em' }} title={item.status}>
          <Icon type={IconType.Application} />
        </div>
      ) : (
        <div style={{ minWidth: '2em' }} title={item.status}>
          {item.status === MissionApplicationStatus.Shortlist ? (
            <CheckIcon fill={'#79CE00'} />
          ) : (
            <Icon type={IconType.Inbox} />
          )}
        </div>
      )}
      <div>
        <Link
          to={MissionApplicationLocation(item.mission.mid, item.aid)}
          className={styles.missionLink}
        >
          {item.mission.title}
          <br />
          <span className={styles.companyName}>{item.companyName}</span>
          {props.showRoleHeadline ? (
            <p className={styles.roleDescription}>{item.roleHeadline}</p>
          ) : null}
          <p className={styles.roleCategory}>{item.roleCategory.title}</p>
          <TagList className={styles.roleSkills}>
            {item.roleRequiredSkills?.map((skill) => (
              <Tag thin key={skill}>
                {skill}
              </Tag>
            ))}
          </TagList>
          {isApplicationEdited && item?.changes?.lastReviewAt ? (
            <Tag className={styles.tagEdited}>
              Edited,{' '}
              {format(
                new Date(item.changes.lastReviewAt),
                "MMMM d, yyyy 'at' h:mm a",
              )}
            </Tag>
          ) : null}
        </Link>
        <div>
          {!auth.withMissionApplicationStatusV2 && item.internalStatus && (
            <OldApplicationStatusIndication
              hired={hired}
              status={{
                internalStatus: item.internalStatus,
                proposedToClient: item.proposedToClient,
                exclusiveStatus: item.exclusiveStatus,
                lowCompetitiveness: item.lowCompetitiveness,
                proposalInterviewing: item.proposalInterviewing,
              }}
              missionStatus={item.mission.status}
              roleStatus={item.roleStatus}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            paddingTop: 16,
          }}
        >
          {item?.proposedRates?.builderHourlyRate && (
            <div className={styles.status}>
              Builder hourly rate: $
              {item?.proposedRates?.builderHourlyRate.toFixed(2)}
            </div>
          )}
          {item?.proposedRates?.clientHourlyRate && (
            <div className={styles.status}>
              Client hourly rate: $
              {item?.proposedRates?.clientHourlyRate.toFixed(1)}
            </div>
          )}
          <div className={styles.status}>
            {item.roleCategory.title}{' '}
            {item.hourlyRateRange && (
              <>
                ${item.hourlyRateRange.min} - ${item.hourlyRateRange.max}
              </>
            )}
          </div>
          <div className={styles.status}>
            {`Submitted: ${format(new Date(item.createdAt), 'MMMM dd, yyyy')}`}
          </div>
        </div>
        {auth.withMissionApplicationStatusV2 && (
          <div className={styles.statusTagContainer}>
            <div className={styles.statusTagTitle}>
              Status {item.lastStatusChangeWasAutomatic ? '(Automatic)' : ''}
            </div>
            <ApplicationStatusIndication
              useTagStyle
              hired={hired}
              confirmedAnotherBuilder={confirmedAnotherBuilder}
              withdrawn={item.withdrawn}
              status={{
                reviewStatus: item.reviewStatus,
                internalStatus: item.internalStatus,
                proposedToClient: item.proposedToClient,
                exclusiveStatus: item.exclusiveStatus,
                lowCompetitiveness: item.lowCompetitiveness,
                proposalInterviewing: item.proposalInterviewing,
                proposal: item.proposal,
              }}
              missionStatus={item.mission.status}
              roleStatus={item.roleStatus}
            />
          </div>
        )}
        {sortedInterviews?.map((interview) => {
          return (
            <div>
              <div className={styles.interviewFeedbackDetails}>
                <strong>
                  Interview -{' '}
                  {format(new Date(interview.interviewDate), 'MMMM dd, yyyy')}{' '}
                  at {format(new Date(interview.interviewDate), 'hh:mm a')}
                </strong>
                {interview.interviewFeedback?.action && (
                  <p>{interview.interviewFeedback.action}</p>
                )}
                {interview.interviewFeedback?.note && (
                  <p>{interview.interviewFeedback.note}</p>
                )}
              </div>
              {interview.transcripts?.map((transcript) => (
                <div className={styles.interviewActions}>
                  <Button
                    onClick={() => {
                      openTranscriptUrl(transcript.jobId);
                    }}
                    className={styles.interviewActionButton}
                    variant="ghost"
                  >
                    <Icon type={IconType.Article} /> Open transcript
                  </Button>
                  <Button
                    onClick={() => {
                      openRecordingUrl(transcript.recordingUrl);
                    }}
                    className={styles.interviewActionButton}
                    variant="ghost"
                  >
                    <Icon type={IconType.Play} />
                    View recording
                  </Button>
                </div>
              ))}
            </div>
          );
        })}
        {showApplicationText && (
          <div className={styles.pitchContainer}>
            <div className={styles.pitchTitle}>Pitch</div>
            <div className={styles.pitch}>{item.pitch}</div>
            {item.about && (
              <>
                <div className={styles.aboutTitle}>About</div>
                <div className={styles.about}>{item.about}</div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationItem;
