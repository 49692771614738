import { action, computed, observable, ObservableMap } from 'mobx';
import { MissionAdminObject } from '@a_team/models/dist/MissionObject';
import MissionRole, {
  MissionAdminRole,
  MissionRoleId,
} from '@a_team/models/dist/MissionRole';
import {
  AdminMissionApplicationObject,
  ApplicationRateRange,
  ExclusiveStatus,
  MissionApplicationId,
} from '@a_team/models/dist/MissionApplicationObject';
import { Stores } from '@src/stores';
import { apiProposals, apiUsers } from '@ateams/api';
import {
  ProposalCandidate,
  ProposalCandidateRecommendation,
  ProposalData,
  ProposalDataCurrency,
  ProposalTfsPitch,
} from '@a_team/models/dist/ProposalObject';
import UserObject, { UserId } from '@a_team/models/dist/UserObject';
import {
  BuilderTfsPitchDescriptor,
  MissionProposalsResponse,
} from '@ateams/api/dist/endpoints/Proposals';
import { logger } from '@sentry/utils';
import { removeTagsFromHtmlString } from '../utils';
import { MinimalAccountObject } from '@a_team/models/dist/Account';
import { omit, pick } from 'lodash';
import { AdminPitch } from '@a_team/models/dist/AdminNotesObject';

interface ProposalFormData {
  roles: ({
    id: MissionRoleId;
    candidates: ProposalTeamMember[];
  } & MissionRole)[];
  currency: ProposalDataCurrency;
}

export interface ProposalTeamMember {
  aid: MissionApplicationId;
  rid: MissionRoleId;
  userId?: UserId;
  fullName: string;
  profilePictureURL: string;
  hourlyRate?: number;
  monthlyRate?: number;
  tfsPitch?: ProposalTfsPitch;
  githubUrl?: string;
  cvUrl?: string;
  portfolioUrl?: string;
  portfolioPassword?: string;
  summary: UserObject['aboutMe'];
  address: UserObject['location'];
  roleCategory: UserObject['roleCategory'];
  jobExperiences: UserObject['jobs'];
  projectExperiences: UserObject['projects'];
  expertiseTags: UserObject['profileTags']['expertise'];
  message: AdminMissionApplicationObject['pitch'];
  hourlyRateRange: AdminMissionApplicationObject['hourlyRateRange'];
  monthlyRateRange: AdminMissionApplicationObject['monthlyRateRange'];
  showHourlyRate?: boolean;
  showMonthlyRate?: boolean;
  isTeamLead?: boolean;
  roleStatus: ProposalCandidateRecommendation | undefined;
  username: string;
  includeCustomQuestionReply?: boolean;
  gptUsageLogId?: string;
}

export interface ProposalStoreData {
  account?: MinimalAccountObject;
  mission: MissionAdminObject;
  applications: AdminMissionApplicationObject[] | null;
  formData: ProposalFormData;
  url?: string;
  proposals?: MissionProposalsResponse;
  users: Record<UserId, UserObject>;
  isNarrativeProposal?: boolean;
  narrativeTypesSelected?: string[];
  teamBlurb?: string;
}

export enum ProposalMemberPropertyToUpdate {
  HourlyRate,
  Website,
  Blurb,
  GithubUrl,
  CVUrl,
  PortfolioUrl,
  PortfolioPassword,
  MonthlyRate,
  ShowMonthlyRate,
  ShowHourlyRate,
  GptUsageLogId,
}

export default class Proposal implements ProposalStoreData {
  @observable account?: MinimalAccountObject | undefined;
  @observable applications: ProposalStoreData['applications'] = [];
  @observable mission: ProposalStoreData['mission'];
  @observable formData: ProposalStoreData['formData'] = {
    roles: [],
    currency: ProposalDataCurrency.USD,
  };
  @observable url: ProposalStoreData['url'];
  @observable proposals: ProposalStoreData['proposals'] = undefined;
  @observable users: ProposalStoreData['users'] = {};
  @observable builderTfsPitches: ObservableMap<
    UserId,
    Array<BuilderTfsPitchDescriptor>
  > = new ObservableMap({});
  @observable isNarrativeProposal: ProposalStoreData['isNarrativeProposal'];
  @observable
  narrativeTypesSelected: ProposalStoreData['narrativeTypesSelected'];
  @observable teamBlurb: ProposalStoreData['teamBlurb'];

  private authStore: Stores['auth'];

  public constructor(
    auth: Stores['auth'],
    mission: MissionAdminObject,
    applications: AdminMissionApplicationObject[] | null,
  ) {
    this.authStore = auth;
    this.mission = mission;
    this.applications = applications;
    this.setData();
  }

  serialize = (): ProposalStoreData => {
    return {
      account: this.account,
      mission: this.mission,
      applications: this.applications,
      formData: this.formData,
      url: this.url,
      users: this.users,
      proposals: this.proposals,
      isNarrativeProposal: this.isNarrativeProposal,
      narrativeTypesSelected: this.narrativeTypesSelected,
      teamBlurb: this.teamBlurb,
    };
  };

  calculateRate = (rateHRange: ApplicationRateRange): number => {
    const { min } = rateHRange;
    return min + (min / 100) * 20;
  };

  getApplicationMemberData = async (
    application: AdminMissionApplicationObject,
  ): Promise<ProposalTeamMember> => {
    const existingUserData = this.users[application.user.uid];
    let profile;
    if (existingUserData) {
      profile = existingUserData;
    } else {
      profile = await apiUsers.getUserByUsername(
        this.authStore,
        application.user.username,
      );
      this.setUserData(profile);
    }
    return {
      rid: application.rid,
      aid: application.aid,
      userId: application.user.uid,
      fullName: application.user.fullName,
      profilePictureURL: application.user.profilePictureURL,
      summary: profile.aboutMe,
      address: profile.location,
      hourlyRate: application.hourlyRateRange
        ? this.calculateRate(application.hourlyRateRange)
        : 0,
      monthlyRate: application.monthlyRateRange
        ? this.calculateRate(application.monthlyRateRange)
        : 0,
      roleCategory: profile.roleCategory,
      jobExperiences: profile.jobs,
      projectExperiences: profile.projects,
      hourlyRateRange: application.hourlyRateRange,
      monthlyRateRange: application.monthlyRateRange,
      message: application.pitch || '',
      expertiseTags: profile.profileTags.expertise,
      roleStatus: undefined,
      username: application.user.username,
      includeCustomQuestionReply:
        application.customQuestionsReplies &&
        application.customQuestionsReplies.length > 0
          ? true
          : undefined,
      githubUrl: profile.github?.profileUrl?.trim(),
      cvUrl: profile.cvURL?.trim(),
      portfolioUrl: profile.portfolio?.url?.trim(),
      portfolioPassword: profile.portfolio?.password,
    };
  };

  getExistingProposals = async (): Promise<void> => {
    const res = await apiProposals.getProposalsForMission(
      this.authStore,
      this.mission.mid,
    );
    this.setProposalsData(res);
  };

  prepareMemberData = (member: ProposalTeamMember): ProposalCandidate => {
    return {
      rid: member.rid,
      aid: member.aid,
      userId: member.userId,
      hourlyRate: member.hourlyRate || 0,
      isTeamLead: member.isTeamLead,
      recommendation: member.roleStatus,
      roleCategoryId: member.roleCategory?.cid,
      tfsPitch: member.tfsPitch,
      includeCustomQuestionReply: member.includeCustomQuestionReply,
      githubUrl: member.githubUrl,
      cvUrl: member.cvUrl,
      portfolioUrl: member.portfolioUrl,
      portfolioPassword: member.portfolioPassword,
      monthlyRate: member.monthlyRate,
      showMonthlyRate: member.showMonthlyRate,
      showHourlyRate: member.showHourlyRate,
      gptUsageLogId: member.gptUsageLogId,
    };
  };

  getReorderedCandidates = (
    candidates: ProposalTeamMember[],
    fromIndex: number,
    toIndex: number,
  ): ProposalTeamMember[] => {
    const movingCandidate = { ...{}, ...candidates[fromIndex] };

    candidates[fromIndex] = { ...{}, ...candidates[toIndex] };
    candidates[toIndex] = movingCandidate;

    return candidates;
  };

  setData = async (): Promise<void> => {
    this.setInitialValues();
    this.getExistingProposals();
    this.retrieveDataFromCache();
  };

  generateProposal = async (
    missionsStore: Stores['missions'],
    teamBlurb?: string,
    teamNarratives?: ProposalData['teamNarratives'],
  ): Promise<void> => {
    const candidates = this.candidates.map((candidate) =>
      this.prepareMemberData(candidate),
    );

    if (
      teamNarratives &&
      teamNarratives.length &&
      !removeTagsFromHtmlString(teamBlurb || '')
    ) {
      throw new Error('Team blurb is required');
    }

    const missionSpecId = this.mission.missionSpecId;
    if (!missionSpecId) {
      logger.error('Unable to genereate proposal, missing mission spec id');
      throw new Error('Unable to generate');
    }

    const proposalData = {
      missionSpecId,
      mid: this.mission.mid,
      ...{ candidates },
      ...(teamNarratives && teamNarratives.length
        ? {
            teamBlurb,
            teamNarratives,
          }
        : {}),
      currency: this.formData.currency,
    };
    const proposal = await apiProposals.create(this.authStore, proposalData);
    proposal && this.setUrl(proposal.publicURL);
    await missionsStore.reloadCurrentMission(
      this.mission.mid,
      this.authStore.isAdmin,
    );
    this.clearDataFromCache();
  };

  updateProposal = async ({
    proposalId,
    isShared,
  }: {
    proposalId: string;
    isShared: boolean;
  }): Promise<void> => {
    await apiProposals.update(this.authStore, proposalId, {
      isShared,
    });
    await this.getExistingProposals();
  };

  loadBuilderTfsPitches = async ({
    aid,
    userId,
  }: ProposalTeamMember): Promise<void> => {
    try {
      if (!userId) return;

      let tfsPitches: BuilderTfsPitchDescriptor[] = [],
        adminPitch: AdminPitch | undefined;
      if (this.builderTfsPitches.has(userId)) {
        tfsPitches = this.builderTfsPitches.get(userId) || [];
      } else {
        const res = await apiProposals.getBuilderTfsPitches(
          this.authStore,
          userId,
        );
        tfsPitches = res.tfsPitches;
        adminPitch = res.adminPitch;
        this.setBuilderTfsPitches(userId, tfsPitches);
      }

      if (tfsPitches.length) {
        const [{ tfsPitch }] = tfsPitches;

        tfsPitch.blurb &&
          this.updateMemberData(
            aid,
            ProposalMemberPropertyToUpdate.Blurb,
            tfsPitch.blurb,
            true,
          );
        tfsPitch.website &&
          this.updateMemberData(
            aid,
            ProposalMemberPropertyToUpdate.Website,
            tfsPitch.website,
            true,
          );
      } else if (adminPitch && adminPitch.text) {
        this.updateMemberData(
          aid,
          ProposalMemberPropertyToUpdate.Blurb,
          adminPitch.text,
        );
      }
    } catch (error) {
      logger.error(error);
    }
  };

  @computed get isShowingRatesForAllBuilders(): boolean {
    return this.candidates.every((member) => {
      const role = this.roles.find((role) => role.rid === member.rid);
      return (
        member.showHourlyRate &&
        (role?.collectMonthlyRate ? member.showMonthlyRate : true)
      );
    });
  }

  @computed get roles(): MissionAdminRole[] {
    return this.mission.roles;
  }

  @computed get candidates(): ProposalTeamMember[] {
    const done: ProposalTeamMember[] = [];
    this.formData.roles.forEach((role) => {
      if (!role.candidates) return;
      done.push(...role.candidates);
    });
    return done;
  }

  @action setBuilderTfsPitches = (
    userId: UserId,
    tfsPitches: Array<BuilderTfsPitchDescriptor>,
  ) => {
    this.builderTfsPitches.set(userId, tfsPitches);
  };

  @action setInitialValues = (): void => {
    this.roles.forEach((role) =>
      this.formData.roles.push({
        ...role,
        id: role.rid,
        candidates: [],
      }),
    );
  };

  @action setUrl = (url: string): void => {
    this.url = url;
  };

  @action setTeamBlurb = (teamBlurb: string): void => {
    this.teamBlurb = teamBlurb;
    this.saveCurrentDataToCache();
  };

  @action updateCurrency = (value: ProposalDataCurrency): void => {
    this.formData.currency = value;
    this.saveCurrentDataToCache();
  };

  @action updateIsNarrativeProposal = (value: boolean): void => {
    this.isNarrativeProposal = value;
    this.saveCurrentDataToCache();
  };

  @action updateNarrativeTypesSelected = (value: string[]): void => {
    this.narrativeTypesSelected = value;
    this.saveCurrentDataToCache();
  };

  @action setProposalsData = (data: MissionProposalsResponse): void => {
    this.proposals = data;
  };

  @action changeRoleOrder = (
    rid: MissionRoleId,
    updateIndex: 'up' | 'down',
  ): void => {
    const roleIndex = this.formData.roles.findIndex((role) => role.id === rid);
    const newIndex = updateIndex === 'up' ? roleIndex - 1 : roleIndex + 1;
    const movingRole = { ...{}, ...this.formData.roles[roleIndex] };

    this.formData.roles[roleIndex] = this.formData.roles[newIndex];
    this.formData.roles[newIndex] = movingRole;
  };

  @action addTeamMember = async (
    aid: MissionApplicationId,
    rid: MissionRoleId,
    defaultMemberData?: Partial<ProposalTeamMember>,
    fromIndex?: number,
    toIndex?: number,
  ): Promise<void> => {
    const didDrag = fromIndex !== undefined && toIndex !== undefined;
    const existing = this.candidates.find((member) => member.aid === aid);
    if (existing && !didDrag) {
      return;
    }

    const application = this.applications?.find((app) => app.aid === aid);
    const memberData = application
      ? await this.getApplicationMemberData(application)
      : undefined;

    if (memberData) {
      memberData.rid = rid;
      memberData.userId = application?.user.uid;
      memberData.showHourlyRate = true;
      const role = this.roles.find((role) => role.rid === rid);
      role && (memberData.roleCategory = role.category);
      const roleIndex = this.formData.roles.findIndex(
        (role) => role.id === rid,
      );

      if (fromIndex !== undefined && toIndex !== undefined) {
        this.formData.roles[roleIndex].candidates = this.getReorderedCandidates(
          [...[], ...this.formData.roles[roleIndex].candidates],
          fromIndex,
          toIndex,
        );
      } else {
        this.formData.roles[roleIndex].candidates.push({
          ...memberData,
          ...(defaultMemberData ? defaultMemberData : {}),
        });
      }
      await this.loadBuilderTfsPitches(memberData);
      if (!defaultMemberData) {
        // No default member data means not called from the cache
        this.updateNarrativeTypesSelected([]);
      } else {
        this.saveCurrentDataToCache();
      }
    }
  };

  @action removeTeamMember = (
    aid: MissionApplicationId,
    rid: MissionRoleId,
  ): void => {
    const roleIndex = this.formData.roles.findIndex((role) => role.id === rid);
    this.formData.roles[roleIndex].candidates = this.formData.roles[
      roleIndex
    ].candidates.filter((member) => member.aid !== aid);
    this.saveCurrentDataToCache();
  };

  @action clearTeamMembers = (): void => {
    this.formData.roles.forEach((role) => (role.candidates = []));
    this.saveCurrentDataToCache();
  };

  @action updateShowRatesForAllCandidates = (showRates: boolean): void => {
    this.candidates.forEach((member) => {
      const role = this.roles.find((role) => role.rid === member.rid);
      member.showHourlyRate = showRates;
      if (role?.collectMonthlyRate) {
        member.showMonthlyRate = showRates;
      }
    });
    this.saveCurrentDataToCache();
  };

  @action updateMemberData = (
    aid: MissionApplicationId,
    property: ProposalMemberPropertyToUpdate,
    data: number | string | boolean,
    doNotOverride?: boolean,
  ): void => {
    const member = this.candidates.find((member) => member.aid === aid);
    if (!member) return;
    switch (property) {
      case ProposalMemberPropertyToUpdate.ShowHourlyRate:
        member.showHourlyRate = data as boolean;
        break;
      case ProposalMemberPropertyToUpdate.ShowMonthlyRate:
        member.showMonthlyRate = data as boolean;
        break;
      case ProposalMemberPropertyToUpdate.HourlyRate:
        member.hourlyRate = data as number;
        break;
      case ProposalMemberPropertyToUpdate.MonthlyRate:
        member.monthlyRate = data as number;
        break;
      case ProposalMemberPropertyToUpdate.Website:
        if (doNotOverride && member.tfsPitch && member.tfsPitch.website) {
          return;
        }
        member.tfsPitch = member.tfsPitch || {};
        member.tfsPitch.website = data as string;
        break;
      case ProposalMemberPropertyToUpdate.Blurb:
        if (doNotOverride && member.tfsPitch && member.tfsPitch.blurb) {
          return;
        }
        member.tfsPitch = member.tfsPitch || {};
        member.tfsPitch.blurb = data as string;
        break;
      case ProposalMemberPropertyToUpdate.GithubUrl:
        member.githubUrl = (data as string).trim();
        break;
      case ProposalMemberPropertyToUpdate.CVUrl:
        member.cvUrl = (data as string).trim();
        break;
      case ProposalMemberPropertyToUpdate.PortfolioUrl:
        member.portfolioUrl = (data as string).trim();
        break;
      case ProposalMemberPropertyToUpdate.PortfolioPassword:
        member.portfolioPassword = data as string;
        break;
      case ProposalMemberPropertyToUpdate.GptUsageLogId:
        member.gptUsageLogId = data as string;
        break;
    }
    this.saveCurrentDataToCache();
  };

  @action updateIncludeReply = (
    aid: MissionApplicationId,
    include: boolean,
  ): void => {
    const member = this.candidates.find((member) => member.aid === aid);
    if (!member) {
      return;
    }
    member.includeCustomQuestionReply = include;
  };

  @action setUserData = (user: UserObject): void => {
    this.users[user.uid] = user;
  };

  /**
   * Updates all of a given user's applications with the correct exclusive status
   */
  @action updateApplicationsExclusiveStatus = (
    uid: UserId,
    exclusiveApplication?: MissionApplicationId,
  ): void => {
    if (!this.applications) return;
    this.applications = this.applications.map((application) => {
      if (application.user.uid !== uid) return application;
      return {
        ...application,
        exclusiveStatus: exclusiveApplication
          ? application.aid === exclusiveApplication
            ? application.exclusiveStatus
            : ExclusiveStatus.OnHold
          : undefined,
      };
    });
  };

  @action saveCurrentDataToCache = (): void => {
    sessionStorage.setItem(
      `proposal-${this.mission.mid}`,
      JSON.stringify({
        currency: this.formData.currency,
        candidates: this.candidates.map((candidate) =>
          pick(candidate, [
            'aid',
            'rid',
            'tfsPitch',
            'hourlyRate',
            'githubUrl',
            'cvUrl',
            'portfolioUrl',
            'portfolioPassword',
            'showHourlyRate',
            'showMonthlyRate',
            'monthlyRate',
            'gptUsageLogId',
          ]),
        ),
        isNarrativeProposal: this.isNarrativeProposal,
        narrativeTypesSelected: this.narrativeTypesSelected,
        teamBlurb: this.teamBlurb,
      }),
    );
  };
  @action retrieveDataFromCache = (): void => {
    const data = sessionStorage.getItem(`proposal-${this.mission.mid}`);
    if (!data) return;
    const parsedData = JSON.parse(data);
    parsedData.candidates.forEach((candidate: Partial<ProposalTeamMember>) => {
      if (!candidate.aid || !candidate.rid) return;
      this.addTeamMember(
        candidate.aid,
        candidate.rid,
        omit(candidate, ['aid', 'rid']),
        undefined,
        undefined,
      );
    });
    parsedData.currency && this.updateCurrency(parsedData.currency);
    parsedData.isNarrativeProposal &&
      this.updateIsNarrativeProposal(parsedData.isNarrativeProposal);
    parsedData.narrativeTypesSelected &&
      this.updateNarrativeTypesSelected(parsedData.narrativeTypesSelected);
    parsedData.teamBlurb && this.setTeamBlurb(parsedData.teamBlurb);
  };
  @action clearDataFromCache = (): void => {
    sessionStorage.removeItem(`proposal-${this.mission.mid}`);
  };
}
