import { DateISOString } from '../misc';
import { TalentSkill } from '../TalentCategories';
import TimezoneObject from '../TimezoneObject';
import { VettingProcessFeedbackRoles } from './feedback';
import { VettingFormVariant } from './vetting-processes';

export enum CompanySize {
  MarketPowerhouse = 'marketPowerhouse',
  Enterprise = 'enterprise',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export const CompanySizeLabels: Partial<Record<CompanySize, string>> = {
  [CompanySize.Enterprise]: 'Enterprise (>1000 employees)',
  [CompanySize.Large]: 'Large company (200-1000 employees)',
  [CompanySize.Medium]: 'Medium size (30-200 employees)',
  [CompanySize.Small]: 'Startup / small company',
};

export enum DidBuildProductsFromScratch {
  No = 'no',
  YesAndNotUsedAnymore = 'yesAndNotUsedAnymore',
  YesAndStillUsed = 'yesAndStillUsed',
}

export const DidBuildProductsFromScratchLabels: Record<
  DidBuildProductsFromScratch,
  string
> = {
  [DidBuildProductsFromScratch.No]: 'No',
  [DidBuildProductsFromScratch.YesAndNotUsedAnymore]:
    "Yes, but isn't used anymore",
  [DidBuildProductsFromScratch.YesAndStillUsed]: 'Yes and it is still used',
};

export enum ManagementExperience {
  None = 'none',
  SmallTeam = 'smallTeam',
  MediumTeam = 'mediumTeam',
  LargeAndAboveTeam = 'largeAndAboveTeam',
}

export const ManagementExperienceLabels: Record<ManagementExperience, string> =
  {
    [ManagementExperience.None]: 'No management experience',
    [ManagementExperience.SmallTeam]: 'Managed 1-5 people',
    [ManagementExperience.MediumTeam]: 'Managed 6-10 people',
    [ManagementExperience.LargeAndAboveTeam]: 'Managed 11+ people',
  };

export const ManagementExprienceRadioLabels: Partial<
  Record<ManagementExperience, string>
> = {
  [ManagementExperience.SmallTeam]: '1-5',
  [ManagementExperience.MediumTeam]: '6-10',
  [ManagementExperience.LargeAndAboveTeam]: '11+',
};

export enum TypicalTeamSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const TypicalTeamSizeLabels: Record<TypicalTeamSize, string> = {
  [TypicalTeamSize.Small]: '0-5 people',
  [TypicalTeamSize.Medium]: '6-10 people',
  [TypicalTeamSize.Large]: '11+ people',
};

export interface TimeOfDay {
  minutes: number;
  hours: number;
}

export type HoursRange = [null | TimeOfDay, null | TimeOfDay];

export type DailyHoursRange = null | HoursRange[];

export type WeeklyHoursRanges = [
  DailyHoursRange,
  DailyHoursRange,
  DailyHoursRange,
  DailyHoursRange,
  DailyHoursRange,
  DailyHoursRange,
  DailyHoursRange,
];

export interface WeeklyHours {
  timezone: TimezoneObject;
  hoursRanges: WeeklyHoursRanges;
}

export type VettingProcessPreVettingForm =
  | VettingProcessSoftwareEngineeringPreVettingForm
  | VettingProcessProductManagementPreVettingForm
  | VettingProcessProjectManagementPreVettingForm
  | VettingProcessDesignPreVettingForm;

interface BaseVettingProcessPreVettingForm {
  calendarUrl?: string;
  weeklyHours?: WeeklyHours;
  submitDate: DateISOString;
}

export interface VettingProcessSoftwareEngineeringPreVettingForm
  extends BaseVettingProcessPreVettingForm {
  variant?: VettingFormVariant.SoftwareEngineering;
  companySize: CompanySize[];
  didBuildProductsFromScratch: DidBuildProductsFromScratch;
  managementExperience: ManagementExperience;
  codeSampleProgrammingLanguages: TalentSkill[];
  otherCodeSampleProgrammingLanguage?: string;
  codeSample: string;
  codeSampleNotes?: string;
  codeSampleLinkType?: LinkType;
}

export interface VettingProcessProductManagementPreVettingForm
  extends BaseVettingProcessPreVettingForm {
  variant:
    | VettingFormVariant.ProductManagement
    | VettingFormVariant.Marketing
    | VettingFormVariant.Operations;
  primaryExpertise: PrimaryExpertise[];
  companySize: CompanySize[];
  productType: ProductType[];
  hasExperienceWorkingOnAProduct: boolean;
  typicalTeamSize: TypicalTeamSize;
  hadProductDesignerOnTeam: boolean;
  hasProfitAndLossResponsibility?: boolean;
  hasTechnicalBackground: boolean;
}

export interface VettingProcessProjectManagementPreVettingForm
  extends BaseVettingProcessPreVettingForm {
  variant: VettingFormVariant.ProjectManagement;
  primaryExpertise: PrimaryExpertise[];
  companySize: CompanySize[];
  productType: ProductType[];
  hasExperienceWorkingOnAProduct: boolean;
  typicalTeamSize: TypicalTeamSize;
  hadProductDesignerOnTeam: boolean;
  hasProfitAndLossResponsibility?: boolean;
  hasTechnicalBackground: boolean;
}

export interface VettingProcessDesignPreVettingForm
  extends BaseVettingProcessPreVettingForm {
  variant: VettingFormVariant.Design;
  primaryRoles: VettingProcessFeedbackRoles[];
  companySize: CompanySize[];
  didDesignedProductsFromScratch?: DidDesignedProductsFromScratch;
  caseStudy: string;
  caseStudyLinkType?: LinkType;
  hadDesignedCaseStudyAsTeamOrIndividual: string;
}

export enum LinkType {
  Download = 'Download',
  URL = 'URL',
}

export enum PrimaryExpertise {
  Seed = 'Seed',
  EarlyStage = 'EarlyStage',
  GrowthStage = 'GrowthStage',
  MatureStage = 'MatureStage',
}

export const PrimaryExpertiseLabels: Record<PrimaryExpertise, string> = {
  [PrimaryExpertise.Seed]: 'Seed',
  [PrimaryExpertise.EarlyStage]: 'Early Stage',
  [PrimaryExpertise.GrowthStage]: 'Growth Stage',
  [PrimaryExpertise.MatureStage]: 'Mature Stage',
};

export enum ProductType {
  B2B = 'B2B',
  B2C = 'B2C',
  B2G = 'B2G',
  B2B2C = 'B2B2C',
}

export const ProductTypeLabels: Record<ProductType, string> = {
  [ProductType.B2B]: 'Business to Business (B2B)',
  [ProductType.B2C]: 'Business to Consumer (B2C)',
  [ProductType.B2G]: 'Business to Government (B2G)',
  [ProductType.B2B2C]: 'Business to Business to Consumer (B2B2C)',
};

export enum TeamSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export const TeamSizeLabels: Record<TeamSize, string> = {
  [TeamSize.Small]: '0-5 people',
  [TeamSize.Medium]: '6-10 people',
  [TeamSize.Large]: '11+ people',
};

export enum DidDesignedProductsFromScratch {
  No = 'no',
  YesAndNotUsedAnymore = 'yesAndNotUsedAnymore',
  YesAndStillUsed = 'yesAndStillUsed',
}

export const DidDesignedProductsFromScratchLabels: Record<
  DidDesignedProductsFromScratch,
  string
> = {
  [DidDesignedProductsFromScratch.No]: 'No',
  [DidDesignedProductsFromScratch.YesAndNotUsedAnymore]:
    "Yes, but isn't used anymore",
  [DidDesignedProductsFromScratch.YesAndStillUsed]: 'Yes and it is still used',
};
