import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { PreVettingLayout } from '@src/layouts/PreVetting';
import { OnPreVettingFormSubmit, PreVettingForm } from './form';
import { useHistory } from 'react-router-dom';
import { PreVettingFormSubmittedLocation } from '@src/locations';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import { useValidateURL } from './use-validate-url';
import { PreVettingLayoutOld } from '@src/layouts/PreVettingOld';
import { PreVettingFormOld } from './formOld';

const PreVettingView: React.FC = () => {
  const { auth, vettingProcesses } = useStores();
  const history = useHistory();
  const { nonce, loading: validateNonceLoading } = useValidateURL();
  const [submitFormLoading, setSubmitFormLoading] = useLoadingState();

  const onSubmit: OnPreVettingFormSubmit = (payload) => {
    setSubmitFormLoading(
      (async () => {
        await vettingProcesses.submitPreVettingForm(nonce, payload);

        if (auth.withNewEvaluation) {
          const { pathname, search } = history.location;
          const newPathname = pathname.replace('/form/', '/schedule/');
          const newUrl = `${newPathname}${search}`;
          history.replace(newUrl);
        } else {
          history.push(PreVettingFormSubmittedLocation);
        }
      })(),
    );
  };

  return auth.withNewEvaluation ? (
    <PreVettingLayout title={'Pre-evaluation'}>
      {!validateNonceLoading &&
        vettingProcesses.preVettingFormUser &&
        vettingProcesses.formVariant && (
          <PreVettingForm
            variant={vettingProcesses.formVariant}
            user={vettingProcesses.preVettingFormUser}
            onSubmit={onSubmit}
          />
        )}
      <LoadingIndicator loading={validateNonceLoading || submitFormLoading} />
    </PreVettingLayout>
  ) : (
    <PreVettingLayoutOld title={'Pre-evaluation'}>
      {!validateNonceLoading &&
        vettingProcesses.preVettingFormUser &&
        vettingProcesses.formVariant && (
          <PreVettingFormOld
            variant={vettingProcesses.formVariant}
            user={vettingProcesses.preVettingFormUser}
            onSubmit={onSubmit}
          />
        )}
      <LoadingIndicator loading={validateNonceLoading || submitFormLoading} />
    </PreVettingLayoutOld>
  );
};

export default observer(PreVettingView);
