import React, {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  ReactElement,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import useStyles from './styles';
import { ColorName, Colors } from '@ateams/components';

const DEFAULT_MIN_HEIGHT = 20;

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  errorColor?: ColorName;
  readonly?: boolean;
  label?: string;
  minLength?: number;
  wrapperClassname?: string;
  autoResize?: boolean;
  minHeight?: number;
  showCharsLeft?: boolean;
  footer?: ReactElement;
}

const TextAreaInput: React.FC<Props> = (props) => {
  const {
    error,
    errorColor,
    className,
    readonly,
    disabled,
    label,
    minLength,
    wrapperClassname,
    onChange,
    onInput,
    onFocus,
    autoResize,
    minHeight,
    showCharsLeft,
    footer,
    ...inputProps
  } = props;
  const styles = useStyles({
    error,
    errorColor,
    autoResize,
  });
  // value is not meant to control the textarea. it's only to determine the current length of the textarea value
  const [value, setValue] = useState<string>('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const maybeAdjustTextareaHeight = (): void => {
    if (!autoResize) {
      return;
    }
    const defaultHeight = minHeight ?? DEFAULT_MIN_HEIGHT;

    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      const height =
        textareaRef.current.scrollHeight < defaultHeight
          ? defaultHeight
          : textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${height}px`;
    }
  };

  useEffect(() => {
    maybeAdjustTextareaHeight();
  }, [autoResize]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    if (onChange) {
      onChange(e);
    }
    setValue(e.target.value);
    maybeAdjustTextareaHeight();
  };

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>): void => {
    if (onFocus) {
      onFocus(e);
    }
    setValue(e.currentTarget.value);
    maybeAdjustTextareaHeight();
  };

  const handleInput = (e: FormEvent<HTMLTextAreaElement>): void => {
    if (onInput) {
      onInput(e);
    }
    maybeAdjustTextareaHeight();
  };

  const maybeRenderCharsLeft = () => {
    if (!showCharsLeft || !props.maxLength) {
      return null;
    }

    return (
      <div
        className={styles.charsLeft}
      >{`${value.length.toLocaleString()}/${props.maxLength.toLocaleString()}`}</div>
    );
  };

  return (
    <div className={cx(styles.wrapper, wrapperClassname)}>
      {label ? <label className={styles.label}>{label}</label> : null}
      <textarea
        ref={textareaRef}
        {...inputProps}
        disabled={readonly || disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onInput={handleInput}
        className={cx({ [styles.readonly]: readonly }, [
          className,
          styles.error,
          styles.textarea,
        ])}
      />
      {footer && footer}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {!!minLength && (
          <span
            style={{
              fontSize: 12,
              color: '#6E7177',
              ...(error && errorColor && { color: Colors[errorColor] }),
            }}
          >
            Enter at least {minLength} characters.
          </span>
        )}
        {props.maxLength &&
        props.showCharsLeft &&
        value.length >= props.maxLength ? (
          <span
            style={{
              fontSize: 12,
              color: Colors.primary,
            }}
          >
            {`Text cannot exceed ${props.maxLength} characters.`}
          </span>
        ) : (
          maybeRenderCharsLeft()
        )}
      </div>
    </div>
  );
};

export default TextAreaInput;
