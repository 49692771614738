import { BasicClientCompanyObject } from './ClientCompanies';
import { DateISOString } from './misc';

export type ClientInterviewId = string;

export enum ClientInterviewStatus {
  InterviewRequested = 'InterviewRequested',
  InterviewAccepted = 'InterviewAccepted',
  InterviewRejected = 'InterviewRejected',
  InterviewCancelled = 'InterviewCancelled',
  InterviewExpired = 'InterviewExpired',
}

export enum ClientInterviewDeclineReason {
  NotEnoughAvailability = 'NotEnoughAvailability',
  RoleNotRelevant = 'RoleNotRelevant',
  NotInterested = 'NotInterested',
  RateTooLow = 'RateTooLow',
  Other = 'Other',
}

export interface BasicClientInterviewObject {
  ciid: ClientInterviewId;
  clientCompany: BasicClientCompanyObject;
  interviewRoleDescription: string;
  startDate: string;
  endDate: string;
  builderTimezone: string;
  clientMinHourlyRate?: number;
  clientMaxHourlyRate?: number;
  builderHourlyRate?: number;
  status: ClientInterviewStatus;
  declineReason?: ClientInterviewDeclineReason;
  cancelReasonAfterAccepted?: string;
  declineOtherReasonDetails?: string;
  createdAt: DateISOString;
}
