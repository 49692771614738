import { apiMissions } from '@ateams/api';
import { BulkMissionApplicationStatusUpdateItem } from '@ateams/api/dist/endpoints/Missions';
import {
  AdminMissionApplicationObject,
  MissionApplicationInternalStatus,
  MissionApplicationLowCompetitivenessReason,
  MissionApplicationRejectionReason,
  MissionApplicationReviewStatusNotSelected,
  MissionApplicationReviewStatusOpportunityToUpdate,
  MissionApplicationReviewStatusOther,
  MissionApplicationReviewStatusWaitlisted,
  MissionApplicationStatus,
  ProposedMethod,
} from '@a_team/models/dist/MissionApplicationObject';
import MissionRole, {
  BasicMissionRole,
  MissionRoleStatus,
} from '@a_team/models/dist/MissionRole';
import {
  BulkMenuMainItems,
  BulkMenuItem,
  BulkMenuItemOld,
  BulkSubMenuItem,
  BulkSubMenuItemOld,
} from '@src/components/ApplicantPill';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import AuthStore from '@src/stores/Auth';
import queryString, { StringifiableRecord } from 'query-string';
import Mission from '@src/stores/Missions/Mission';
import MissionsStore from '@src/stores/Missions/Missions';
import { addWeeks, format } from 'date-fns';
import { MissionAdminObject } from '@a_team/models/dist/MissionObject';
import {
  ExperienceGraphService,
  OpenAPI,
  TalentSpecialization,
} from '@a_team/data-science-api-client';
import _ from 'lodash';
import { TalentSkillId } from '@a_team/models/dist/TalentCategories';
import { fetchSkillList } from './talent-skills-api';
import { MissionPreviewRoleDto } from '@a_team/user-notification-service-js-sdk';
import { v1SearchUserFilterApiToSearchCriteria } from '@src/views/SkillTargeter/SearchView/utils';

export interface TabFromMission {
  label: string;
  url: string;
}

export async function filterVerifiedSkillsFromRoles<
  T extends BasicMissionRole | MissionRole,
>(roles: T[]): Promise<T[]> {
  const allSkillIds: string = roles
    .map(({ requiredSkills, preferredSkills }) => [
      ...(requiredSkills ?? []),
      ...(preferredSkills ?? []),
    ])
    .reduce(
      (skillIds: TalentSkillId[], roleSkills) => [
        ...skillIds,
        ...roleSkills.map(({ talentSkillId }) => talentSkillId),
      ],
      [],
    )
    .join(',');

  const verifiedSkills = await fetchSkillList({
    filter: {
      id: allSkillIds?.split(','),
      isVerified: true,
    },
  });

  const verifiedSkillsMap = new Map<TalentSkillId, true>(
    verifiedSkills.map(({ id }) => [id, true]),
  );

  return roles.map((role) => ({
    ...role,
    requiredSkills: (role.requiredSkills ?? []).filter(({ talentSkillId }) =>
      verifiedSkillsMap.get(talentSkillId),
    ),
    preferredSkills: (role.preferredSkills ?? []).filter(({ talentSkillId }) =>
      verifiedSkillsMap.get(talentSkillId),
    ),
  }));
}

export const fetchAdjacentSpecializationsMap = async (
  auth: AuthStore,
  specializations: string[],
): Promise<Record<string, string[]>> => {
  const adjacentSpecializations = await Promise.all(
    specializations?.map((specialization) => {
      OpenAPI.TOKEN = auth.token ?? undefined;
      return ExperienceGraphService.getAdjacentRoles(specialization);
    }) || [],
  );

  const newAdjacentSpecializations: Record<string, string[]> = {};

  adjacentSpecializations.forEach((adjacentSpecialization, idx) => {
    const adjacentSpecializationsList: string[] = [];
    adjacentSpecialization.items.forEach((item) => {
      adjacentSpecializationsList.push((item as TalentSpecialization).id);
    });
    newAdjacentSpecializations[specializations[idx]] =
      adjacentSpecializationsList;
  });

  return newAdjacentSpecializations;
};

export const getAdjacentSpecializations = async (
  auth: AuthStore,
  specializations: string[],
  includeAdjacentSpecializations: boolean,
): Promise<string[]> => {
  const adjacentSpecializationsMapToAdd = await fetchAdjacentSpecializationsMap(
    auth,
    specializations,
  );

  if (includeAdjacentSpecializations) {
    return _.uniq([...Object.values(adjacentSpecializationsMapToAdd).flat()]);
  } else {
    return [];
  }
};

export const getTabsFromMission = async (
  auth: AuthStore,
  mid: string,
  type: string,
  role?: string,
): Promise<{
  tabs: TabFromMission[];
  activeRoleTabNames: string[];
  mission: MissionAdminObject;
  role?: string;
}> => {
  const mission = await apiMissions.adminGetMissionById(auth, mid);

  const rolesToOpen = role
    ? mission.roles.filter((r) => r.rid === role)
    : mission.roles.filter(
        (role) => role.status === MissionRoleStatus.Open && !role.user,
      ) || [];

  const tabs: {
    label: string;
    url: string;
  }[] = [];

  let roleIndex = 0;

  const activeRoleTabNames: string[] = [];

  if (type === 'teamsearch') {
    const activeRoles = mission.roles.filter(
      (role) => role.status === MissionRoleStatus.Active && role.user,
    );

    for (const role of activeRoles) {
      const filters: RoleFilter = {};
      filters.keyword = role.user?.email;
      filters.includeNotAvailable = true;
      const newUrl = queryString.stringify(filters as StringifiableRecord, {
        arrayFormat: 'bracket',
      });

      const label = `${roleIndex + 1}: ${role.user?.fullName}`;
      activeRoleTabNames.push(label);

      tabs.push({
        label,
        url: newUrl,
      });

      roleIndex++;
    }
  }

  for await (const role of rolesToOpen) {
    for await (const filteredRole of await filterVerifiedSkillsFromRoles([
      role,
    ])) {
      const filters: RoleFilter = {};

      filters.specializations = [filteredRole?.category.cid];
      filters.preferredIndustries = mission.industries?.map((e) => e.id);

      filters.preferredSkills = filteredRole?.preferredSkills?.map(
        (e) => e.talentSkillId,
      );

      if (type === 'outreach') {
        filters.requiredSkills = filteredRole?.requiredSkills?.map(
          (e) => `${e.talentSkillId}-3`,
        );
      } else {
        filters.requiredSkills = filteredRole?.requiredSkills?.map(
          (e) => `${e.talentSkillId}-4`,
        );
      }

      if (filteredRole?.locations) {
        filters.countries = filteredRole?.locations;
      }

      filters.weeklyHoursAvailable =
        filteredRole?.availability?.weeklyHoursAvailable;
      filters.availabilityDate = format(addWeeks(new Date(), 4), 'yyyy-MM-dd');

      filters.includeAdjacentSpecializations = true;
      filters.adjacentSpecializations = await getAdjacentSpecializations(
        auth,
        filters.specializations,
        filters.includeAdjacentSpecializations,
      );

      if (type !== 'teamsearch') {
        filters.contextMission = mission.mid;
        filters.appliedRoles = [role.rid];
        filters.excludeAppliedRoles = type !== 'review';
      }

      if (type === 'outreach') {
        filters.notifiedForRoles = [role.rid];
        filters.requiredNotificationCategories = ['MissionNotification'];
        filters.includeRichReachoutEmailType = false;
        filters.includePlainReachoutEmailType = false;
      }

      filters.hourlyRateMin = 0;

      if (filteredRole.builderRateMax) {
        filters.hourlyRateMax = Math.round(filteredRole.builderRateMax * 1.2);
      } else {
        filters.hourlyRateMax = 250;
      }

      filters.whTz = filteredRole.workingHours?.name;
      filters.whFrom = filteredRole.workingHours?.daily?.[0].startTime;
      filters.whTo = filteredRole.workingHours?.daily?.[0].endTime;
      filters.whOl = filteredRole.workingHours?.numberOfMinutesOverlap;

      filters.vettedATeamer = true;
      filters.residentATeamer = true;
      filters.exceptionalATeamer = true;
      filters.unvetted = true;
      filters.vettingScheduled = true;
      filters.vettingInterviewDate = true;

      filters.includeExceptional = true;
      filters.includeVerified = true;

      const newUrl = queryString.stringify(filters as StringifiableRecord, {
        arrayFormat: 'bracket',
      });

      tabs.push({
        label: `${roleIndex + 1}: ${role.category.title}`,
        url: newUrl,
      });

      roleIndex++;
    }
  }

  return { tabs, activeRoleTabNames, mission };
};

export const getTabsFromAutomatedReachoutMissionPreview = (
  roles: MissionPreviewRoleDto[],
  mid: string,
): TabFromMission[] => {
  return roles.map((role, idx) => {
    const roleFilters = v1SearchUserFilterApiToSearchCriteria(
      role.filters,
      mid,
    );
    const newUrl = queryString.stringify(roleFilters as StringifiableRecord, {
      arrayFormat: 'bracket',
    });

    return {
      label: `${idx + 1}: ${role.roleCategory}`,
      url: newUrl,
    };
  });
};

export const onApplicationPillBulkMenuClick = async (
  withMissionApplicationStatusV2: boolean,
  currentMission: Mission | undefined,
  missions: MissionsStore,
  applications: AdminMissionApplicationObject[] | null | undefined,
  filteredApplications: AdminMissionApplicationObject[] | null | undefined,
  unionLowCompetitiveness:
    | Map<MissionApplicationLowCompetitivenessReason, number>
    | undefined,
  unionRejectionReason:
    | Map<MissionApplicationRejectionReason, number>
    | undefined,
  unionNotSelected:
    | Map<MissionApplicationReviewStatusNotSelected, number>
    | undefined,
  unionOpportunityToUpdate:
    | Map<MissionApplicationReviewStatusOpportunityToUpdate, number>
    | undefined,
  unionWaitlisted:
    | Map<MissionApplicationReviewStatusWaitlisted, number>
    | undefined,
  unionOther:
    | Map<MissionApplicationReviewStatusOther | ProposedMethod, number>
    | undefined,
  selectedApplicants: string[],
  setSelectedApplicants: (value: React.SetStateAction<string[]>) => void,
  application: AdminMissionApplicationObject,
  item: BulkMenuItemOld | BulkMenuItem,
  subItem?: BulkSubMenuItemOld | BulkSubMenuItem,
  checked?: boolean,
  apply?: boolean,
  setItemsToUpdate?: (
    value: React.SetStateAction<BulkMissionApplicationStatusUpdateItem[]>,
  ) => void | undefined,
) => {
  if (!currentMission) return;

  if (apply) {
    let aids = [application.aid];

    if (selectedApplicants.length > 0) {
      aids = Array.from(selectedApplicants.values());
    }

    const itemsToUpdate: BulkMissionApplicationStatusUpdateItem[] = [];

    for (const aid of aids) {
      if (withMissionApplicationStatusV2) {
        const notSelected = applications?.find((a) => a.aid === aid)
          ?.reviewStatus?.notSelected;

        const opportunityToUpdate = applications?.find((a) => a.aid === aid)
          ?.reviewStatus?.opportunityToUpdate;

        const waitlisted = applications?.find((a) => a.aid === aid)
          ?.reviewStatus?.waitlisted;

        const other = applications?.find((a) => a.aid === aid)?.reviewStatus
          ?.other;

        const proposed = applications
          ?.find((a) => a.aid === aid)
          ?.reviewStatus?.other?.includes(
            MissionApplicationReviewStatusOther.PresentedToClient,
          );

        const data: BulkMissionApplicationStatusUpdateItem = {
          aid,
        };

        const reviewStatus = {
          notSelected,
          opportunityToUpdate,
          waitlisted,
          other,
        };

        if (reviewStatus) {
          const value = (item as BulkMenuItem).value;

          switch (value) {
            case BulkMenuMainItems.NotSelected:
              if (!reviewStatus.notSelected) {
                reviewStatus.notSelected = [];
              }

              unionNotSelected?.forEach((value, key) => {
                if (value === selectedApplicants.length) {
                  reviewStatus.notSelected?.push(key);
                } else if (notSelected?.includes(key)) {
                  reviewStatus.notSelected?.push(key);
                }
              });

              if (reviewStatus.notSelected.length > 0) {
                reviewStatus.notSelected = _.uniq(reviewStatus.notSelected);
                reviewStatus.opportunityToUpdate = [];
                reviewStatus.waitlisted = [];
              }
              break;

            case BulkMenuMainItems.OpportunityToUpdate:
              if (!reviewStatus.opportunityToUpdate) {
                reviewStatus.opportunityToUpdate = [];
              }

              unionOpportunityToUpdate?.forEach((value, key) => {
                if (value === selectedApplicants.length) {
                  reviewStatus.opportunityToUpdate?.push(key);
                } else if (opportunityToUpdate?.includes(key)) {
                  reviewStatus.opportunityToUpdate?.push(key);
                }
              });

              if (reviewStatus.opportunityToUpdate.length > 0) {
                reviewStatus.opportunityToUpdate = _.uniq(
                  reviewStatus.opportunityToUpdate,
                );
                reviewStatus.waitlisted = [];
              }
              break;

            case BulkMenuMainItems.Waitlisted:
              reviewStatus.waitlisted = [];

              unionWaitlisted?.forEach((value, key) => {
                if (value === selectedApplicants.length) {
                  reviewStatus.waitlisted?.push(key);
                } else if (waitlisted?.includes(key)) {
                  reviewStatus.waitlisted?.push(key);
                }
              });

              reviewStatus.waitlisted = _.uniq(reviewStatus.waitlisted);
              break;

            case BulkMenuMainItems.Other:
              if (!reviewStatus.other) {
                reviewStatus.other = [];
              }

              unionOther?.forEach((value, key) => {
                if (value === selectedApplicants.length) {
                  reviewStatus.other?.push(
                    key as MissionApplicationReviewStatusOther,
                  );
                } else if (
                  other?.includes(key as MissionApplicationReviewStatusOther)
                ) {
                  reviewStatus.other?.push(
                    key as MissionApplicationReviewStatusOther,
                  );
                }
              });

              reviewStatus.other = _.uniq(reviewStatus.other);
              break;
          }

          data.reviewStatus = reviewStatus;
          data.proposed = proposed;
          itemsToUpdate.push(data);
        }
      } else {
        const lowCompetitiveness = applications?.find(
          (a) => a.aid === aid,
        )?.lowCompetitiveness;

        const rejectionReason = applications?.find(
          (a) => a.aid === aid,
        )?.rejectionReason;

        const data: BulkMissionApplicationStatusUpdateItem = {
          aid: aid,
          status: MissionApplicationStatus.Created,
          internalStatus: MissionApplicationInternalStatus.New,
          lowCompetitiveness,
          rejectionReason,
        };

        if ((rejectionReason?.length || 0) > 0) {
          data.internalStatus = MissionApplicationInternalStatus.Rejected;
        }

        switch (item.value) {
          case MissionApplicationInternalStatus.Interviewing:
            data.internalStatus = MissionApplicationInternalStatus.Interviewing;
            break;

          case MissionApplicationInternalStatus.ShortlistGood:
            data.status = MissionApplicationStatus.Shortlist;
            data.internalStatus =
              MissionApplicationInternalStatus.ShortlistGood;
            break;

          case MissionApplicationInternalStatus.ShortlistStrong:
            data.status = MissionApplicationStatus.Shortlist;
            data.internalStatus =
              MissionApplicationInternalStatus.ShortlistStrong;
            break;

          case MissionApplicationInternalStatus.New:
            data.lowCompetitiveness = [];

            unionLowCompetitiveness?.forEach((value, key) => {
              if (value === selectedApplicants.length) {
                data.lowCompetitiveness?.push(key);
              } else if (lowCompetitiveness?.includes(key)) {
                data.lowCompetitiveness?.push(key);
              }
            });
            break;

          case MissionApplicationInternalStatus.Rejected:
            data.internalStatus = MissionApplicationInternalStatus.Rejected;

            data.rejectionReason = [];

            unionRejectionReason?.forEach((value, key) => {
              if (value === selectedApplicants.length) {
                data.rejectionReason?.push(key);
              } else if (rejectionReason?.includes(key)) {
                data.rejectionReason?.push(key);
              }
            });
            break;

          case MissionApplicationInternalStatus.NotAvailable:
            data.internalStatus = MissionApplicationInternalStatus.NotAvailable;
            break;
        }

        itemsToUpdate.push(data);
      }
    }

    if (setItemsToUpdate) {
      setItemsToUpdate(
        (prevValue: BulkMissionApplicationStatusUpdateItem[]) => {
          // Filter out items with aid values in the aids array
          const filteredItems = prevValue.filter(
            (item) => itemsToUpdate.findIndex((i) => i.aid === item.aid) < 0,
          );

          // Return the filtered items combined with new items
          return [...filteredItems, ...itemsToUpdate];
        },
      );
      setSelectedApplicants([]);
    } else {
      const resList = await currentMission.bulkUpdateMissionApplicationStatus({
        itemsToUpdate,
      });

      for (const res of resList) {
        missions.currentApplication?.application?.setApplicationStatus(res);
      }

      setSelectedApplicants([]);
    }
  } else {
    if (subItem && selectedApplicants && selectedApplicants.length > 0) {
      const selectedApplications = filteredApplications?.filter((a) =>
        selectedApplicants.includes(a.aid),
      );

      selectedApplications?.forEach((a) => {
        if (withMissionApplicationStatusV2) {
          const value = (item as BulkMenuItem).value;

          if (!a.reviewStatus) {
            a.reviewStatus = {
              notSelected: [],
              opportunityToUpdate: [],
              waitlisted: [],
              other: [],
            };
          }

          if (checked) {
            switch (value) {
              case BulkMenuMainItems.NotSelected:
                a.reviewStatus.notSelected = [
                  ...(a.reviewStatus.notSelected || []),
                  subItem.value,
                ] as MissionApplicationReviewStatusNotSelected[];
                break;

              case BulkMenuMainItems.OpportunityToUpdate:
                a.reviewStatus.opportunityToUpdate = [
                  ...(a.reviewStatus.opportunityToUpdate || []),
                  subItem.value,
                ] as MissionApplicationReviewStatusOpportunityToUpdate[];
                break;

              case BulkMenuMainItems.Waitlisted:
                a.reviewStatus.waitlisted = [
                  ...(a.reviewStatus.waitlisted || []),
                  subItem.value,
                ] as MissionApplicationReviewStatusWaitlisted[];
                break;

              case BulkMenuMainItems.Other:
                a.reviewStatus.other = [
                  ...(a.reviewStatus.other || []),
                  subItem.value,
                ] as MissionApplicationReviewStatusOther[];
                break;
            }
          } else {
            switch (value) {
              case BulkMenuMainItems.NotSelected:
                a.reviewStatus.notSelected = (
                  a.reviewStatus.notSelected || []
                ).filter((lc) => lc !== subItem.value);
                break;

              case BulkMenuMainItems.OpportunityToUpdate:
                a.reviewStatus.opportunityToUpdate = (
                  a.reviewStatus.opportunityToUpdate || []
                ).filter((lc) => lc !== subItem.value);
                break;

              case BulkMenuMainItems.Waitlisted:
                a.reviewStatus.waitlisted = (
                  a.reviewStatus.waitlisted || []
                ).filter((lc) => lc !== subItem.value);
                break;

              case BulkMenuMainItems.Other:
                a.reviewStatus.other = (a.reviewStatus.other || []).filter(
                  (lc) => lc !== subItem.value,
                );
                break;
            }
          }
        } else {
          if (checked) {
            if (item.value === MissionApplicationInternalStatus.Rejected) {
              a.rejectionReason = [
                ...(a.rejectionReason || []),
                subItem.value,
              ] as MissionApplicationRejectionReason[];
            } else {
              a.lowCompetitiveness = [
                ...(a.lowCompetitiveness || []),
                subItem.value,
              ] as MissionApplicationLowCompetitivenessReason[];
            }
          } else {
            if (item.value === MissionApplicationInternalStatus.Rejected) {
              a.rejectionReason = (a.rejectionReason || []).filter(
                (lc) => lc !== subItem.value,
              );
            } else {
              a.lowCompetitiveness = (a.lowCompetitiveness || []).filter(
                (lc) => lc !== subItem.value,
              );
            }
          }
        }
      });

      setSelectedApplicants((prevValue) => [...prevValue]);
    }
  }
};
