import React from 'react';
import {
  DidDesignedProductsFromScratch,
  DidDesignedProductsFromScratchLabels,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { InputContainer } from '../components/input-container';
import { InputLabel } from '../components/input-label';
import { RadioGroup } from '../components/radio-group';
import { Item, OnRadioGroupChange } from '@src/components/RadioGroup';

export type OnDesignedProductsFromScratchChange = (
  didDesignedProductsFromScratch?: DidDesignedProductsFromScratch,
) => void;

export interface DesignedProductsFromScratchProps {
  value?: DidDesignedProductsFromScratch;
  onChange?: OnDesignedProductsFromScratchChange;
  error?: string;
  className?: string;
  'data-testing-id'?: string;
}

const items: Item[] = Object.entries(DidDesignedProductsFromScratchLabels).map(
  ([key, value]) => {
    return { label: value, value: key };
  },
);

export const DesignedProductsFromScratchSelect: React.FC<
  DesignedProductsFromScratchProps
> = (props) => {
  const onChange: OnRadioGroupChange = (item) => {
    if (item.value === props.value) {
      props.onChange?.(undefined);
      return;
    }

    props.onChange?.(item.value);
  };

  return (
    <InputContainer error={props.error} className={props.className}>
      <InputLabel>Have you ever designed a product from 0 to 1?</InputLabel>

      <RadioGroup
        selectedValue={props.value}
        items={items}
        onChange={onChange}
        data-testing-id={props['data-testing-id']}
      />
    </InputContainer>
  );
};
