import { Input, Select, SelectOption } from '@a_team/ui-components';
import React from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { FormValues } from '.';

interface RoleProps {
  register: UseFormRegister<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  roles: SelectOption[];
  jobRoleId?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  label: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  input: {
    width: '100%',
    marginTop: 0,
    border: '1px solid #DADADC !important',
  },
});

const Role = ({
  register,
  setValue,
  trigger,
  roles,
  jobRoleId,
}: RoleProps): JSX.Element => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <label className={styles.label}>Your title and specialization</label>
      <div className={styles.wrapper}>
        <Input
          {...register('jobTitle')}
          size="stretch"
          placeholder="Role name"
          className={styles.input}
        />
        <Select
          styles={{
            input: (base) => ({
              ...base,
              width: '100%',
            }),
            control: (base) => ({
              ...base,
              width: '100%',
              border: '1px solid #DADADC',
              borderColor: '#DADADC !important',
              backgroundColor: 'white',
            }),
            placeholder: (base) => ({
              ...base,
              color: '#62646A',
            }),
          }}
          {...register('jobRoleId')}
          placeholder="Select specialization"
          options={roles}
          defaultValue={roles.find((role) => role.value === jobRoleId)}
          onChange={(data) => {
            setValue('jobRoleId', (data as SelectOption).value, {
              shouldDirty: true,
            });
            trigger('jobRoleId');
          }}
        />
      </div>
    </div>
  );
};

export default Role;
