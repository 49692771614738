import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { theme } from '@a_team/ui-components';
import {
  BorderRadius,
  Colors,
  TextColors,
  TimezoneSelect,
} from '@ateams/components';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import {
  DailyHoursRange,
  WeeklyHours,
  WeeklyHoursRanges,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { DailyHoursInput } from './daily-hours-input';

export type OnWeeklyHoursChange = (weeklyHours: Partial<WeeklyHours>) => void;

interface WeeklyHoursInputProps {
  defaultValue?: WeeklyHours;
  onChange?: OnWeeklyHoursChange;
  timezoneError?: string;
  onTimezoneBlur?: () => void;
  weeklyHoursErrors?: string;
  className?: string;
  disabled?: boolean;
  readonly?: boolean;
  'data-testing-id'?: string;
}

const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${Colors.regular}`,
    borderRadius: '4px',
  },
  timezoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 24px',
    borderBottom: `1px solid ${Colors.regular}`,
  },
  timezoneLabel: {
    fontSize: '16px',
    fontWeight: 600,
  },
  timezoneSelect: {
    marginBottom: '6px',
  },
  daysContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 24px',
    paddingBottom: 0,
  },
  weeklyHoursLabel: {
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '8px',
  },
  errorLabel: {
    color: TextColors.dangerLight,
    fontSize: '14px',
  },
  weeklyHoursErrorContainer: {
    width: '100%',
    borderRadius: BorderRadius.medium,
    backgroundColor: theme.colors.Red[100],
    padding: '10px',
  },
});

export const WeeklyHoursInput: React.FC<WeeklyHoursInputProps> = (props) => {
  const styles = useStyles();
  const [timezone, setTimezone] = useState<TimezoneObject | undefined>(
    props.defaultValue?.timezone,
  );
  const [hoursRanges, setHoursRanges] = useState<WeeklyHoursRanges>(
    props.defaultValue?.hoursRanges ||
      (new Array(DAYS.length).fill(null) as WeeklyHoursRanges),
  );

  const onTimezoneChange = (timezone?: TimezoneObject) => {
    setTimezone(timezone);
    props.onChange?.({ timezone, hoursRanges });
  };

  const onDailyHoursChange = (dailyHours: DailyHoursRange, index: number) => {
    const newHoursRanges = [
      ...hoursRanges.slice(0, index),
      dailyHours,
      ...hoursRanges.slice(index + 1),
    ] as WeeklyHoursRanges;

    setHoursRanges(newHoursRanges);
    props.onChange?.({ timezone, hoursRanges: newHoursRanges });
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <div className={styles.timezoneContainer}>
        <div className={styles.timezoneLabel}>Time Zone</div>
        <TimezoneSelect
          className={styles.timezoneSelect}
          value={timezone}
          onChange={onTimezoneChange}
          disabled={props.disabled || props.readonly}
          readonly={props.readonly}
          error={Boolean(props.timezoneError)}
          onBlur={props.onTimezoneBlur}
          data-testing-id={`${props['data-testing-id']}-timezone-select`}
        />

        {props.timezoneError && (
          <div className={styles.errorLabel}>{props.timezoneError}</div>
        )}
      </div>

      <div className={styles.daysContainer}>
        <div className={styles.weeklyHoursLabel}>Set your weekly hours</div>

        {props.weeklyHoursErrors && (
          <div className={styles.weeklyHoursErrorContainer}>
            <div className={styles.errorLabel}>{props.weeklyHoursErrors}</div>
          </div>
        )}

        {DAYS.map((day, index) => (
          <DailyHoursInput
            day={day}
            defaultValue={hoursRanges[index]}
            onChange={(dailyHours) => onDailyHoursChange(dailyHours, index)}
            disabled={props.disabled}
            readonly={props.readonly}
            key={day}
            data-testing-id={`${props['data-testing-id']}-daily-hours-input-${index}`}
          />
        ))}
      </div>
    </div>
  );
};
