import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { RadioGroup as DefaultRadioGroup } from '@src/components/RadioGroup';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const RadioGroup: typeof DefaultRadioGroup = (props) => {
  const styles = useStyles();

  return (
    <DefaultRadioGroup
      {...props}
      className={cx(styles.container, props.className)}
    />
  );
};
