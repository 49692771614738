import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { stringify, StringifiableRecord } from 'query-string';
import { UserUsername } from '@a_team/models/dist/UserObject';
import {
  Checkbox,
  Colors,
  Icon,
  IconType,
  TextColors,
} from '@ateams/components';
import TextButton from '@src/components/TextButton';
import useLoadingState from '@src/hooks/useLoadingState';
import LoadingIndicator from '@src/components/LoadingIndicator';
import Tooltip from '@src/components/Tooltip';
import RoleFilters from '@src/components/TeamGraphBuilderQuery';
import TargeterCardList from '@src/views/SkillTargeter/SearchView/TargeterCardList';
import { useStores } from '@src/stores';
import {
  SelectableBuilder,
  TargeterSearchCache,
  parseTargeterQuery,
} from '@src/stores/TeamGraph/TargeterTabManager';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import {
  mapTargeterApiRequest,
  mapTargeterApiRankingParamsRequest,
  ElasticTargeterQueryResult,
  getElasticTargeterSearch,
} from './utils';
import { Pagination } from '@src/components/Pagination';
import { SearchKeywordBox } from '@src/views/SkillTargeter/SearchView/SearchKeywordBox';
import FilterGroupContainer from '@src/components/TeamGraphBuilderQuery/FilterGroupContainer';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';

interface Props {
  builders: SelectableBuilder[];
  selectedBuilders: TargeterSearchCache['selectedBuilders'];
  onQueryChange: (query: string) => void;
  onSearchStart: (searchKey: string) => void;
  onSearchComplete: (
    searchKey: string,
    result: ElasticTargeterQueryResult<SelectableBuilder>,
  ) => void;
  onBuilderSelect: (users: UserUsername[], selected?: boolean) => void;
  query?: string;
  metadata?: TargeterSearchCache['metadata'];
}

const useStyles = createUseStyles({
  textButton: {
    color: TextColors.primaryLight,
  },
  textButtonContainer: {
    marginTop: '0.5em',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  content: {
    background: `#F7F7F7`,
    flex: 1,
    display: 'flex',
  },
  sidebar: {
    width: '28em',
    padding: '1em',
    boxShadow: `5px 0 5px ${Colors.regularLight}`,
  },
  targeterTips: {
    color: TextColors.regularLight,
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchBar: {
    display: 'flex',
    alignItems: 'baseline',
  },
  filterContainer: {
    marginTop: 24,
    // add virtual space to make sure the last item are not hidden
    marginBottom: '10rem',
  },
  integerInput: {
    margin: 0,
    '& > div': {
      borderRadius: 4,
      minHeight: 'unset',
      padding: '0.6em 0.5em',
    },
  },
  results: {
    padding: '1em',
    paddingBottom: '3em',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '80vh',
  },
  paginationContainer: {
    width: '100%',
    padding: '0 10px',

    /** Sticky Footer */
    position: 'sticky',
    bottom: 0,
    left: 0,
    background: Colors.backgroundLight,

    /** {@link https://stackoverflow.com/questions/41882616/why-border-is-not-visible-with-position-sticky-when-background-color-exists} */
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      borderTop: `1px solid ${Colors.regular}`,
    },
  },
});

const TargeterSearchView = (props: Props): ReactElement => {
  const styles = useStyles();
  const { auth, users } = useStores();
  const {
    builders,
    selectedBuilders,
    onSearchStart,
    onSearchComplete,
    onBuilderSelect,
    onQueryChange,
    query,
    metadata,
  } = props;

  const [loading, setLoading] = useLoadingState();
  const keywordRef = useRef<HTMLDivElement | null>(null);
  const semanticSearchRef = useRef<HTMLDivElement | null>(null);
  const [filter, setFilter] = useState<RoleFilter>({});

  useEffect(() => {
    const roleFilter = parseTargeterQuery(query);
    if (keywordRef && keywordRef.current) {
      keywordRef.current.innerText = String(roleFilter.keyword ?? '');
    }
    setFilter(roleFilter);
    builders.length === 0 && search(roleFilter);
  }, [query]);

  const updateFilter = (roleFilter: RoleFilter) => {
    const filterQuery = stringify(roleFilter as StringifiableRecord, {
      arrayFormat: 'bracket',
    });

    onQueryChange(filterQuery);
  };

  const search = async (roleFilter: RoleFilter, loadMore?: boolean) => {
    setLoading(false);

    const criteria = mapTargeterApiRequest(roleFilter);

    const rankingParams = mapTargeterApiRankingParamsRequest(roleFilter);

    const searchKey = String(Date.now());
    onSearchStart(searchKey);

    const fetchingBuilders = getElasticTargeterSearch({
      auth,
      criteria,
      rankingParams,
    }).then((results) => {
      onSearchComplete(searchKey, {
        items: results.items,
        metadata: results?.metadata,
      });
    });

    setLoading(fetchingBuilders, 'Fetched builders.');
  };

  const clearAllFilters = (): void => updateFilter({});
  const {
    pageSize = 0,
    totalPages = 0,
    totalRecords = 0,
    currentPage = 0,
  } = metadata || {};

  const onPageClick = async (page: number) => {
    if (page === (filter.page || currentPage)) {
      return;
    }
    updateFilter({ ...filter, page });
    users.trackTargeterResultPageChanged(currentPage, page);
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.sidebar}>
          <div className={styles.targeterTips}>
            <span>Not sure what it all means?</span>

            <Tooltip
              label={'See the Targeter documentation'}
              style={{ marginTop: -20 }}
            >
              <a
                href="https://ateams.notion.site/TeamGraph-Targeter-38090f2fdac842ff9798b86e429157a3"
                target="_blank"
                rel="noreferrer"
              >
                <Icon type={IconType.Info} style={{ cursor: 'pointer' }} />
              </a>
            </Tooltip>
          </div>

          <FilterGroupContainer
            sectionTitle={'Semantic Search'}
            clearCallBack={() => {
              updateFilter({
                ...filter,
                semanticSearchQuery: undefined,
                semanticResults: undefined,
                searchType: undefined,
              });
            }}
          >
            <SearchKeywordBox
              keywordRef={semanticSearchRef}
              value={filter.semanticSearchQuery}
              placeholder="Semantic search..."
              updateFilter={(query) =>
                updateFilter({ ...filter, semanticSearchQuery: query })
              }
            />
            <OutlinedInput
              type="number"
              label="Builder count from semantic search"
              className={styles.integerInput}
              onBlur={(e) =>
                updateFilter({
                  ...filter,
                  semanticResults: Number.isInteger(parseInt(e.target.value))
                    ? parseInt(e.target.value)
                    : undefined,
                })
              }
              onChange={(e) =>
                updateFilter({
                  ...filter,
                  semanticResults: Number.isInteger(parseInt(e.target.value))
                    ? parseInt(e.target.value)
                    : undefined,
                })
              }
              value={filter.semanticResults || 150}
            />
            <Checkbox
              onChange={(e) => {
                updateFilter({
                  ...filter,
                  searchType: e.target.checked ? 'semanticRaw' : undefined,
                });
              }}
              checked={filter?.searchType === 'semanticRaw'}
              label="Ignore targeter filters (pure semantic search)"
            />
          </FilterGroupContainer>

          <FilterGroupContainer
            sectionTitle={'Keyword Search'}
            clearCallBack={() => {
              updateFilter({
                ...filter,
                keyword: undefined,
                useKeywordForSorting: undefined,
              });
            }}
          >
            <div className={styles.searchBar}>
              <SearchKeywordBox
                keywordRef={keywordRef}
                value={filter.keyword}
                updateFilter={(keyword) => updateFilter({ ...filter, keyword })}
              />
            </div>
            <Checkbox
              margin={'none'}
              onChange={(e) => {
                updateFilter({
                  ...filter,
                  useKeywordForSorting: e.target.checked ? true : undefined,
                });
              }}
              checked={filter?.useKeywordForSorting !== undefined}
              label="Use query for sorting"
            />
          </FilterGroupContainer>

          <div className={styles.textButtonContainer}>
            <TextButton className={styles.textButton} onClick={clearAllFilters}>
              Reset all filters
            </TextButton>
          </div>

          <div className={styles.filterContainer}>
            <RoleFilters
              sidebar
              builders={builders}
              filter={filter}
              setFilterValue={(update: Partial<RoleFilter>) =>
                updateFilter({ ...filter, ...update, page: undefined })
              }
            />
          </div>
        </div>

        <div className={styles.results}>
          <div
            style={{
              minHeight: '98%',
            }}
          >
            <TargeterCardList
              filter={filter}
              builders={builders}
              metadata={metadata}
              selectedBuilderCount={selectedBuilders.length}
              clearSelectedBuilders={() =>
                onBuilderSelect(
                  selectedBuilders.map(({ user }) => user.username),
                  false,
                )
              }
              handleBuilderSelect={(user, selected) =>
                onBuilderSelect([user], selected)
              }
              clearFilters={clearAllFilters}
            />
          </div>
          {totalPages > 1 && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageBatchSize={pageSize}
                recordsCount={totalRecords}
                pageSizeOptions={undefined}
                onPageBatchSizeChange={undefined}
                showRecordsCount={true}
                currentPage={filter.page || currentPage}
                pagesCount={totalPages}
                onPageClick={onPageClick}
              />
            </div>
          )}
        </div>
      </div>

      <LoadingIndicator loading={loading} />
    </>
  );
};

export default TargeterSearchView;
