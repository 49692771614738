import React, { ReactElement } from 'react';
import { FormData } from '../FormData';
import SectionHeading from '@src/components/SectionHeading';
import DropdownInput from '@src/components/Inputs/DropdownInput';
import {
  MissionAdminObject,
  MissionApplyStatus,
  MissionApplyStatusLabels,
  MissionBillingPeriod,
  MissionInvoicingPeriod,
  MissionStatus,
} from '@a_team/models/dist/MissionObject';
import MissionManagersSelector from '@src/views/Mission/EditMission/MissionManagersSelector';
import TextButton from '@src/components/TextButton';
import { CreateCompanyUser } from '@src/views/AdminDashboard/AdminTeamwork/CreateCompanyUser/CreateCompanyUser';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import LabeledCheckboxInput from '@src/components/Inputs/LabeledCheckboxInput';
import { Colors, Divider, SelectOption, Tag } from '@ateams/components';
import useToggle from '@src/hooks/useToggle';
import { SetLoading } from '@src/hooks/useLoadingState';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import { InvoiceGreetingInput } from '@src/views/Mission/EditMission/AdminSettings/invoiceGreetingInput';
import { BasicUserObject } from '@a_team/models/dist/UserObject';
import { AdminUserSelector } from '@src/views/Mission/EditMission/AdminSettings/AdminUserSelector';
import { IS_SANDBOX } from '@src/config';
import { AdminBillingAddress } from './BillingAddress';
import { BillingPaymentDue } from '@a_team/models/dist/BillingAccount';
import ClientUserList from './ClientUsersList';

interface Props {
  setData: (data: FormData) => void;
  data: FormData;
  setLoading: SetLoading;
  isEditMode?: boolean;
}

const useStyles = createUseStyles({
  titleSection: {
    marginTop: '88px',
    width: 'calc(100vw - 32px)',
  },
  label: {
    marginTop: 0,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    titleSection: {
      marginTop: '0',
      width: 'auto',
    },
  },
});

const AdminSettings = (props: Props): ReactElement => {
  const styles = useStyles();
  const { data, setData, setLoading, isEditMode = true } = props;
  const [createCompanyUserOpen, toggleCreateCompanyUserOpen] = useToggle(false);
  const { missions, users } = useStores();
  const orgData = isEditMode ? missions.current : null;

  const adminMission = orgData as MissionAdminObject | null;
  const billingInfo = adminMission?.billingAccount?.billingInfo;

  const stores = useStores();
  const { auth } = stores;

  const queryUsers = async (query: string): Promise<SelectOption[]> => {
    const res = await users.adminQueryByString(query, {
      queryOnlyAdminUsers: true,
    });
    return res.map((user) => {
      return {
        ...user,
        label: user.fullName,
        value: user.username,
      };
    });
  };

  return (
    <div className={styles.titleSection}>
      <SectionHeading isFirst>Mission Settings</SectionHeading>
      <div>
        Change the status and make adjustments to this mission as needed. The
        FormationTeam will automatically be notified when the mission is edited,
        roles are added/removed, or if a role has changed.
      </div>
      <SectionHeading>Mission Status</SectionHeading>
      <DropdownInput
        placeholder="Select Status"
        value={data.status}
        required
        onChange={(e): void => {
          setData({
            ...data,
            status: e.target.value as MissionStatus,
          });
          missions.setSelectedStatus(e.target.value as MissionStatus);
        }}
        width="fixed"
      >
        {missions.availableMissionStatusOptions.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </DropdownInput>
      {data.status === MissionStatus.ScheduledToEnd && (
        <Tag square color="info">
          When ScheduledToEnd is selected the mission and its roles will
          automatically be marked as Ended at the end of the current payment
          cycle, up until then builders will still be able to fill hours and
          access the mission.
        </Tag>
      )}
      <SectionHeading>Apply Settings</SectionHeading>
      <div>
        Change the apply status will send notification to all current mission
        applications. Please make sure all application are with the right
        status.
      </div>
      <DropdownInput
        placeholder="Apply Status"
        value={data.applyStatus}
        required
        onChange={(e): void =>
          setData({
            ...data,
            applyStatus: e.target.value as MissionApplyStatus,
          })
        }
        width="fixed"
      >
        {Object.values(MissionApplyStatus).map((status) => (
          <option key={status} value={status}>
            {MissionApplyStatusLabels[status]}
          </option>
        ))}
      </DropdownInput>
      <SectionHeading>Timesheet Periods</SectionHeading>
      <p>
        - Weekly - Monday to Sunday <br />- Bi-Weekly - On the date of 15 and
        the end of month <br />- Monthly - On the end of month
      </p>
      <DropdownInput
        placeholder="Select Period"
        value={data.billingPeriod}
        required
        onChange={(e): void =>
          setData({
            ...data,
            billingPeriod: e.target.value as MissionBillingPeriod,
          })
        }
        width="fixed"
      >
        {IS_SANDBOX && (
          <option value={MissionBillingPeriod.Daily}>Daily (Test)</option>
        )}
        <option value={MissionBillingPeriod.Weekly}>Weekly</option>
        <option value={MissionBillingPeriod.BiWeekly}>Bi-Weekly</option>
        <option value={MissionBillingPeriod.Monthly}>Monthly</option>
      </DropdownInput>
      <SectionHeading>Automatic Invoicing</SectionHeading>
      <p>
        - Bi-Weekly - On the dates 4th and 20th
        <br />- Monthly - On the 4th of month
      </p>
      <DropdownInput
        value={data.automaticInvoicingPeriod || ''}
        required
        // disabled until we are ready for automated invoicing.
        disabled={true}
        onChange={(e): void =>
          setData({
            ...data,
            automaticInvoicingPeriod:
              (e.target.value as MissionInvoicingPeriod) || null,
          })
        }
        width="fixed"
      >
        <option value={''}>Disabled</option>
        <option value={MissionInvoicingPeriod.BiWeekly}>Bi-Weekly</option>
        <option value={MissionInvoicingPeriod.Monthly}>Monthly</option>
      </DropdownInput>
      <SectionHeading>
        TFS Owner{' '}
        {data.owner?.username && (
          <div
            onClick={() => {
              setData({ ...data, owner: undefined });
            }}
            style={{
              display: 'inline-block',
              fontSize: '12px',
              marginLeft: '0.5em',
              cursor: 'pointer',
              color: Colors.primaryDark,
            }}
          >
            Clear
          </div>
        )}
      </SectionHeading>
      <AdminUserSelector
        loadOptions={queryUsers}
        value={{
          ...data.owner,
          label: data.owner?.fullName,
          value: data.owner?.username,
        }}
        onChange={(option) => {
          setData({
            ...data,
            owner: option as unknown as BasicUserObject,
          });
        }}
      />

      <SectionHeading>Mission Clients</SectionHeading>

      {auth.withClientUserMgmt && (
        <ClientUserList
          managers={data.managers}
          workspaceId={adminMission?.accountId}
          missionSpecId={adminMission?.missionSpecId}
        />
      )}

      {!auth.withClientUserMgmt && (
        <>
          <p>Select the clients for this mission</p>
          <MissionManagersSelector
            managers={data.managers}
            mainManagerUsername={data.mainManagerUsername}
            onChange={(managers, mainManagerUsername?: string): void => {
              setData({ ...data, ...{ managers, mainManagerUsername } });
            }}
          />
          <TextButton
            color={'success'}
            onClick={toggleCreateCompanyUserOpen}
            style={{ marginTop: 8 }}
          >
            {createCompanyUserOpen ? '-' : '+'} Create company user
          </TextButton>
          <div
            style={{
              maxHeight: createCompanyUserOpen ? '500px' : 0,
              marginTop: createCompanyUserOpen ? 16 : 0,
              overflow: 'hidden',
              transition: 'all 0.5s',
            }}
          >
            <CreateCompanyUser
              onCreateUser={missions.createCompanyUser}
              setLoading={setLoading}
            />
          </div>
          <SectionHeading>Invoice greeting</SectionHeading>
          <p>
            This will be the first line of the invoice emails clients receive
          </p>
          <InvoiceGreetingInput
            managers={data.managers}
            value={data.invoiceEmailGreeting}
            onChange={(e): void =>
              setData({
                ...data,
                invoiceEmailGreeting: e.target.value,
              })
            }
          />
        </>
      )}

      <SectionHeading>Invoice P.O.</SectionHeading>
      <p>
        If defined, the purchase order number will be referenced throughout the
        mission invoices with the client.
      </p>
      <OutlinedInput
        placeholder="Enter P.O. number"
        value={data.invoicing?.purchaseOrderNumber ?? ''}
        onChange={(e): void =>
          setData({
            ...data,
            invoicing: {
              ...data.invoicing,
              purchaseOrderNumber: e.target.value || undefined,
            },
          })
        }
      />

      <SectionHeading>Margin from A.Teamers (hidden)</SectionHeading>
      <OutlinedInput
        value={data.rolesMargin ? data.rolesMargin * 100 : undefined}
        type="number"
        onChange={(e): void =>
          setData({
            ...data,
            rolesMargin: e.target.value
              ? Number(e.target.value) / 100
              : undefined,
          })
        }
        placeholder="0"
        precursor="%"
        style={{ width: '91px' }}
      />
      <SectionHeading>Margin from Billing Client (visible)</SectionHeading>
      <OutlinedInput
        value={
          data.clientMargin
            ? parseFloat((data.clientMargin * 100).toFixed(2))
            : undefined
        }
        type="number"
        onChange={(e): void =>
          setData({
            ...data,
            clientMargin: e.target.value
              ? Number(e.target.value) / 100
              : undefined,
          })
        }
        placeholder="0"
        precursor="%"
        style={{ width: '91px' }}
      />

      {!!billingInfo && (
        <>
          <SectionHeading>Billing Address</SectionHeading>
          <p>
            This address will be used for all invoices sent to the billing
            customer. It's stored both on Stripe customers and the client
            customer entity and can be updated by the client.
          </p>
          <AdminBillingAddress
            value={data.billingInfo?.address || billingInfo.address}
            onChange={(address) =>
              setData({ ...data, billingInfo: { ...billingInfo, address } })
            }
          />
        </>
      )}

      <SectionHeading>Payment Terms</SectionHeading>
      <DropdownInput
        value={data.paymentTerms?.due || 0}
        onChange={(e): void =>
          setData({
            ...data,
            paymentTerms: e.target.value
              ? {
                  due: e.target.value as BillingPaymentDue,
                }
              : undefined,
          })
        }
        width="fixed"
      >
        <option value={0} disabled>
          - Default (NET 15) -
        </option>
        {Object.values(BillingPaymentDue).map((item) => (
          <option key={item} value={item}>
            {item.replace(/Net(\d+)/, 'NET $1')}
          </option>
        ))}
      </DropdownInput>

      <SectionHeading>Contracts</SectionHeading>
      <LabeledCheckboxInput
        label={
          'Generate contracts for any member and client changes for this mission'
        }
        className={styles.label}
        checked={!data.skipContracts}
        onChange={(e) =>
          setData({
            ...data,
            skipContracts: !e.target.checked,
          })
        }
      />
      <Divider />
    </div>
  );
};

export default observer(AdminSettings);
