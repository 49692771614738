import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { Card } from '@ateams/components';
import { TableRow } from '@src/components/Table/TableRow';
import cx from 'classnames';

export type TableItem = {
  id: string;
  content: ReactNode;
};

interface Props {
  headers: ReactNode[];
  headerClassName?: string;
  children: ReactNode;
  noDataText?: string;
  noMargin?: boolean;
}

const useStyles = createUseStyles({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #C0C0C0',
    borderLeft: 'none',
    borderTop: 'none',
    fontSize: 15,
    fontWeight: 'normal',
    color: '#62646A',
    padding: 32,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  noData: {
    padding: '40px',
  },
});

const Table: React.FC<Props> = ({
  headers,
  headerClassName,
  noMargin,
  noDataText,
  children,
}) => {
  const styles = useStyles();
  return (
    <Card style={{ padding: 0, margin: noMargin ? 0 : undefined }}>
      <table className={styles.table}>
        <tbody>
          <tr>
            {headers.map((header, i) => (
              <th key={i} className={cx(styles.th, headerClassName)}>
                {header}
              </th>
            ))}
          </tr>
          {children || <div className={styles.noData}>{noDataText}</div>}
        </tbody>
      </table>
    </Card>
  );
};

export default Table;
export { TableRow };
