import { ServiceAuth, ServiceEndpoint } from './utils';
import InvitationObject, {
  BasicInvitationObject,
  InvitationId,
} from '@a_team/models/dist/InvitationObject';
import { MissionRoleId } from '@a_team/models/dist/MissionRole';
import { UserExpertise, UserScrubbed } from '@a_team/models/dist/UserObject';
import { UserSpecializationAssignmentData } from './User';
import { TeamId, TeamMemberRole } from '@a_team/models/dist/TeamObject';

export const BasePath = '/invitations';

export interface InvitationRequestData extends InvitationApproveData {
  fullName: string;
  email: string;
  expertise?: UserExpertise[];
  talentSpecialization?: UserSpecializationAssignmentData;
  pictureURL?: string;
  linkedInUsername?: string;
  websiteURL?: string;
  rid?: MissionRoleId;
  autoApprove?: boolean; // require admin auth
  fromAirtable?: boolean; // invite sent via airtable
  team?: {
    // Invite to join a team
    tid?: TeamId;
    memberRole: TeamMemberRole;
    ownerEmail?: string;
    ownerProfile?: string;
    optedIn?: boolean;
  };
}

export interface InvitationApproveData {
  // TODO make scrubbed required when approving user
  scrubbed?: UserScrubbed;
}

// exported functions

export default class InvitationsEndpoint extends ServiceEndpoint {
  public getInvitation(
    id: InvitationId,
    nonce: string,
  ): Promise<InvitationObject> {
    return this.fetch(null, BasePath + `/${id}-${nonce}`);
  }

  public invite(
    auth: ServiceAuth,
    requestData: InvitationRequestData,
  ): Promise<BasicInvitationObject> {
    return this.fetch(auth, BasePath, null, 'post', requestData);
  }

  public adminApproveInvite(
    auth: ServiceAuth,
    id: InvitationId,
    data: InvitationApproveData,
  ): Promise<BasicInvitationObject> {
    return this.fetch(auth, BasePath + `/${id}/approve`, null, 'post', data);
  }
}
