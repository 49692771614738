import { CompanyPicker } from '@src/components/CompanyPicker';
import { CompanyV2Picker } from '@src/components/CompanyV2Picker';
import { useStores } from '@src/stores';
import { ExistingJob, NewJob } from '@src/stores/Profile/models';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { FormValues } from '.';

interface CompanyNameProps {
  register: UseFormRegister<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  trigger: UseFormTrigger<FormValues>;
  watch: UseFormWatch<FormValues>;
  job?: NewJob | ExistingJob;
}

const useStyles = createUseStyles({
  label: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  companyPicker: {
    height: '40px',
    border: '1px solid #DADADC !important',
  },
  companyNameWrapper: {
    '& span[class^="companyAvatarsContainer"]': {
      display: 'none',
    },
    '& input[class^="companyPickerInput"] + div + span': {
      display: 'none',
    },
    '& > span': {
      display: 'none',
    },
  },
});

const CompanyName = ({ job, setValue }: CompanyNameProps): JSX.Element => {
  const styles = useStyles();
  const [companyError] = useState('');
  const { auth } = useStores();
  const isOwner = true;

  return (
    <div>
      <div className={styles.companyNameWrapper}>
        <label className={styles.label}>Company name</label>
        {auth.withEnrichedCompaniesLinkedToExperiences ? (
          <CompanyV2Picker
            className={styles.companyPicker}
            onSelectCompanies={(companies) => {
              const company = companies[0] || null;

              setValue('companyName', company?.name ?? undefined, {
                shouldDirty: true,
              });
              setValue('companyUrl', company?.url ?? undefined);
              setValue('companyV2Id', company?.id ?? undefined, {
                shouldDirty: true,
              });
              setValue('logoURL', company?.logoUrl ?? '');
            }}
            error={companyError}
            disabled={!isOwner}
            initiallySelectedCompanyIds={
              job?.companyV2Id ? [job.companyV2Id] : []
            }
            displayName={job?.companyName ?? undefined}
            onlySearchVerified
            canAddCompany
          />
        ) : (
          <CompanyPicker
            withCompanyIcon
            inputTestingId={'profile-job-company-select'}
            className={styles.companyPicker}
            onSelectCompanies={(companies) => {
              const company = companies[0] || null;

              setValue('companyName', company?.name ?? undefined, {
                shouldDirty: true,
              });
              setValue('companyId', company?.id ?? undefined, {
                shouldDirty: true,
              });
              setValue('logoURL', company?.logoUrl ?? undefined);
            }}
            canCreateNewCompanies={true}
            error={companyError}
            autoFocus
            disabled={!isOwner}
            initiallySelectedCompanyIds={job?.companyId ? [job.companyId] : []}
          />
        )}
      </div>
    </div>
  );
};

export default observer(CompanyName);
