import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import MissionRole, {
  MissionRoleRequiredSkill,
  MissionRoleSkill,
} from '@a_team/models/dist/MissionRole';
import { SelectProps, Select, SelectOption } from '@ateams/components';
import { TalentSkillId } from '@a_team/models/dist/TalentCategories';
import { useTalentSkills } from '@src/rq/useTalentSkills';

type SkillType = 'required' | 'preferred' | 'all';

export interface SkillSelectorProps<IsMulti extends boolean = true>
  extends Omit<SelectProps<IsMulti>, 'loadOptions'> {
  className?: string;
  role?: Partial<MissionRole>;
  talentCategoryIds?: TalentSkillId[];
  placeholder?: string;
  selected?: string[];
  onChange: (
    list: IsMulti extends true ? TalentSkillId[] : TalentSkillId | null,
  ) => void;
  defaultValue?:
    | (IsMulti extends true
        ? SelectOption[] | TalentSkillId[]
        : SelectOption | TalentSkillId)
    | null;
  type: SkillType;
  disabled?: boolean;
  label?: string;
}

function getSkillsFromRole(
  role: Partial<MissionRole>,
  type: SkillType,
): SelectOption[] {
  if (!role) return [];

  let skillsToMap: (MissionRoleSkill | MissionRoleRequiredSkill)[] = [];

  switch (type) {
    case 'required':
      skillsToMap = role.requiredSkills ?? [];
      break;

    case 'preferred':
      skillsToMap = role.preferredSkills ?? [];
      break;

    case 'all':
      skillsToMap = [
        ...(role.preferredSkills ?? []),
        ...(role.requiredSkills ?? []),
      ];
      break;
  }

  return skillsToMap
    .map(({ talentSkillId, talentSkillName }) =>
      talentSkillName
        ? { value: talentSkillId, label: talentSkillName }
        : undefined,
    )
    .filter((x: SelectOption | undefined): x is SelectOption => !!x);
}

export const SkillSelector = <IsMulti extends boolean = true>(
  props: SkillSelectorProps<IsMulti>,
): ReactElement => {
  const {
    role,
    talentCategoryIds,
    defaultValue,
    onChange,
    selected,
    type,
    className,
    label,
    disabled = false,
    isMulti = true,
    ...rest
  } = props;
  const [value, setValue] = useState<SelectOption | SelectOption[] | null>(
    defaultValue &&
      typeof (Array.isArray(defaultValue) && defaultValue?.length
        ? defaultValue[0]
        : defaultValue) !== 'string'
      ? (defaultValue as SelectOption[])
      : [],
  );
  const skillsQueryResult = useTalentSkills({});

  useEffect(() => {
    if (!role || (value && value.length > 0)) return;

    const skills = getSkillsFromRole(role, type);
    setValue(skills);
  }, [role]);

  const skills = useMemo((): SelectOption[] => {
    const options = skillsQueryResult?.data
      ?.filter((item) => {
        if (item.name.match(/\[.+\]/g)) return false;

        if (talentCategoryIds?.length) {
          return talentCategoryIds.some((talentCategoryIdToFilter) =>
            item.talentCategoryIds.includes(talentCategoryIdToFilter),
          );
        }
        return true;
      })
      .map((skill) => ({
        value: skill.id,
        label: skill.name,
        selected: selected?.includes(skill.id),
      }));

    return options || [];
  }, [skillsQueryResult, selected, talentCategoryIds]);

  const onSkillsSelected = (values: SelectOption | SelectOption[] | null) => {
    setValue(values);

    if (isMulti) {
      const selectedSkillIds: string[] = values
        ? values
            .map((option: SelectOption) => option.value)
            .concat(selected || [])
        : selected;
      onChange(selectedSkillIds as IsMulti extends true ? string[] : string);
    } else {
      onChange(
        (values as SelectOption | null)?.value as IsMulti extends true
          ? string[]
          : string,
      );
    }
  };

  const onRemoveOption = (option: SelectOption) => {
    const newValues = (value as SelectOption[]).filter(
      (o) => o.value !== option.value,
    );
    setValue(newValues);
    const selectedSkillIds = newValues
      ? newValues
          .map((option: SelectOption) => option.value)
          .concat(selected || [])
      : selected;
    onChange(selectedSkillIds as IsMulti extends true ? string[] : string);
  };

  return (
    <Select
      disabled={disabled}
      value={typeof props.value === 'undefined' ? value : props.value}
      options={skills}
      onChange={onSkillsSelected}
      onRemoveOption={onRemoveOption}
      className={className}
      isMulti={isMulti}
      placeholder={props.placeholder || 'Add skills...'}
      margin={'none'}
      label={label}
      {...rest}
    />
  );
};
