import React, { ReactElement } from 'react';
import Section, {
  SectionProps,
} from '@src/views/Profile/Main/partials/Section';
import { Button, IconType, Icon, Colors } from '@ateams/components';
import { createUseStyles } from 'react-jss';

interface Props {
  applicationRole: string;
  hasRole?: boolean;
  guidanceMessage?: ReactElement;
  onAddClick: () => void;
  withIcon?: boolean;
  type?: SectionProps['type'];
}

const useStyles = createUseStyles({
  button: {
    padding: '4px 10px',
    minWidth: 115,
    height: 32,
    display: 'flex',
    alignItems: 'center',
  },
  appRole: ({ hasRole }) => ({
    marginRight: 8,
    ...(!hasRole && {
      textDecoration: 'line-through',
      color: '#818388',
    }),
  }),
  add: {
    color: Colors.secondaryDark,
  },
  copy: {
    marginBottom: 0,
  },
});

const Role = (props: Props): ReactElement => {
  const {
    applicationRole,
    hasRole,
    guidanceMessage,
    onAddClick,
    withIcon,
    type,
  } = props;
  const styles = useStyles({ hasRole });

  return (
    <Section
      type={type}
      iconType={withIcon ? IconType.SuitcaseGray : undefined}
      title={'Role'}
      dataTestingId={'mission-application-role-section'}
    >
      <p className={styles.copy}>
        This opening is available to builders with a {applicationRole} role.
      </p>
      {guidanceMessage}
      <Button
        onClick={onAddClick}
        size={'small'}
        width={'auto'}
        color={hasRole ? 'backgroundDark' : 'backgroundLight'}
        squared
        dataTestingId={'mission-application-role-update-button'}
        className={styles.button}
      >
        <span className={styles.appRole}>{applicationRole}</span>
        {hasRole ? (
          <Icon
            clickable
            type={IconType.TrashBlack}
            size={'exact'}
            title={'Remove role'}
          />
        ) : (
          <span className={styles.add}>Add role</span>
        )}
      </Button>
    </Section>
  );
};

export default Role;
