import {
  TimesheetInitiativeId,
  TimesheetInitiativeObject,
  TimesheetSummaryBlurbNode,
} from '@a_team/models/src/TimesheetInitiativeObject';
import { ServiceAuth, ServiceEndpoint } from './utils';
import TimesheetObject, {
  TimesheetId,
  TimesheetRecord,
  TimesheetRecordData,
  TimesheetRecordKey,
} from '@a_team/models/dist/TimesheetObject';

export const BasePath = '/timesheets';

// exported functions

export default class TimesheetsEndpoint extends ServiceEndpoint {
  public submit(
    auth: ServiceAuth,
    sid: TimesheetId,
  ): Promise<{
    timesheet: TimesheetObject;
    didEndRole: boolean;
  }> {
    return this.fetch(auth, BasePath + `/${sid}/submit`, null, 'post');
  }

  public adminReopen(
    auth: ServiceAuth,
    sid: TimesheetId,
  ): Promise<TimesheetObject> {
    return this.fetch(auth, BasePath + `/${sid}/reopen`, null, 'post');
  }

  public setRecord(
    auth: ServiceAuth,
    sid: TimesheetId,
    key: TimesheetRecordKey,
    data: TimesheetRecordData,
  ): Promise<TimesheetRecord> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/records/${key}/set`,
      null,
      'put',
      data,
    );
  }

  public addRecord(
    auth: ServiceAuth,
    sid: TimesheetId,
    data: TimesheetRecordData,
  ): Promise<TimesheetRecord> {
    return this.fetch(auth, BasePath + `/${sid}/records`, null, 'post', data);
  }

  public updateRecord(
    auth: ServiceAuth,
    sid: TimesheetId,
    key: TimesheetRecordKey,
    data: TimesheetRecordData,
  ): Promise<TimesheetRecord> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/records/${key}`,
      null,
      'put',
      data,
    );
  }

  public setRecords(
    auth: ServiceAuth,
    sid: TimesheetId,
    data: { records: TimesheetRecordData[] },
  ): Promise<TimesheetRecord> {
    return this.fetch(auth, BasePath + `/${sid}/records`, null, 'put', data);
  }

  public deleteRecord(
    auth: ServiceAuth,
    sid: TimesheetId,
    key: TimesheetRecordKey,
  ): Promise<TimesheetRecord> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/records/${key}`,
      null,
      'delete',
    );
  }

  public deleteRecords(
    auth: ServiceAuth,
    sid: TimesheetId,
    data: { keys: TimesheetRecordKey[] },
  ): Promise<TimesheetRecord> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/records/`,
      null,
      'delete',
      data,
    );
  }

  public createTimesheetInitiativeForMission(
    auth: ServiceAuth,
    sid: TimesheetId,
    data: { name: string },
  ): Promise<TimesheetInitiativeObject> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/initiatives`,
      null,
      'post',
      data,
    );
  }

  public getTimesheetInitiativesForMission(
    auth: ServiceAuth,
    sid: TimesheetId,
  ): Promise<TimesheetInitiativeObject[]> {
    return this.fetch(auth, BasePath + `/${sid}/initiatives`, null, 'get');
  }

  public hideTimesheetInitiativeForMission(
    auth: ServiceAuth,
    sid: TimesheetId,
    siid: TimesheetInitiativeId,
  ): Promise<TimesheetRecord> {
    return this.fetch(
      auth,
      BasePath + `/${sid}/initiatives/${siid}/hide`,
      null,
      'post',
    );
  }

  public generateSummaryBlurb(
    auth: ServiceAuth,
    sid: TimesheetId,
  ): Promise<{ timesheetSummaryBlurbNodes: TimesheetSummaryBlurbNode[] }> {
    return this.fetch(auth, BasePath + `/${sid}/summary/blurb`, null, 'get');
  }

  public saveTimesheetSummary(
    auth: ServiceAuth,
    sid: TimesheetId,
    data: {
      demoLink?: string;
      summary: string;
      summaryHtml: string;
      usedMachineTextForSummary: boolean;
    },
  ): Promise<TimesheetObject> {
    return this.fetch(auth, BasePath + `/${sid}/summary`, null, 'post', data);
  }
}
