import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import MainLayout from '@src/layouts/Main';
import SearchInputV2 from './components/SearchInput/SearchInputV2';
import GrowYourNetworkBanner from './components/GrowYourNetworkBanner';
import { useSuggestedUsers } from '@src/rq/discovery';
import useIntersectionObserver from '@src/hooks/useIntersectionObserver';
import DiscoveryUserCardV2, {
  DiscoveryCardType,
} from '@src/components/DiscoveryUserCard/DiscoveryUserCardV2';
import EndOfRecommendations from './components/EndOfRecommendations';
import NoResults from './components/NoResults';
import cx from 'classnames';
import { useDismissDiscoverySuggestion } from '@src/rq/signals';
import DiscoveryUserCardSkeletonV2 from '@src/components/DiscoveryUserCard/DiscoveryUserCardSkeletonV2';
import { useSearchUsers } from '@src/rq/search';
import { DEFAULT_PROFILE_IMAGES } from '@a_team/models/dist/constants/Profile';
import { useStores } from '@src/stores';

const useStyles = createUseStyles({
  mainLayout: {},
  wrapper: {
    background: 'white',
    padding: '65px 24px 24px 24px',
  },
  title: {
    marginTop: 0,
    textAlign: 'center',
  },
  container: {
    maxWidth: 1100,
    width: '100%',
    margin: '0 auto',
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    justifyContent: 'center',
    gap: 24,
    marginTop: 0,
    marginBottom: 40,
  },
  growYourNetworkBanner: {
    gridColumn: '1 / -1',
    marginBottom: 16,
  },
  main: {
    gridColumn: '1 / -1',
  },
  discoveryCard: {
    width: '100%',
    minWidth: 300,
  },
  searchInput: {
    margin: '0 auto',
    position: 'relative',
    marginBottom: 64,
    marginTop: 24,
    width: '100%',
    maxWidth: 600,
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 600,
    textAlign: 'center',
  },
  otherMembers: {
    marginTop: 60,
  },
  [`@media (min-width: 520px)`]: {
    cardsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
    },
    wrapper: {
      padding: '65px 100px 100px 100px',
    },
  },
});

const DiscoveryV2 = (): JSX.Element => {
  const styles = useStyles();
  const { data, hasNextPage, fetchNextPage, isLoading, isFetching } =
    useSuggestedUsers();

  const [submitted, setSubmitted] = useState(false);

  const [query, setQuery] = useState('');

  const { data: searchData, isFetching: isSearching } = useSearchUsers({
    query,
    enabled: !!query && submitted,
  });

  const [dismissedBuilders, setDismissedBuilders] = useState<string[]>([]);

  const { search } = useStores();
  const { onSearchQueryChange } = search;

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  const { mutate } = useDismissDiscoverySuggestion();

  const suggestedUsers = useMemo(() => {
    const suggestions = data?.pages.flatMap(
      ({ suggestedUsers }) => suggestedUsers,
    );
    return suggestions?.filter(
      (suggestedUser) => !dismissedBuilders.includes(suggestedUser.uid),
    );
  }, [data?.pages, dismissedBuilders]);

  const searchedUsers = useMemo(() => {
    return searchData?.items
      .flat()
      .filter(
        ({ profilePictureURL }) =>
          !Object.values(DEFAULT_PROFILE_IMAGES).includes(profilePictureURL),
      );
  }, [searchData?.items]);

  useEffect(() => {
    if (
      isVisible &&
      hasNextPage &&
      !isSearching &&
      (searchedUsers?.length === 0 || !searchedUsers)
    ) {
      fetchNextPage();
    }
  }, [isVisible, hasNextPage, isSearching, searchedUsers]);

  const onDismiss = (builderId: string) => {
    mutate([builderId], {
      onError: (error) => {
        console.error(error);
      },
    });
    setDismissedBuilders((prev) => [...prev, builderId]);
  };

  const onResetSearch = () => {
    setQuery('');
    onSearchQueryChange('');
    setSubmitted(false);
  };

  return (
    <MainLayout
      className={styles.mainLayout}
      title="Discovery"
      style={{ backgroundColor: 'white', padding: 0 }}
    >
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.cardsContainer}>
            <div className={styles.main}>
              <h2 className={styles.title}>Discover new connections</h2>
              <SearchInputV2
                query={query}
                setQuery={setQuery}
                className={styles.searchInput}
                setSubmitted={setSubmitted}
                submitted={submitted}
              />
              {submitted && query ? (
                searchedUsers && searchedUsers.length > 0 ? (
                  <div className={styles.subtitle}>
                    {searchedUsers.length}{' '}
                    {searchedUsers.length === 1 ? 'Builder' : 'Builders'} found
                  </div>
                ) : (
                  <>
                    {!isSearching && (
                      <NoResults onResetSearch={onResetSearch} />
                    )}

                    {!isSearching && (suggestedUsers || []).length > 0 && (
                      <div className={cx(styles.subtitle, styles.otherMembers)}>
                        Other members you might know
                      </div>
                    )}
                  </>
                )
              ) : null}
            </div>

            {!query && (
              <GrowYourNetworkBanner className={styles.growYourNetworkBanner} />
            )}

            {submitted &&
              isSearching &&
              Array.from(Array(4).keys()).map((index) => (
                <DiscoveryUserCardSkeletonV2
                  key={index}
                  className={cx(styles.discoveryCard)}
                />
              ))}

            {!isSearching &&
              searchedUsers &&
              searchedUsers &&
              searchedUsers.map((user) => {
                return (
                  <DiscoveryUserCardV2
                    className={styles.discoveryCard}
                    type={DiscoveryCardType.Search}
                    key={user.uid}
                    suggestedUser={user}
                  />
                );
              })}

            {isSearching || (query && searchedUsers && searchedUsers.length > 0)
              ? null
              : !isLoading &&
                suggestedUsers &&
                suggestedUsers.length > 0 &&
                suggestedUsers?.map((suggestedUser) => {
                  return (
                    <DiscoveryUserCardV2
                      onDismiss={onDismiss}
                      className={styles.discoveryCard}
                      type={DiscoveryCardType.Discovered}
                      key={suggestedUser.uid}
                      suggestedUser={suggestedUser}
                    />
                  );
                })}

            {(isLoading || isFetching) &&
              Array.from(Array(8).keys()).map((index) => (
                <DiscoveryUserCardSkeletonV2
                  key={index}
                  className={cx(styles.discoveryCard)}
                />
              ))}

            <div
              style={{
                height: '1px',
                width: '100%',
              }}
              ref={ref}
            />
          </div>
          <EndOfRecommendations />
        </div>
      </div>
    </MainLayout>
  );
};

export default observer(DiscoveryV2);
