import { BuilderSuggestionType } from '@a_team/models/dist/ProfileEnhancements';
import { UserId } from '@a_team/models/dist/UserObject';
import {
  useGetAdminProfileSuggestions,
  useGetProfileSuggestions,
  usePartiallyRejectSuggestion,
} from '@src/rq/profileSuggestions';
import { NewJob } from '@src/stores/Profile/models';
import { format } from 'date-fns';
import { omit } from 'lodash';
import React from 'react';
import Job from './Job';
import AuthStore from '@src/stores/Auth';

interface SuggestedJobsProps {
  userId: UserId;
  adminOnly?: boolean;
  onAccept: (job: NewJob) => void;
  auth: AuthStore;
}
const SuggestedJobs = ({ adminOnly, onAccept, auth }: SuggestedJobsProps) => {
  const { data } = useGetProfileSuggestions();
  const { data: adminData } = useGetAdminProfileSuggestions();

  const { mutateAsync: rejectSuggestion } = usePartiallyRejectSuggestion();

  const rawSuggestions = adminOnly ? adminData : data;

  const suggestionId = rawSuggestions?.jobs?.sid;

  const jobs = rawSuggestions?.jobs?.jobs.map((job) => {
    return {
      suggestionId,
      partialSuggestionId: job.eid,
      label: job?.companyData?.name || job.companyName,
      companyLogo: job?.companyData?.logoUrl,
      subLabel: job.jobRole,
      metaData: `${
        job.startDate ? format(new Date(job.startDate), 'MM/yyyy') : ''
      } - ${
        job.endDate ? format(new Date(job.endDate), 'MM/yyyy') : 'Current'
      }`,
      type: BuilderSuggestionType.JOBS,
    };
  });

  const onDiscard = async (eid: string) => {
    if (!suggestionId) {
      return;
    }

    try {
      await rejectSuggestion({
        sid: suggestionId,
        type: BuilderSuggestionType.JOBS,
        partialSuggestionId: eid,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleAccept = (jobId: string) => {
    try {
      const acceptedJob = rawSuggestions?.jobs?.jobs.find(
        (job) => job.eid === jobId,
      );

      if (acceptedJob?.eid) {
        onAccept({
          ...omit(acceptedJob, 'eid'),
          _id: acceptedJob.eid,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {jobs?.map((job, i) => {
        return (
          <Job
            auth={auth}
            key={`${job?.suggestionId}${i}`}
            isSuggested={true}
            jobTitle={job.label || undefined}
            companyName={job.subLabel}
            companyLogo={job.companyLogo}
            period={job.metaData}
            onAcceptSuggestion={
              adminOnly
                ? undefined
                : () => handleAccept(job.partialSuggestionId)
            }
            onDiscardSuggestion={
              adminOnly ? undefined : () => onDiscard(job.partialSuggestionId)
            }
          />
        );
      })}
    </>
  );
};

export default SuggestedJobs;
