import ProjectCardV2 from '@src/components/ProjectCardV2/ProjectCardV2';
import { ApplicationStoreData } from '@src/stores/Application';
import { Expertise, LocalProjects } from '@src/stores/Profile/models';
import { getProjectId } from '@src/views/Profile/helpers/experience';
import React from 'react';
import { createUseStyles } from 'react-jss';
import CommonStyles from '../common/styles';
import { noop } from 'lodash';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';

interface OtherProjectsProps {
  projects: LocalProjects;
  hiddenProjects?: ApplicationStoreData['hiddenProjects'];
  allIndustries?: Expertise[];
  onOpenProject?: (projectId: string) => void;
  selectedProjectId: string;
}

const useStyles = createUseStyles({
  container: {
    marginTop: 64,
  },
  projects: {
    marginTop: 24,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100%, max-content))',
    gap: 40,
  },
  [`@media (min-width: 600px)`]: {
    projects: {
      gridTemplateColumns: 'repeat(auto-fit, 240px)',
    },
  },
});

const OtherProjects = ({
  projects,
  hiddenProjects,
  allIndustries,
  onOpenProject,
  selectedProjectId,
}: OtherProjectsProps): JSX.Element | null => {
  const commonStyles = CommonStyles();
  const styles = useStyles();
  const { auth } = useStores();

  return (
    <div className={styles.container}>
      <div className={commonStyles.title}>Other projects from this builder</div>
      <div className={styles.projects}>
        {projects
          .filter((project) => !project.isSuggested)
          .map((project, index) => {
            const projectId = getProjectId(project);

            const isHidden = hiddenProjects?.get(projectId) || false;

            const industry = allIndustries?.find(
              (item) =>
                item.id === project.industry || item.name === project.industry,
            );

            const industries =
              allIndustries?.filter((item) =>
                project?.industries?.includes(item.id),
              ) ?? [];

            const projectIndustries = industries.map((item) => item.name);

            return (
              <ProjectCardV2
                key={projectId}
                index={index}
                isHidden={isHidden}
                isSuggested={project.isSuggested}
                companyLogo={project.logoURL || ''}
                companyName={
                  // rm observer wrapper from component export
                  (auth.withEnrichedCompaniesLinkedToExperiences
                    ? project.companyName
                    : project.companyData?.name) || ''
                }
                projectCover={project.imagesUrls?.[0] || undefined}
                projectName={project.title}
                projectIndustry={industry?.name || ''}
                projectIndustries={projectIndustries}
                startDate={project.startDate}
                endDate={project.endDate}
                onOpenProject={() => onOpenProject && onOpenProject(projectId)}
                onEdit={noop}
                onDelete={noop}
                currentlyViewing={projectId === selectedProjectId}
                isMissingMinimumRequirements={
                  project.isMissingMinimumRequirements
                }
              />
            );
          })}
      </div>
    </div>
  );
};

export default observer(OtherProjects);
