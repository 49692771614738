import React, { AnchorHTMLAttributes, ReactElement } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { ColorName, Colors } from '@ateams/components';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  color?: ColorName;
  highlight?: boolean;
}

const useStyles = createUseStyles({
  root: {
    border: 0,
    margin: 0,
    padding: 0,
    background: 'none',
    fontSize: '1em',
    fontFamily: 'inherit',
    fontWeight: (props: Props): string | number =>
      props.highlight ? 500 : 'inherit',
    color: (props: Props): string =>
      props.highlight
        ? Colors.primary
        : props.color
        ? Colors[props.color]
        : '#62646a',
  },
});

export default function Anchor(props: Props): ReactElement {
  const { className, highlight, children, ...left } = props;
  const styles = useStyles(props);

  return (
    <a className={cx(styles.root, className)} {...left}>
      {children}
    </a>
  );
}
