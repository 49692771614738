import { TimesheetInitiativeId } from '@a_team/models/dist/TimesheetInitiativeObject';
import {
  TimesheetRecordData,
  TimesheetRecordType,
} from '@a_team/models/dist/TimesheetObject';
import { Button } from '@ateams/components';
import {
  useMutationDeleteRecords,
  useMutationSetRecords,
} from '@src/rq/timesheets';
import Mission from '@src/stores/Missions/Mission';
import {
  ColumnDef,
  RowData,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cx from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { RoleRecord } from '../../TimesheetsTable/MobileTableRow';
import { SelectableUser } from './../AdminTable';
import ClearRowDropdown from './../ClearRowDropdown';
import InitiativeSelect from './../InitiativeSelect';
import { TimeEntry } from './../TanstackTable';
import TextInput from './../TextInput';
import TimeInput, { minutesToTime } from './../TimeInput';
import TypeSelect from './../TypeSelect';
import UserTabs from './../UserTabs';
import EmptyCell from './../common/EmptyCell';
import { useCommonStyles } from './../common/commonStyles';
import { outOfOfficeTypes } from './../data';
import {
  areArraysEqualRegardlessOfOrder,
  checkIfRowIsValid,
  formatDate,
  isRowFilled,
} from './../utils';
import ConfirmModalOnLeave from './ConfirmModalOnLeave';
import useGetPaymentAndHoursForGivenPeriod from '../../hooks/useGetPaymentAndHoursForGivenPeriod';
import { useEvaluateFeatureFlag } from '@src/rq/users';
import SectionHeader from '../common/SectionHeader';
import useGetGroupedTimeByDay from '../../hooks/useGetGroupedTimeByDay';
import { FeatureFlagNames } from '@a_team/models/dist/FeatureFlag';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    updateData: (rowKey: string, columnId: string, value: unknown) => void;
    removeRow: (rowKey: string) => void;
    hourlyRate?: number;
    sid: string;
    defaultData?: TimeEntry[];
  }
}

const useStyles = createUseStyles({
  cellWithErrors: {
    borderTop: '1px solid #FE630C !important',
    borderRight: '1px solid #DADADC',
    borderBottom: '1px solid #FE630C !important',
    borderLeft: '1px solid #DADADC',
    borderCollapse: 'collapse',
  },
  firstCellWithErrors: {
    borderTop: '1px solid #FE630C',
    borderRight: '1px solid #DADADC',
    borderBottom: '1px solid #FE630C',
    borderLeft: '1px solid #FE630C',
    borderCollapse: 'collapse',
  },
  lastCellWithErrors: {
    borderTop: '1px solid #FE630C',
    borderRight: '1px solid #FE630C',
    borderBottom: '1px solid #FE630C',
    borderLeft: '1px solid #DADADC',
    borderCollapse: 'collapse',
  },
  saveChangesBannerParent: {
    position: 'sticky',
    bottom: 16,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
  },
  saveChangesBannerContainer: {
    position: 'relative',
    borderRadius: 16,
    background: '#222222',
    color: '#FFFFFF',
    padding: 24,
    maxWidth: 600,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: 24,
    bottom: 16,
    zIndex: 999,
    width: '100%',
  },
  saveChangesBannerText: {
    display: 'flex',
    alignItems: 'center',
  },
  saveChangesBannerTextButtons: {
    display: 'flex',
    gap: 8,
  },
  cell: {
    position: 'relative',
  },
  saveChangesBannerButton: {
    width: 80,
    padding: '8px 12px',
    borderRadius: 8,
    height: 40,
    color: '#FFFFFF',
  },
});

const columns: ColumnDef<TimeEntry>[] = [
  {
    accessorKey: 'date',
    header: 'Date',
    footer: 'Total',
    size: 140,
    maxSize: 140,
    minSize: 140,
    cell: (info) => {
      const rowIndex = info.table.getRowModel().rows.indexOf(info.row);
      const isFirstEntryOfDay =
        info.table.getRowModel().rows.length === 0 ||
        info.table.getRowModel().rows[rowIndex - 1]?.original.date !==
          info.row.original.date;

      const date = formatDate(info.row.original.date);

      if (isFirstEntryOfDay) {
        const timeEntry = info.table.options.meta?.timeEntriesMap?.get(
          info.row.original.date,
        );

        return (
          <div>
            <div>{date}</div>
            {timeEntry && timeEntry.totalRecordsAssociated > 1 && (
              <div style={{ marginTop: 4, color: '#818388' }}>
                {timeEntry.totalFormattedTime}
              </div>
            )}
          </div>
        );
      }

      return <div />;
    },
  },
  {
    accessorKey: 'time',
    header: 'Time',
    footer: (info) => {
      if (info.table.options.meta?.isFullTimeRetainer) {
        return `${info.table.options.meta?.fixedHoursAmount}h`;
      } else {
        const total = info.table
          .getRowModel()
          .rows.reduce((acc, row) => acc + Number(row.original.time), 0);

        return minutesToTime(total);
      }
    },
    size: 100,
    maxSize: 100,
    minSize: 100,
    cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
      const originalRow = table.options.meta?.defaultData?.find(
        (row: TimeEntry) => row.key === rowKey,
      );

      if (!originalRow || !isRowFilled(originalRow)) {
        return <EmptyCell padding={16} />;
      }

      const cellValue = getValue() as number;

      const onChange = (value: number) => {
        table.options.meta?.updateData(rowKey, id, value);
      };

      const rowType = table
        .getRowModel()
        .rows.find((row) => row.original.key === rowKey)?.original.type;

      if (
        (rowType && outOfOfficeTypes.has(rowType)) ||
        cellValue === 0 ||
        table.options.meta?.isFullTimeRetainer
      ) {
        return <EmptyCell padding={16} />;
      }

      return <TimeInput onChange={onChange} initialValue={cellValue} />;
    },
  },
  {
    accessorKey: 'type',
    header: 'Type',
    size: 200,
    maxSize: 200,
    minSize: 200,
    cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
      const originalRow = table.options.meta?.defaultData?.find(
        (row: TimeEntry) => row.key === rowKey,
      );

      if (!originalRow || !isRowFilled(originalRow)) {
        return <EmptyCell padding={16} />;
      }

      const cellValue = getValue() as TimesheetRecordType;

      const onChange = (value: TimesheetRecordType | undefined) => {
        table.options.meta?.updateData(rowKey, id, value);
      };

      return <TypeSelect onChange={onChange} cellValue={cellValue} />;
    },
  },
  {
    accessorKey: 'task',
    header: 'Task',
    size: 450,
    maxSize: 450,
    minSize: 450,
    cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
      const originalRow = table.options.meta?.defaultData?.find(
        (row: TimeEntry) => row.key === rowKey,
      );

      if (!originalRow || !isRowFilled(originalRow)) {
        return <EmptyCell padding={16} />;
      }

      const cellValue = getValue() as string;

      const onChange = (value: string) => {
        table.options.meta?.updateData(rowKey, id, value);
      };

      const value = table
        .getRowModel()
        .rows.find((row) => row.original.key === rowKey)?.original.type;

      if (value && outOfOfficeTypes.has(value)) {
        return <EmptyCell padding={16} />;
      }

      return (
        <TextInput
          onChange={onChange}
          placeholder="Enter task"
          cellValue={cellValue}
          minChars={20}
        />
      );
    },
  },
  {
    accessorKey: 'initiativeIds',
    header: 'Initiative',
    size: 300,
    maxSize: 300,
    minSize: 300,
    cell: ({ getValue, row: { id: rowKey }, column: { id }, table }) => {
      const originalRow = table.options.meta?.defaultData?.find(
        (row: TimeEntry) => row.key === rowKey,
      );

      if (!originalRow || !isRowFilled(originalRow)) {
        return <EmptyCell padding={16} />;
      }

      const cellValue = getValue() as TimesheetInitiativeId[];

      const type = table
        .getRowModel()
        .rows.find((row) => row.original.key === rowKey)?.original.type;

      if (type && outOfOfficeTypes.has(type)) {
        return <EmptyCell padding={16} />;
      }

      const onChange = (value: TimesheetInitiativeId[]) => {
        table.options.meta?.updateData(rowKey, id, value);
      };

      return (
        <InitiativeSelect
          sid={table.options.meta?.sid ?? ''}
          cellValue={cellValue}
          onChange={onChange}
        />
      );
    },
  },
  {
    accessorKey: 'actions',
    header: '',
    size: 48,
    maxSize: 48,
    minSize: 48,
    enableHiding: false,
    cell: ({ row: { id: rowKey }, table }) => {
      const originalRow = table.options.meta?.defaultData?.find(
        (row: TimeEntry) => row.key === rowKey,
      );

      if (!originalRow || !isRowFilled(originalRow)) {
        return <EmptyCell padding={16} />;
      }

      const handleRemoveRow = () => {
        const rows = table.getRowModel().rows;
        const currentRow = rows.find((row) => row.original.key === rowKey);

        const howManyEqualDates = rows.filter(
          (row) => row.original.date === currentRow?.original.date,
        );

        if (howManyEqualDates.length > 1) {
          table.options.meta?.removeRow(rowKey);
        } else {
          table.options.meta?.updateData(rowKey, 'time', null);
          table.options.meta?.updateData(rowKey, 'type', null);
          table.options.meta?.updateData(rowKey, 'task', '');
          table.options.meta?.updateData(rowKey, 'initiativeIds', []);
        }
      };

      return <ClearRowDropdown onClearRow={handleRemoveRow} />;
    },
  },
];

interface TanstackTableAdminProps {
  records: RoleRecord[];
  currentMission?: Mission;
  allSelectableUsers: SelectableUser[];
  setSelectedUser: (user: SelectableUser | undefined) => void;
  selectedUser: SelectableUser;
}

function TanstackTableAdmin({
  records,
  currentMission,
  allSelectableUsers,
  setSelectedUser,
  selectedUser,
}: TanstackTableAdminProps) {
  const paymentCycleId =
    currentMission?.selectedPaymentCycle?.yid ??
    currentMission?.data.paymentCycles?.current?.yid ??
    '';
  const commonStyles = useCommonStyles({ cellPadding: 0 });
  const styles = useStyles();

  const defaultData = useMemo(() => {
    return records.map((record) => {
      const entry: TimeEntry = {
        key: record.key,
        date: new Date(record.date).getTime(),
        time: record.minutes,
        type: record.type || null,
        task: record.details,
        hasErrors: false,
        initiativeIds: record.initiativeIds ?? [],
      };

      return entry;
    });
  }, [records]);
  const [userToSwitch, setUserToSwitch] = useState<SelectableUser>();
  const [data, setData] = React.useState<TimeEntry[]>([]);

  const { data: isFeatureFlagOpen } = useEvaluateFeatureFlag({
    uid: selectedUser?.user.uid,
    featureFlag: FeatureFlagNames.MonthlyRetainerInvoicing,
  });

  const isFullTimeRetainer =
    (!!isFeatureFlagOpen && selectedUser?.role?.isFullTimeRetainer) ?? false;

  const timeEntriesMap = useGetGroupedTimeByDay(data, isFullTimeRetainer);

  const { startDate: paymentCycleStartDate, endDate: paymentCycleEndDate } =
    currentMission?.selectedPaymentCycle?.data ?? {};
  const { fixedHoursAmount } = useGetPaymentAndHoursForGivenPeriod(
    paymentCycleStartDate,
    paymentCycleEndDate,
    selectedUser?.role,
  );

  const [thereAreChangesToSave, setThereAreChangesToSave] = useState(false);
  const { mutate: deleteRecords } = useMutationDeleteRecords({
    mid: currentMission?.mid || '',
    yid: paymentCycleId,
  });
  const { mutate: setRecords, isLoading } = useMutationSetRecords({
    mid: currentMission?.mid || '',
    yid: paymentCycleId,
  });

  useEffect(() => {
    setThereAreChangesToSave(false);
  }, [records]);

  useEffect(() => {
    setData(defaultData);
  }, [records]);

  const table = useReactTable({
    data,
    columns,
    getRowId: (row) => row.key,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      onUpdateMutation: () => ({}),
      updateData: (rowKey: string, columnId: string, value: unknown) => {
        setData((old) => {
          const newData = old.map((row: TimeEntry) => {
            if (row.key === rowKey) {
              setThereAreChangesToSave(true);
              return {
                ...row,
                [columnId]: value,
              };
            } else {
              return row;
            }
          });

          setThereAreChangesToSave(
            JSON.stringify(defaultData) !== JSON.stringify(newData),
          );

          return newData;
        });
      },
      removeRow: (rowKey: string) => {
        setData((old) => {
          const newData = old.filter((row) => row.key !== rowKey);

          setThereAreChangesToSave(
            JSON.stringify(defaultData) !== JSON.stringify(newData),
          );
          return newData;
        });
      },
      hourlyRate: currentMission?.currentUserRole?.hourlyRate,
      sid: selectedUser?.sid ?? '',
      defaultData,
      isFullTimeRetainer,
      fixedHoursAmount,
      timeEntriesMap,
    },
  });

  const handleSave = async () => {
    if (checkForErrors()) {
      return;
    }

    const recordsToDelete = defaultData
      .filter((row) => !data.find((newRow) => newRow.key === row.key))
      .map((row) => row.key);

    if (recordsToDelete.length > 0) {
      await deleteRecords({ sid: selectedUser.sid, keys: recordsToDelete });
    }

    const recordsToUpdate = data
      .filter((row: TimeEntry) => {
        const found = defaultData.find(
          (defaultRow) => defaultRow.key === row.key,
        );
        return (
          found &&
          (row.time !== found.time ||
            row.type !== found.type ||
            row.task !== found.task ||
            !areArraysEqualRegardlessOfOrder(
              row.initiativeIds,
              found.initiativeIds,
            ))
        );
      })
      .map((row) => ({
        key: row.key,
        date: new Date(row.date).toISOString(),
        minutes: row.time || 0,
        details: row.task,
        type: row.type,
        initiativeIds: row.initiativeIds,
      })) as TimesheetRecordData[];

    if (recordsToUpdate.length > 0) {
      await setRecords({
        sid: selectedUser.sid,
        data: recordsToUpdate,
      });
    }
  };

  const handleDiscard = () => {
    if (JSON.stringify(data) !== JSON.stringify(defaultData)) {
      setData(defaultData);
      setThereAreChangesToSave(false);
    }
  };

  const handleSetSelectedUser = (user: SelectableUser | undefined) => {
    thereAreChangesToSave ? setUserToSwitch(user) : setSelectedUser(user);
  };

  const checkForErrors = () => {
    let tableHasAnError = false;
    setData((data) => {
      return data.map((row) => {
        if (checkIfRowIsValid(row, isFullTimeRetainer)) {
          const { hasErrors, ...newRow } = row;

          return newRow;
        }

        tableHasAnError = true;

        // otherwise it has errors
        return { ...row, hasErrors: true };
      });
    });

    return tableHasAnError;
  };

  if (!data) return <div>No data</div>;

  return (
    <>
      <SectionHeader
        sid={selectedUser.sid}
        currentUserRole={selectedUser.role}
      />

      <div className={commonStyles.container}>
        <UserTabs
          selectedUser={selectedUser}
          allSelectableUsers={allSelectableUsers}
          setSelectedUser={handleSetSelectedUser}
        />
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      width: header.getSize(),
                      maxWidth: header.getSize(),
                      minWidth: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, rowIndex) => {
              let isOutOfOfficeType = false;

              if (row.original.type) {
                isOutOfOfficeType = outOfOfficeTypes.has(row.original.type);
              }

              const hasErrors = row.original.hasErrors;

              return (
                <tr
                  className={cx({
                    [commonStyles.disabledBg]: isOutOfOfficeType,
                  })}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => {
                    // Determine if this is a date cell
                    const isDateCell = cell.column.id === 'date';
                    const isActionsCell = cell.column.id === 'actions';

                    // Check if it's not the first entry of the day
                    const isSubsequentEntryOfDay =
                      rowIndex > 0 &&
                      table.getRowModel().rows[rowIndex - 1].original.date ===
                        row.original.date;

                    // Choose the appropriate class name
                    const cellClassName =
                      isDateCell && isSubsequentEntryOfDay
                        ? commonStyles.emptyCell
                        : isDateCell
                        ? commonStyles.dateCell
                        : commonStyles.cell;

                    return (
                      <td
                        key={cell.id}
                        className={cx(styles.cell, {
                          [cellClassName]: !hasErrors,
                          [styles.cellWithErrors]: hasErrors,
                          [styles.firstCellWithErrors]: hasErrors && isDateCell,
                          [styles.lastCellWithErrors]:
                            hasErrors && isActionsCell,
                        })}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => {
                  if (header.column.columnDef.footer === undefined) {
                    return null;
                  }
                  return (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>

      {thereAreChangesToSave && (
        <div className={styles.saveChangesBannerParent}>
          <div className={styles.saveChangesBannerContainer}>
            <div className={styles.saveChangesBannerText}>Save changes?</div>
            <div className={styles.saveChangesBannerTextButtons}>
              <Button
                onClick={handleDiscard}
                className={styles.saveChangesBannerButton}
                color="regularDark"
              >
                Discard
              </Button>
              <Button
                onClick={handleSave}
                className={styles.saveChangesBannerButton}
                color="secondaryDark"
                loading={isLoading}
                disabled={isLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}

      <ConfirmModalOnLeave
        thereAreChangesToSave={thereAreChangesToSave}
        userToSwitch={userToSwitch}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        setUserToSwitch={setUserToSwitch}
      />
    </>
  );
}

export default observer(TanstackTableAdmin);
