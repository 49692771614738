import {
  ExperienceType,
  MetricData,
} from '@a_team/models/dist/ExperienceObject';
import { UserId } from '@a_team/models/dist/UserObject';
import { DateISOString } from '@a_team/models/dist/misc';
import { Modal } from '@a_team/ui-components';
import { Breakpoints, Colors, Icon, IconType } from '@ateams/components';
import HideScrollerGlobally from '@src/components/HideScrollerGlobally';
import ConfirmModalV2 from '@src/components/Modal/ConfirmModalV2';
import { useStores } from '@src/stores';
import {
  ExistingProject,
  Expertise,
  LocalJobs,
  NewProject,
} from '@src/stores/Profile/models';
import { isNewExperienceItem } from '@src/views/Profile/helpers/experience';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import Collaborators from './sections/Collaborators';
import Description from './sections/Description';
import Footer from './sections/Footer';
import HasManagedPeople from './sections/HasManagedPeople';
import HasZeroToOneExperience from './sections/HasZeroToOneExperience';
import Header from './sections/Header';
import Images from './sections/Images';
import Metrics from './sections/Metrics';
import Role from './sections/Role';
import Skills from './sections/Skills';
import RelatedJob from './sections/RelatedJob';

interface EditProjectModalV2Props {
  allSkills: Expertise[] | undefined;
  allJobs: LocalJobs | undefined;
  currentUserId: UserId;
  setEditModalOpen(val: boolean): void;
  editModalOpen: boolean;
  selectedProject: NewProject | ExistingProject;
  isOwner: boolean;
  handleRemove?(project: ExistingProject | NewProject): void;
  onClose: () => void;
  roles: { value: string; label: string }[] | undefined;
  onSubmitProject: (project: NewProject | ExistingProject) => void;
  loading?: boolean;
  withProjectDescriptionAssistant?: boolean;
}

const useStyles = createUseStyles({
  modal: {
    '& > div': {
      padding: 0,
    },
  },
  container: {
    margin: '24px 24px 110px 24px',
  },
  formContainer: {
    height: '100%',
    overflow: 'auto',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '90vw !important',
      maxWidth: '1200px !important',
      padding: 0,
      borderRadius: '16px !important',
      height: '90vh !important',
      maxHeight: '90vh !important',
      '& > div': {
        padding: 0,
        overflow: 'hidden !important',
      },
    },
    container: {
      maxWidth: '800px',
      minHeight: 500,
      margin: '40px auto 110px auto',
    },
  },
  suggestion: {
    display: 'flex',
    flexDirection: 'column',
    background: Colors.banner,
    padding: 12,
    borderRadius: 4,
    gap: 10,
    margin: '8px 4px 15px 0',
  },
  suggestionTitle: {
    color: Colors.secondaryDark,
    fontSize: 15,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  suggestionDescription: {
    fontSize: 14,
    fontWeight: 500,
    color: Colors.dark,
  },
  missingDetailsBannerWrapper: {
    background: 'linear-gradient(107deg, #EAD4FF 0%, #FFE9F8 61.46%)',
    padding: 8,
    borderRadius: 16,
    marginBottom: 64,
  },
  missingDetailsBannerContent: {
    background: '#FFFFFF',
    borderRadius: 8,
    padding: 24,
  },
  missingDetailsBannerTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  missingDetailsBannerDescription: {
    marginTop: 8,
    fontSize: 15,
    color: Colors.regularDark,
  },
});

const EditProjectModalV2 = ({
  allSkills,
  currentUserId,
  setEditModalOpen,
  editModalOpen,
  selectedProject,
  isOwner,
  handleRemove,
  onClose,
  roles,
  onSubmitProject,
  loading,
  allJobs,
}: EditProjectModalV2Props): JSX.Element => {
  const styles = useStyles();
  const { auth } = useStores();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const headerRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const imagesRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const skillsRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const hasZeroToOneExperienceRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const hasManagedPeopleRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const metricsRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const roleRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const descriptionRef = useRef<HTMLDivElement | null | undefined>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;

  const isNewProject = isNewExperienceItem(selectedProject);

  const methods = useForm<NewProject | ExistingProject>({
    defaultValues: { ...selectedProject },
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset({ ...selectedProject });
  }, [selectedProject, editModalOpen]);

  const onSubmit = (project: NewProject | ExistingProject) => {
    const cleanedUpProject: NewProject | ExistingProject = {
      ...project,
      metrics: project.metrics?.filter(
        (metric) => metric.title && metric.description,
      ),
      type: ExperienceType.Project,
    };

    onSubmitProject(cleanedUpProject);
  };

  const hasUnsavedData = useMemo(() => {
    const cleanMetrics = (metrics: MetricData[] | undefined) => {
      if (!metrics) {
        return [];
      }
      return metrics.filter((metric) => {
        return metric.title || metric.description;
      });
    };

    const checkIfFiledChanged = (
      initialValue:
        | string
        | DateISOString
        | boolean
        | number
        | undefined
        | null,
      currentValue:
        | string
        | DateISOString
        | boolean
        | number
        | undefined
        | null,
    ) => {
      return (initialValue ?? '') !== (currentValue ?? '');
    };

    const unsavedData = Object.values({
      logoURL: checkIfFiledChanged(selectedProject.logoURL, watch('logoURL')),
      title: checkIfFiledChanged(selectedProject.title, watch('title')),
      companyId: checkIfFiledChanged(
        selectedProject.companyId,
        watch('companyId'),
      ),
      websiteURL: checkIfFiledChanged(
        selectedProject.websiteURL,
        watch('websiteURL'),
      ),
      industry:
        !auth.withMultipleIndustriesForExperiences &&
        checkIfFiledChanged(selectedProject.industry, watch('industry')),
      industries:
        auth.withMultipleIndustriesForExperiences &&
        !isEqual(selectedProject.industries, watch('industries')),
      projectUrl: checkIfFiledChanged(
        selectedProject.projectUrl,
        watch('projectUrl'),
      ),
      imagesUrls: !isEqual(
        selectedProject.imagesUrls ?? [],
        watch('imagesUrls'),
      ),
      jobRole: checkIfFiledChanged(selectedProject.jobRole, watch('jobRole')),
      jobRoleId: checkIfFiledChanged(
        selectedProject.jobRoleId,
        watch('jobRoleId'),
      ),
      startDate: checkIfFiledChanged(
        selectedProject.startDate,
        watch('startDate'),
      ),
      endDate: checkIfFiledChanged(selectedProject.endDate, watch('endDate')),
      skills: !isEqual(selectedProject.skills, watch('skills')),
      metrics: !isEqual(
        cleanMetrics(selectedProject.metrics),
        cleanMetrics(watch('metrics')),
      ),
      descriptionHTML: checkIfFiledChanged(
        selectedProject.descriptionHTML,
        watch('descriptionHTML'),
      ),
      members: !isEqual(selectedProject.members, watch('members')),
      hasZeroToOneExperience: checkIfFiledChanged(
        selectedProject.hasZeroToOneExperience,
        watch('hasZeroToOneExperience'),
      ),
      hasManagedPeople: checkIfFiledChanged(
        selectedProject.hasManagedPeople,
        watch('hasManagedPeople'),
      ),
      numberOfPeopleManaged: checkIfFiledChanged(
        selectedProject.numberOfPeopleManaged,
        watch('numberOfPeopleManaged'),
      ),
      relatedJobId: checkIfFiledChanged(
        selectedProject.relatedJobId,
        watch('relatedJobId'),
      ),
    });

    return unsavedData.includes(true);
  }, [
    selectedProject,
    watch('logoURL'),
    watch('title'),
    watch('companyId'),
    watch('websiteURL'),
    watch('industry'),
    watch('industries'),
    watch('projectUrl'),
    watch('imagesUrls'),
    watch('jobRole'),
    watch('jobRoleId'),
    watch('startDate'),
    watch('endDate'),
    watch('skills'),
    watch('metrics'),
    watch('descriptionHTML'),
    watch('members'),
    watch('hasZeroToOneExperience'),
    watch('hasManagedPeople'),
    watch('numberOfPeopleManaged'),
    watch('relatedJobId'),
  ]);

  const handleClose = () => {
    if (isNewExperienceItem(selectedProject)) {
      // If it is a new project remove it from the projects array
      handleRemove && handleRemove(selectedProject);
    }

    onClose && onClose();
    setShowConfirmModal(false);
    setEditModalOpen(false);
  };

  const handleModalClose = () => {
    if (hasUnsavedData) {
      setShowConfirmModal(true);
    } else {
      handleClose();
    }
  };

  return (
    <>
      <Modal
        variant="slideUp"
        isOpen={editModalOpen}
        onClose={handleModalClose}
        className={styles.modal}
        shouldHideGradientStroke={true}
        style={{ display: showConfirmModal ? 'none' : 'flex' }}
      >
        <FormProvider {...methods}>
          <form
            className={styles.formContainer}
            onSubmit={handleSubmit(onSubmit)}
            data-testing-id="profile-project-edit-modal"
          >
            <div className={styles.container}>
              {selectedProject.isMissingMinimumRequirements && (
                <div className={styles.missingDetailsBannerWrapper}>
                  <div className={styles.missingDetailsBannerContent}>
                    <div className={styles.missingDetailsBannerTitle}>
                      This project is missing details.
                    </div>
                    <div className={styles.missingDetailsBannerDescription}>
                      To be able to apply to missions with this project, you
                      need to fill out the missing details.
                    </div>
                  </div>
                </div>
              )}
              {!selectedProject.isMissingMinimumRequirements &&
                selectedProject.isSuggested && (
                  <div className={styles.suggestion}>
                    <div className={styles.suggestionTitle}>
                      <Icon size="smaller" type={IconType.SuggestedTeams} />
                      Editing suggested project
                    </div>
                    <div className={styles.suggestionDescription}>
                      This project is suggested based on your LinkedIn profile.
                    </div>
                  </div>
                )}
              <div ref={headerRef}>
                <Header isOwner={isOwner} selectedProject={selectedProject} />
              </div>

              <div ref={imagesRef}>
                <Images />
              </div>

              <div ref={roleRef}>
                <Role roles={roles} />
              </div>

              <div ref={skillsRef}>
                <Skills allSkills={allSkills} />
              </div>

              {auth.withNewProjectExperienceFields && (
                <>
                  <div ref={hasManagedPeopleRef}>
                    <HasManagedPeople />
                  </div>

                  <div ref={hasZeroToOneExperienceRef}>
                    <HasZeroToOneExperience />
                  </div>
                </>
              )}

              <div ref={metricsRef}>
                <Metrics />
              </div>

              <div ref={descriptionRef}>
                <Description
                  isNewProject={isNewProject}
                  allSkills={allSkills}
                />
              </div>

              <Collaborators
                currentUserId={currentUserId}
                roles={roles}
                isOwner={isOwner}
              />
              {auth.withRelatedExperiences && <RelatedJob allJobs={allJobs} />}
            </div>
            <Footer
              isMissingMinimumRequirements={
                selectedProject.isMissingMinimumRequirements
              }
              handleClose={handleModalClose}
              headerRef={headerRef}
              imagesRef={imagesRef}
              roleRef={roleRef}
              skillsRef={skillsRef}
              hasZeroToOneExperienceRef={hasZeroToOneExperienceRef}
              hasManagedPeopleRef={hasManagedPeopleRef}
              metricsRef={metricsRef}
              descriptionRef={descriptionRef}
              errors={errors}
              loading={loading}
            />
          </form>
        </FormProvider>
        {editModalOpen && <HideScrollerGlobally />}
      </Modal>

      <ConfirmModalV2
        title="Are you sure you want to quit? All progress will be lost."
        description="Your project details will be removed."
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onCancel={() => setShowConfirmModal(false)}
        onCancelText="Never mind"
        onConfirm={handleClose}
        onConfirmText="Quit"
        onConfirmColor="secondaryDark"
      />
    </>
  );
};

export default observer(EditProjectModalV2);
