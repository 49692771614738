import React, { CSSProperties, ReactElement } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Colors, Spacing } from '@ateams/components';
import MainLayout from '@src/layouts/Main';
import SectionHeading from '@src/components/SectionHeading';

// <MainLayout /> accepts no className styling
const LAYOUT_STYLES: CSSProperties = {
  padding: 0,
  background: Colors.backgroundWhite,
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const useStyles = createUseStyles({
  navBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 90,
    borderBottom: `1px solid ${Colors.backgroundDark}`,
    padding: `0 ${Spacing.large}px`,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  buttonWithSpacing: {
    marginRight: Spacing.small,
  },
  title: {
    margin: 0,
    marginRight: Spacing.large,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
  },
  mainContent: {
    padding: Spacing.large,
    flexGrow: 1,
  },
  sidebar: {
    width: 375,
    minWidth: 375,
    maxWidth: 375,
    borderLeft: `1px solid ${Colors.backgroundDark}`,
  },
});

interface Props {
  onClickOnEditAudience: () => void;
  onClickSendEmail?: () => void;
  onClickPreviewEmail?: () => void;
  onClickResetForm?: () => void;
  sidebarElement?: ReactElement;
  sendEmailDisabled?: boolean;
  editAudienceDisabled?: boolean;
}

export const TargeterEmailLayout: React.FC<Props> = ({
  children,
  onClickOnEditAudience,
  onClickSendEmail,
  onClickPreviewEmail,
  onClickResetForm,
  sidebarElement,
  sendEmailDisabled,
  editAudienceDisabled,
}): ReactElement => {
  const styles = useStyles();

  return (
    <MainLayout title="Targeter" style={LAYOUT_STYLES}>
      <div className={styles.navBar}>
        <div className={styles.titleContainer}>
          <SectionHeading isFirst className={styles.title}>
            TeamGraph Targeter
          </SectionHeading>
          <Button
            width="auto"
            squared
            outlined
            size="small"
            color="regularDark"
            onClick={onClickOnEditAudience}
            disabled={editAudienceDisabled}
          >
            Edit audience
          </Button>
        </div>
        <div>
          <Button
            className={styles.buttonWithSpacing}
            width="auto"
            squared
            outlined
            size="small"
            color="regularDark"
            onClick={() => onClickResetForm && onClickResetForm()}
          >
            Reset form
          </Button>
          <Button
            className={styles.buttonWithSpacing}
            width="auto"
            squared
            outlined
            size="small"
            onClick={() => onClickPreviewEmail && onClickPreviewEmail()}
          >
            Preview email
          </Button>
          <Button
            disabled={sendEmailDisabled}
            width="auto"
            squared
            size="small"
            onClick={() => onClickSendEmail && onClickSendEmail()}
          >
            Send email
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.mainContent}>{children}</div>
        {sidebarElement ? (
          <div className={styles.sidebar}>{sidebarElement}</div>
        ) : null}
      </div>
    </MainLayout>
  );
};
