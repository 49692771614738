import {
  CommunityBadgeType,
  UserBadge as UserBadgeType,
} from '@a_team/models/dist/UserObject';
import React from 'react';
import { createUseStyles } from 'react-jss';
import communityLeader from './images/communityLeader.svg';
import guildAi from './images/guildAi.svg';
import guildFintech from './images/guildFintech.svg';
import guildFrontend from './images/guildFrontend.svg';
import guildHealthcare from './images/guildHealthcare.svg';
import powerUser from './images/powerUser.svg';
import selectionTeam from './images/selectionTeam.svg';
import valuableFeedbackGiver from './images/valuableFeedbackGiver.svg';
import CourseAIAugmentedGraduate from './images/CourseAIAugmentedGraduate.svg';

interface UserBadgeDetailedProps {
  communityBadgeType: CommunityBadgeType | UserBadgeType.SelectionTeam;
}

export const availableCommunityBadges = {
  /**
   * The selection team badge is a special case and we count it as a community badge for this purpose
   * @link https://buildateam.atlassian.net/browse/NEXUS-702
   */
  [UserBadgeType.SelectionTeam]: {
    title: 'Selection team',
    desc: 'Builders who are vetting potential A.Team members.',
    badge: selectionTeam,
  },

  [CommunityBadgeType.PowerUser]: {
    title: 'Power user',
    desc: 'Builders who actively participate in out beta community initiatives.',
    badge: powerUser,
  },
  [CommunityBadgeType.CommunityLeader]: {
    title: 'Community leader',
    desc: 'Builders leading a micro community.',
    badge: communityLeader,
  },
  [CommunityBadgeType.GuildFintech]: {
    title: 'Fintech guild',
    desc: 'Members of Fintech guild.',
    badge: guildFintech,
  },
  [CommunityBadgeType.GuildHealthcare]: {
    title: 'Healthtech guild',
    desc: 'Members of Healthtech guild.',
    badge: guildHealthcare,
  },
  [CommunityBadgeType.GuildAI]: {
    title: 'A.I. guild',
    desc: 'Members of A.I. guild.',
    badge: guildAi,
  },
  [CommunityBadgeType.GuildFrontend]: {
    title: 'Front-end guild',
    desc: 'Members of Front-end guild.',
    badge: guildFrontend,
  },
  [CommunityBadgeType.ValuableFeedbackGiver]: {
    title: 'Valuable feedback giver',
    desc: 'Builders who provide feedback to help improve A.Team platform.',
    badge: valuableFeedbackGiver,
  },
  [CommunityBadgeType.CourseAIAugmentedGraduate]: {
    title: 'AI Course Graduate',
    desc: 'Completed the AI augmented training.',
    badge: CourseAIAugmentedGraduate,
  },
};

const useStyles = createUseStyles({
  userBadgeDetailedWrapper: {
    display: 'flex',
    borderBottom: '1px solid #E5E5E5',
    padding: 16,
    '&:last-child': {
      borderRadius: 8,
    },
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    marginLeft: 16,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
  },
  desc: {
    fontSize: 12,
    fontWeight: 400,
    color: '#818388',
    textAlign: 'left',
  },
});

const UserBadgeDetailed: React.FC<UserBadgeDetailedProps> = ({
  communityBadgeType,
}) => {
  const styles = useStyles();
  const { title, desc, badge } = availableCommunityBadges[communityBadgeType];

  return (
    <div className={styles.userBadgeDetailedWrapper}>
      <div>
        <img width={80} height={80} src={badge} alt={title} />
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{desc}</div>
      </div>
    </div>
  );
};

export default UserBadgeDetailed;
