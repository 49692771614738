import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import {
  Breakpoints,
  Button as CallToActionButton,
  Colors,
  Icon,
  IconType,
  Tag,
  TagList,
  CloseButton,
} from '@ateams/components';
import { MissionStatus } from '@a_team/models/dist/MissionObject';
import { Link, useHistory } from 'react-router-dom';
import {
  ClientMissionPageLocation,
  EditMissionLocation,
  MissionAboutLocation,
  MissionAdminAboutLocation,
  MissionAdminDocumentsLocation,
  MissionAdminFAQLocation,
  MissionAdminNotificationsLocation,
  MissionAdminTeamPulseLocation,
  MissionAdminTimeTrackingLocation,
  MissionDocumentsLocation,
  MissionFAQLocation,
  MissionPageAdminLocation,
  MissionPageLocation,
  MissionProposalLocation,
  MissionTeamPulseLocation,
  MissionTimeTrackingLocation,
  ProfileLocation,
  SignInLocation,
} from '@src/locations';

import TextButton from '@src/components/TextButton';
import UserAvatar from '@src/components/UserAvatar';
import TabButtons, { TabNavLink } from '@src/components/TabButtons';

import { Tab } from '@src/views/Mission';
import { useScreenClass } from 'react-grid-system';
import { useStores } from '@src/stores';
import TooltipWrapped from '@src/components/TooltipWrapped';
import Indicator from '@src/components/Indicator';
import { truncate } from '@src/helpers/strings';
import Anchor from '@src/components/Anchor';
import { BasicUserObject, UserStatus } from '@a_team/models/dist/UserObject';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';
import ActionButton from '@src/views/MissionControl/MissionControlCard/ActionButton';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import TeamGraphMissionRolesSearchUrl from '@src/views/Mission/AboutMission/TeamGraphMissionRolesSearchUrl';

interface Props {
  toggleModal(force?: unknown): void;

  toggleDelete(force?: unknown): void;

  togglePublish(force?: unknown): void;

  sticky: boolean;

  setStickyTop(val: number): void;
}

const useStyles = createUseStyles({
  topBar: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '58px',
    paddingBottom: 0,
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
    position: 'static',
    top: 0,
    width: '100%',
    backgroundColor: '#F7F7F7',
    zIndex: 100,
  },
  title: {
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '34px',
    letterSpacing: '0.002em',
    color: '#222222',
    marginTop: '32px',
  },
  details: {
    display: 'flex',
    alignItems: 'left',
    marginTop: '16px',
    flexDirection: 'column',
    '& > *': {
      marginBottom: 10,
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 8,
  },
  smallDivider: {
    borderRight: '1px solid #C0C0C0',
    height: '16px',
    marginLeft: '12px',
    marginRight: '12px',
  },
  adminActions: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '8px 0',
  },
  actionLabel: {
    marginLeft: '11px',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    'a > &': {
      color: '#62646A',
    },
  },
  titleBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  detailsContainer: {
    display: 'block',
    marginTop: 16,
  },
  logo: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    marginRight: 16,
    marginBottom: 8,
  },
  signIn: {
    padding: '8px 32px',
    height: 'auto',
  },
  notInterested: {
    minWidth: 120,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    topBar: {
      paddingLeft: '56px',
      paddingRight: '56px',
      paddingTop: '48px',
      paddingBottom: 0,
      height: 'auto',
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
      position: 'static',
      top: 0,
      width: '100%',
      backgroundColor: '#F7F7F7',
    },
    titleBar: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    smallDivider: {
      display: 'block',
    },
    actionContainer: {
      marginBottom: 0,
    },
    details: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '16px',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    logo: {
      marginBottom: 0,
    },
    detailsContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: '24px',
    },
    notInterested: {
      marginRight: '10px',
    },
  },
});

const MissionNavbar = (props: Props): ReactElement => {
  const styles = useStyles();
  const {
    auth,
    missions: { currentMission },
    missionControl,
  } = useStores();
  const currentItem = currentMission?.data;
  const screenClass = useScreenClass();
  const [stickyHeight, setStickyHeight] = useState(0);
  const analytics = useAnalytics();

  const { toggleModal, toggleDelete, togglePublish } = props;
  const history = useHistory();

  const activeTeamMembers = useMemo(() => {
    return currentItem?.roles.filter((role) => {
      const user = role.user as BasicUserObject;
      return (
        user &&
        user.status !== UserStatus.Deleted &&
        (role.status === MissionRoleStatus.Active ||
          role.status === MissionRoleStatus.ScheduledToEnd)
      );
    });
  }, [currentItem]);

  const promotedTags = currentItem?.promotedTags || [];
  const isOwner = currentItem?.creator?.uid === auth.uid || auth.isAdmin;
  const isRecruiting =
    currentItem?.status === MissionStatus.Created ||
    currentItem?.status === MissionStatus.Published;
  const isInReview = currentItem?.status === MissionStatus.Created;
  const isNotPublished = currentItem?.status === MissionStatus.Created;
  const tabsBarRef = useRef<HTMLDivElement>(null);
  const topBarRef = useRef<HTMLDivElement>(null);

  /**
   * The original call to this function is made before the authentication store is populated, so no team pulses are loaded.
   * This workaround allows the user to access the teampulse section if the user reloads the page.
   */
  useEffect(() => {
    currentMission?.refreshTeamPulse(auth.isAdmin);
  }, [auth.currentUser]);

  useEffect(() => {
    if (tabsBarRef.current && topBarRef.current) {
      props.setStickyTop(tabsBarRef.current.offsetTop);
      setStickyHeight(topBarRef.current.offsetHeight);
    }
  }, [tabsBarRef.current, topBarRef.current]);

  const notInterestedItems = missionControl.notInterested?.items;
  useEffect(() => {
    if (!notInterestedItems && auth.currentUser) {
      missionControl.loadNotInterestedMissions();
    }
  }, [notInterestedItems, auth.currentUser]);

  const toggleHideMission = () => {
    if (currentItem && currentMission) {
      analytics.trackMissionCardNotInterested(
        currentItem,
        !!currentMission.isRecommended,
        !currentMission.isNotInterested,
      );

      if (currentMission?.isNotInterested) {
        missionControl.restoreNotInterested(currentMission.mid);
      } else {
        missionControl.setNotInterested(currentMission.mid);
      }
    }
  };

  const tabs: Tab[] = currentMission
    ? [
        {
          id: 'time',
          label: 'Time Tracking',
          to:
            currentMission?.isAdminMode || currentMission?.isMissionManager
              ? MissionAdminTimeTrackingLocation(currentMission.mid)
              : MissionTimeTrackingLocation(currentMission?.mid),
          disabled: currentMission?.timeTrackingTabDisabled,
        },
        {
          id: 'about',
          label: 'About Mission',
          to: currentMission?.isAdminMode
            ? MissionAdminAboutLocation(currentMission.mid)
            : MissionAboutLocation(currentMission.mid),
        },
        {
          id: 'documents',
          label: (
            <span>
              {!currentMission?.isAdminMode &&
                !currentMission?.isMissionManager && (
                  <TooltipWrapped
                    title={'This information is only available to you.'}
                    arrow
                    animation="fade"
                  >
                    <Icon
                      type={IconType.Privacy}
                      size="small"
                      style={{
                        cursor: 'pointer',
                        height: 20,
                        display: 'inline-block',
                      }}
                    />
                  </TooltipWrapped>
                )}{' '}
              {currentMission?.isAdminMode || currentMission?.isMissionManager
                ? 'Documents'
                : 'My Documents'}
              {currentMission?.pendingContracts &&
                currentMission.pendingContracts.length > 0 && (
                  <TooltipWrapped
                    title={`You have ${currentMission?.pendingContracts.length} pending contracts`}
                    arrow
                    animation="fade"
                    style={{ marginLeft: 8 }}
                  >
                    <Tag color={'primary'} thin style={{ padding: '2px 6px' }}>
                      {currentMission.pendingContracts.length}
                    </Tag>
                  </TooltipWrapped>
                )}
            </span>
          ),
          to: currentMission?.isAdminMode
            ? MissionAdminDocumentsLocation(currentMission.mid)
            : MissionDocumentsLocation(currentMission.mid),
          disabled: currentMission?.documentsTabDisabled,
        },
        {
          id: 'faq',
          label: 'Payment Terms',
          to: currentMission?.isAdminMode
            ? MissionAdminFAQLocation(currentMission.mid)
            : MissionFAQLocation(currentMission.mid),
          disabled: currentMission?.timeTrackingTabDisabled,
        },
      ]
    : [];

  const myOpenIncompleteTeamPulseSurveyCount =
    currentMission?.myOpenIncompleteTeamPulseSurveys?.length;
  const teamPulsesCount = currentMission?.teamPulses?.length;
  if (
    currentMission &&
    (currentMission?.isAdminMode ||
      teamPulsesCount ||
      myOpenIncompleteTeamPulseSurveyCount)
  ) {
    tabs.push({
      id: 'teamPulse',
      label: (
        <>
          <span>TeamPulse</span>
          {!!myOpenIncompleteTeamPulseSurveyCount && (
            <Indicator
              fill={Colors['primary']}
              style={{
                top: '-9px',
                left: '2px',
                display: 'inline-block',
                position: 'relative',
              }}
            />
          )}
        </>
      ),
      to: currentMission?.isAdminMode
        ? MissionAdminTeamPulseLocation(currentMission.mid)
        : MissionTeamPulseLocation(currentMission?.mid),
      disabled: currentMission?.teamPulseTabDisabled,
    });
  }

  const emailSubject = `Question about ${
    currentMission?.data.title ?? 'a mission'
  } for ${currentMission?.data.owner?.fullName ?? 'the Formation Team'}`;

  const isCurrentUserOnMission = useMemo(() => {
    if (!auth.user) {
      return false;
    }
    return auth.user?.activeMissions?.some(
      (mission) => mission.mid === currentMission?.mid,
    );
  }, [auth.user?.uid, currentMission?.mid]);

  const missionTitle = useMemo(() => {
    if (!currentItem) return '';
    const parts = currentItem.title.split(':');
    return parts.length > 1
      ? parts.slice(1).join(':').trim()
      : currentItem.title;
  }, [currentItem?.title]);

  return (
    <div
      className={styles.topBar}
      ref={topBarRef}
      style={{ ...(props.sticky && { height: stickyHeight }) }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TagList>
          {promotedTags.map((tag) => (
            <Tag key={tag} color="info">
              {tag}
            </Tag>
          ))}

          {isInReview && <Tag>In Review</Tag>}
          {auth.isAdmin && isNotPublished && <Tag>Not Published</Tag>}
          {currentItem?.hidden && <Tag>Hidden</Tag>}
        </TagList>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!!auth.user &&
            !!missionControl.notInterested &&
            !isCurrentUserOnMission && (
              <ActionButton
                type={currentMission?.isNotInterested ? 'restore' : 'hide'}
                onClick={toggleHideMission}
                className={styles.notInterested}
              />
            )}
          {isRecruiting && (
            <div style={{ display: 'flex' }}>
              <Icon
                type={IconType.ShareGrey}
                onClick={toggleModal}
                style={{ marginRight: '16px' }}
              />
              <CloseButton onClick={history.goBack} style={{ padding: 0 }} />
            </div>
          )}
          {!auth.user && (
            <CallToActionButton
              onClick={() => history.push(SignInLocation)}
              width={'auto'}
              className={styles.signIn}
            >
              Sign In
            </CallToActionButton>
          )}
        </div>
      </div>
      <div className={styles.detailsContainer}>
        {currentItem?.logoURL && (
          <img
            src={currentItem?.logoURL}
            className={styles.logo}
            alt={'logo'}
          />
        )}
        <div>
          <div className={styles.titleBar}>
            <div className={styles.title} style={{ marginTop: 0 }}>
              {currentItem?.companyName}: {missionTitle}
            </div>

            {isOwner && (
              <div className={styles.adminActions}>
                <Link
                  style={{ marginLeft: '16px', cursor: 'pointer' }}
                  className={styles.actionContainer}
                  to={EditMissionLocation(currentItem ? currentItem.mid : '')}
                >
                  <Icon
                    style={{ cursor: 'pointer' }}
                    type={IconType.EditGray}
                  />
                </Link>
                {currentMission?.data.status === MissionStatus.Created ||
                  (currentMission?.data.status === MissionStatus.Published && (
                    <>
                      <div className={styles.smallDivider} />
                      <TextButton
                        onClick={toggleDelete}
                        className={styles.actionContainer}
                        color="danger"
                      >
                        <Icon
                          style={{ cursor: 'pointer' }}
                          type={IconType.TrashRed}
                        />
                      </TextButton>
                    </>
                  ))}

                {auth.isAdmin && isInReview && (
                  <>
                    <div className={styles.smallDivider} />
                    <TextButton
                      onClick={togglePublish}
                      className={styles.actionContainer}
                      color="success"
                    >
                      <Icon
                        type={IconType.Approve}
                        style={{ cursor: 'pointer' }}
                      />
                      <div className={styles.actionLabel}>Publish Mission</div>
                    </TextButton>
                  </>
                )}
                {auth.isAdmin && currentItem && (
                  <>
                    <div className={styles.smallDivider} />
                    <TextButton
                      onClick={() =>
                        history.push(MissionProposalLocation(currentItem.mid))
                      }
                      className={styles.actionContainer}
                      highlight
                    >
                      Create proposal
                    </TextButton>
                    <div className={styles.smallDivider} />
                    <TextButton
                      onClick={() =>
                        history.push(
                          MissionAdminNotificationsLocation(currentItem.mid),
                        )
                      }
                      className={styles.actionContainer}
                      highlight
                    >
                      Reach out history
                    </TextButton>
                    {currentItem.missionSpecId ? (
                      <>
                        <div className={styles.smallDivider} />
                        <TextButton
                          onClick={() => {
                            const url = ClientMissionPageLocation(
                              currentItem.missionSpecId || '',
                            );
                            window.open(url, '_blank');
                          }}
                          className={styles.actionContainer}
                          highlight
                        >
                          Go to Client App
                        </TextButton>
                      </>
                    ) : null}
                    <TeamGraphMissionRolesSearchUrl mission={currentMission} />
                  </>
                )}
                {auth.isAdmin && currentItem && !isRecruiting && (
                  <>
                    <div className={styles.smallDivider} />
                    {currentMission?.isAdminMode && (
                      <Tag style={{ marginRight: 8 }}>Admin view</Tag>
                    )}
                    <TextButton
                      highlight
                      className={styles.actionContainer}
                      onClick={
                        currentMission?.isAdminMode
                          ? () =>
                              history.push(MissionPageLocation(currentItem.mid))
                          : () =>
                              history.push(
                                MissionPageAdminLocation(currentItem.mid),
                              )
                      }
                    >
                      {currentMission?.isAdminMode
                        ? 'Go to User view'
                        : 'Go to Admin view'}
                    </TextButton>
                  </>
                )}
              </div>
            )}
          </div>
          {!!currentMission?.data.shortCompanyDescription && (
            <TooltipWrapped
              disabled={
                currentMission?.data.shortCompanyDescription?.length <= 100
              }
              title={currentMission?.data.shortCompanyDescription}
              arrow
              position={'top'}
            >
              <p style={{ display: 'inline-block' }}>
                {truncate(currentMission?.data.shortCompanyDescription, 100)}
              </p>
            </TooltipWrapped>
          )}
          <div className={styles.details}>
            {!!currentItem?.expectedDurationMonths && (
              <div>Duration: {currentItem?.expectedDurationMonths} months</div>
            )}

            {currentItem?.industries && currentItem.industries.length > 0 && (
              <>
                {screenClass !== 'xs' &&
                  !!currentItem?.expectedDurationMonths && (
                    <div className={styles.smallDivider} />
                  )}
                <div style={{ maxWidth: '500px' }}>
                  Industry:{' '}
                  {currentItem.industries.map((e) => e.name).join(', ')}
                </div>
              </>
            )}

            {activeTeamMembers &&
              !!activeTeamMembers.length &&
              currentMission?.shouldDisplayMembers && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {screenClass !== 'xs' &&
                    (!!currentItem?.expectedDurationMonths ||
                      !!currentItem?.industries?.length) && (
                      <div className={styles.smallDivider} />
                    )}
                  <div>Team:</div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {activeTeamMembers.map(
                      (member) =>
                        member.user && (
                          <Link
                            key={member.rid}
                            to={
                              member.user.username
                                ? ProfileLocation(member.user.username)
                                : ''
                            }
                            style={{ height: '24px', marginLeft: '8px' }}
                          >
                            <UserAvatar
                              size={24}
                              src={
                                member.user.username
                                  ? member.user.profilePictureURL
                                  : undefined
                              }
                            />
                          </Link>
                        ),
                    )}
                  </div>
                </div>
              )}
            {currentMission &&
              'owner' in currentMission.data &&
              currentMission.data.owner && (
                <>
                  {screenClass !== 'xs' &&
                    (!!currentItem?.expectedDurationMonths ||
                      !!currentItem?.industries?.length ||
                      (!!activeTeamMembers?.length &&
                        currentMission?.shouldDisplayMembers)) && (
                      <div className={styles.smallDivider} />
                    )}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Team Architect:</span>
                    <UserAvatar
                      containerStyle={{ marginLeft: 8, marginRight: 4 }}
                      size={24}
                      src={currentMission?.data.owner.profilePictureURL}
                    />
                    <Anchor
                      highlight
                      href={`mailto:formation@a.team?subject=${encodeURIComponent(
                        emailSubject,
                      )}`}
                      target={'_blank'}
                    >
                      {currentMission?.data.owner.fullName}
                    </Anchor>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
      {currentMission?.areTabsShown && (
        <div
          ref={tabsBarRef}
          style={{
            ...(props.sticky &&
              screenClass !== 'xs' && {
                position: 'fixed',
                zIndex: 999,
                top: 0,
                left: 120,
                width: '100%',
                backgroundColor: '#f7f7f7',
                marginRight: -56,
                paddingLeft: 56,
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 10px',
              }),
          }}
        >
          <TabButtons
            style={{
              boxShadow: 'none',
              ...(props.sticky &&
                screenClass !== 'xs' && {
                  marginTop: 0,
                }),
            }}
          >
            {tabs.map((tabButton) => (
              <TabNavLink
                key={tabButton.id}
                to={tabButton.to || ''}
                disabled={tabButton.disabled}
              >
                <div
                  style={{
                    fontWeight: 500,
                    color: '#222',
                  }}
                >
                  {tabButton.label}
                </div>
              </TabNavLink>
            ))}
          </TabButtons>
        </div>
      )}
    </div>
  );
};

export default observer(MissionNavbar);
