import React, { ReactElement } from 'react';
import { MissionRoleTimesheetInvoiceObject } from '@a_team/models/dist/InvoiceObject';
import FooterTotal from '../FooterTotal';
import HeroSections, { HeroAddressSection, HeroSection } from '../HeroSections';
import PageLayout from '../PageLayout';
import { stringifyMinutes, stringifyDate } from '@src/helpers/time';
import Table, { CellContent } from '../Table';

export type InvoiceProps = 'iid' | 'to' | 'from' | 'paymentCycle' | 'role';

interface Props {
  invoice: MissionRoleTimesheetInvoiceObject;
  records: MissionRoleTimesheetInvoiceObject['timesheetRecords'];
  hero?: boolean;
  hasMore?: boolean;
}

export default function MissionRoleTimesheetInvoicePage(
  props: Props,
): ReactElement {
  const { invoice, hasMore, records, hero } = props;
  const { period, to, from, totalMinutes, hourlyRate, totalAmount, totalVAT } =
    invoice;

  let footer;
  if (!hasMore) {
    const total = `$${totalAmount.toLocaleString()}`;

    footer = (
      <FooterTotal
        details={{
          'Total Hours': stringifyMinutes(totalMinutes),
          '$ Per Hour': `$${hourlyRate.toLocaleString()}`,
          VAT: totalVAT ? `$${totalVAT.toLocaleString()}` : null,
        }}
        total={total}
      />
    );
  }

  return (
    <PageLayout invoice={invoice} period={period} footer={footer}>
      {!!hero && (
        <HeroSections>
          <HeroSection grow title="Summary for Period">
            {stringifyDate(period.startDate, true)}-
            {stringifyDate(period.endDate)}
          </HeroSection>
          <HeroAddressSection title="From" address={from} />
          <HeroAddressSection title="To" address={to} />
        </HeroSections>
      )}
      <Table>
        <thead>
          <tr>
            <th style={{ width: '15%' }}>Date</th>
            <th style={{ width: '20%' }}># of Hours</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {records.map((item) => (
            <tr key={item.key}>
              <td>{stringifyDate(item.date)}</td>
              <td>{stringifyMinutes(item.minutes)}</td>
              <td>
                <CellContent>{item.details}</CellContent>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </PageLayout>
  );
}
