import {
  MetricData,
  ProjectDescriptionBlurbNode,
  ProjectDescriptionBlurbNodeType,
} from '@a_team/models/dist/ExperienceObject';
import { Button } from '@a_team/ui-components';
import { Breakpoints, Icon, IconType, Spinner } from '@ateams/components';
import cx from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    background: '#FCFAFF',
    padding: 16,
    color: '#6D00D7',
  },
  desc: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 8,
    fontSize: 12,
  },
  suggestedTitle: {
    fontSize: 14,
    fontWeight: 500,
    margin: '24px 0 8px 0',
  },
  suggestedDescription: {
    fontSize: 12,
    marginBottom: 24,
  },
  wrapper: {
    display: 'flex',

    justifyContent: 'space-between',

    gap: 8,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  btn: {
    padding: '8px !important',
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    borderRadius: '6px !important',
    // select first span child
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
  },
  rewriteDiscardBtn: {
    background: '#EDE0FF !important',
    '& span': {
      color: '#222222 !important',
    },
  },
  btnsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
  },
  hideOnMobile: {
    display: 'none !important',
  },
  hideOnDesktop: {
    display: 'inline !important',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    desc: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    hideOnMobile: {
      display: 'inline !important',
    },
    hideOnDesktop: {
      display: 'none !important',
    },
    suggestedTitle: {
      margin: '8px 0',
    },
    suggestedDescription: {
      marginBottom: 8,
    },
  },
});

interface SuggestionProps {
  onInsertSuggestion: (suggestion: ProjectDescriptionBlurbNode[]) => void;
  suggestion?: ProjectDescriptionBlurbNode[];
  onDiscardSuggestion: (suggestion: ProjectDescriptionBlurbNode[]) => void;
  onGenerateSuggestion: () => void;
  isLoading: boolean;
  charCount: number;
  isDiscarded?: boolean;
  roles?: { value: string; label: string }[] | undefined;
  hasInsertedSuggestion: boolean;
  hasEditedAfterInsert: boolean;
  isNewProject?: boolean;
}

const Suggestion = ({
  onInsertSuggestion,
  onGenerateSuggestion,
  onDiscardSuggestion,
  suggestion,
  isLoading,
  charCount,
  isDiscarded,
  hasEditedAfterInsert,
  roles,
  hasInsertedSuggestion,
  isNewProject,
}: SuggestionProps) => {
  const { getValues } = useFormContext();

  const getHasRemainingFields = () => {
    if (!getValues) return false;
    const {
      title,
      description,
      industry,
      skills,
      jobRole,
      jobRoleId,
      startDate,
      companyId,
      metrics,
      hasZeroToOneExperience,
      hasManagedPeople,
      numberOfPeopleManaged,
    } = getValues();

    const role =
      jobRole || roles?.find((role) => role.value === jobRoleId)?.label || '';

    const hasMetrics = (metrics as MetricData[])?.every(
      (metric) =>
        metric.title &&
        metric.title.length > 0 &&
        metric.description &&
        metric.description.length > 0,
    );

    const hasSkills = skills && skills.length > 0;

    return (
      !startDate ||
      !title ||
      !description ||
      !companyId ||
      !industry ||
      !hasSkills ||
      !hasMetrics ||
      !role ||
      !hasZeroToOneExperience ||
      !hasManagedPeople ||
      !numberOfPeopleManaged
    );
  };

  const hasRemainingFields = getHasRemainingFields();

  if (suggestion) {
    return (
      <Created
        isLoading={isLoading}
        onGenerateSuggestion={onGenerateSuggestion}
        onDiscardSuggestion={onDiscardSuggestion}
        onInsertSuggestion={onInsertSuggestion}
        suggestion={suggestion}
      />
    );
  }

  if (
    (hasInsertedSuggestion || !isNewProject) &&
    charCount > 99 &&
    hasEditedAfterInsert
  ) {
    return (
      <AvailableAfterSuggestion
        isLoading={isLoading}
        onGenerateSuggestion={onGenerateSuggestion}
      />
    );
  }

  if (hasRemainingFields && hasInsertedSuggestion) {
    return <Remaining />;
  }

  if (isDiscarded || hasEditedAfterInsert) {
    return null;
  }

  if (charCount > 99) {
    return (
      <Available
        isLoading={isLoading}
        onGenerateSuggestion={onGenerateSuggestion}
      />
    );
  }

  return <Empty />;
};

export default Suggestion;

const Empty = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.desc}>
        <Icon size="smaller" type={IconType.SuggestedTeams} />
        Highlight the company's mission or purpose, and then transition into the
        specific project you contributed to.
      </div>
    </div>
  );
};

const Remaining = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.desc}>
        <Icon size="smaller" type={IconType.SuggestedTeams} />
        Fill out remaining empty fields to improve the description suggestion.
      </div>
    </div>
  );
};

interface AvailableProps {
  onGenerateSuggestion: () => void;
  isLoading: boolean;
}

const Available = ({ onGenerateSuggestion, isLoading }: AvailableProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.desc}>
          <Icon size="smaller" type={IconType.SuggestedTeams} />
          Suggested description available. Continue adding details for better
          suggestions.
        </div>
        <Button
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            onGenerateSuggestion();
          }}
          className={styles.btn}
        >
          {isLoading ? (
            <Spinner size={14} color="white" />
          ) : (
            <Icon type={IconType.CrystalBallWhite} size="exact" />
          )}
          {isLoading ? 'Writing' : 'Start writing'}
        </Button>
      </div>
    </div>
  );
};

const AvailableAfterSuggestion = ({
  onGenerateSuggestion,
  isLoading,
}: AvailableProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.desc}>
          <Icon size="smaller" type={IconType.SuggestedTeams} />
          Suggested description available.
        </div>
        <Button
          disabled={isLoading}
          onClick={(e) => {
            e.preventDefault();
            onGenerateSuggestion();
          }}
          className={styles.btn}
        >
          {isLoading ? (
            <Spinner size={14} color="white" />
          ) : (
            <Icon type={IconType.CrystalBallWhite} size="exact" />
          )}
          {isLoading ? 'Writing' : 'Start writing'}
        </Button>
      </div>
    </div>
  );
};

interface CreatedProps {
  suggestion: ProjectDescriptionBlurbNode[];
  onInsertSuggestion: (suggestion: ProjectDescriptionBlurbNode[]) => void;
  onDiscardSuggestion: (suggestion: ProjectDescriptionBlurbNode[]) => void;
  onGenerateSuggestion: () => void;
  isLoading: boolean;
}

const Created = ({
  onInsertSuggestion,
  onDiscardSuggestion,
  onGenerateSuggestion,
  suggestion,
  isLoading,
}: CreatedProps) => {
  const styles = useStyles();

  // Create an array of JSX elements for the titles and descriptions
  const renderSuggestions = suggestion.map((node, index) => {
    if (node.type === ProjectDescriptionBlurbNodeType.Title) {
      return (
        <div key={index} className={styles.suggestedTitle}>
          {node.content}
        </div>
      );
    } else if (node.type === ProjectDescriptionBlurbNodeType.Paragraph) {
      const descWithLineBreaks = node.content
        .split('\n')
        .map((item, i) => <p key={i}>{item}</p>);
      return (
        <div key={index} className={styles.suggestedDescription}>
          {descWithLineBreaks}
        </div>
      );
    }
    return null;
  });

  return (
    <div className={styles.container}>
      <div className={styles.desc}>
        <Icon size="smaller" type={IconType.SuggestedTeams} />
        Suggested description
      </div>
      <div>
        {renderSuggestions}
        <div className={styles.btnsContainer}>
          <div className={styles.btnsContainer}>
            <Button
              className={styles.btn}
              onClick={(e) => {
                e.preventDefault();
                onInsertSuggestion(suggestion);
              }}
            >
              <span className={styles.hideOnDesktop}>Use</span>
              <span className={styles.hideOnMobile}>Use suggestion</span>
            </Button>
            <Button
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                onGenerateSuggestion();
              }}
              className={cx(styles.btn, styles.rewriteDiscardBtn)}
            >
              {isLoading ? (
                <Spinner size={14} color="black" />
              ) : (
                <Icon type={IconType.CrystalBall} size="exact" />
              )}
              Rewrite
            </Button>
          </div>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onDiscardSuggestion(suggestion);
            }}
            className={cx(styles.btn, styles.rewriteDiscardBtn)}
          >
            Discard
          </Button>
        </div>
      </div>
    </div>
  );
};
