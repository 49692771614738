import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import { TalentIndustry } from '@a_team/models/dist/TalentIndustry';
import { fetchIndustryList } from '@src/helpers/talent-industry-api';

export const useQueryTalentIndustries = ({
  query,
  onSuccess,
  enabled = true,
}: {
  query?: string;
  onSuccess?: (data: TalentIndustry[]) => void;
  enabled?: boolean;
} = {}) => {
  return useQuery({
    queryKey: queryKeys.industries.list(query || ''),
    queryFn: async () => {
      const items = await fetchIndustryList({
        filter: { query: query },
      });

      return items.map(({ id, name }) => ({
        id,
        name,
        featured: true,
      }));
    },
    onSuccess: onSuccess,
    enabled: enabled,
  });
};
