import React from 'react';
import {
  ManagementExperience,
  ManagementExperienceLabels,
} from '@a_team/models/dist/vetting-processes/pre-vetting';
import { Select, SelectProps } from '@ateams/components';
import { InputContainer } from '../components/input-container';
import { InputLabel } from '../components/input-label';

const ManagementExperienceOptions = Object.entries(
  ManagementExperienceLabels,
).map(([key, label]) => ({ value: key, label }));

export type OnManagementExperienceChange = (
  didBuildProductsFromScratch: ManagementExperience,
) => void;

export interface ManagementExperienceSelectProps {
  onChange?: OnManagementExperienceChange;
  error?: string;
  onBlur?: () => void;
  className?: string;
  selectClassName?: string;
  'data-testing-id'?: string;
}

export const ManagementExperienceSelect: React.FC<
  ManagementExperienceSelectProps
> = (props) => {
  const onChange: SelectProps['onChange'] = (option) => {
    if (option) {
      const newManagementExperience = option.value as ManagementExperience;

      props.onChange?.(newManagementExperience);
    }
  };

  return (
    <InputContainer error={props.error} className={props.className}>
      <InputLabel>Do You Have Management Experience? *</InputLabel>

      <Select
        placeholder={'Select management experience...'}
        onChange={onChange}
        options={ManagementExperienceOptions}
        className={props.selectClassName}
        error={Boolean(props.error)}
        onBlur={props.onBlur}
        data-testing-id={props['data-testing-id']}
      />
    </InputContainer>
  );
};
