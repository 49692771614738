import {
  MissionCardObject,
  MissionStatus,
  RecommendedMission,
} from '@a_team/models/dist/MissionObject';
import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import companyLikedYouIcon from '@src/assets/svgs/company-liked-you-star.svg';
import { observer } from 'mobx-react';
import { useStores } from '@src/stores';
import { Breakpoints, Colors } from '@ateams/components';
import stripHtml from 'striptags';
import { VideoPlayer } from '@src/components/VideoPlayer';
import { MissionLocation } from '@src/locations';
import { getRecommendationMatchingCriteria } from '../RecommendedMissionCard';
import ateamPurpleIcon from './ateam-purple.svg';
import { Modal } from '@a_team/ui-components';
import useMediaQuery from '@src/hooks/useMediaQuery';
import cx from 'classnames';
import PlayMissionVideo from './PlayMissionVideo';
import NotInterested from './NotInterested';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { generateThumbnail } from '@src/helpers/images';
import NoVideo from './NoVideo';
import AspectRatioBox from '@src/components/AspectRatioBox';
import { Link } from 'react-router-dom';
import NotInterestedConfirmationModal from './NotInterestedConfirmationModal';
import TooltipWrapped from '@src/components/TooltipWrapped';
import { useScreenClass } from 'react-grid-system';
import { decodeHtmlEntities, joinWithCommasAnd } from '@src/logic/utils';
import {
  getAllAdditionalTalentCategoryIds,
  getFirstRoleToHighlight,
  getVisibleRoles,
  groupAndPrefix,
  isValidSecondaryRole,
} from './utils';
import TeamStatus from './TeamStatus';
import NotPublished from '@src/components/NotPublished';
import { MissionRoleStatus } from '@a_team/models/dist/MissionRole';

interface MissionControlCardV2Props {
  mission: MissionCardObject | RecommendedMission | undefined;
  showVideo?: boolean;
  hideMatchedOn?: boolean;
  companyLiked?: boolean;
  number?: number;
}

const useStyles = createUseStyles({
  container: {
    height: '100%',
    position: 'relative',
    padding: 16,
    background: 'white',
    border: '1px solid #DADADC',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
    flex: '0 0 100%',
    transition: 'all 0.2s',
    '&:hover': {
      borderColor: Colors.secondaryDark,
    },
  },
  wrapper: {
    width: '100%',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // remove link styles
    '&, &:hover, &:active, &:visited': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  imageContainer: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
    overflow: 'hidden',
    borderRadius: '8px',
    '& > img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
  },
  companyName: {
    marginTop: 16,
    textAlign: 'center',
    fontSize: '14px',
  },
  missionTitle: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 15,
  },
  roles: {
    textAlign: 'center',
    color: '#62646A',
    fontSize: 14,
  },
  description: {
    marginTop: 'auto',
    paddingTop: 16,
    maxWidth: '100%',
    marginBottom: '4px',
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  companyMainInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
  },
  noLogo: {
    height: 48,
    width: 48,
    borderRadius: '8px',
    background:
      'linear-gradient(197.15deg, rgb(148, 254, 12) 0%, rgb(84, 214, 255) 88.21%);',
  },
  companyLikedYouIcon: {
    position: 'absolute',
    top: '16px',
    left: '16px',
    transition: 'color 0.3s ease, transform 0.3s ease',
    cursor: 'default',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  notInterested: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  roleCategory: {
    color: '#6D00D7',
  },
  videoPlayer: {
    aspectRatio: '16 / 9',
    marginTop: 'auto',
    width: '100%',
    height: 'auto',
    background: '#f8f9fa',
  },
  matchedOnText: {
    margin: '24px 0',
    color: '#6D00D7',
    textAlign: 'center',
    '& > img': {
      marginRight: 5,
      height: 16,
      width: 16,
      verticalAlign: 'text-top',
    },
  },
  videoPreview: {
    pointerEvents: 'none',
  },
  videoImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    aspectRatio: '16 / 9',
    marginTop: 'auto',
    borderRadius: 8,
  },
  videoWrapper: {
    width: '100%',
    borderRadius: 8,
    cursor: 'pointer',
    marginTop: 'auto',
  },
  empty: {
    cursor: 'default',
  },
  thumbnailContainer: {
    position: 'relative',
  },
  modal: {
    padding: 10,
    width: '85vw !important',
    height: 'fit-content !important',
  },
  player: {
    position: 'absolute',
    bottom: 15,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  teamSection: {
    marginTop: 24,
  },
});

const MissionControlCardV2 = ({
  mission,
  showVideo,
  hideMatchedOn,
  companyLiked,
  number,
}: MissionControlCardV2Props): JSX.Element => {
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.md}px)`);
  const styles = useStyles();
  const screenClass = useScreenClass();
  const analytics = useAnalytics();
  const { auth, missionControl } = useStores();
  const [play, setPlay] = useState(false);
  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  const [
    showNotInterestedConfirmationModal,
    setShowNotInterestedConfirmationModal,
  ] = useState(false);

  const [showVideoModal, setShowVideoModal] = useState(false);

  const openRoles = useMemo(() => {
    if (!mission) return [];

    return getVisibleRoles(mission.roles, auth.isAdmin);
  }, [mission?.roles]);

  const missionTitle = useMemo(() => {
    if (!mission) return '';
    const parts = mission.title.split(':');
    return parts.length > 1 ? parts.slice(1).join(':').trim() : mission.title;
  }, [mission?.title]);

  const userCategory = auth.currentUser?.talentProfile;

  // If there's no user category, or mainTalentCategoryId is absent, the primary role won't exist
  const mainTalentCategoryId = userCategory?.mainTalentCategoryId;
  const primaryMatchedRole = useMemo(() => {
    return mainTalentCategoryId
      ? openRoles.find((openRole) =>
          openRole.category.talentCategoryIds.includes(mainTalentCategoryId),
        )
      : undefined;
  }, [openRoles, mainTalentCategoryId]);

  const primaryMatchedRoleTitle = primaryMatchedRole?.category.title;

  // Extract additional talent category IDs
  const userAdditionalTalentCategoryIds = useMemo(
    () => getAllAdditionalTalentCategoryIds(userCategory),
    [userCategory],
  );

  let rolesToHighlight = useMemo(() => {
    const secondaryMatchedRolesTitles = openRoles
      .filter((openRole) =>
        isValidSecondaryRole(
          openRole,
          primaryMatchedRole,
          userAdditionalTalentCategoryIds,
        ),
      )
      .map((role) => role.category.title);

    return [
      ...(primaryMatchedRoleTitle ? [primaryMatchedRoleTitle] : []),
      ...secondaryMatchedRolesTitles,
    ];
  }, [
    openRoles,
    isValidSecondaryRole,
    primaryMatchedRole,
    userAdditionalTalentCategoryIds,
    primaryMatchedRoleTitle,
  ]);
  const matchedRolesFound = rolesToHighlight.length > 0;

  if (!matchedRolesFound && openRoles.length > 0) {
    rolesToHighlight = getFirstRoleToHighlight(openRoles);
  }

  const stripDescriptionHtml = (description: string): string => {
    const strippedDescription = stripHtml(description);
    return decodeHtmlEntities(strippedDescription);
  };

  const otherRolesCount = openRoles.length - rolesToHighlight.length;

  const rolesText =
    otherRolesCount === 1
      ? 'and 1 other role'
      : `and ${otherRolesCount} other roles`;

  const description = useMemo(() => {
    if (!mission) return '';
    return stripDescriptionHtml(mission.description);
  }, [mission?.description]);

  const criteriasText: string[] = mission
    ? getRecommendationMatchingCriteria(mission)
    : [];

  const matchedOnText = useMemo(() => {
    if (!criteriasText || criteriasText.length === 0) {
      return '';
    }

    if (criteriasText.length === 1) {
      return `Matched on ${criteriasText[0]}`;
    }

    if (criteriasText.length === 2) {
      return `Matched on ${criteriasText[0]} and ${criteriasText[1]}`;
    }

    const displayedSkills = criteriasText.slice(0, 2);
    const otherSkillsCount = criteriasText.length - 2;

    return `Matched on ${displayedSkills.join(', ')}, and ${
      otherSkillsCount > 1
        ? '+' + otherSkillsCount + ' more skills'
        : '+1 more skill'
    }`;
  }, [criteriasText]);

  const thumbnail = generateThumbnail(mission?.videoURL);

  const handleShowVideo = () => {
    if (isDesktop) {
      setShowVideoModal(true);
    } else {
      setPlay(!play);
    }
  };

  const isNotPublished = mission?.status === MissionStatus.Created;

  const roles = () => {
    const rolesToHighlightText = groupAndPrefix(rolesToHighlight).join(', ');

    const content =
      rolesToHighlight.length > 0 ? (
        <>
          <span
            className={cx(matchedRolesFound ? styles.roleCategory : undefined)}
          >
            {rolesToHighlightText}
          </span>{' '}
          {otherRolesCount > 0 && <span>{rolesText}</span>}
        </>
      ) : otherRolesCount > 0 ? (
        `${otherRolesCount} ${otherRolesCount > 1 ? 'roles' : 'role'}`
      ) : (
        ''
      );

    if (otherRolesCount === 0) {
      return content;
    }

    const nonHighlightedRoles = openRoles.filter(
      (openRole) =>
        !rolesToHighlight.some((role) => role === openRole.category.title),
    );

    const roleTitles = nonHighlightedRoles.map(
      (openRole) => openRole.category.title,
    );

    const tooltipTitle = joinWithCommasAnd(groupAndPrefix(roleTitles));

    return (
      <div
        onClick={(e) => {
          if (isMobile) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        <TooltipWrapped
          title={tooltipTitle}
          style={{ cursor: 'pointer' }}
          trigger={isMobile ? 'click' : 'mouseenter'}
        >
          {content}
        </TooltipWrapped>
      </div>
    );
  };

  const onHide = (): void => {
    if (!mission) return;
    const recommendedIndices = missionControl.getRecommendedMissionIndices;
    const recommended = recommendedIndices.get(mission.mid) !== undefined;
    analytics.trackMissionCardNotInterested(mission, recommended, true);
    missionControl.setNotInterested(mission.mid);
    setShowNotInterestedConfirmationModal(false);
  };

  const hasAtLeastOneOpenRoleLookingForMoreApplicants = useMemo(
    () =>
      mission?.roles.some(
        (role) =>
          role.status === MissionRoleStatus.Open && role.lookingForApplications,
      ) ?? false,
    [mission?.roles],
  );

  return (
    <div className={styles.container}>
      <Link
        to={`${MissionLocation}/${mission?.mid}`}
        className={styles.wrapper}
      >
        {companyLiked && auth.withBuilderLikes && (
          <img
            title={`${mission?.companyName} starred you`}
            className={styles.companyLikedYouIcon}
            src={companyLikedYouIcon}
            alt={`${mission?.companyName} starred you`}
          />
        )}

        {auth.isAdmin && isNotPublished ? (
          <NotPublished className={styles.notInterested} />
        ) : (
          <NotInterested
            onClick={() => {
              setShowNotInterestedConfirmationModal(true);
            }}
            className={styles.notInterested}
          />
        )}

        <div className={styles.imageContainer}>
          {mission?.logoURL ? (
            <img src={mission.logoURL} alt="Company Logo" />
          ) : (
            <div className={styles.noLogo} />
          )}
        </div>
        <div className={styles.companyMainInfo}>
          <div className={styles.companyName}>{mission?.companyName}</div>
          <div className={styles.missionTitle}>{missionTitle}</div>
          <div className={styles.roles}>
            {roles()}
            <div className={styles.teamSection}>
              <TeamStatus
                hasAtLeastOneOpenRoleLookingForMoreApplicants={
                  hasAtLeastOneOpenRoleLookingForMoreApplicants
                }
              />
            </div>
          </div>

          {matchedOnText && !hideMatchedOn && (
            <div className={styles.matchedOnText}>
              <img src={ateamPurpleIcon} alt="Ateam Purple Icon" />
              {matchedOnText}
            </div>
          )}
        </div>
        {!showVideo && <div className={styles.description}>{description}</div>}
      </Link>
      {showVideo &&
        (mission?.videoURL ? (
          <div className={styles.videoWrapper} onClick={handleShowVideo}>
            {thumbnail && isDesktop ? (
              <AspectRatioBox aspectRatio={16 / 9}>
                <div className={styles.thumbnailContainer}>
                  <img
                    className={styles.videoImage}
                    src={thumbnail}
                    alt="No Mission"
                  />
                  <PlayMissionVideo className={styles.player} />
                </div>
              </AspectRatioBox>
            ) : (
              <VideoPlayer
                playing={!isDesktop && play}
                light={
                  thumbnail ? (
                    <div className={styles.thumbnailContainer}>
                      <img
                        className={styles.videoImage}
                        src={thumbnail}
                        alt="No Mission"
                      />
                      <PlayMissionVideo className={styles.player} />
                    </div>
                  ) : undefined
                }
                hideLoading={!!thumbnail}
                controls={!isDesktop}
                className={cx(
                  styles.videoPlayer,
                  isDesktop ? styles.videoPreview : undefined,
                )}
                url={mission?.videoURL}
                noBorder={true}
                borderRadius={0}
                width={'100%'}
                height={'100%'}
                hideLoomFields
              />
            )}
          </div>
        ) : (
          <NoVideo
            companyLogo={mission?.logoURL}
            number={number || 0}
            className={cx(styles.empty)}
          />
        ))}
      <Modal
        className={styles.modal}
        isOpen={showVideoModal}
        onClose={() => setShowVideoModal(false)}
      >
        {showVideoModal && (
          <VideoPlayer
            playing
            className={styles.videoPlayer}
            url={mission?.videoURL}
            noBorder={true}
            borderRadius={0}
            width={'100%'}
            height={'100%'}
            hideLoomFields
          />
        )}
      </Modal>
      <NotInterestedConfirmationModal
        open={showNotInterestedConfirmationModal}
        onClose={() => setShowNotInterestedConfirmationModal(false)}
        onConfirm={onHide}
      />
    </div>
  );
};

export default observer(MissionControlCardV2);
