import React from 'react';
import { createUseStyles } from 'react-jss';
import { TeamPulseSectionsEnum } from './TeamPulseModal';
import calendlyIcon from './assets/calendly.svg';
import { Breakpoints, TextColors } from '@ateams/components';
import ControlButtons from './common/ControlButtons';

interface ScheduleCallProps {
  setSection: (section: string) => void;
  setUserReviewSection: (section: string) => void;
  needsToReviewUsers?: boolean;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 40,
    height: '100%',
    justifyContent: 'space-between',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    gap: 36,
    padding: 20,
  },
  title: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'center',
  },
  button: {
    height: 40,
    padding: '8px 24px',
    width: 'fit-content',
    background: '#006BFF',
    borderRadius: 100,
    color: TextColors.info,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    justifyContent: 'center',
  },
  [`@media (min-width: ${Breakpoints.md}px)`]: {
    contentWrapper: {
      padding: 0,
    },
  },
});

const ScheduleCall = ({
  setSection,
  setUserReviewSection,
  needsToReviewUsers,
}: ScheduleCallProps): JSX.Element => {
  const styles = useStyles();

  const onSkip = (): void => {
    if (needsToReviewUsers) {
      setUserReviewSection(TeamPulseSectionsEnum.TeamMemberRating);
    } else {
      setSection(TeamPulseSectionsEnum.AllDone);
    }
  };

  const onScheduleCall = (): void => {
    if (needsToReviewUsers) {
      setUserReviewSection(TeamPulseSectionsEnum.TeamMemberRating);
    } else {
      setSection(TeamPulseSectionsEnum.AllDone);
    }
  };

  const onBack = (): void => {
    setSection(TeamPulseSectionsEnum.TellUsMore);
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h4 className={styles.title}>
          Thanks for sharing. We’d love to help resolve this issue. Let’s hop on
          a call and work it out
        </h4>
        <a
          onClick={onScheduleCall}
          target="_blank"
          rel="noreferrer"
          href="https://calendly.com/d/g2v-xdx-5ts/a-teamer-mission-check-in?month=2022-10"
          className={styles.button}
        >
          <img src={calendlyIcon} alt="refer" />
          Schedule a Call
        </a>
      </div>
      <ControlButtons onContinue={onSkip} onBack={onBack} />
    </div>
  );
};

export default ScheduleCall;
