import { Icon, IconType } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import {
  BuilderSuggestionType,
  SuggestionActionType,
} from '@a_team/models/dist/ProfileEnhancements';
import { usePartiallyRejectSuggestion } from '@src/rq/profileSuggestions';
import { useAnalytics } from '@ateams/analytics/dist/platform';
import { UserId } from '@a_team/models/dist/UserObject';
import { isNil } from 'lodash';

interface SuggestionProps {
  suggestionId?: string;
  partialSuggestionId?: string;
  type: BuilderSuggestionType;
  userId: UserId;
  label?: string;
  subLabel?: string;
  metaData?: string;
  adminOnly?: boolean;
  onAccept?: (partialSuggestionId: string) => Promise<void> | void;
}

const useStyles = createUseStyles({
  container: ({ subLabel }) => ({
    width: subLabel ? '100%' : 'fit-content',
    display: 'flex',
    color: '#6D00D7',
    background: '#EDE0FF',
    // padding: '4px 10px',
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: subLabel ? 'flex-start' : 'center',
    // gap: 4,
    fontSize: 12,
  }),
  separator: {
    height: 15,
    width: 1.5,
    background: '#D4A8FF',
  },
  // remove button styles
  iconContainer: {
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      cursor: 'pointer',
    },
  },
  iconBtn: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    margin: 0,
    '& i': {
      cursor: 'pointer',
    },
  },
  suggestionText: ({ canAccept, subLabel }) => ({
    padding: '4px 6px 4px 10px',
    width: subLabel ? '80%' : 'auto',
    ...(canAccept
      ? {
          // if we can accept a suggestin individually
          // we show the check icon to accept and clicking on
          // label triggers no action so we show default cursor
          cursor: 'default',
        }
      : {
          // if we can't individually accept a suggestion,
          // it means we'll be opening a Modal so we show hand cursor
          // to indicate clickable label
          cursor: 'pointer',
        }),
  }),
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  labels: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: ({ subLabel }) => ({
    fontWeight: subLabel ? 600 : 400,
    fontSize: subLabel ? 14 : 12,
  }),
  subLabel: {
    fontWeight: 400,
  },
  metaData: {
    fontWeight: 400,
  },
});

const Suggestion = ({
  userId,
  label,
  subLabel,
  metaData,
  suggestionId,
  type,
  partialSuggestionId,
  adminOnly,
  onAccept,
}: SuggestionProps): JSX.Element => {
  const styles = useStyles({ canAccept: !isNil(onAccept), subLabel });
  const analytics = useAnalytics();
  const { mutateAsync: rejectSuggestion, isLoading: isRejectingSuggestion } =
    usePartiallyRejectSuggestion();

  const handleRemove = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    if (!suggestionId || !partialSuggestionId) {
      return;
    }

    try {
      await rejectSuggestion({
        sid: suggestionId,
        type,
        partialSuggestionId,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleAccept = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (!onAccept || !partialSuggestionId || !suggestionId) {
      return;
    }
    event.stopPropagation();

    try {
      await onAccept(partialSuggestionId);

      analytics.trackProfileSuggestionInteraction(
        userId,
        suggestionId,
        SuggestionActionType.ACCEPT,
        type,
      );
    } catch (err) {
      console.error(err);
    }
  };

  const renderLabel = () => {
    if (!subLabel) {
      return <span className={styles.label}>{label}</span>;
    }

    return (
      <div className={styles.labelContainer}>
        <div className={styles.labels}>
          <span className={styles.label}>{label}</span>
          <span className={styles.subLabel}>{subLabel}</span>
        </div>
        <div className={styles.metaData}>{metaData}</div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.suggestionText}>{renderLabel()}</div>
      {!adminOnly && !isNil(onAccept) && (
        <button
          onClick={handleAccept}
          disabled={isRejectingSuggestion}
          className={cx(styles.iconContainer, styles.iconBtn)}
        >
          <Icon size="exact" type={IconType.CheckPurple} title="Accept" />
        </button>
      )}
      {!adminOnly && <span className={styles.separator} />}
      {!adminOnly && (
        <button
          onClick={handleRemove}
          disabled={isRejectingSuggestion}
          className={cx(styles.iconContainer, styles.iconBtn)}
        >
          <Icon size="exact" type={IconType.RemovePurple} title="Reject" />
        </button>
      )}
    </div>
  );
};

export default Suggestion;
