import { find, orderBy } from 'lodash';
import TargeterSearchObject, {
  TargeterSearchTab,
} from '@a_team/models/dist/TargeterSearchObject';
import { SkillTargeterUserObject } from '@a_team/models/dist/TeamGraphObject';
import { UserUsername } from '@a_team/models/dist/UserObject';
import { TargeterSearchData } from '@ateams/api/dist/endpoints/TeamGraph';
import { TargeterLocation } from '@src/locations';
import {
  DEFAULT_TAB,
  SelectableBuilder,
  TargeterTabCache,
  TargeterSearchCache,
} from '@src/stores/TeamGraph/TargeterTabManager';
import { TargeterSuggestedTeamsSidebarParameters } from '@a_team/models/dist/TargeterSuggestedTeams';

export function getTargeterUrlFromQuery(query: string): string {
  return [TargeterLocation, ...(query ? [query] : [])].join('?');
}

export function getCurrentTabData(
  cache: TargeterTabCache,
  activeTab: string,
): {
  tab: TargeterSearchCache;
  tabArray: TargeterSearchCache[];
} {
  const query = window.location.search.substr(1) ?? '';

  const tab = Object.keys(cache).includes(activeTab)
    ? cache[activeTab]
    : find(cache, ({ url }) => url === query) ?? { ...DEFAULT_TAB, url: query };

  const tabArray = orderBy(cache, 'position').map((tabCache, position) => ({
    ...tabCache,
    position,
  }));

  return { tab, tabArray };
}

export function cacheToSearchData(
  name: string,
  searchKey: string,
  cache: TargeterSearchCache[],
  activeTabIndex: number,
  suggestedTeamsParameters: TargeterSuggestedTeamsSidebarParameters,
): TargeterSearchData {
  return {
    name,
    searchKey,
    tabs: orderBy(cache, 'position').map((tab) => ({
      label: tab.label,
      url: tab.url,
      selectedBuilders: tab.selectedBuilders.map(
        ({ user, hourlyRate, blurb, skills }) => ({
          user: user.uid,
          hourlyRate,
          blurb,
          skills,
        }),
      ),
    })),
    activeTabIndex,
    suggestedTeamsParameters,
  } as TargeterSearchData;
}

export function searchToCache(search: TargeterSearchObject): TargeterTabCache {
  return search.tabs.reduce(
    (tabs: TargeterTabCache, tab, position) => ({
      ...tabs,
      [tab.label]: { ...tab, position, builders: [], next: undefined },
    }),
    {},
  );
}

export function getSelectedBuilders(
  selectedBuilders: TargeterSearchTab['selectedBuilders'],
  builders: SelectableBuilder[],
): SelectableBuilder[] {
  const selectionMap = new Map<UserUsername, boolean>(
    selectedBuilders.map(({ user }) => [user.username, true]),
  );

  return builders.map((user) => ({
    ...user,
    select: selectionMap.get(user.username),
  }));
}

const RATE_INCREMENT = 5;

export function getUserWithDefaultRate(user: SelectableBuilder): {
  user: SkillTargeterUserObject;
  hourlyRate?: number;
  blurb?: string;
  skills?: string[];
} {
  const hourlyRate = user.rateRange?.min
    ? RATE_INCREMENT * Math.ceil((1.2 * user.rateRange.min) / RATE_INCREMENT)
    : undefined;

  return { user, hourlyRate, blurb: '', skills: [] };
}
