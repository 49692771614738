import { apiUser } from '@ateams/api';
import { REACT_APP_CALCOM_URL } from '@src/config';
import { profileKeys } from '@src/rq/keys/profile';
import AuthStore from '@src/stores/Auth';
import { QueryClient } from '@tanstack/react-query';

const DELAY_POLL_INTERVAL = 1000; // poll per second

export const openCalComLinkWithAutoLogin = (path: string) => {
  window.open(
    `${REACT_APP_CALCOM_URL}/auth/login?autoLogin=true&callbackUrl=${encodeURIComponent(
      path,
    )}`,
    '_blank',
  );
};

export const goToAddGoogleCalendarClick = (
  auth: AuthStore,
  rqClient: QueryClient,
  initialPath: string,
) => {
  openCalComLinkWithAutoLogin('/apps/google-calendar');

  pollCalendarSetup(auth, rqClient, initialPath);
};

export const goToAddAppleCalendarClick = (
  auth: AuthStore,
  rqClient: QueryClient,
  initialPath: string,
) => {
  openCalComLinkWithAutoLogin('/apps/apple-calendar');

  pollCalendarSetup(auth, rqClient, initialPath);
};

export const goToAddOutlookCalendarClick = (
  auth: AuthStore,
  rqClient: QueryClient,
  initialPath: string,
) => {
  openCalComLinkWithAutoLogin('/apps/office365-calendar');

  pollCalendarSetup(auth, rqClient, initialPath);
};

export const goToManageCalendarClick = () => {
  openCalComLinkWithAutoLogin('/apps/installed/calendar');
};

export const goToWorkingHoursClick = (scheduleId?: number) => {
  openCalComLinkWithAutoLogin(`/availability/${scheduleId}`);
};

const pollCalendarSetup = async (
  auth: AuthStore,
  rqClient: QueryClient,
  initialPath: string,
): Promise<void> => {
  let timer: ReturnType<typeof setTimeout> | undefined = undefined;
  const currentPath = window.location.pathname;

  try {
    const calendar = await apiUser.getUserCalendar(auth);

    if (!calendar.calcom?.selectedCalendar && initialPath === currentPath) {
      timer = setTimeout(
        () => pollCalendarSetup(auth, rqClient, initialPath),
        DELAY_POLL_INTERVAL,
      );
    } else {
      if (timer) {
        clearTimeout(timer);
      }
      rqClient.setQueryData(profileKeys.calendar(auth.uid ?? ''), calendar);
      // somce we have multiple ways to connect, not possible to dismiss dialog everywhere, so we just refresh the page to have calendar fully loaded
      window.location.reload();
    }
  } catch (err) {
    // do nothing
  }
};

export const getSupport = () => window.open('mailto:support@a.team');
