import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useCommonStyles } from '../commonStyles';
import { Button, Checkbox } from '@a_team/ui-components';
import { useMutationOptoutCalendar } from '@src/rq/profile';
import MessageSent from '../ReasonsModal/svgs/sent.svg';
import { NoCalendarReason } from '@a_team/models/dist/UserObject';
import { InterviewAvailability } from '../InterviewAvailability';

interface OptoutProps {
  mainReason: NoCalendarReason;
  reasonText?: string;
  onSuccess: () => void;
  onClose: () => void;
}

export const Optout = (props: OptoutProps) => {
  const styles = useCommonStyles();
  const [discoveryOptout, setDiscoveryOptout] = useState(false);
  const [interviewOptout, setInterviewOptout] = useState(false);
  const [opportunityOptout, setOpportunity] = useState(false);
  const [autoAvailabilityOptout, setAutoAvailabilityOptout] = useState(false);
  const [showSetAvailability, setShowSetAvailability] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { mutate: optoutCalendar } = useMutationOptoutCalendar();

  useEffect(() => {
    window.onmessage = function (e) {
      if (e.data === 'USER_AVAILABILITY_UPDATED') {
        handleAvailabilitySet();
      }
    };
  }, []);

  const handleAvailabilitySet = () => {
    optoutCalendar({
      data: {
        mainReason: props.mainReason,
        reasonText: props.reasonText,
      },
      onSuccess: () => setShowSuccess(true),
    });
  };

  if (showSuccess) {
    return (
      <>
        <div className={styles.hero}>
          <img
            src={MessageSent}
            className={styles.heroImg}
            alt="Message Sent"
          />
          <h4 className={styles.modalTitle}>
            You’re ready to apply to missions
          </h4>
          <p className={cx(styles.heroCopy, 'lessPadding')}>
            Thanks for setting your availability! You can update it any time on
            the interview page in settings.
          </p>
        </div>
        <div className={styles.actions} style={{ paddingTop: 32 }}>
          <Button
            className={styles.closeBtn}
            variant="main"
            size="sm"
            onClick={props.onSuccess}
          >
            Done
          </Button>
        </div>
      </>
    );
  }

  if (showSetAvailability) {
    return (
      <InterviewAvailability
        title="Confirm your working hours"
        copy="Confirm when you are available for mission interviews. Clients can schedule interviews during the times you specify below for any mission you’ve applied to."
        fullWidth
      />
    );
  }

  return (
    <>
      <div className={styles.hero}>
        <h4 className={styles.modalTitle}>
          By not connecting my calendar, I understand:
        </h4>
        <p className={cx(styles.heroCopy, 'lessPadding')}>
          By choosing not to link your calendar, you confirm that you have read
          and understood the following.
        </p>
      </div>
      <div className={styles.howtoWrap}>
        <div className={cx(styles.panel, 'fixed')}>
          <div className={styles.panelHeader}>
            <h5 className={styles.panelTitle}>
              My profile will not be discoverable in search
            </h5>
            <Checkbox
              checked={discoveryOptout}
              onChange={() => setDiscoveryOptout(!discoveryOptout)}
            />
          </div>
          <p className={styles.panelCopy}>
            Without a connected calendar, clients aren’t able to see your
            profile in search or discovery.{' '}
            <a
              className={styles.linkButtonLight}
              href="https://guide.a.team/getting-started/getting-discovered-selected-for-interviews"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
            .
          </p>
        </div>
        <div className={cx(styles.panel, 'fixed')}>
          <div className={styles.panelHeader}>
            <h5 className={styles.panelTitle}>
              My profile will not be eligible for direct interview booking
            </h5>
            <Checkbox
              checked={interviewOptout}
              onChange={() => setInterviewOptout(!interviewOptout)}
            />
          </div>
          <p className={styles.panelCopy}>
            You can only be booked for interviews on missions you applied for.
            Clients cannot request interviews with you directly.
          </p>
        </div>
        <div className={cx(styles.panel, 'fixed')}>
          <div className={styles.panelHeader}>
            <h5 className={styles.panelTitle}>
              I may miss out on opportunities
            </h5>
            <Checkbox
              checked={opportunityOptout}
              onChange={() => setOpportunity(!opportunityOptout)}
            />
          </div>
          <p className={styles.panelCopy}>
            Clients won’t be able to reach out directly about opportunities that
            aren’t published in mission control since your profile isn’t
            eligible for search and discovery.
          </p>
        </div>
        <div className={cx(styles.panel, 'fixed')}>
          <div className={styles.panelHeader}>
            <h5 className={styles.panelTitle}>
              It’s my responsibility to keep my working hours up to date
            </h5>
            <Checkbox
              checked={autoAvailabilityOptout}
              onChange={() =>
                setAutoAvailabilityOptout(!autoAvailabilityOptout)
              }
            />
          </div>
          <p className={styles.panelCopy}>
            When you apply to a mission, companies can book an interview with
            you at any time during your set working hours.
          </p>
        </div>
        <div className={styles.actions} style={{ paddingTop: 32 }}>
          <Button
            className={styles.closeBtn}
            variant="secondary"
            size="sm"
            onClick={props.onClose}
          >
            Back
          </Button>
          <Button
            disabled={
              !discoveryOptout ||
              !interviewOptout ||
              !autoAvailabilityOptout ||
              !opportunityOptout
            }
            className={styles.primaryButton}
            variant="main"
            size="sm"
            onClick={() => setShowSetAvailability(true)}
          >
            Set availability
          </Button>
        </div>
      </div>
    </>
  );
};
