import { MissionApplicationBadgeStatus } from '@a_team/models/dist/MissionApplicationObject';

const Colors = {
  regular: '#C0C0C0',
  regularLight: '#EDEDED',
  regularDark: '#62646A',
  // Primary
  primary: '#fe630c',
  primaryDark: '#DF5101',
  primaryLight: '#FFCBAE',
  primaryVeryLight: '#FFFAF7',
  // Secondary
  secondary: '#ab54ff',
  secondaryDark: '#6D00D7',
  secondaryLight: '#E8D0FF',
  // Danger
  danger: '#f63131',
  dangerDark: '#B81F1F',
  dangerLight: '#FFCDCD',
  // Warnings
  warning: '#FEBA0C',
  warningDark: '#B78301',
  warningLight: '#FFF9EB',
  //Info
  info: '#08a5fe',
  infoDark: '#017DC2',
  infoLight: '#AEE2FF',
  // Success
  success: '#79CE00',
  successDark: '#66AD00',
  successLight: '#E6FFC5',
  // Background
  backgroundDark: '#efeff0',
  backgroundMedium: '#FCFCFC',
  backgroundLight: '#f7f7f7',
  backgroundWhite: '#fff',
  // custom components colors
  tag: '#ededf0',
  placeholderRegular: '#757575',
  transparent: 'transparent',
  banner: '#FCFAFF',
  bannerTop: '#EFEFF0',
  dark: '#222',
  light: '#fff',
  lighter: '#fff',
};

export default Colors;
export type ColorName = keyof typeof Colors;

export const TextColors: { [name in ColorName]: string } = {
  regular: '#222',
  regularLight: '#62646A',
  regularDark: '#C0C0C0',
  primary: '#fff',
  primaryDark: '#fff',
  primaryLight: '#FE630C',
  primaryVeryLight: '#FE630C',
  secondary: '#fff',
  secondaryDark: '#fff',
  secondaryLight: '#AB54FF',
  info: '#fff',
  infoDark: '#017DC2',
  infoLight: '#08A5FE',
  danger: '#fff',
  dangerDark: '#fff',
  dangerLight: '#F63131',
  success: '#fff',
  successDark: '#fff',
  successLight: '#79CE00',
  backgroundDark: '#282828',
  backgroundMedium: '#282828',
  backgroundLight: '#282828',
  backgroundWhite: '#282828',
  warning: '#282828',
  warningLight: '#FEBA0C',
  warningDark: '#fff',
  transparent: '#62646A',

  // custom components colors
  tag: '#222',
  placeholderRegular: '#757575',
  banner: '#222',
  bannerTop: '#222',
  dark: '#fff',
  light: '#222',
  lighter: '#818388',
};

export const BorderColors = {
  light: '#c0c0c0',
  lighter: '#DADADC',
};

export const ApplicationStatusAdminColors: {
  [name in MissionApplicationBadgeStatus]: ColorName;
} = {
  New: 'primary',
  Proposed: 'info',
  Rejected: 'dangerDark',
  ShortlistGood: 'secondary',
  ShortlistStrong: 'success',
  VettingScheduled: 'warning',
  NotAvailable: 'regular',
  /** @deprecated **/
  ProbablyNot: 'dangerLight',
  Exclusive: 'infoLight',
  OnHold: 'primaryLight',
  Accepted: 'infoDark',
  LowCompetitiveness: 'dangerLight',
  Interviewing: 'warningDark',
  OpportunityToUpdate: 'secondaryDark',
};

export const ApplicationStatusUserColors: Partial<{
  [name in ColorName]: string;
}> = {
  tag: '#EFEFF0',
  success: '#70C93C',
  successLight: '#D3F1C1',
  regular: '#EFEFF0',
  regularDark: '#B5BAB6',
  danger: '#F63131',
  dangerLight: '#FFDCDC',
  info: '#21ADFF',
  warning: '#FFDE6A',
};
