import { useStores } from '@src/stores';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './keys';
import { apiUser, apiUsers } from '@ateams/api';
import { skillToExpertise } from '@src/helpers/expertise';
import { Expertise } from '@src/stores/Profile/models';
import UserObject, {
  CalendarOptoutObject,
  CalendarSettings,
  CustomCalendarObject,
  UserId,
} from '@a_team/models/dist/UserObject';
import { sortBy } from 'lodash';
import { UserCV } from '@src/stores/Profile/Profile';

export type ExtendedUserObject = UserObject & {
  industryExperiences: {
    id: string;
    name: string;
    featured: boolean;
  }[];
  skills: {
    mainSkills: Expertise[];
    additionalSkills: Expertise[];
  };
  mainAndAdditionalSkills: Expertise[];
  allSkills: Expertise[];
};

export const useGetCVByUsername = (username?: string) => {
  const stores = useStores();
  const usernameToQuery = username || (stores.auth.username as string);

  return useQuery({
    queryKey: queryKeys.profile.byUsername(usernameToQuery),
    queryFn: () => {
      return apiUsers.getUserByUsername(stores.auth, usernameToQuery);
    },
    select: (data): UserCV | undefined =>
      data.cvURL && data.cvURL !== ''
        ? {
            label: `${data.firstName}'s Resume`,
            downloadUrl: data.cvURL,
          }
        : undefined,
  });
};

export const useSetCurrentUserCV = () => {
  const stores = useStores();
  const queryClient = useQueryClient();
  const username = stores.auth.username as string;

  return useMutation({
    mutationFn: async (cvURL: string | undefined) => {
      return apiUser.updateCV(stores.auth, { cvURL });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.profile.byUsername(username));
    },
  });
};

export const useGetProfileByUsername = (username?: string) => {
  const stores = useStores();
  const usernameToQuery = username || (stores.auth.username as string);
  return useQuery({
    queryKey: queryKeys.profile.byUsername(usernameToQuery),
    queryFn: () => {
      return apiUsers.getUserByUsername(stores.auth, usernameToQuery);
    },
    // add the industry experiences to user
    select: (data): ExtendedUserObject => {
      const talentProfile = data?.talentProfile;
      const talentIndustries =
        talentProfile?.talentIndustries?.experiences ?? [];
      const industryExperiences = talentIndustries.map(
        ({ talentIndustryId, talentIndustryName }) => ({
          id: talentIndustryId,
          name: talentIndustryName,
          featured: true,
        }),
      );

      const mainSkills =
        data.talentProfile?.talentSkills.mainTalentSkills.map((skill) =>
          skillToExpertise(skill, true),
        ) ?? [];

      const additionalSkills =
        data.talentProfile?.talentSkills.additionalTalentSkills.map((skill) =>
          skillToExpertise(skill),
        ) ?? [];

      const skills = {
        mainSkills: mainSkills,
        additionalSkills: additionalSkills,
      };

      const mainAndAdditionalSkills = [...mainSkills, ...additionalSkills].sort(
        (a, b) => a.name.localeCompare(b.name),
      );

      const allSkills = sortBy(
        [...skills.mainSkills, ...skills.additionalSkills],
        ['featured: desc', 'rating: desc'],
      ).map((s) => ({
        ...s,
        verifiedSkill:
          data.verifiedSkills?.find((vs) => vs === s.id) !== undefined,
      }));

      return {
        ...data,
        industryExperiences: industryExperiences,
        skills: skills,
        mainAndAdditionalSkills: mainAndAdditionalSkills,
        allSkills,
      };
    },
  });
};

export const useMutationUpdateTalentSpecializations = () => {
  const { auth } = useStores();
  return useMutation({
    mutationFn: async ({
      additionalSpecializationIds,
      mainRoleId,
    }: {
      additionalSpecializationIds: string[];
      mainRoleId: string | undefined;
    }) => {
      return await apiUser.updateTalentSpecializations(auth, {
        mainSpecializationId: mainRoleId,
        additionalSpecializationIds: additionalSpecializationIds,
      });
    },
  });
};

export const useMutationSetOptOutClientDiscovery = ({
  onSuccess,
}: {
  onSuccess?: (optedOut: boolean) => void;
}) => {
  const { auth } = useStores();
  return useMutation({
    mutationFn: async ({ optOut }: { optOut: boolean }) => {
      return await apiUser.setOptOutClientDiscovery(auth, optOut);
    },
    onSuccess: (optedOut) => {
      onSuccess && onSuccess(optedOut);
    },
  });
};

export const useMutationSetOptOutBuilderDiscovery = ({
  onSuccess,
}: {
  onSuccess?: (optedOut: boolean) => void;
}) => {
  const { auth } = useStores();
  return useMutation({
    mutationFn: async ({ optOut }: { optOut: boolean }) => {
      return await apiUser.setOptOutBuilderDiscovery(auth, optOut);
    },
    onSuccess: (optedOut) => {
      onSuccess && onSuccess(optedOut);
    },
  });
};

export const useGetUserCalendar = () => {
  const { auth } = useStores();

  return useQuery({
    queryKey: queryKeys.profile.calendar(auth.uid ?? ''),
    queryFn: () => {
      return apiUser.getUserCalendar(auth);
    },
  });
};

export const useMutationUpdateCalendarPreferences = () => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (preferences: { autoRecord: boolean }) => {
      return await apiUser.updateCalendarPreferences(auth, preferences);
    },
    onSuccess: (_, vars) => {
      const queryKey = queryKeys.profile.calendar(auth.uid ?? '');
      const currentData: CalendarSettings | undefined =
        queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, {
        ...currentData,
        calcom: {
          ...currentData?.calcom,
          ...vars,
        },
      });
    },
  });
};

export const useGetApplicantFlags = (uid?: UserId) => {
  const { auth } = useStores();

  return useQuery({
    enabled: !!uid,
    queryKey: queryKeys.profile.applicantFlags(uid || ''),
    queryFn: () => {
      return apiUsers.getApplicantFlags(auth, uid || '');
    },
  });
};

export const useMutationSaveCustomCalendar = () => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      data,
      onSuccess,
    }: {
      data: CustomCalendarObject;
      onSuccess?: () => void;
    }) => {
      return apiUser.saveCustomCalendar(auth, data);
    },
    onSuccess: (data: CalendarSettings, vars) => {
      if (data) {
        queryClient.setQueryData(
          queryKeys.profile.calendar(auth.uid ?? ''),
          data,
        );
      }
      vars.onSuccess && vars.onSuccess();
    },
  });
};

export const useMutationOptoutCalendar = () => {
  const { auth } = useStores();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      data,
      onSuccess,
    }: {
      data: CalendarOptoutObject;
      onSuccess?: () => void;
    }) => {
      return apiUser.calendarOptout(auth, data);
    },
    onSuccess: (data: CalendarSettings, vars) => {
      if (data) {
        queryClient.setQueryData(
          queryKeys.profile.calendar(auth.uid ?? ''),
          data,
        );
      }
      vars.onSuccess && vars.onSuccess();
    },
  });
};
