import { createQueryKeys } from '@lukemorales/query-key-factory';

export const experiencesKeys = createQueryKeys('experiences', {
  byUserId: (userId: string) => ({
    userId,
  }),
  hasPendingCollaboratorRequest: (userId: string, eid: string) => ({
    userId,
    eid,
  }),
});
