import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { Spacing, TextColors } from '@ateams/components';
import { RadioButton } from '@src/components/RadioGroup/RadioButton';
import { InputContainer } from '../components/input-container';
import { InputLabel } from '../components/input-label';
import {
  PreVettingFormClearErrors,
  PreVettingFormErrors,
  PreVettingFormValidators,
} from '../use-errors';
import { OutlinedInput } from '../components/outlined-input';
import fileIcon from './file.svg';

export enum CaseStudyType {
  URL = 'url',
  FILE = 'file',
}

export interface CaseStudy {
  caseStudyType: CaseStudyType;
  caseStudyRemoteUrl: string;
  caseStudyFile?: File;
  hadDesignedCaseStudyAsTeamOrIndividual: string;
}

export type OnCaseStudyChange = (caseStudy: Partial<CaseStudy>) => void;

export interface CaseStudyInputProps {
  defaultValues: CaseStudy;
  onChange: OnCaseStudyChange;
  formErrors: PreVettingFormErrors;
  validators: PreVettingFormValidators;
  clearErrors: PreVettingFormClearErrors;
  className?: string;
  'data-testing-id'?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginBottomXSmall: {
    marginBottom: Spacing.xsmall,
  },
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  inputDescription: {
    fontSize: '15px',
    lineHeight: '24px',
    marginBottom: Spacing.small,
  },
  textButton: {
    fontSize: '15px',
    fontWeight: 400,
    height: '24px',
    color: TextColors.primaryLight,
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
  },
  uploadCaseStudyRadioButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  uploadCaseStudyFileInfoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  uploadCaseStudyFileInfoNameContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '16px',
    marginBottom: '16px',
  },
  fileIcon: {
    width: '16px',
    height: '16px',
    marginRight: '4px',
  },
});

export const CaseStudyInput: React.FC<CaseStudyInputProps> = (props) => {
  const styles = useStyles();
  const { defaultValues, clearErrors, formErrors, validators } = props;
  const [caseStudyType, setCaseStudyType] = useState(
    defaultValues.caseStudyType,
  );
  const [caseStudyRemoteUrl, setCaseStudyRemoteUrl] = useState(
    defaultValues.caseStudyRemoteUrl,
  );
  const [caseStudyFile, setCaseStudyFile] = useState(
    defaultValues.caseStudyFile,
  );
  const [
    hadDesignedCaseStudyAsTeamOrIndividual,
    setHadDesignedCaseStudyAsTeamOrIndividual,
  ] = useState(defaultValues.hadDesignedCaseStudyAsTeamOrIndividual);

  const onCaseStudyTypeChange = (newCaseStudyType: CaseStudyType) => {
    if (newCaseStudyType === caseStudyType) {
      return;
    }

    setCaseStudyType(newCaseStudyType);
    clearErrors.clearCaseStudyErrors();
    props.onChange({ caseStudyType: newCaseStudyType });
  };

  const onCaseStudyRemoteUrlChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const caseStudyRemoteUrl = e.target.value;
    setCaseStudyRemoteUrl(caseStudyRemoteUrl);
    props.onChange({ caseStudyRemoteUrl });
  };

  const onCaseStudyUploadFileChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const file = e.target.files?.[0];

    if (file) {
      setCaseStudyFile(file);
      clearErrors.clearCaseStudyErrors();
      props.onChange({ caseStudyFile: file });
    }
  };

  const clearCaseStudyUploadFile = () => {
    setCaseStudyFile(undefined);
    props.onChange({ caseStudyFile: undefined });
  };

  const onHadDesignedCaseStudyAsTeamOrIndividualChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const hadDesignedCaseStudyAsTeamOrIndividual = e.target.value;
    setHadDesignedCaseStudyAsTeamOrIndividual(
      hadDesignedCaseStudyAsTeamOrIndividual,
    );
    props.onChange({ hadDesignedCaseStudyAsTeamOrIndividual });
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <InputLabel className={styles.marginBottomXSmall}>
        Share a link to your case study *
      </InputLabel>
      <div className={styles.inputDescription}>
        A case study should include a project description, your role, and
        workflow images (including sketches, versions, and wireframes, if it’s a
        product design) with short explanations and final results & conclusions
        of how your work has affected your client’s business. For instance,{' '}
        <a
          href={'https://readymag.com/hency/1205816/'}
          target="_blank"
          rel="noreferrer"
        >
          ‘Ambience app design’ by Hency
        </a>{' '}
        ,{' '}
        <a
          href={'https://robin-noguier.com/project/google/'}
          target="_blank"
          rel="noreferrer"
        >
          Robin Noguier - Google Express
        </a>
      </div>

      <RadioButton
        item={{ label: 'URL with a case study', value: CaseStudyType.URL }}
        onClick={() => onCaseStudyTypeChange(CaseStudyType.URL)}
        checked={caseStudyType === CaseStudyType.URL}
        data-testing-id={`${props['data-testing-id']}-remote-url-radio-button`}
      />
      {caseStudyType === CaseStudyType.URL && (
        <InputContainer
          error={formErrors.caseStudy.caseStudyRemoteUrl}
          className={styles.marginBottom}
        >
          <OutlinedInput
            value={caseStudyRemoteUrl}
            placeholder={'https://github.com/...'}
            onChange={onCaseStudyRemoteUrlChange}
            error={Boolean(formErrors.caseStudy.caseStudyRemoteUrl)}
            onBlur={() => validators.validateCaseStudy()}
            data-testing-id={`${props['data-testing-id']}-remote-url-input`}
          />
        </InputContainer>
      )}
      <InputContainer error={formErrors.caseStudy.caseStudyFile}>
        <div className={styles.uploadCaseStudyRadioButtonContainer}>
          <RadioButton
            item={{ label: 'Upload a case study', value: CaseStudyType.FILE }}
            onClick={() => onCaseStudyTypeChange(CaseStudyType.FILE)}
            checked={caseStudyType === CaseStudyType.FILE}
            data-testing-id={`${props['data-testing-id']}-file-radio-button`}
          />
          {caseStudyType === CaseStudyType.FILE && (
            <label className={styles.textButton} htmlFor="caseStudyUpload">
              <input
                type="file"
                onChange={onCaseStudyUploadFileChange}
                id="caseStudyUpload"
                hidden
                data-testing-id={`${props['data-testing-id']}-file-input`}
              />
              Upload file
            </label>
          )}
        </div>
        {caseStudyType === CaseStudyType.FILE && (
          <div>
            {caseStudyFile && (
              <div className={styles.uploadCaseStudyFileInfoContainer}>
                <div className={styles.uploadCaseStudyFileInfoNameContainer}>
                  <img src={fileIcon} className={styles.fileIcon} alt="" />
                  <div>{caseStudyFile.name}</div>
                </div>
                <button
                  className={styles.textButton}
                  onClick={clearCaseStudyUploadFile}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        )}
      </InputContainer>

      <InputContainer
        error={props.formErrors.hadDesignedCaseStudyAsTeamOrIndividual}
      >
        <InputLabel>
          Did you design a project from your case study as a part of the team or
          worked on it individually? *
        </InputLabel>

        <OutlinedInput
          value={hadDesignedCaseStudyAsTeamOrIndividual}
          onChange={onHadDesignedCaseStudyAsTeamOrIndividualChange}
          multiline
          error={Boolean(formErrors.hadDesignedCaseStudyAsTeamOrIndividual)}
          onBlur={() =>
            validators.validateHadDesignedCaseStudyAsTeamOrIndividual()
          }
          data-testing-id={`${props['data-testing-id']}-had-designed-case-study-as-team-or-individual-input`}
        />
      </InputContainer>
    </div>
  );
};
