import React, {
  ChangeEvent,
  CSSProperties,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import SearchMenu from '@src/components/SearchMenu';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import { Icon, IconType } from '@ateams/components';
import { useHistory } from 'react-router-dom';
import { Breakpoints } from '@ateams/components';
import { ProfileLocation } from '@src/locations';

interface Props {
  style?: CSSProperties;
  withMenu?: boolean;
  className?: string;
  submitted: boolean;
  query: string;
  setSubmitted: (submitted: boolean) => void;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = createUseStyles({
  searchBarContainer: {
    width: '100%',
    height: '54',
    display: 'flex',
    margin: 32,

    border: '1px solid #DADADC',
    background: '#FFF',
    boxShadow: '0px 11px 16px 0px rgba(46, 20, 80, 0.09)',
    borderRadius: 8,
  },
  searchInput: {
    margin: 0,
    width: '100%',
    '& > div:first-child': {
      border: 'none',
    },
  },
  searchButton: {
    cursor: 'pointer',
    background: 'white',
    height: '56px',
    width: '80px',
    borderTopRightRadius: '8px',
    flexShrink: 0,
    borderBottomRightRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), white',
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    searchBarContainer: {
      width: '744px',
      margin: 0,
    },
  },
});

const SearchInputV2: React.FC<Props> = (props) => {
  const styles = useStyles();
  const history = useHistory();
  const searchRef = useRef(null);
  const { search } = useStores();
  const { style, withMenu = true, className } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>();

  const highlightedItem = useMemo(() => {
    return highlightedIndex !== undefined
      ? search.totalSearchItems[highlightedIndex]
      : undefined;
  }, [highlightedIndex]);

  const redirectToUserProfile = (): void => {
    search.setUserResultsSummary();
    highlightedItem?.user &&
      history.push(ProfileLocation(highlightedItem.user.username));
  };

  const onInputEnterPress = () => {
    if (search.searchQuery) {
      props.setSubmitted(true);
      props.setQuery(search.searchQuery || '');
    }
    setMenuOpen(false);
    if (highlightedItem) {
      search.addSearchHistory(highlightedItem);
      search.onExistingSearchSelect(highlightedItem);
      if (highlightedItem.user) {
        redirectToUserProfile();
      }
      return;
    }
    search.setUserResults();
    search.fetchSearchResults(false);
  };

  const traverseList = (key: KeyboardEvent['key']) => {
    switch (key) {
      case 'ArrowDown':
        if (highlightedIndex === search.totalSearchItemsLength - 1) return;
        setHighlightedIndex(
          highlightedIndex !== undefined ? highlightedIndex + 1 : 0,
        );
        break;
      case 'ArrowUp':
        setHighlightedIndex((highlightedIndex || 1) - 1);
        break;
      default:
        return;
    }
  };

  return (
    <div
      className={cx(styles.searchBarContainer, className)}
      ref={searchRef}
      style={{ ...style }}
    >
      <OutlinedInput
        loading={search.loadingResults}
        borderRadius={'left'}
        value={search.searchQuery}
        placeholder="Search builders by name"
        className={styles.searchInput}
        onFocus={(): void => setMenuOpen(true)}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          if (e.target.value === '') {
            props.setSubmitted(false);
            props.setQuery('');
          }

          search.onSearchQueryChange(e.target.value);
          setHighlightedIndex(undefined);
        }}
        onKeyPress={(e): void =>
          e.key === 'Enter' ? onInputEnterPress() : undefined
        }
        onKeyDown={(e) => traverseList(e.key)}
      />
      <div
        className={styles.searchButton}
        onClick={(): void => search.fetchSearchResults(false)}
      >
        <Icon
          type={IconType.SearchPurple}
          size="small"
          className={styles.icon}
          clickable
        />
      </div>
      {menuOpen && withMenu && (
        <SearchMenu
          hideTags
          highlightedIndex={highlightedIndex}
          searchRef={searchRef}
          onClose={(): void => setMenuOpen(false)}
        />
      )}
    </div>
  );
};

export default observer(SearchInputV2);
