import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { TalentSkillId } from '@a_team/models/dist/TalentCategories';
import { AsyncSelectProps, Spacing, TextColors } from '@ateams/components';
import { RadioButton } from '@src/components/RadioGroup/RadioButton';
import { CodeSampleProgrammingLanguageSelector } from '@src/views/VettingDashboard/code-sample-programming-language-selector';
import { InputContainer } from '../components/input-container';
import { InputLabel } from '../components/input-label';
import {
  PreVettingFormClearErrors,
  PreVettingFormErrors,
  PreVettingFormValidators,
} from '../use-errors';
import { OutlinedInput } from '../components/outlined-input';
import fileIcon from './file.svg';

export enum CodeSampleType {
  URL = 'url',
  FILE = 'file',
}

export interface CodeSample {
  codeSampleProgrammingLanguages: TalentSkillId[];
  otherCodeSampleProgrammingLanguage: string;
  codeSampleType: CodeSampleType;
  codeSampleRemoteUrl: string;
  codeSampleFile?: File;
  codeSampleNotes: string;
}

export type OnCodeSampleChange = (codeSample: Partial<CodeSample>) => void;

export interface CodeSampleInputProps {
  defaultValues: CodeSample;
  onChange: OnCodeSampleChange;
  formErrors: PreVettingFormErrors;
  validators: PreVettingFormValidators;
  clearErrors: PreVettingFormClearErrors;
  className?: string;
  'data-testing-id'?: string;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginBottomXSmall: {
    marginBottom: Spacing.xsmall,
  },
  marginBottom: {
    marginBottom: Spacing.medium,
  },
  tagListClassName: {
    marginTop: 0,
  },
  input: {
    fontSize: '15px',
    margin: 0,
  },
  inputDescription: {
    fontSize: '15px',
    lineHeight: '24px',
    marginBottom: Spacing.small,
  },
  textButton: {
    fontSize: '15px',
    fontWeight: 400,
    height: '24px',
    color: TextColors.primaryLight,
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
  },
  uploadSampleCodeRadioButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  uploadSampleCodeFileInfoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  uploadSampleCodeFileInfoNameContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '16px',
    marginBottom: '16px',
  },
  fileIcon: {
    width: '16px',
    height: '16px',
    marginRight: '4px',
  },
});

export const CodeSampleInput: React.FC<CodeSampleInputProps> = (props) => {
  const styles = useStyles();
  const { defaultValues, clearErrors, formErrors, validators } = props;
  const [codeSampleProgrammingLanguages, setCodeSampleProgrammingLanguages] =
    useState<TalentSkillId[]>(defaultValues.codeSampleProgrammingLanguages);
  const [
    otherCodeSampleProgrammingLanguage,
    setOtherCodeSampleProgrammingLanguage,
  ] = useState(defaultValues.otherCodeSampleProgrammingLanguage);
  const [codeSampleType, setCodeSampleType] = useState(
    defaultValues.codeSampleType,
  );
  const [codeSampleRemoteUrl, setCodeSampleRemoteUrl] = useState(
    defaultValues.codeSampleRemoteUrl,
  );
  const [codeSampleFile, setCodeSampleFile] = useState(
    defaultValues.codeSampleFile,
  );
  const [codeSampleNotes, setCodeSampleNotes] = useState(
    defaultValues.codeSampleNotes,
  );

  const onCodeSampleProgrammingLanguagesChange: AsyncSelectProps<true>['onChange'] =
    (options) => {
      const codeSampleProgrammingLanguages =
        options?.map((option) => option.value) || [];
      setCodeSampleProgrammingLanguages(codeSampleProgrammingLanguages);
      validators.validateCodeSampleProgrammingLanguages(
        codeSampleProgrammingLanguages,
      );
      props.onChange({ codeSampleProgrammingLanguages });
    };

  const onOtherCodeSampleProgrammingLanguageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const otherCodeSampleProgrammingLanguage = e.target.value;
    setOtherCodeSampleProgrammingLanguage(otherCodeSampleProgrammingLanguage);
    props.onChange({ otherCodeSampleProgrammingLanguage });
  };

  const onCodeSampleTypeChange = (newCodeSampleType: CodeSampleType) => {
    if (newCodeSampleType === codeSampleType) {
      return;
    }

    setCodeSampleType(newCodeSampleType);
    clearErrors.clearCodeSampleErrors();
    props.onChange({ codeSampleType: newCodeSampleType });
  };

  const onCodeSampleRemoteUrlChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const codeSampleRemoteUrl = e.target.value;
    setCodeSampleRemoteUrl(codeSampleRemoteUrl);
    props.onChange({ codeSampleRemoteUrl });
  };

  const onCodeSampleUploadFileChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const file = e.target.files?.[0];

    if (file) {
      setCodeSampleFile(file);
      clearErrors.clearCodeSampleErrors();
      props.onChange({ codeSampleFile: file });
    }
  };

  const clearCodeSampleUploadFile = () => {
    setCodeSampleFile(undefined);
    props.onChange({ codeSampleFile: undefined });
  };

  const onCodeSampleNotesChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const codeSampleNotes = e.target.value;
    setCodeSampleNotes(codeSampleNotes);
    props.onChange({ codeSampleNotes });
  };

  return (
    <div className={cx(styles.container, props.className)}>
      <InputContainer
        error={formErrors.codeSampleProgrammingLanguages}
        className={styles.marginBottom}
      >
        <InputLabel>Select a Tech Stack for Your Code Sample *</InputLabel>

        <CodeSampleProgrammingLanguageSelector
          isMulti
          isClearable={false}
          placeholder={'Select skills...'}
          onChange={onCodeSampleProgrammingLanguagesChange}
          tagListClassName={cx({
            [styles.tagListClassName]: !codeSampleProgrammingLanguages?.length,
          })}
          className={styles.input}
          error={Boolean(formErrors.codeSampleProgrammingLanguages)}
          onBlur={() => validators.validateCodeSampleProgrammingLanguages()}
          data-testing-id={`${props['data-testing-id']}-programming-languages`}
        />
      </InputContainer>

      <InputContainer className={styles.marginBottom}>
        <InputLabel>Other Developer Skills Included (optional)</InputLabel>

        <OutlinedInput
          value={otherCodeSampleProgrammingLanguage}
          placeholder={'Other skills...'}
          onChange={onOtherCodeSampleProgrammingLanguageChange}
          data-testing-id={`${props['data-testing-id']}-other-programming-languages`}
        />
      </InputContainer>

      <InputLabel className={styles.marginBottomXSmall}>
        Share a Code Sample *
      </InputLabel>
      <div className={styles.inputDescription}>
        Provide a code sample that best showcases your work. It should be
        recent, have neat syntax, and be something you contributed significantly
        to. Alternatively, share a link to a live project (website/app) and be
        ready to discuss its architecture and tech stack.
      </div>

      <RadioButton
        item={{ label: 'URL with a sample code', value: CodeSampleType.URL }}
        onClick={() => onCodeSampleTypeChange(CodeSampleType.URL)}
        checked={codeSampleType === CodeSampleType.URL}
        data-testing-id={`${props['data-testing-id']}-remote-url-radio-button`}
      />
      {codeSampleType === CodeSampleType.URL && (
        <InputContainer
          error={formErrors.codeSample.codeSampleRemoteUrl}
          className={styles.marginBottom}
        >
          <OutlinedInput
            value={codeSampleRemoteUrl}
            placeholder={'https://github.com/...'}
            onChange={onCodeSampleRemoteUrlChange}
            error={Boolean(formErrors.codeSample.codeSampleRemoteUrl)}
            onBlur={() => validators.validateCodeSample()}
            data-testing-id={`${props['data-testing-id']}-remote-url-input`}
          />
        </InputContainer>
      )}
      <InputContainer error={formErrors.codeSample.codeSampleFile}>
        <div className={styles.uploadSampleCodeRadioButtonContainer}>
          <RadioButton
            item={{ label: 'Upload a sample code', value: CodeSampleType.FILE }}
            onClick={() => onCodeSampleTypeChange(CodeSampleType.FILE)}
            checked={codeSampleType === CodeSampleType.FILE}
            data-testing-id={`${props['data-testing-id']}-file-radio-button`}
          />
          {codeSampleType === CodeSampleType.FILE && (
            <label className={styles.textButton} htmlFor="codeSampleUpload">
              <input
                type="file"
                onChange={onCodeSampleUploadFileChange}
                id="codeSampleUpload"
                hidden
                data-testing-id={`${props['data-testing-id']}-file-input`}
              />
              Upload file
            </label>
          )}
        </div>
        {codeSampleType === CodeSampleType.FILE && (
          <div>
            {codeSampleFile && (
              <div className={styles.uploadSampleCodeFileInfoContainer}>
                <div className={styles.uploadSampleCodeFileInfoNameContainer}>
                  <img src={fileIcon} className={styles.fileIcon} alt="" />
                  <div>{codeSampleFile.name}</div>
                </div>
                <button
                  className={styles.textButton}
                  onClick={clearCodeSampleUploadFile}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        )}
      </InputContainer>

      <InputContainer>
        <InputLabel>Code Sample Notes (optional)</InputLabel>

        <OutlinedInput
          value={codeSampleNotes}
          onChange={onCodeSampleNotesChange}
          multiline
          data-testing-id={`${props['data-testing-id']}-notes`}
        />
      </InputContainer>
    </div>
  );
};
