import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from '@a_team/ui-components';
import { SelectOption, SelectProps, Spacing } from '@ateams/components';
import { AdminNotesScore } from '@a_team/models/dist/AdminNotesObject';
import { DateISOString } from '@a_team/models/dist/misc';
import { VettingProcessFeedbackScores } from '@a_team/models/dist/vetting-processes/feedback';
import {
  AccentScoreOptions,
  EnglishLevelOptions,
  ExpertiseScoreOptions,
  InteractionExperienceOptions,
} from '@src/components/ScoreInput/Scores';
import { Select } from '../components/select';
import { Card } from '../components/card';
import { DateInput, OnDateChange } from '../components/date-input';
import { VettingFeedbackFormErrors } from './form-errors';
import {
  CheckboxValueTag,
  CheckboxValueTagProps,
} from '../components/value-tag';
import { InputContainer } from '../components/input-container';

const useStyles = createUseStyles({
  inputsRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: Spacing.medium,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  inputContainer: {
    width: '200px',
    marginRight: Spacing.medium,
  },
  separator: {
    width: '100%',
    height: '1px',
    background: theme.colors.Grey[300],
    marginBottom: Spacing.medium,
  },
});

export interface VettingFeedbackFormMainInfo {
  interviewDate?: DateISOString;
  builderDidNotShowUp: boolean;
  scores?: Partial<VettingProcessFeedbackScores>;
}

export type OnVettingFeedbackFormMainInfoChange = (
  data: VettingFeedbackFormMainInfo,
) => void;

export interface VettingFeedbackFormMainInfoProps {
  errors: VettingFeedbackFormErrors['mainInfo'];
  defaultValues: VettingFeedbackFormMainInfo;
  onChange?: OnVettingFeedbackFormMainInfoChange;
  isReadOnly?: boolean;
  className?: string;
}

export function getScoreColor(score: number) {
  if (score >= 7) {
    return theme.colors.Green[200];
  }

  if (score >= 4) {
    return '#AEE2FF';
  }

  if (score >= 3) {
    return theme.colors.Baracus[200];
  }

  return theme.colors.Red[200];
}

export const VettingFeedbackFormMainInfoForm: React.FC<VettingFeedbackFormMainInfoProps> =
  React.memo((props) => {
    const { isReadOnly } = props;
    const styles = useStyles();
    const [interviewDate, setInterviewDate] = useState(
      props.defaultValues.interviewDate
        ? new Date(props.defaultValues.interviewDate)
        : undefined,
    );
    const [builderDidNotShowUp, setBuilderDidNotShowUp] = useState(
      props.defaultValues.builderDidNotShowUp,
    );
    const [expertise, setExpertise] = useState(
      props.defaultValues.scores?.expertise,
    );
    const [interactionExperience, setInteractionExperience] = useState(
      props.defaultValues.scores?.interactionExperience,
    );
    const [englishLevel, setEnglishLevel] = useState(
      props.defaultValues.scores?.englishLevel,
    );
    const [accent, setAccent] = useState(props.defaultValues.scores?.accent);

    const expertiseOption = useMemo(() => {
      if (typeof expertise !== 'number') {
        return null;
      }

      return ExpertiseScoreOptions.find(
        (option) => option.value === expertise,
      ) as unknown as SelectOption;
    }, [expertise]);

    const interactionExperienceOption = useMemo(() => {
      if (typeof interactionExperience !== 'number') {
        return null;
      }

      return InteractionExperienceOptions.find(
        (option) => option.value === interactionExperience,
      ) as unknown as SelectOption;
    }, [interactionExperience]);

    const englishLevelOption = useMemo(() => {
      if (typeof englishLevel !== 'number') {
        return null;
      }

      return EnglishLevelOptions.find(
        (option) => option.value === englishLevel,
      ) as unknown as SelectOption;
    }, [englishLevel]);

    const accentOption = useMemo(() => {
      if (typeof accent !== 'number') {
        return null;
      }

      return AccentScoreOptions.find(
        (option) => option.value === accent,
      ) as unknown as SelectOption;
    }, [accent]);

    const onChange = (data: Partial<VettingFeedbackFormMainInfo>) => {
      props.onChange?.({
        interviewDate: data.interviewDate || interviewDate?.toISOString(),
        builderDidNotShowUp:
          typeof data.builderDidNotShowUp === 'boolean'
            ? data.builderDidNotShowUp
            : builderDidNotShowUp,
        scores: {
          expertise:
            typeof data.scores?.expertise === 'number'
              ? data.scores.expertise
              : expertise,
          interactionExperience:
            typeof data.scores?.interactionExperience === 'number'
              ? data.scores.interactionExperience
              : interactionExperience,
          englishLevel:
            typeof data.scores?.englishLevel === 'number'
              ? data.scores.englishLevel
              : englishLevel,
          accent:
            typeof data.scores?.accent === 'number'
              ? data.scores.accent
              : accent,
        },
      });
    };

    const onInterviewDateChange: OnDateChange = (date) => {
      if (date) {
        setInterviewDate(date);
        onChange({ interviewDate: date.toISOString() });
      }
    };

    const onBuilderDidNotShowUpChange: CheckboxValueTagProps['onClick'] = (
      checked,
    ) => {
      setBuilderDidNotShowUp(checked);
      onChange({ builderDidNotShowUp: checked });
    };

    const onExpertiseChange: SelectProps['onChange'] = (option) => {
      if (option) {
        const expertise = parseInt(option.value) as AdminNotesScore;

        setExpertise(expertise);
        onChange({ scores: { expertise } });
      }
    };

    const onInteractionExperienceChange: SelectProps['onChange'] = (option) => {
      if (option) {
        const interactionExperience = parseInt(option.value) as AdminNotesScore;

        setInteractionExperience(interactionExperience);
        onChange({ scores: { interactionExperience } });
      }
    };

    const onEnglishLevelChange: SelectProps['onChange'] = (option) => {
      if (option) {
        const englishLevel = parseInt(option.value) as AdminNotesScore;

        setEnglishLevel(englishLevel);
        onChange({ scores: { englishLevel } });
      }
    };

    const onAccentChange: SelectProps['onChange'] = (option) => {
      if (option) {
        const accent = parseInt(option.value) as AdminNotesScore;

        setAccent(accent);
        onChange({ scores: { accent } });
      }
    };

    return (
      <Card title={'Interview observations'} className={props.className}>
        <div className={styles.inputsRow}>
          <DateInput
            className={styles.inputContainer}
            label={isReadOnly ? 'Interview date' : 'Interview date *'}
            selected={interviewDate}
            onChange={onInterviewDateChange}
            isClearable={false}
            error={props.errors.interviewDate}
            isReadOnly={isReadOnly}
          />

          {!isReadOnly && (
            <InputContainer label={'Attendance'}>
              <CheckboxValueTag
                label={'Builder didn’t show up'}
                checked={builderDidNotShowUp}
                onClick={onBuilderDidNotShowUpChange}
                isReadOnly={isReadOnly}
                data-testing-id={
                  'vetting-feedback-form-builder-did-not-show-up'
                }
              />
            </InputContainer>
          )}
        </div>

        <div className={styles.separator} />

        <div className={styles.inputsRow}>
          <Select
            className={styles.inputContainer}
            label={isReadOnly ? 'Expertise level' : 'Expertise level *'}
            value={expertiseOption}
            onChange={onExpertiseChange}
            isClearable={false}
            hideSelectedOptions={false}
            options={ExpertiseScoreOptions as unknown as SelectOption[]}
            color={
              expertiseOption
                ? getScoreColor(parseInt(expertiseOption.value, 10))
                : undefined
            }
            placeholder={'Select'}
            error={props.errors.scores.expertise}
            isReadOnly={isReadOnly}
            menuPortalTarget={document.body}
          />

          <Select
            className={styles.inputContainer}
            label={
              isReadOnly ? 'Interpersonal skills' : 'Interpersonal skills *'
            }
            value={interactionExperienceOption}
            onChange={onInteractionExperienceChange}
            isClearable={false}
            hideSelectedOptions={false}
            options={InteractionExperienceOptions as unknown as SelectOption[]}
            color={
              interactionExperienceOption
                ? getScoreColor(parseInt(interactionExperienceOption.value, 10))
                : undefined
            }
            placeholder={'Select'}
            error={props.errors.scores.interactionExperience}
            isReadOnly={isReadOnly}
            menuPortalTarget={document.body}
          />

          <Select
            className={styles.inputContainer}
            label={isReadOnly ? 'English proficiency' : 'English proficiency *'}
            value={englishLevelOption}
            onChange={onEnglishLevelChange}
            isClearable={false}
            hideSelectedOptions={false}
            options={EnglishLevelOptions as unknown as SelectOption[]}
            color={
              englishLevelOption
                ? getScoreColor(parseInt(englishLevelOption.value, 10))
                : undefined
            }
            placeholder={'Select'}
            error={props.errors.scores.englishLevel}
            isReadOnly={isReadOnly}
            menuPortalTarget={document.body}
          />

          <Select
            className={styles.inputContainer}
            label={
              isReadOnly ? 'English pronunciation' : 'English pronunciation *'
            }
            value={accentOption}
            onChange={onAccentChange}
            isClearable={false}
            hideSelectedOptions={false}
            options={AccentScoreOptions as unknown as SelectOption[]}
            color={
              accentOption
                ? getScoreColor(parseInt(accentOption.value, 10))
                : undefined
            }
            placeholder={'Select'}
            error={props.errors.scores.accent}
            isReadOnly={isReadOnly}
            menuPortalTarget={document.body}
          />
        </div>
      </Card>
    );
  });
