import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import Modal, { ModalBasicProps } from '..';
import SectionHeading from '@src/components/SectionHeading';
import { Button as CallToActionButton } from '@ateams/components';
import { Expertise } from '@src/stores/Profile/models';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import { useScreenClass } from 'react-grid-system';
import ExpertiseSelector from '@src/components/ExpertiseSelector';
import SuggestionsBanner from '@src/components/SuggestionsBanner/SuggestionsBanner';

interface Props extends ModalBasicProps {
  title?: string;
  isLoading?: boolean;
  onSubmit: (items: Expertise[]) => void;
  onSearch: (query: string) => Promise<Expertise[]>;
  selectedItems?: Expertise[];
  placeholder?: string;
  description?: ReactNode;
  buttonText?: string;
  displayType?: 'score' | 'tags';
  singleSelection?: boolean;
  required?: boolean;
  featuredLimit?: number;
  defaultFeatured?: boolean;
  suggestedType?: 'skills' | 'industries' | 'roles';
  hideSearch?: boolean;
  blockSkillsWhenNotRated?: boolean;
  closeMenuOnSelect?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
}

const defaultProps = {
  displayType: 'score',
  singeSelection: false,
};

const useStyles = createUseStyles<{
  description?: ReactNode;
}>({
  modal: {
    display: 'flex',
    maxWidth: 745,
  },
  title: {
    fontSize: 17,
  },
  mobileDescription: {
    display: 'block',
  },
  legend: {},
  description: { display: 'none' },
  main: { width: '100%' },
  button: {
    height: 40,
    padding: 0,
    // change bg color to orange when disabled
    '&:disabled': {
      backgroundColor: '#FFCBAE',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '60% !important',
      padding: '0px !important',
    },
    title: {
      fontSize: 28,
    },
    main: {
      minWidth: '50%',
      flex: 1,
      padding: '24px 24px 0 24px',
      background: (props) => (props.description ? '#F7F7F7' : '#FFFFFF'),
    },
    mobileDescription: {
      display: 'none',
    },
    description: {
      display: 'block',
      marginTop: 55,
      marginBottom: 40,
    },
    legend: {
      borderTop: '1px solid #DADADC',
      width: '45%',
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
});

const ExpertiseModal = (props: Props): ReactElement => {
  const styles = useStyles({
    description: props.description,
  });
  const screenClass = useScreenClass();
  const {
    onClose,
    onSubmit,
    onSearch,
    title,
    selectedItems,
    description,
    placeholder,
    buttonText,
    displayType,
    singleSelection,
    required,
    featuredLimit,
    defaultFeatured,
    suggestedType,
    hideSearch,
    isLoading,
    blockSkillsWhenNotRated,
    closeMenuOnSelect,
    ...left
  } = props;

  const [localItems, setLocalItems] = useState<Expertise[]>(
    selectedItems || [],
  );

  const featuredItems = useMemo(() => {
    return localItems.filter((item) => item.featured);
  }, [localItems]);

  const withinFeaturedLimit = useMemo(() => {
    return featuredLimit ? featuredItems.length <= featuredLimit : true;
  }, [featuredItems, featuredLimit]);

  const handleSubmit = useCallback(() => {
    const filteredItems = localItems.filter((item) =>
      displayType === 'score' ? item.rating !== undefined : item.featured,
    );
    setLocalItems(filteredItems);
    onSubmit(filteredItems);
  }, [localItems]);

  return (
    <Modal
      onClose={onClose}
      hideCloseButton={false}
      className={styles.modal}
      style={{
        flexDirection: description && screenClass !== 'xs' ? 'row' : 'column',
      }}
      {...left}
    >
      <div
        className={styles.main}
        style={
          suggestedType === 'roles' ? { background: '#FFFFFF' } : undefined
        }
      >
        {title && (
          <>
            {suggestedType && (
              <SuggestionsBanner
                title={`Adding suggested ${suggestedType}`}
                description={`These ${suggestedType} are suggested based on your LinkedIn
                profile.`}
              />
            )}
            <SectionHeading className={styles.title} isFirst>
              {title}
            </SectionHeading>
          </>
        )}
        {displayType === 'score' && (
          <p className={styles.mobileDescription}>
            Tell us how familiar you are with given skills, using between 1 (No
            experience) and 5 (Expert).
          </p>
        )}
        <ExpertiseSelector
          closeMenuOnSelect={closeMenuOnSelect}
          limitAnswersHeight={!(suggestedType === 'roles')}
          hideSearch={hideSearch}
          suggestedType={suggestedType}
          onSearch={onSearch}
          onChange={setLocalItems}
          selectedItems={selectedItems}
          displayType={displayType}
          singleSelection={singleSelection}
          defaultFeatured={defaultFeatured}
          placeholder={placeholder}
          disableFeatured={!withinFeaturedLimit}
          blockSkillsWhenNotRated={blockSkillsWhenNotRated}
        />
      </div>
      <div
        className={styles.legend}
        style={{ ...(!description && { width: '100%' }) }}
      >
        {description && <div className={styles.description}>{description}</div>}
        {/* {if there's items with undefined show error message saying "Rate newly added skills before saving to profile."} */}
        {blockSkillsWhenNotRated && displayType === 'score' && (
          <div>
            <p
              style={{
                color: '#F63131',
                fontSize: 12,
                marginTop: 0,
                visibility: localItems.some((item) => !item.rating)
                  ? 'visible'
                  : 'hidden',
              }}
            >
              Rate newly added skills before saving to profile.
            </p>
          </div>
        )}
        <CallToActionButton
          className={styles.button}
          loading={isLoading}
          style={{ alignSelf: 'flex-end' }}
          width={description ? 'expend' : 'small'}
          onClick={handleSubmit}
          disabled={
            (required && featuredItems.length === 0) ||
            (suggestedType === 'roles' && localItems.length === 0) ||
            (blockSkillsWhenNotRated &&
              displayType === 'score' &&
              localItems.some((item) => !item.rating))
          }
        >
          {buttonText || 'Add'}
          {localItems &&
            suggestedType === 'skills' &&
            ` (${
              localItems.filter(
                (item) => displayType === 'score' && item.rating !== undefined,
              ).length
            })`}
        </CallToActionButton>
      </div>
    </Modal>
  );
};

ExpertiseModal.defaultProps = defaultProps;
export default ExpertiseModal;
