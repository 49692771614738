import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cx from 'classnames';
import FileDropArea from '@src/components/FileDropArea';
import { FileRejection, FileWithPath } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import * as uploadcareApi from '@src/logic/uploadcare';
import { UploadingView } from '@src/components/FileUploader/UploadingView';
import { EmptyView } from '@src/components/FileUploader/EmptyView';
import { FileSelectionButtons } from '@src/components/FileUploader/components/FileSelectionButtons';
import ImageCropModal from '@src/components/Modal/ImageCropModal';
import { Crop } from 'react-image-crop';
import { getCroppedImg, onImageLoad } from '@src/helpers/images';
import { fetchRandomCoverImage } from '@src/logic/unsplash';
import { Breakpoints, Colors } from '@ateams/components';
import MultipleImagesArrows from '@src/views/Profile/Main/Projects/MultipleImages/MultipleImagesArrows';
import PreviewImagesUnderneath from './components/PreviewImagesUnderneath';
import ImageCropInline from './components/ImageCropInline';
import ImageOptions from './components/ImageOptions';
import { useScreenClass } from 'react-grid-system';

export type UploaderTypes = 'cover' | 'default' | undefined;

export interface FileUploaderProps {
  fileUrl?: string;
  type?: UploaderTypes;
  onRemoveImage?: () => void;
  onFileUploadSuccess?: (fileUrl: string) => void;
  cropAspect?: number; // e.g 16 / 9
  buttonsPosition?: 'bottom' | 'right';
  style?: CSSProperties;
  withOptionBox?: boolean;
  withCrop?: boolean;
  downscale?: { width: number; height: number };
  className?: string;
  fileDropAreaClassName?: string;
  circularCrop?: boolean;
  backgroundSize: 'contain' | 'cover';
  hints?: string[];
  currentImage?: string;
  next?: () => void;
  prev?: () => void;
  setCurrentImage?: (image: string) => void;
  onAddImage?: (newImageUrl: string) => void;
  images?: string[];
  setImages?: (images: string[]) => void;
  onSetCoverImage?: () => void;
  getIsCoverImage?: () => boolean;
  inlineCropping?: boolean;
  previewImagesUnderneath?: boolean;
  maxFileSize?: number;
  minFileDimensions?: {
    width: number; // in pixels
    height: number; // in pixels
  };
  setIsCropping?: (isCropping: boolean) => void;
  customButtons?: ReactElement;
}

const defaultProps: Partial<FileUploaderProps> = {
  type: 'default',
  withCrop: true,
  withOptionBox: true,
  circularCrop: true,
  buttonsPosition: 'bottom',
  backgroundSize: 'cover',
};

interface StyleProps extends FileUploaderProps {
  isDragging: boolean;
  url: string;
}

const useStyles = createUseStyles({
  root: (props: StyleProps) => ({
    height: '100%',
    position: 'relative',
    zIndex: props.type === 'cover' ? 99 : 9,
  }),
  container: (props: StyleProps) => ({
    border: props.inlineCropping ? '1px solid #DADADC' : 'none',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: props.type === 'cover' ? 0 : 8,
    background: props.isDragging ? '#FCFAFF' : '#F3F3F3',
    padding: 50,
    ...(props.type === 'cover' && {
      background: props.url
        ? `url(${props.url}) center center / ${props.backgroundSize} no-repeat`
        : props.isDragging
        ? '#FCFAFF'
        : '#F7F7F7',
      padding: 0,
    }),
    transition: 'all 0.5s',
  }),
  logoImage: {
    width: 'auto',
    maxWidth: '80%',
  },
  circular: {
    maxWidth: 'unset',
    borderRadius: '50%',
    width: '100%',
    height: '100%',
  },
  error: {
    color: Colors.danger,
    fontSize: 14,
    marginBottom: 5,
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    root: (props: StyleProps) => ({
      height: '100%',
    }),
    container: {
      justifyContent: 'flex-start',
    },
  },

  [`@media (min-width: ${Breakpoints.sm}px) and (max-width: ${Breakpoints.md}px)`]:
    {
      container: (props: StyleProps) => ({
        justifyContent: 'flex-start',
      }),
    },
});

export const ERROR_MAP: { [errorCode: string]: string } = {
  'file-invalid-type':
    'File type must be an image (Supported: PNG, JPG, GIF, SVG)',
  'file-too-large': 'Your file is too large, Please upload a smaller file',
  'general-error':
    "Hmm. We weren't able to upload your image. Try again. Or upload another image.",
  'file-dimensions': 'Please make sure your photo is at least 1000x1000px.',
};
const SVG_TYPE = 'image/svg+xml';

const FileUploader = (props: FileUploaderProps): ReactElement => {
  const {
    type,
    buttonsPosition,
    style,
    withOptionBox,
    withCrop,
    circularCrop,
    onRemoveImage,
    backgroundSize,
    currentImage,
    setCurrentImage,
    next,
    prev,
    images,
    setImages,
    onSetCoverImage,
    getIsCoverImage,
    inlineCropping,
    previewImagesUnderneath,
    maxFileSize,
    setIsCropping,
    fileDropAreaClassName,
    customButtons,
  } = props;
  // Files & urls
  const [displayedUrl, setDisplayedUrl] = useState<string | undefined>();

  const [unsplashUrl, setUnsplashUrl] = useState<string | undefined>();
  const [croppedImage, setCroppedImage] = useState<Blob | undefined>();
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPath[]>();
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  // Uploader states
  const [isDragging, setIsDragging] = useState(false);
  const [errors, setErrors] = useState<string[] | undefined>();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const screenClass = useScreenClass();

  const isImageAvailable = !!(props.fileUrl || displayedUrl || currentImage);
  const showButtons = errors || (isImageAvailable && !uploading && !isDragging);
  const showLogoImage =
    (!images || images?.length === 0) && showButtons && !errors;
  const showEmptyView =
    (isDragging || !isImageAvailable || errors) && !uploading;
  const isMobile = screenClass === 'xs' || screenClass === 'sm';

  // Cropping
  const [cropModalShown, setCropModalShown] = useState(false);
  const [imageRef, setImageRef] = useState<HTMLImageElement>();

  const styles = useStyles({
    isDragging,
    url: currentImage || displayedUrl || unsplashUrl || props.fileUrl || '',
    type,
    backgroundSize,
    inlineCropping,
  });

  const getMeta = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve: (image: HTMLImageElement) => void, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = url;
    });
  };

  const onFileDrop = async (
    acceptedFiles: FileWithPath[],
    rejectedFiles: FileRejection[],
  ): Promise<void> => {
    setErrors(undefined);
    setIsDragging(false);
    let tooSmall = false;

    if (acceptedFiles.length) {
      if (props.minFileDimensions) {
        const img = await getMeta(URL.createObjectURL(acceptedFiles[0]));
        if (
          (props.minFileDimensions &&
            img.naturalHeight < props.minFileDimensions.height) ||
          (props.minFileDimensions &&
            img.naturalWidth < props.minFileDimensions.width)
        ) {
          tooSmall = true;
        }
      }

      if (tooSmall) {
        setErrors([ERROR_MAP['file-dimensions']]);
        return;
      }

      if (acceptedFiles[0].type === SVG_TYPE || !withCrop) {
        setErrors(undefined);
        setAcceptedFiles(acceptedFiles);
        const config =
          acceptedFiles[0].type === SVG_TYPE ? { type: SVG_TYPE } : undefined;
        setUploading(true);
        const blob = new Blob([acceptedFiles[0]], config);
        setCroppedImage(blob);
        setDisplayedUrl(URL.createObjectURL(blob));
      } else {
        startCrop(acceptedFiles);
      }
    }

    if (rejectedFiles.length) {
      setRejectedFiles(rejectedFiles);
    }
  };

  const startCrop = (files: FileWithPath[]) => {
    if (files) {
      setErrors(undefined);
      setAcceptedFiles(files);
      const config =
        files[0].type === SVG_TYPE ? { type: SVG_TYPE } : undefined;
      setCroppedImage(new Blob([files[0]], config));
      setCropModalShown(true);
    }
  };

  const disregardCropping = () => {
    setCropModalShown(false);
  };

  const onCropComplete = async (crop: Crop) => {
    if (imageRef) {
      const updatedCroppedImg = await getCroppedImg(
        imageRef,
        crop,
        '',
        acceptedFiles && acceptedFiles[0].type,
      );
      if (updatedCroppedImg) {
        setCroppedImage(updatedCroppedImg);
        setCropModalShown(false);
        setUploading(true);
        setDisplayedUrl(URL.createObjectURL(updatedCroppedImg));
      }
    }
  };

  const uploadFile = (): void => {
    if (!acceptedFiles || !uploadcareApi.uploadFile || !croppedImage) {
      return;
    }

    const file = acceptedFiles[0];
    uploadcareApi
      .uploadFile(
        new File([croppedImage], file.name, { type: acceptedFiles[0].type }),
        {
          onProgress: (loaded) =>
            setProgress((loaded * 100) / croppedImage.size),
          preview: props.downscale,
        },
      )
      .then(async (url) => {
        onImageLoad(url, () => {
          onUploadComplete(url);
        });
      })
      .catch(() => {
        onUploadError();
      });
  };

  const uploadFromUrl = (url: string): void => {
    if (!uploadcareApi.uploadFileFromUrl) {
      return;
    }

    setUploading(true);
    setProgress(0);

    uploadcareApi
      .uploadFileFromUrl(
        url,
        (loaded, total) =>
          total && loaded && setProgress((loaded * 100) / total),
      )
      .then((newUrl) => {
        onUploadComplete(newUrl);
      })
      .catch(() => {
        onUploadError();
      });
  };

  const onUploadComplete = (url: string) => {
    setUploading(false);
    setProgress(0);
    props.onFileUploadSuccess && props.onFileUploadSuccess(url);
  };

  const onUploadError = () => {
    setUploading(false);
    setProgress(0);
    setErrors([ERROR_MAP['general-error']]);
  };

  const onRandomSelect = () => {
    setUploading(true);
    fetchRandomCoverImage()
      .then((res) => {
        onImageLoad(res.imageURLs.desktop, () =>
          onUnsplashImageSelect(res.imageURLs.desktop),
        );
      })
      .catch(() => onUploadError());
  };

  const onUnsplashImageSelect = (url: string) => {
    setUnsplashUrl(url);
    setDisplayedUrl(undefined);
    props.onFileUploadSuccess && props.onFileUploadSuccess(url);
    setUploading(false);
  };

  const croppedImageUrl = useMemo(() => {
    return croppedImage ? URL.createObjectURL(croppedImage) : null;
  }, [croppedImage]);

  useEffect(() => {
    if (setIsCropping) {
      setIsCropping(
        !!((inlineCropping && croppedImageUrl && cropModalShown) || uploading),
      );
    }
  }, [
    setIsCropping,
    inlineCropping,
    croppedImageUrl,
    cropModalShown,
    uploading,
  ]);

  useEffect(() => {
    if (displayedUrl && displayedUrl !== props.fileUrl) {
      uploadFile();
    }
  }, [displayedUrl]);

  useEffect(() => {
    props.fileUrl ? setDisplayedUrl(props.fileUrl) : setDisplayedUrl(undefined);
  }, [props.fileUrl]);

  useEffect(() => {
    if (images?.length === 0) {
      setDisplayedUrl(undefined);
    }
  }, [images?.length]);

  useEffect(() => {
    rejectedFiles.forEach((rejection) => {
      setErrors(
        rejection.errors.map(
          (error) => ERROR_MAP[error.code] || ERROR_MAP['general-error'],
        ),
      );
    });
  }, [rejectedFiles]);

  if (inlineCropping && croppedImageUrl && cropModalShown) {
    return (
      <ImageCropInline
        src={croppedImageUrl}
        onCropSubmit={onCropComplete}
        disregardCropping={disregardCropping}
        onImageLoaded={setImageRef}
        circularCrop={type === 'default' && circularCrop}
        aspect={props.cropAspect}
      />
    );
  }

  return (
    <div className={cx(styles.root, props.className)} style={style}>
      {previewImagesUnderneath &&
        images &&
        images.length > 0 &&
        getIsCoverImage &&
        onSetCoverImage &&
        onRemoveImage && (
          <ImageOptions
            images={images}
            getIsCoverImage={getIsCoverImage}
            onSetCoverImage={onSetCoverImage}
            onRemoveImage={onRemoveImage}
          />
        )}

      {croppedImageUrl && (
        <ImageCropModal
          src={croppedImageUrl}
          open={cropModalShown}
          onCropSubmit={onCropComplete}
          onClose={() => setCropModalShown(false)}
          onImageLoaded={setImageRef}
          circularCrop={type === 'default' && circularCrop}
          aspect={props.cropAspect}
        />
      )}

      {images && images.length > 1 && next && prev && (
        <MultipleImagesArrows next={next} prev={prev} />
      )}

      <FileDropArea
        onFileDrag={setIsDragging}
        onFileDrop={onFileDrop}
        disabled={uploading}
        maxFileSize={maxFileSize}
      >
        {({ openDialog }): ReactElement => (
          <>
            <div
              className={cx(styles.container, fileDropAreaClassName, {
                error: errors && errors.length > 0,
              })}
            >
              {uploading && (
                <UploadingView
                  progress={progress}
                  type={type}
                  radius={isMobile ? 50 : 60}
                />
              )}
              {showLogoImage && (
                <img
                  alt="logo"
                  src={props.fileUrl || displayedUrl}
                  className={cx(styles.logoImage, {
                    [styles.circular]: circularCrop,
                  })}
                />
              )}

              {showEmptyView && (
                <EmptyView
                  errors={errors}
                  isDragging={isDragging}
                  type={type}
                  hints={props.hints}
                  openDialog={openDialog}
                  onUrlUpload={uploadFromUrl}
                />
              )}
            </div>
            {showButtons &&
              (customButtons ? (
                React.cloneElement(customButtons, { openDialog, errors })
              ) : (
                <FileSelectionButtons
                  disabled={uploading}
                  position={buttonsPosition}
                  openDialog={openDialog}
                  withOptionsBox={withOptionBox}
                  onUrlUpload={uploadFromUrl}
                  onUnsplashSelect={(url) => onUnsplashImageSelect(url)}
                  onRandomClick={type === 'cover' ? onRandomSelect : undefined}
                  withImageSearch={type === 'cover'}
                  isImageAvailable={isImageAvailable}
                  onRemoveImage={onRemoveImage}
                  onSetCoverImage={onSetCoverImage}
                  getIsCoverImage={getIsCoverImage}
                />
              ))}
            {previewImagesUnderneath &&
              images &&
              currentImage &&
              setCurrentImage && (
                <>
                  {errors && (
                    <div className={styles.errors}>
                      {errors.map((error) => (
                        <span key={error} className={styles.error}>
                          {error}
                        </span>
                      ))}
                    </div>
                  )}
                  <PreviewImagesUnderneath
                    images={images}
                    setImages={setImages}
                    currentImage={currentImage}
                    setCurrentImage={setCurrentImage}
                    openDialog={openDialog}
                  />
                </>
              )}
          </>
        )}
      </FileDropArea>
    </div>
  );
};

FileUploader.defaultProps = defaultProps;

export default FileUploader;
